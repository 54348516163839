import React, { useState, useEffect } from "react";
import { Grid, Box, Text, Flex, Separator } from "@radix-ui/themes";
import { BrowseUnifiIntegrations } from "./unifi/BrowseUnifiIntegrations";
import { BrowseBconnectIntegrations } from "./bconnect/BrowseBconnectIntegrations";
import { useProfileContext } from "context";
import { BrowseMailchimpIntegration } from "./mailchimp/BrowseMailchimpIntegration";
import { BrowseUnifiCard } from "./unifi/BrowseUnifiCard";
import { BrowseIntegrateCard } from "components";
import { BrowseMailchimpCard } from "./mailchimp/BrowseMailchimpCard";
import { useGetActiveIntegrations, useCheckCiscoIntegration } from "hooks";
import { BrowseAirshipCard } from "./airship/BrowseAirshipCard";

export default function BrowseIntegrations() {
  const { userProfile } = useProfileContext();

  const [openDialog, setOpenDialog] = React.useState({
    auth: false,
    detail: false,
  });

  const handleOpenChange = (dialog, isOpen) => {
    setOpenDialog((prev) => ({ ...prev, [dialog]: isOpen }));
  };

  const { data: activeIntegrationsData } = useGetActiveIntegrations(userProfile.organisation_id);
  const [integrationStatus, setIntegrationStatus] = useState("contact-support");

  useEffect(() => {
    const isActive = isBconnectActive();
    setIntegrationStatus(isActive ? "active" : "contact-support");
  }, [activeIntegrationsData]);

  const isBconnectActive = () => {
    if (!activeIntegrationsData?.data?.integrations) return false;
    return activeIntegrationsData.data.integrations.some((integration) => integration.name === "bconnect");
  };

  const [ciscoMerakiStatus, setCiscoMerakiStatus] = useState("contact-support");

  const { data: ciscoIntegrationData } = useCheckCiscoIntegration(userProfile.organisation_id);

  useEffect(() => {
    const isCiscoActive = ciscoIntegrationData?.data?.is_active;
    setCiscoMerakiStatus(isCiscoActive ? "active" : "contact-support");
  }, [ciscoIntegrationData]);

  return (
    <Box style={{ width: "100%", overflowY: "auto", maxHeight: "calc(100vh - 184px)" }}>
      <Flex direction="column" gap="5">
        <Text size="4" weight="medium">
          Wi-Fi & Presence
        </Text>
        <Grid columns="1fr 1fr" gap="5" rows="repeat(3)" width="100%">
          {/* <BrowseBconnectIntegrations userProfile={userProfile} /> */}
          {/* <BrowseUnifiIntegrations userProfile={userProfile}/> This is old implementation */}
          {/* <BrowseMailchimpIntegration userProfile={userProfile}/> This is old implementation */}
          <BrowseIntegrateCard.Root name="b connect Wi-Fi" subtitle="Wi-Fi" image_url="favicon-light.png" status={integrationStatus} openDialog={openDialog} handleOpenChange={handleOpenChange}>
            <BrowseIntegrateCard.AuthDialog
              dialog_description="The URL could be a local address (e.g., https://192.168.1.100:8443), a remote server (e.g., https://controller.mybusiness.com:8443), or UniFi Cloud (https://unifi.ui.com)."
              onCancel={() => handleOpenChange("auth", false)}
            />
          </BrowseIntegrateCard.Root>
          <BrowseUnifiCard userProfile={userProfile} />
          {/* <BrowseIntegrateCard.Root
        name="UniFi"
        subtitle="Wi-Fi"
        image_url="unifi.png"
        status="contact-support"
        openDialog={openDialog}
        handleOpenChange={handleOpenChange}
      >
        <BrowseIntegrateCard.AuthDialog
          dialog_description="The URL could be a local address (e.g., https://192.168.1.100:8443), a remote server (e.g., https://controller.mybusiness.com:8443), or UniFi Cloud (https://unifi.ui.com)."
          onCancel={() => handleOpenChange("auth", false)}
        />
      </BrowseIntegrateCard.Root> */}
          <BrowseIntegrateCard.Root name="Cambium Networks" subtitle="Wi-Fi" image_url="cambium-networks.png" status="contact-support" openDialog={openDialog} handleOpenChange={handleOpenChange}>
            <BrowseIntegrateCard.AuthDialog dialog_description="Cisco Meraki integration is coming soon" onCancel={() => handleOpenChange("auth", false)} />
          </BrowseIntegrateCard.Root>

          <BrowseIntegrateCard.Root
            name="Cisco Meraki"
            subtitle="Wi-Fi and Presence analytics"
            image_url="cisco.png"
            status={ciscoMerakiStatus}
            openDialog={openDialog}
            handleOpenChange={handleOpenChange}
          >
            <BrowseIntegrateCard.AuthDialog dialog_description="Cisco Meraki integration is coming soon" onCancel={() => handleOpenChange("auth", false)} />
          </BrowseIntegrateCard.Root>

          <BrowseIntegrateCard.Root
            name="Aruba Enterprise"
            subtitle="Wi-Fi and Presence analytics"
            image_url="aruba.png"
            status="contact-support"
            openDialog={openDialog}
            handleOpenChange={handleOpenChange}
          >
            <BrowseIntegrateCard.AuthDialog dialog_description="Cisco Meraki integration is coming soon" onCancel={() => handleOpenChange("auth", false)} />
          </BrowseIntegrateCard.Root>

          <BrowseIntegrateCard.Root
            name="Aruba Instant On"
            subtitle="Wi-Fi and Presence analytics"
            image_url="aruba.png"
            status="contact-support"
            openDialog={openDialog}
            handleOpenChange={handleOpenChange}
          >
            <BrowseIntegrateCard.AuthDialog dialog_description="Cisco Meraki integration is coming soon" onCancel={() => handleOpenChange("auth", false)} />
          </BrowseIntegrateCard.Root>

          <BrowseIntegrateCard.Root name="Meraki Go" subtitle="Wi-Fi" image_url="meraki-go.png" status="contact-support" openDialog={openDialog} handleOpenChange={handleOpenChange}>
            <BrowseIntegrateCard.AuthDialog dialog_description="Cisco Meraki integration is coming soon" onCancel={() => handleOpenChange("auth", false)} />
          </BrowseIntegrateCard.Root>

          <BrowseIntegrateCard.Root name="TP-Link Omada" subtitle="Wi-Fi" image_url="tp-link-omada.png" status="contact-support" openDialog={openDialog} handleOpenChange={handleOpenChange}>
            <BrowseIntegrateCard.AuthDialog dialog_description="Cisco Meraki integration is coming soon" onCancel={() => handleOpenChange("auth", false)} />
          </BrowseIntegrateCard.Root>

          {/* <BrowseIntegrateCard.Root name="Hubspot" subtitle="CRM" image_url="hubspot.png" status="coming-soon" openDialog={openDialog} handleOpenChange={handleOpenChange}>
            <BrowseIntegrateCard.AuthDialog dialog_description="Cisco Meraki integration is coming soon" onCancel={() => handleOpenChange("auth", false)} />
          </BrowseIntegrateCard.Root>

          <BrowseIntegrateCard.Root name="Zapier" subtitle="Integration automation" image_url="zapier.png" status="coming-soon" openDialog={openDialog} handleOpenChange={handleOpenChange}>
            <BrowseIntegrateCard.AuthDialog dialog_description="Cisco Meraki integration is coming soon" onCancel={() => handleOpenChange("auth", false)} />
          </BrowseIntegrateCard.Root> */}

          <Box style={{ gridColumn: "1 / -1" }}>
            <Flex direction="column" py="4">
              <Separator size="4" />
            </Flex>
          </Box>

          <Box style={{ gridColumn: "1 / -1" }}>
            <Text size="4" weight="medium" mb="5">
              CRM & Marketing
            </Text>
          </Box>

          <BrowseMailchimpCard userProfile={userProfile} />
          {/* <BrowseIntegrateCard.Root name="Airship" subtitle="CRM & Email marketing" image_url="airship.png" status="inactive" openDialog={openDialog} handleOpenChange={handleOpenChange}>
            <BrowseIntegrateCard.AuthDialog dialog_description="Airship integration is coming soon" onCancel={() => handleOpenChange("auth", false)} />
          </BrowseIntegrateCard.Root> */}
          <BrowseAirshipCard userProfile={userProfile} />

          {/* <Box style={{ gridColumn: "1 / -1" }}>
            <BrowseIntegrateCard.Root
              name="Looking for a new integration?"
              subtitle="Submit your request, and receive a reply soon."
              status="request"
              openDialog={openDialog}
              handleOpenChange={handleOpenChange}
            >
              <BrowseIntegrateCard.AuthDialog dialog_description="Submit your integration request" onCancel={() => handleOpenChange("auth", false)} />
            </BrowseIntegrateCard.Root>
          </Box> */}
        </Grid>
      </Flex>
    </Box>
  );
}
