import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
    attemptGetOrganisationDetails, 
    attemptCreateOrganisationDetails, 
    attempUpdateOrganisationDetails 
} from 'api/OrgApi';

export const useGetOrganisationDetails = (orgId) => {
    return useQuery({
        queryKey: ['org-details', orgId],
        enabled: !!orgId,
        queryFn: () => attemptGetOrganisationDetails(orgId),
    });
}

export const useCreateOrganisationDetails = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({orgID, data}) => attemptCreateOrganisationDetails(orgID, data),
        onSuccess: () => {
            queryClient.invalidateQueries('org-details');
        }
    });
}

export const useUpdateOrganisationDetails = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ orgID, data }) => attempUpdateOrganisationDetails(orgID, data),
        onSuccess: () => {
            queryClient.invalidateQueries('org-details');
        }
    });
}
