import axios from "axios";
import params from "../configs/params";

const baseCiscoMerakiApi = axios.create({
  baseURL: params.merakiUrl, // Make sure to add merakiUrl in your params config
  withCredentials: true,
});

baseCiscoMerakiApi.defaults.headers.common["Content-Type"] = "application/json";
baseCiscoMerakiApi.defaults.headers.common["ngrok-skip-browser-warning"] = "true";

// Add a request interceptor for adding the Authorization header
baseCiscoMerakiApi.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor for handling 401 errors
baseCiscoMerakiApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        try {
          const response = await axios.post(`${params.apiUrl}auth/token/refresh/`, { token: refreshToken });
          const newAccessToken = response.data.accessToken;
          localStorage.setItem("accessToken", newAccessToken);
          axios.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default baseCiscoMerakiApi;
