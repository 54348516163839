import React, { useEffect } from "react";
import { Flex, Text } from "@radix-ui/themes";
import { useOutletContext } from "react-router-dom";
import { useProfileContext, useLocationContext, useDateRangeContext } from "context";
import { usePresenceMetrics } from "../../hooks/presence/usePresenceMetrics";
import { MetricCard, ChartCard } from "components";
import { isEqual, startOfDay } from "date-fns";
import { presenceChartConfig } from "../../configs/PresenceChartConfig";

export default function Presence() {
  const { userProfile } = useProfileContext();
  const { locationSelection, isLocationsSuccess } = useLocationContext();
  const { selectedDateRange } = useDateRangeContext();
  const { zoneSelection, activeView, setDisableBefore } = useOutletContext();

  const { presenceMetrics, isLoading } = usePresenceMetrics(selectedDateRange, locationSelection, zoneSelection, isLocationsSuccess);

  useEffect(() => {
    if (presenceMetrics?.disable_before_date) {
      setDisableBefore(presenceMetrics.disable_before_date);
    }
  }, [presenceMetrics]);

  const metricTileData = [
    {
      name: "Est. unique guests",
      icon: "",
      metric: presenceMetrics?.est_guest ?? "Loading...",
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(presenceMetrics?.disable_before_date))
        ? "The last period does not have data"
        : `${presenceMetrics?.last_period_changes.visitor_change >= 0 ? "+" : ""}${presenceMetrics?.last_period_changes.visitor_change ?? "..."}% since last period`,
      tooltip: "Total estimated guests in the selected locations",
    },
    {
      name: "Est. unique passerby",
      icon: "",
      metric: presenceMetrics?.est_passerby ?? "Loading...",
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(presenceMetrics?.disable_before_date))
        ? "The last period does not have data"
        : `${presenceMetrics?.last_period_changes.passerby_change >= 0 ? "+" : ""}${presenceMetrics?.last_period_changes.passerby_change ?? "..."}% since last period`,
      tooltip: "Total estimated passersby near the selected locations",
    },
    {
      name: "Est. passerby conversion",
      icon: "",
      metric: `${presenceMetrics?.passerby_conversion ?? "Loading..."}%`,
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(presenceMetrics?.disable_before_date))
        ? "The last period does not have data"
        : `${presenceMetrics?.last_period_changes.conversion_change >= 0 ? "+" : ""}${presenceMetrics?.last_period_changes.conversion_change ?? "..."}% since last period`,
      tooltip: "Percentage of passersby that became guests",
    },
    {
      name: "Avg. guest dwell",
      icon: "",
      metric: `${presenceMetrics?.avg_guest_dwell ?? "Loading..."} mins`,
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(presenceMetrics?.disable_before_date))
        ? "The last period does not have data"
        : `${presenceMetrics?.last_period_changes.dwell_change >= 0 ? "+" : ""}${presenceMetrics?.last_period_changes.dwell_change ?? "..."}% since last period`,
      tooltip: "Average time guests spent in the location",
    },
  ];

  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  const normalizedFrom = startOfDay(selectedDateRange.from);
  const normalizedTo = startOfDay(selectedDateRange.to);
  const selectedDateRangeInDays = Math.ceil((normalizedTo.getTime() - normalizedFrom.getTime()) / oneDayInMilliseconds) + 1;

  // Determine which charts to show based on date range
  let chartTypesToShow = [];
  if (selectedDateRangeInDays === 1) {
    chartTypesToShow = ["timeOfDay"];
  } else if (selectedDateRangeInDays > 1 && selectedDateRangeInDays < 62) {
    chartTypesToShow = ["day", "timeOfDay", "dayOfWeek"];
  } else if (selectedDateRangeInDays >= 62) {
    chartTypesToShow = ["month", "day", "timeOfDay", "dayOfWeek"];
  }

  const showNoResults = !selectedDateRange?.from || !locationSelection?.length || (zoneSelection?.available && zoneSelection?.selected?.length === 0);
  const bottomOffset = 180;

  const renderContent = () => {
    if (showNoResults) {
      return (
        <Flex
          width="100%"
          style={{
            display: "flex",
            flexDirection: "column",
            height: `calc(100vh - ${bottomOffset}px)`,
            flex: "1 0 0",
          }}
        >
          <Flex
            p="5"
            direction="column"
            gap="5"
            style={{
              alignSelf: "stretch",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              borderRadius: "8px",
              border: "1px dashed var(--gray-a6)",
              flex: 1,
              display: "flex",
              position: "relative",
              backgroundColor: "var(--color-background)",
            }}
          >
            <Flex direction="column" gap="2" style={{ alignItems: "center" }}>
              <Text size="5" weight="medium">
                No results
              </Text>
              <Text size="2" weight="regular" color="gray" style={{ width: "296px", textAlign: "center" }}>
                Adjust your filters or try again later.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      );
    }

    return (
      <Flex direction="column" gap="4" style={{ width: "100%" }}>
        {/* Metrics Cards */}
        <Flex className="card-container" style={{ alignSelf: "stretch" }} align="start" gap="5">
          {metricTileData.map((entry, index) => (
            <MetricCard key={index} metricName={entry.name} metricIcon={entry.icon} metric={entry.metric} updates={entry.updates} tooltip={entry.tooltip} />
          ))}
        </Flex>

        {/* Charts Section */}
        <Flex direction="column" gap="5" style={{ alignSelf: "stretch", alignItems: "center" }}>
          {chartTypesToShow.map((chartType) => {
            const config = presenceChartConfig[activeView][chartType];
            return (
              <ChartCard key={chartType} text={config.text} tooltip={config.tooltip}>
                <config.ChartComponent
                  data={{
                    data: presenceMetrics,
                  }}
                  startTimeMilliseconds={selectedDateRange.from.getTime()}
                  endTimeMilliseconds={selectedDateRange.to.getTime()}
                />
              </ChartCard>
            );
          })}
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      className="content-container"
      direction="column"
      gap="5"
      style={{
        alignSelf: "stretch",
        width: "100%",
        height: "100%",
        alignItems: "center",
        flex: "1 0 0",
      }}
    >
      <Flex
        direction="column"
        gap="5"
        style={{
          alignSelf: "stretch",
          flex: "1 0 0",
          alignItems: "center",
          overflowY: "scroll",
        }}
      >
        {renderContent()}
      </Flex>
    </Flex>
  );
}
