import { apiURL, encryptionKey, insightsURL, bChatURL, bIntegrationsURL, merakiUrl, airshipUrl } from "../helpers/config";

const params = {
  apiUrl: apiURL,
  insightsUrl: insightsURL,
  bChatUrl: bChatURL,
  bIntegrationsUrl: bIntegrationsURL,
  encryptionKey: encryptionKey,
  merakiUrl: merakiUrl,
  airshipUrl: airshipUrl,
};

export default params;
