export const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const validatePassword = (password) =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(password);

export const validateUnifiControllerUrl = (url) => {
  try {
    const parsedUrl = new URL(url);
    if (parsedUrl.protocol !== 'https:') return false;

    // Validate hostname
    const hostname = parsedUrl.hostname;
    const isValidIp = /^(?:(?:25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)\.){3}(?:25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)$/.test(hostname);
    const isValidDomain = /^(?:(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})$/.test(hostname);
    const isUnifiCloud = hostname === 'unifi.ui.com';

    if (!(isValidIp || isValidDomain || isUnifiCloud)) return false;

    // Validate port if present
    if (parsedUrl.port) {
      const portNumber = Number(parsedUrl.port);
      if (portNumber < 1 || portNumber > 65535) return false;
    }

    return true;
  } catch (e) {
    return false;
  }
}

