import { Text, Card, Flex, Tooltip, IconButton, Badge } from "@radix-ui/themes";
import { InfoCircledIcon } from "@radix-ui/react-icons";

export function LiveConnectionsCard({ metric, metricIcon }) {
  return (
    <Card
      style={{
        display: "flex",
        padding: "var(--space-4)",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        flex: "1 0 0",
        alignSelf: "stretch",
        height: "100%",
        background: "var(--blue-a3)",
        "--card-background-color": "var(--blue-2)",
      }}
    >
      {/* Top section */}
      <Flex
        direction="column"
        style={{
          alignSelf: "stretch",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        {/* Header with title and badge */}
        <Flex display="flex" align="center" justify="between" style={{ alignSelf: "stretch" }}>
          {metricIcon}
          <Text gap="2" size="1" weight="medium" style={{ color: "var(--blue-12)", display: "flex", alignItems: "center" }}>
            Connections
          </Text>
          <Badge variant="soft" size="1">
            Live
          </Badge>
        </Flex>

        {/* Bottom section with metric and tooltip */}
        <Flex justify="between" align="end" style={{ alignSelf: "stretch" }}>
          <Flex align="center" gap="2" style={{ color: "var(--blue-11)" }}>
            <Text size="6" weight="bold">
              {metric}
            </Text>
          </Flex>
          {/* <Tooltip content="Current number of active connections">
            <IconButton size="1" variant="ghost" style={{color:'var(--blue-12)'}}>
              <InfoCircledIcon />
            </IconButton>
          </Tooltip> */}
        </Flex>
      </Flex>
    </Card>
  );
}
