import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement, Filler } from "chart.js";
import { DateTime } from "luxon";
import { Badge } from "@radix-ui/themes";
import { useTheme } from "../../context/ThemeContext";
import "./ChartColours.css";
import { set } from "date-fns";

ChartJS.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement, Filler);

const getCSSVariable = (variable) => getComputedStyle(document.documentElement).getPropertyValue(variable).trim();

export const GuestConnectionsByMonthChart = ({ data, startTimeMilliseconds, endTimeMilliseconds, timezone, displayBy }) => {
  const { appearance } = useTheme();
  const [fontColor, setFontColor] = useState("");
  const [chartNeutralColour, setChartNeutralColour] = useState("");
  const [chartNeutralBorderColour, setChartNeutralBorderColour] = useState("");
  const [gridColour, setGridColour] = useState("");
  const [chartPointsBgColour, setChartPointsBgColour] = useState("");
  const [chartContainerRef, setChartContainerRef] = useState(null);
  const [badgePosition, setBadgePosition] = useState({ left: 0, top: 0, display: "none" });
  const [chartNewConnectionColour, setChartNewConnectionColour] = useState("");
  const [chartReturningConnectionColour, setChartReturningConnectionColour] = useState("");
  const [chartAgeOver65Colour, setChartAgeOver65Colour] = useState("");
  const [chartAge5564Colour, setChartAge5564Colour] = useState("");
  const [chartAge4554Colour, setChartAge4554Colour] = useState("");
  const [chartAge3544Colour, setChartAge3544Colour] = useState("");
  const [chartAge2534Colour, setChartAge2534Colour] = useState("");
  const [chartAge1824Colour, setChartAge1824Colour] = useState("");
  const [chartAgeBelow18Colour, setChartAgeBelow18Colour] = useState("");
  const [chartGenderMaleColour, setChartGenderMaleColour] = useState("");
  const [chartGenderFemaleColour, setChartGenderFemaleColour] = useState("");
  const [chartGenderOtherColour, setChartGenderOtherColour] = useState("");
  const [chartGenderNoResponseColour, setChartGenderNoResponseColour] = useState("");
  const [chartNewConnectionBorderColour, setChartNewConnectionBorderColour] = useState("");
  const [chartReturningConnectionBorderColour, setChartReturningConnectionBorderColour] = useState("");
  const [chartAgeOver65BorderColour, setChartAgeOver65BorderColour] = useState("");
  const [chartAge5564BorderColour, setChartAge5564BorderColour] = useState("");
  const [chartAge4554BorderColour, setChartAge4554BorderColour] = useState("");
  const [chartAge3544BorderColour, setChartAge3544BorderColour] = useState("");
  const [chartAge2534BorderColour, setChartAge2534BorderColour] = useState("");
  const [chartAge1824BorderColour, setChartAge1824BorderColour] = useState("");
  const [chartAgeBelow18BorderColour, setChartAgeBelow18BorderColour] = useState("");
  const [chartGenderMaleBorderColour, setChartGenderMaleBorderColour] = useState("");
  const [chartGenderFemaleBorderColour, setChartGenderFemaleBorderColour] = useState("");
  const [chartGenderOtherBorderColour, setChartGenderOtherBorderColour] = useState("");
  const [chartGenderNoResponseBorderColour, setChartGenderNoResponseBorderColour] = useState("");

  useEffect(() => {
    const fontColourLight = getCSSVariable("--chart-font-colour-light");
    const fontColourDark = getCSSVariable("--chart-font-colour-dark");
    const positiveColour = getCSSVariable("--chart-positive-colour");
    const gridColourLight = getCSSVariable("--chart-grid-colour-light");
    const gridColourDark = getCSSVariable("--chart-grid-colour-dark");
    const chartPointsBgColourLight = getCSSVariable("--chart-points-bg-colour-light");
    const chartPointsBgColourDark = getCSSVariable("--chart-points-bg-colour-dark");
    const chartNeutralColour = getCSSVariable("--chart-neutral-colour");
    const chartNeutralBorderColour = getCSSVariable("--chart-neutral-border-colour");
    const chartNeutralColourDark = getCSSVariable("--chart-neutral-colour-dark");
    const chartNeutralBorderColourDark = getCSSVariable("--chart-neutral-border-colour-dark");
    const chartNewConnectionColour = getCSSVariable("--chart-new-connection-colour");
    const chartReturningConnectionColour = getCSSVariable("--chart-returning-connection-colour");
    const chartAgeOver65Colour = getCSSVariable("--chart-age-over-65-colour");
    const chartAge5564Colour = getCSSVariable("--chart-age-55-64-colour");
    const chartAge4554Colour = getCSSVariable("--chart-age-45-54-colour");
    const chartAge3544Colour = getCSSVariable("--chart-age-35-44-colour");
    const chartAge2534Colour = getCSSVariable("--chart-age-25-34-colour");
    const chartAge1824Colour = getCSSVariable("--chart-age-18-24-colour");
    const chartAgeBelow18Colour = getCSSVariable("--chart-age-below-18-colour");
    const chartGenderMaleColour = getCSSVariable("--chart-gender-male-colour");
    const chartGenderFemaleColour = getCSSVariable("--chart-gender-female-colour");
    const chartGenderOtherColour = getCSSVariable("--chart-gender-other-colour");
    const chartGenderNoResponseColour = getCSSVariable("--chart-gender-no-response-colour");
    const chartNewConnectionBorderColour = getCSSVariable("--chart-new-connection-border-colour");
    const chartReturningConnectionBorderColour = getCSSVariable("--chart-returning-connection-border-colour");
    const chartNewConnectionBorderColourDark = getCSSVariable("--chart-new-connection-border-colour-dark");
    const chartReturningConnectionBorderColourDark = getCSSVariable("--chart-returning-connection-border-colour-dark");
    const chartNewConnectionColourDark = getCSSVariable("--chart-new-connection-colour-dark");
    const chartReturningConnectionColourDark = getCSSVariable("--chart-returning-connection-colour-dark");
    const chartAgeOver65BorderColour = getCSSVariable("--chart-age-over-65-border-colour");
    const chartAge5564BorderColour = getCSSVariable("--chart-age-55-64-border-colour");
    const chartAge4554BorderColour = getCSSVariable("--chart-age-45-54-border-colour");
    const chartAge3544BorderColour = getCSSVariable("--chart-age-35-44-border-colour");
    const chartAge2534BorderColour = getCSSVariable("--chart-age-25-34-border-colour");
    const chartAge1824BorderColour = getCSSVariable("--chart-age-18-24-border-colour");
    const chartAgeBelow18BorderColour = getCSSVariable("--chart-age-below-18-border-colour");
    const chartAgeBelow18BorderColourDark = getCSSVariable("--chart-age-below-18-border-colour-dark");
    const chartAge1824BorderColourDark = getCSSVariable("--chart-age-18-24-border-colour-dark");
    const chartAge2534BorderColourDark = getCSSVariable("--chart-age-25-34-border-colour-dark");
    const chartAge3544BorderColourDark = getCSSVariable("--chart-age-35-44-border-colour-dark");
    const chartAge4554BorderColourDark = getCSSVariable("--chart-age-45-54-border-colour-dark");
    const chartAge5564BorderColourDark = getCSSVariable("--chart-age-55-64-border-colour-dark");
    const chartAgeOver65BorderColourDark = getCSSVariable("--chart-age-over-65-border-colour-dark");
    const chartAgeOver65ColourDark = getCSSVariable("--chart-age-over-65-colour-dark");
    const chartAge5564ColourDark = getCSSVariable("--chart-age-55-64-colour-dark");
    const chartAge4554ColourDark = getCSSVariable("--chart-age-45-54-colour-dark");
    const chartAge3544ColourDark = getCSSVariable("--chart-age-35-44-colour-dark");
    const chartAge2534ColourDark = getCSSVariable("--chart-age-25-34-colour-dark");
    const chartAge1824ColourDark = getCSSVariable("--chart-age-18-24-colour-dark");
    const chartAgeBelow18ColourDark = getCSSVariable("--chart-age-below-18-colour-dark");
    const chartGenderMaleBorderColour = getCSSVariable("--chart-gender-male-border-colour");
    const chartGenderFemaleBorderColour = getCSSVariable("--chart-gender-female-border-colour");
    const chartGenderOtherBorderColour = getCSSVariable("--chart-gender-other-border-colour");
    const chartGenderNoResponseBorderColour = getCSSVariable("--chart-gender-no-response-border-colour");
    const chartGenderMaleBorderColourDark = getCSSVariable("--chart-gender-male-border-colour-dark");
    const chartGenderFemaleBorderColourDark = getCSSVariable("--chart-gender-female-border-colour-dark");
    const chartGenderOtherBorderColourDark = getCSSVariable("--chart-gender-other-border-colour-dark");
    const chartGenderNoResponseBorderColourDark = getCSSVariable("--chart-gender-no-response-border-colour-dark");
    const chartGenderMaleColourDark = getCSSVariable("--chart-gender-male-colour-dark");
    const chartGenderFemaleColourDark = getCSSVariable("--chart-gender-feamle-colour-dark");
    const chartGenderOtherColourDark = getCSSVariable("--chart-gender-other-colour-dark");
    const chartGenderNoResponseColourDark = getCSSVariable("--chart-gender-no-response-colour-dark");

    if (appearance === "dark") {
      setFontColor(fontColourDark);
      setGridColour(gridColourDark);
      setChartPointsBgColour(chartPointsBgColourDark);
      setChartNeutralColour(chartNeutralColourDark);
      setChartNeutralBorderColour(chartNeutralBorderColourDark);
      setChartNewConnectionColour(chartNewConnectionColourDark);
      setChartReturningConnectionColour(chartReturningConnectionColourDark);
      setChartNewConnectionBorderColour(chartNewConnectionBorderColourDark);
      setChartReturningConnectionBorderColour(chartReturningConnectionBorderColourDark);
      setChartAgeOver65Colour(chartAgeOver65ColourDark);
      setChartAge5564Colour(chartAge5564ColourDark);
      setChartAge4554Colour(chartAge4554ColourDark);
      setChartAge3544Colour(chartAge3544ColourDark);
      setChartAge2534Colour(chartAge2534ColourDark);
      setChartAge1824Colour(chartAge1824ColourDark);
      setChartAgeBelow18Colour(chartAgeBelow18ColourDark);
      setChartAgeOver65BorderColour(chartAgeOver65BorderColourDark);
      setChartAge5564BorderColour(chartAge5564BorderColourDark);
      setChartAge4554BorderColour(chartAge4554BorderColourDark);
      setChartAge3544BorderColour(chartAge3544BorderColourDark);
      setChartAge2534BorderColour(chartAge2534BorderColourDark);
      setChartAge1824BorderColour(chartAge1824BorderColourDark);
      setChartAgeBelow18BorderColour(chartAgeBelow18BorderColourDark);
      setChartGenderMaleColour(chartGenderMaleColourDark);
      setChartGenderFemaleColour(chartGenderFemaleColourDark);
      setChartGenderOtherColour(chartGenderOtherColourDark);
      setChartGenderNoResponseColour(chartGenderNoResponseColourDark);
      setChartGenderMaleBorderColour(chartGenderMaleBorderColourDark);
      setChartGenderFemaleBorderColour(chartGenderFemaleBorderColourDark);
      setChartGenderOtherBorderColour(chartGenderOtherBorderColourDark);
      setChartGenderNoResponseBorderColour(chartGenderNoResponseBorderColourDark);
    } else {
      setFontColor(fontColourLight);
      setGridColour(gridColourLight);
      setChartPointsBgColour(chartPointsBgColourLight);
      setChartNeutralColour(chartNeutralColour);
      setChartNeutralBorderColour(chartNeutralBorderColour);
      setChartNewConnectionColour(chartNewConnectionColour);
      setChartReturningConnectionColour(chartReturningConnectionColour);
      setChartNewConnectionBorderColour(chartNewConnectionBorderColour);
      setChartReturningConnectionBorderColour(chartReturningConnectionBorderColour);
      setChartAgeOver65Colour(chartAgeOver65Colour);
      setChartAge5564Colour(chartAge5564Colour);
      setChartAge4554Colour(chartAge4554Colour);
      setChartAge3544Colour(chartAge3544Colour);
      setChartAge2534Colour(chartAge2534Colour);
      setChartAge1824Colour(chartAge1824Colour);
      setChartAgeBelow18Colour(chartAgeBelow18Colour);
      setChartAgeOver65BorderColour(chartAgeOver65BorderColour);
      setChartAge5564BorderColour(chartAge5564BorderColour);
      setChartAge4554BorderColour(chartAge4554BorderColour);
      setChartAge3544BorderColour(chartAge3544BorderColour);
      setChartAge2534BorderColour(chartAge2534BorderColour);
      setChartAge1824BorderColour(chartAge1824BorderColour);
      setChartAgeBelow18BorderColour(chartAgeBelow18BorderColour);
      setChartGenderMaleColour(chartGenderMaleColour);
      setChartGenderFemaleColour(chartGenderFemaleColour);
      setChartGenderOtherColour(chartGenderOtherColour);
      setChartGenderNoResponseColour(chartGenderNoResponseColour);
      setChartGenderMaleBorderColour(chartGenderMaleBorderColour);
      setChartGenderFemaleBorderColour(chartGenderFemaleBorderColour);
      setChartGenderOtherBorderColour(chartGenderOtherBorderColour);
      setChartGenderNoResponseBorderColour(chartGenderNoResponseBorderColour);
    }
  }, [appearance]);

  const calculateIntervals = (startTime, endTime) => {
    const intervals = [];
    let current = new Date(startTime);

    while (current < endTime) {
      const next = new Date(current.getFullYear(), current.getMonth() + 1, 1);
      intervals.push(next - current);
      current = next;
    }

    return intervals;
  };

  const generateBins = (start, end, intervals) => {
    const bins = [];
    let current = start;
    let intervalIndex = 0;

    while (current < end) {
      const interval = intervals[intervalIndex % intervals.length];
      bins.push([current, current + interval]);
      current += interval;
      intervalIndex++;
    }

    return bins;
  };

  const calculateInterval = (startTime, endTime) => {
    return calculateIntervals(startTime, endTime);
  };

  const formatXAxisLabel = (date) => {
    const formattedDate = DateTime.fromMillis(date);
    if (!formattedDate.isValid) {
      console.error("Invalid date:", date);
      return "";
    }
    return formattedDate.toFormat("MMM yyyy");
  };

  const findBinIndex = (bins, time) => {
    let low_index = 0;
    let high_index = bins.length - 1;

    while (low_index <= high_index) {
      const middle_index = Math.floor((low_index + high_index) / 2);
      const [lowerBound, upperBound] = bins[middle_index];

      if (lowerBound <= time && time < upperBound) {
        return middle_index;
      } else if (time < lowerBound) {
        high_index = middle_index - 1;
      } else {
        low_index = middle_index + 1;
      }
    }
    return -1;
  };

  const interval = calculateInterval(startTimeMilliseconds, endTimeMilliseconds);
  const bar_chart_bins = generateBins(startTimeMilliseconds, endTimeMilliseconds, interval);

  let processed_chart_data = [];

  // Initialize processed_chart_data before finding current month index
  processed_chart_data = bar_chart_bins.map((entry) => ({
    start_date: entry[0],
    end_date: entry[1],
    new: 0,
    returning: 0,
    total: 0,
    average: 0,
    new_percent_change: 0,
    return_percent_change: 0,
    "Under 18": 0,
    "18-24": 0,
    "25-34": 0,
    "35-44": 0,
    "45-54": 0,
    "55-64": 0,
    "65+": 0,
    male: 0,
    female: 0,
    others: 0,
    "prefer not to respond": 0,
  }));

  // Find the current month's index
  const currentDate = DateTime.now().setZone(timezone);
  const currentMonthIndex = processed_chart_data.findIndex((entry) => {
    const entryDate = DateTime.fromMillis(entry.start_date);
    return entryDate.hasSame(currentDate, "month");
  });

  if (data && data.data && data.data.connection_details && data.data.connection_details.length > 0) {
    let connection_details = data.data.connection_details;

    for (let event of connection_details) {
      const start_timestamp = new Date(event.connection_start_time).getTime();
      const end_timestamp = new Date(event.connection_end_time).getTime();

      let start_index = findBinIndex(bar_chart_bins, start_timestamp);
      let end_index = findBinIndex(bar_chart_bins, end_timestamp);

      if (start_index !== -1 && end_index !== -1) {
        for (let i = start_index; i <= end_index; i++) {
          // Update guest status counts
          if (event.guest_status === "returning") {
            processed_chart_data[i].returning++;
          } else {
            processed_chart_data[i].new++;
          }

          // Update age group counts
          if (event.age) {
            processed_chart_data[i][event.age]++;
          }

          if (event.gender) {
            processed_chart_data[i][event.gender]++;
          }

          // Update total
          processed_chart_data[i].total = processed_chart_data[i].new + processed_chart_data[i].returning;
        }
      }
    }
  }

  const hasNonZeroData = (data) => {
    return data.some((value) => value > 0);
  };

  const chartData = {
    labels: processed_chart_data.map((entry, index) => (index === currentMonthIndex ? "" : formatXAxisLabel(entry.start_date))),
    datasets:
      displayBy === "guest_status"
        ? [
            {
              label: "Returning",
              data: processed_chart_data.map((entry) => entry.returning),
              backgroundColor: chartReturningConnectionColour,
              borderColor: chartReturningConnectionBorderColour,
              fill: true,
              borderWidth: 1,
              tension: 0,
              pointBackgroundColor: chartPointsBgColour,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry.returning)),
            },
            {
              label: "New",
              data: processed_chart_data.map((entry) => entry.new),
              backgroundColor: chartNewConnectionColour,
              borderColor: chartNewConnectionBorderColour,
              fill: true,
              borderWidth: 1,
              tension: 0,
              pointBackgroundColor: chartPointsBgColour,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry.new)),
            },
          ]
        : displayBy === "age"
        ? [
            {
              label: "65+",
              data: processed_chart_data.map((entry) => entry["65+"]),
              backgroundColor: chartAgeOver65Colour,
              borderColor: chartAgeOver65BorderColour,
              fill: true,
              borderWidth: 1,
              tension: 0,
              pointBackgroundColor: chartPointsBgColour,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry["65+"])),
            },
            {
              label: "55-64",
              data: processed_chart_data.map((entry) => entry["55-64"]),
              backgroundColor: chartAge5564Colour,
              borderColor: chartAge5564BorderColour,
              fill: true,
              borderWidth: 1,
              tension: 0,
              pointBackgroundColor: chartPointsBgColour,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry["55-64"])),
            },
            {
              label: "45-54",
              data: processed_chart_data.map((entry) => entry["45-54"]),
              backgroundColor: chartAge4554Colour,
              borderColor: chartAge4554BorderColour,
              fill: true,
              borderWidth: 1,
              tension: 0,
              pointBackgroundColor: chartPointsBgColour,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry["45-54"])),
            },
            {
              label: "35-44",
              data: processed_chart_data.map((entry) => entry["35-44"]),
              backgroundColor: chartAge3544Colour,
              borderColor: chartAge3544BorderColour,
              fill: true,
              borderWidth: 1,
              tension: 0,
              pointBackgroundColor: chartPointsBgColour,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry["35-44"])),
            },
            {
              label: "25-34",
              data: processed_chart_data.map((entry) => entry["25-34"]),
              backgroundColor: chartAge2534Colour,
              borderColor: chartAge2534BorderColour,
              fill: true,
              borderWidth: 1,
              tension: 0,
              pointBackgroundColor: chartPointsBgColour,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry["25-34"])),
            },
            {
              label: "18-24",
              data: processed_chart_data.map((entry) => entry["18-24"]),
              backgroundColor: chartAge1824Colour,
              borderColor: chartAge1824BorderColour,
              fill: true,
              borderWidth: 1,
              tension: 0,
              pointBackgroundColor: chartPointsBgColour,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry["18-24"])),
            },
            {
              label: "Under 18",
              data: processed_chart_data.map((entry) => entry["Under 18"]),
              backgroundColor: chartAgeBelow18Colour,
              borderColor: chartAgeBelow18BorderColour,
              fill: true,
              borderWidth: 1,
              tension: 0,
              pointBackgroundColor: chartPointsBgColour,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry["Under 18"])),
            },
          ]
        : displayBy === "gender"
        ? [
            {
              label: "Male",
              data: processed_chart_data.map((entry) => entry["male"]),
              backgroundColor: chartGenderMaleColour,
              borderColor: chartGenderMaleBorderColour,
              fill: true,
              borderWidth: 1,
              tension: 0,
              pointBackgroundColor: chartPointsBgColour,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry["male"])),
            },
            {
              label: "Female",
              data: processed_chart_data.map((entry) => entry["female"]),
              backgroundColor: chartGenderFemaleColour,
              borderColor: chartGenderFemaleBorderColour,
              fill: true,
              borderWidth: 1,
              tension: 0,
              pointBackgroundColor: chartPointsBgColour,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry["female"])),
            },
            {
              label: "Other",
              data: processed_chart_data.map((entry) => entry["others"]),
              backgroundColor: chartGenderOtherColour,
              borderColor: chartGenderOtherBorderColour,
              fill: true,
              borderWidth: 1,
              tension: 0,
              pointBackgroundColor: chartPointsBgColour,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry["others"])),
            },
            {
              label: "No Response",
              data: processed_chart_data.map((entry) => entry["prefer not to respond"]),
              backgroundColor: chartGenderNoResponseColour,
              borderColor: chartGenderNoResponseBorderColour,
              fill: true,
              borderWidth: 1,
              tension: 0,
              pointBackgroundColor: chartPointsBgColour,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry["prefer not to respond"])),
            },
          ]
        : [
            {
              label: "Total",
              data: processed_chart_data.map((entry) => entry.total),
              backgroundColor: chartNeutralColour,
              borderColor: chartNeutralBorderColour,
              fill: true,
              borderWidth: 1,
              tension: 0,
              pointBackgroundColor: chartPointsBgColour,
            },
          ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      onComplete: function (animation) {
        if (chartContainerRef && currentMonthIndex !== -1) {
          const chart = animation.chart;
          const xAxis = chart.scales.x;
          const currentMonthX = xAxis.getPixelForValue(currentMonthIndex);
          const currentMonthY = xAxis.top + xAxis.options.ticks.padding;

          setBadgePosition({
            left: currentMonthX,
            top: currentMonthY - 6,
            display: "block",
          });
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        usePointStyle: true,
        itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
        boxWidth: 6,
        callbacks: {
          title: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            return formatXAxisLabel(processed_chart_data[index].start_date);
          },
          label: function (context) {
            if (displayBy === "guest_status") {
              const dataPoint = processed_chart_data[context.dataIndex];
              const total = dataPoint.total;
              const percentage = total > 0 ? ((context.parsed.y / total) * 100).toFixed(1) : 0;

              if (context.dataset.label === "New") {
                return `New: ${percentage}% (${context.parsed.y})`;
              } else if (context.dataset.label === "Returning") {
                return `Returning: ${percentage}% (${context.parsed.y})`;
              }
            } else if (displayBy === "age") {
              const dataPoint = processed_chart_data[context.dataIndex];
              const total = dataPoint.total;
              const percentage = total > 0 ? ((context.parsed.y / total) * 100).toFixed(1) : 0;
              return `${context.dataset.label}: ${percentage}% (${context.parsed.y})`;
            } else if (displayBy === "gender") {
              const dataPoint = processed_chart_data[context.dataIndex];
              const total = dataPoint.total;
              const percentage = total > 0 ? ((context.parsed.y / total) * 100).toFixed(1) : 0;
              return `${context.dataset.label}: ${percentage}% (${context.parsed.y})`;
            }
            return `Total: ${context.parsed.y}`;
          },
          afterBody: function (tooltipItems) {
            if (displayBy === "guest_status" || displayBy === "age") {
              const index = tooltipItems[0].dataIndex;
              const total = processed_chart_data[index].total;
              return [`Total: ${total}`];
            }
            return [];
          },
          labelColor: function (context) {
            return {
              borderColor: context.dataset.borderColor,
              backgroundColor: context.dataset.borderColor,
            };
          },
          labelPointStyle: function (context) {
            return {
              pointStyle: "circle",
              rotation: 0,
            };
          },
        },
        bodyFont: {
          family: "Figtree",
        },
        titleFont: {
          family: "Figtree",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: fontColor,
          font: {
            family: "Figtree",
            size: 12,
          },
          padding: 8,
          callback: function (value, index, values) {
            if (bar_chart_bins.length > 17) {
              return index % 2 === 0 ? this.getLabelForValue(value) : "";
            } else {
              return this.getLabelForValue(value);
            }
          },
          maxRotation: 0,
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        position: "right",
        ticks: {
          color: fontColor,
          font: {
            family: "Figtree",
            size: 12,
          },
          padding: 8,
          stepSize: 1, // Force integer steps
          callback: function (value) {
            if (Number.isInteger(value)) {
              return value;
            }
            return null; // Hide non-integer ticks
          },
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        },
      },
    },
  };

  console.log("Processed Chart Data:", processed_chart_data);

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }} ref={setChartContainerRef}>
      <Line data={chartData} options={options} style={{ width: "100%", height: "100%" }} />
      {currentMonthIndex !== -1 && (
        <div
          style={{
            position: "absolute",
            left: `${badgePosition.left}px`,
            top: `${badgePosition.top}px`,
            transform: "translate(-50%, 0)",
            display: badgePosition.display,
            pointerEvents: "none",
            zIndex: 10,
          }}
        >
          <Badge variant="soft" size="1">
            {formatXAxisLabel(processed_chart_data[currentMonthIndex]?.start_date)}
          </Badge>
        </div>
      )}
    </div>
  );
};
