import instance from "./BaseApi";

export function getProfile() {
  return instance.request({
    url: `auth/me/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getUsers() {
  return instance.request({
    url: `users-of-same-org/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptUpdateUser(params) {
  const data = {
    first_name: params.first_name,
    last_name: params.last_name,
  };

  if (params.hasOwnProperty('is_first_login')) {
    data.is_first_login = params.is_first_login;
  }
  
  return instance.request({
    url: `users/${params.id}/`,
    method: "PATCH",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptDeleteUser(id) {
  return instance.request({
    url: `users/${id}/`,
    method: "DELETE",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptInviteUser(data) {
  return instance.request({
    url: `user/invite/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptInviteUserUpdate(data) {
  return instance.request({
    url: `user/invite/update/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptResendInviteUser(data) {
  return instance.request({
    url: `user/invite/resend/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptDeleteInviteUser(data) {
  return instance.request({
    url: `user/invite/cancel/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptGetInviteUsers() {
  return instance.request({
    url: `user-invitations/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptCheckInvitation(token, email) {
  const params = new URLSearchParams({ token, email });
  return instance.request({
    url: `check-user-invitation/?${params.toString()}`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptGetCustomers(filters) {
  return instance.request({
    url: 'customers/',
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: filters
  });
}