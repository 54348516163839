import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Flex, Text, TextField, Button, Link } from "@radix-ui/themes";
import { useRegistration, useCheckInvitation } from "hooks";
import { ThemeProvider, useTheme } from "../../context/ThemeContext"; // Import ThemeProvider and useTheme
import { Icon } from 'components/tokens/Icon';
import useForm from "../../hooks/validation/useForm";
import { validateEmail, validatePassword } from "../../components/utils/validationUtils";

const initialFormState = {
  email: "",
  first_name: "",
  last_name: "",
  password: "",
  confirmPassword: "",
  token: ""
};

export default function Registration() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { appearance } = useTheme();
  const [organisationName, setOrganisationName] = useState('');
  const [organisationImageUrl, setOrganisationImageUrl] = useState('');
  const [signup, setSignup] = useState(false);
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  // Use the custom hook to check the invitation
  const { data, isLoading, error } = useCheckInvitation(token, email);

  const initialState = {
    ...initialFormState,
    email: email || "",
    token: token || ""
  };

  const { mutate: registerUser, isError: isErrorRegistration, isSuccess: isSuccessRegistration} =  useRegistration()

  const validations = {
    firstName: (value) => (!value ? "Input your first name" : ""),
    lastName: (value) => (!value ? "Input your last name" : ""),
    email: (value) =>
      !value
        ? "Input your email address. For example: john@email.com"
        : !validateEmail(value)
        ? "Invalid email address"
        : "",
    password: (value) =>
      !value
        ? "Input a password"
        : !validatePassword(value)
        ? "Password must be at least 8 characters long and include a mix of uppercase letters, lowercase letters, numbers, and special characters."
        : "",
    confirmPassword: (value, formState) =>
      !value
        ? "Confirm new password"
        : value !== formState.password
        ? "Passwords do not match"
        : "",
  };

  const { formState, errors, handleChange, handleSubmit, handleBlur, resetForm, setErrors } = useForm(
    initialState,
    validations
  );

  const handleSignupSubmit = async (formState) => {
    const signupData = { 
      "first_name": formState.firstName , 
      "last_name": formState.lastName, 
      "email": formState.email, 
      "password" : formState.password, 
      "token" : formState.token
     };

    registerUser(signupData)
    if( isErrorRegistration){
      resetForm();
    }
  
  };

  useEffect(() => {
    if (isSuccessRegistration) {
      setSignup(true);
      navigate("/signup-successful");
    }
    
  }, [isSuccessRegistration, navigate]);

  useEffect(() => {
    if (data) {
      setOrganisationName(data.organisation_name);
      setOrganisationImageUrl(data.organisation_image_url);
    }
  }, [data]);

  if (isLoading) {
    return <div></div>;
  }

  if (error || (data && data.expired)) {
    return (
      <React.Fragment>
        <Flex p="5" direction="column" gap="5" height="100%"
            style={{alignSelf: 'stretch', alignItems: 'center', flex: '1 0 0', justifyContent: 'center'}}>
            <Flex direction="column" gap="2" style={{alignItems: 'center'}}>
                <Text size="5" weight="medium">
                    Link Expired
                </Text>
                <Text size="2" weight="regular" color="gray" style={{width:'296px', textAlign:'center'}}>
                    Please check your email inbox for a new link or contact the owner of your organisation to resend an invitation to you.
                </Text>
            </Flex>
        </Flex>
      </React.Fragment>
    );
  }



  const iconColor = appearance === 'dark' ? 'white' : 'blue';

  return (
    <ThemeProvider>
      <React.Fragment>      
        <Flex style={{ height: '100vh', width: '100vw'}}>
          {/* Sign up section */}
          <Flex direction="column" align="stretch" px="7" py="8" style={{alignItems: 'center', flex: '1 1 50%'}}>
            <Flex justify="center" align="stretch" width="100%" style={{alignItems: 'center', flex: '1 0 0'}}>
              <Flex direction="column" gap="7" maxWidth="296px" style={{alignItems: 'center', justifyContent: 'center'}}>
                {/* Registration header */}
                <Flex direction="column" gap="2" maxWidth="169px" style={{alignItems: 'center', width: '100%'}}>
                  <Text size="1" weight="regular" color='gray' align="center" style={{width: '100%'}}>{organisationName}</Text>
                  <Text size="4" weight="bold" align="center" style={{width: '100%'}}>Confirm your details</Text>
                </Flex>
                <form onSubmit={(e) => handleSubmit(e, handleSignupSubmit)}>
                  <Flex direction="column" gap="5" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Flex direction="column" gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                      <Flex direction="row" gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                        {/* First name input field */}
                        <Flex direction="column" gap="1" 
                          style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                          <Text as="label" size="2" weight="medium" style={{width: '100%'}}>First name</Text>
                          <TextField.Root style={{width: '100%'}}
                            name="firstName"
                            value={formState.firstName}
                            onChange={(e) => {
                              handleChange(e);
                              if (errors.firstName) setErrors({...errors, firstName: ''});
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.firstName && (<Text color="red" size="1">Input your first name.</Text>)}
                        </Flex>
                        {/* Last name input field */}
                        <Flex direction="column" gap="1" 
                          style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                          <Text as="label" size="2" weight="medium" style={{width: '100%'}}>Last name</Text>
                          <TextField.Root
                            name="lastName"
                            value={formState.lastName}
                            onChange={(e) => {
                              handleChange(e);
                              if (errors.lastName) setErrors({...errors, lastName: ''});
                            }}
                            onBlur={handleBlur}
                            style={{width: '100%'}}
                          />
                          {errors.lastName && (<Text color="red" size="1">Input your last name.</Text>)}
                        </Flex>
                      </Flex>
                      {/* Email Address readonly Field */}
                      <Flex direction="column" gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                        <Flex direction="column" gap="1" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                          <Text as="label" size="2" weight="medium">Email address</Text>
                          <TextField.Root style={{width: '100%'}} value={formState.email} readOnly/>
                        </Flex>
                      </Flex>
                      {/* New and Confirm new password */}
                      <Flex gap="2" direction="column" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                        <Flex direction="row" gap="4" justifyContent="space-between">
                          <Flex direction="column" gap="1" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                            <Text as="label" size="2" weight="medium" style={{width: '100%'}}>New password</Text>
                            <TextField.Root style={{width: '100%'}}
                              type='password'
                              name="password"
                              value={formState.password}
                              onChange={(e) => {
                                handleChange(e);
                                if (errors.password) setErrors({...errors, password: ''});
                              }}
                              onBlur={handleBlur}
                            />
                          </Flex>
                          <Flex direction="column" gap="1" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                            <Text as="label" size="2" weight="medium" style={{width: '100%'}}>Confirm password</Text>
                            <TextField.Root style={{width: '100%'}}
                              type='password'
                              name="confirmPassword"
                              value={formState.confirmPassword}
                              onChange={(e) => {
                                handleChange(e);
                                if (errors.confirmPassword) setErrors({...errors, confirmPassword: ''});
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.confirmPassword && (<Text color="red" size="1">{errors.confirmPassword}</Text>)}
                          </Flex>
                        </Flex>
                        <Text size="1" color={(errors.password && formState.password) ? "red" : "gray"} >
                          Your password must be at least 8 characters long and include at least one letter, one number, and one 
                          special character.
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex direction="column" gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                      {/* Launch button */}
                      <Flex gap="4" direction="column" style={{alignItems: 'center', alignSelf: 'stretch', width: '100%'}}>
                        <Button type="submit" size="2" variant="solid" style={{alignSelf: 'stretch'}}>
                          Save and finish
                        </Button>
                        <Text size="1" color="gray" align="center">You can update your details later.</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </form>
              </Flex>
            </Flex>
            {/* Footer links */}
            <Flex gap="7" justify="center" width="100%">
              <Link size="1" weight="regular" color="gray" href="https://b-connect.co.uk/terms" target='_blank'>
                Terms of Service
              </Link>
              <Link size="1" weight="regular" color="gray" href="https://b-connect.co.uk/privacy" target='_blank'>
                Privacy Notice
              </Link>
              <Link size="1" weight="regular" color="gray" href="mailto:support@b-connect.co.uk">Support</Link>
            </Flex>
          </Flex>
          {/* Background Image and  Logo Section */}
          <Flex direction="column" align="stretch" style={{ padding: '80px 120px 0 120px', 
            background: `url(${organisationImageUrl}) no-repeat center center / cover`,
            transition: 'background 0.3s ease-in-out',
            backgroundBlendMode: 'soft-light', flex: '1 1 50%', alignItems: 'center'}}>
            <Icon name='LogoBWifi' color={iconColor} width='20px' height='16px'/>
          </Flex>
        </Flex>
      </React.Fragment>
    </ThemeProvider>
  );
}