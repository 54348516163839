import { useQuery } from "@tanstack/react-query";
import { getAirshipUnits } from "../../api/bAirshipApi";

export const useGetAirshipUnits = (organisationId, enabled = true) => {
  return useQuery({
    queryKey: ["airship-units", organisationId],
    queryFn: () => getAirshipUnits(organisationId),
    enabled: !!organisationId && enabled,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};
