import { useState, useEffect } from "react";
import { Flex, Text, Button } from "@radix-ui/themes";
import { MenuItem } from "components";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

export function IntegrationsLayout() {
  const navigate = useNavigate();
  const location = useLocation();

  // Helper function to get menu state from path
  const getMenuStateFromPath = (path) => {
    if (path.includes("/integrations/active")) return "active";
    if (path.includes("/integrations/browse-all")) return "browse-all";
    return "active"; // default
  };

  // State to manage menu selection
  const [integrationsMenuSelection, setIntegrationsMenuSelection] = useState(
    getMenuStateFromPath(location.pathname)
  );

  const handleIntegrationsMenuSelection = (flag) => {
    setIntegrationsMenuSelection(flag);
    navigate(flag);
  };

  // Update menu selection on path change
  useEffect(() => {
    setIntegrationsMenuSelection(getMenuStateFromPath(location.pathname));
  }, [location.pathname]);

  return (
    <Flex
      className="guest-wifi-container"
      display="flex"
      direction="column"
      align="start"
      flexGrow="1"
    >
      <Flex
        className="guest-wifi-layout"
        style={{ alignSelf: "stretch" }}
        display="flex"
        p="8"
        direction="column"
        align="start"
        gap="8"
      >
        <Flex
          className="header-container"
          style={{ alignSelf: "stretch" }}
          display="flex"
          justify="center"
          align="end"
          gap="2"
        >
          <Flex
            className="date-greeting-container"
            display="flex"
            justify="between"
            align="end"
            flexGrow="1"
          >
            <Text size="6" weight="bold">
              Integrations
            </Text>
            <Flex
              display="flex"
              height="var(--space-6)"
              p="3"
              justify="center"
              align="center"
              gap="2"
            >
              <Button
                variant="ghost"
                color="gray"
                onClick={() => {
                  const supportEmail = "support@b-connect.co.uk";
                  const subject = "Support Request";
                  const body = "Hello, I need help with...";
                  const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(
                    subject
                  )}&body=${encodeURIComponent(body)}`;
                  window.location.href = mailtoLink;
                }}
              >
                Contact support
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          className="main-content"
          style={{ alignSelf: "stretch" }}
          display="flex"
          align="start"
          gap="8"
        >
          <Flex
            className="main-content-sidebar"
            display="flex"
            width="144px"
            direction="column"
            align="start"
            gap="1"
          >
            <MenuItem
              flag="active"
              state={integrationsMenuSelection}
              onClick={handleIntegrationsMenuSelection}
              text="Connected"
            />
            <MenuItem
              flag="browse-all"
              state={integrationsMenuSelection}
              onClick={handleIntegrationsMenuSelection}
              text="Browse all"
            />
          </Flex>
          <Flex
            style={{
              alignSelf: "stretch",
              flex: "1 0 0",
              alignItems: "flex-start",
              height: "100%",
            }}
          >
            <Outlet />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
