import React from "react";
import { Dialog, Flex, Text, TextField, Button } from "@radix-ui/themes";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

export function AuthAirshipDialog({ isOpen, onOpenChange, onCancel, onSave, handleApiKeyChange, handleAccountIdChange, isApiKeyValid, isAccountIdValid, isLoading, title = "Connect Airship" }) {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content maxWidth="600px" maxHeight="690px" align="center">
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          To find your API key, log into Airship and navigate to Settings → Api access to copy or create one. See Airship's API access tokens guide for details. If you don't have your Airship Account
          ID on hand, contact the Airship Customer Success team at success@airship.co.uk.
        </Dialog.Description>

        <Flex className="slot-container" style={{ alignSelf: "stretch" }} display="flex" direction="row" align="start" gap="5">
          <Flex className="api-key-field" style={{ flex: 1 }} display="flex" direction="column" align="start" gap="1">
            <Text size="2" weight="medium">
              API key
            </Text>
            <TextField.Root onChange={handleApiKeyChange} placeholder="Paste your key here" style={{ width: "100%" }} size="2" variant="surface" />
            {!isApiKeyValid && (
              <Text size="1" weight="regular" color="red">
                Please enter a valid API key.
              </Text>
            )}
          </Flex>

          <Flex className="account-id-field" style={{ flex: 1 }} display="flex" direction="column" align="start" gap="1">
            <Text size="2" weight="medium">
              Account ID
            </Text>
            <TextField.Root onChange={handleAccountIdChange} placeholder="Paste your ID here" style={{ width: "100%" }} size="2" variant="surface" />
            {!isAccountIdValid && (
              <Text size="1" weight="regular" color="red">
                Please enter a valid Account ID.
              </Text>
            )}
          </Flex>
        </Flex>

        <Flex
          style={{
            alignSelf: "stretch",
            width: "100%",
          }}
          display="flex"
          justify="between"
          align="center"
          mt="4"
        >
          <Button size="2" variant="ghost" color="gray" onClick={() => window.open("https://dashboard.airship.co.uk/personal-access-tokens", "_blank")}>
            <Flex display="flex" align="center" gap="2">
              Open Airship
              <ArrowTopRightOnSquareIcon style={{ width: "16px", height: "16px" }} />
            </Flex>
          </Button>

          <Flex display="flex" gap="3" justify="end">
            <Dialog.Close>
              <Button onClick={onCancel} size="2" variant="soft" color="gray" style={{ cursor: "pointer" }}>
                Cancel
              </Button>
            </Dialog.Close>
            <Button onClick={onSave} size="2" variant="soft" color="accent" loading={isLoading} style={{ cursor: "pointer" }}>
              Save and continue
            </Button>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}
