import React, { useState, useRef, useEffect } from "react";
import { Text, Flex, Button, DropdownMenu, Progress } from "@radix-ui/themes";
import { LocationMultiSelect } from "components";
import { useGetCustomers, useGetActiveIntegrations } from "hooks";
import { useLocationContext, useProfileContext } from "context";
import { ChevronDownIcon, CaretSortIcon, CaretUpIcon, CaretDownIcon } from "@radix-ui/react-icons";
import { MultiSelect } from "components/multiSelect/MultiSelect";
import { Table, Card } from "@radix-ui/themes";
import NoResults from "./NoResults";
import { attemptGetCustomers } from "api/UsersApi";
import { useNavigate } from "react-router-dom";
import { FilterByMultiSelect } from "components/multiSelect/FilterByMultiSelect";
import { ZonesMultiSelect } from "components/zonesMultiSelect/ZonesMultiSelect";
import { useGetZones, useGetAirshipStatus } from "hooks";
import { useQueryClient } from "@tanstack/react-query";

export default function Customers() {
  const { initialItems, locationSelection, setLocationSelection, locationsData, isLocationsSuccess, allLocations } = useLocationContext();
  const queryClient = useQueryClient();
  const { userProfile } = useProfileContext();
  const { data: activeIntegrationsData } = useGetActiveIntegrations(userProfile?.organisation_id);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const { data: airshipStatusData, isLoading: isStatusLoading } = useGetAirshipStatus(userProfile.organisation_id);

  const isMailchimpActive = () => {
    if (!activeIntegrationsData?.data?.integrations) return false;
    return activeIntegrationsData.data.integrations.some((integration) => integration.name === "mailchimp");
  };

  const isAirshipActive = () => {
    if (isStatusLoading) return false;
    return airshipStatusData?.data?.is_active === true;
  };

  const [zoneSelection, setZoneSelection] = useState([]);

  // Add this to fetch zones data based on location selection
  const { data: zonesData } = useGetZones(locationSelection?.map((location) => parseInt(location)) || []);

  useEffect(() => {
    if (zonesData && zonesData.length > 0) {
      const allZoneIds = zonesData.map((zone) => zone.id.toString());
      setZoneSelection(["null", ...allZoneIds]);
    }
  }, [zonesData]);

  // Define zone options
  const zoneOptions = zonesData && zonesData.length > 0 ? [...zonesData, { id: "null", name: "No zone" }] : [];

  const marketingOptions = [
    { label: "Subscribed", value: "subscribed" },
    { label: "Unsubscribed", value: "unsubscribed" },
  ];

  // Define the options
  const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "others" },
    { label: "No Response", value: "prefer not to respond" },
  ];

  const ageOptions = [
    { label: "Under 18", value: "Under 18" },
    { label: "18-24", value: "18-24" },
    { label: "25-34", value: "25-34" },
    { label: "35-44", value: "35-44" },
    { label: "45-54", value: "45-54" },
    { label: "55-64", value: "55-64" },
    { label: "65+", value: "65+" },
    { label: "No Response", value: "prefer not to respond" },
  ];

  const filterOptions = [
    { label: "Email marketing", value: "marketing_status" },
    { label: "Age", value: "age" },
    { label: "Gender", value: "gender" },
  ];

  const navigate = useNavigate();

  // Initialize selections with all options
  const [ageSelection, setAgeSelection] = useState(ageOptions.map((option) => option.value));
  const [genderSelection, setGenderSelection] = useState(genderOptions.map((option) => option.value));
  const [marketingSelection, setMarketingSelection] = useState(marketingOptions.map((option) => option.value));
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Create filters object when any selection changes

  const filters = React.useMemo(() => {
    const includeNullZones = zoneSelection.includes("null");
    const nonNullZones = zoneSelection.filter((zone) => zone !== "null");

    return {
      gender: genderSelection,
      age: ageSelection,
      location_ids: locationSelection,
      marketing_status: marketingSelection,
      zone_ids: nonNullZones,
      include_null_zones: includeNullZones,
      sort_column: sortColumn,
      sort_direction: sortDirection,
    };
  }, [genderSelection, ageSelection, locationSelection, marketingSelection, zoneSelection, sortColumn, sortDirection]);

  // const { data: customerData, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, error } = useGetCustomers(filters, false);
  const { data: customerData, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, error } = useGetCustomers(filters, true, ["filteredCustomers", JSON.stringify(filters)]);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);

    // Format time
    const timeString = date
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
      .toLowerCase();

    // Check if date is today
    if (date.toDateString() === now.toDateString()) {
      return `Today at ${timeString}`;
    }

    // Check if date is yesterday
    if (date.toDateString() === yesterday.toDateString()) {
      return `Yesterday at ${timeString}`;
    }

    // Format older dates
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    return `${formattedDate} at ${timeString}`;
  };

  // Create an all-inclusive filter for the export all functionality
  const allFilters = React.useMemo(
    () => ({
      gender: genderOptions.map((option) => option.value),
      age: ageOptions.map((option) => option.value),
      location_ids: allLocations,
      marketing_status: marketingOptions.map((option) => option.value),
      zone_ids: zonesData ? zonesData.map((zone) => zone.id.toString()) : [],
    }),
    [genderOptions, ageOptions, allLocations, marketingOptions, zonesData]
  );

  // Additional hook instance for all customers
  // const { data: allCustomers, isLoading: isLoadingAll } = useGetCustomers(allFilters);
  // const { data: allCustomers, isLoading: isLoadingAll } = useGetCustomers(
  //   // Pass sort params as null to ensure consistent results
  //   { ...allFilters, sort_column: null, sort_direction: null },
  //   true,
  //   ["totalCustomers", JSON.stringify(allFilters)]
  // );

  const totalFilters = React.useMemo(
    () => ({
      gender: genderOptions.map((option) => option.value),
      age: ageOptions.map((option) => option.value),
      location_ids: allLocations, // All locations regardless of current selection
      marketing_status: marketingOptions.map((option) => option.value),
      zone_ids: [], // Don't filter by zones for total count
      sort_column: null,
      sort_direction: null,
    }),
    [genderOptions, ageOptions, allLocations, marketingOptions] // Remove dependencies on selection
  );

  const { data: allCustomers, isLoading: isLoadingAll } = useGetCustomers(
    totalFilters,
    true,
    ["totalCustomers"] // Use a static query key
  );

  useEffect(() => {
    // Only invalidate filtered customers when filters change
    queryClient.invalidateQueries(["filteredCustomers"]);

    // Don't invalidate totalCustomers
  }, [genderSelection, ageSelection, locationSelection, marketingSelection, zoneSelection, sortColumn, sortDirection, queryClient]);

  const [totalCustomers, setTotalCustomers] = useState(0);
  useEffect(() => {
    if (allCustomers?.pages?.[0]?.total_count) {
      setTotalCustomers(allCustomers.pages[0].total_count);
    }
  }, [allCustomers]);

  // Add this with other state declarations
  const [filteredCustomers, setFilteredCustomers] = useState(0);

  // Add this useEffect after your existing effects
  useEffect(() => {
    if (customerData?.pages?.[0]?.total_count) {
      setFilteredCustomers(customerData.pages[0].total_count);
    }
  }, [customerData]);

  const downloadCSV = (data, filename) => {
    // Create dynamic headers based on visible columns
    const baseHeaders = ["Email", "Email marketing"];
    const optionalHeaders = [];
    
    if (showAgeColumn) optionalHeaders.push("Age group");
    if (showDobColumn) optionalHeaders.push("Date of birth");
    if (showGenderColumn) optionalHeaders.push("Gender");
    
    const finalHeaders = [
      ...baseHeaders,
      ...optionalHeaders,
      "First connected", 
      "Last connected", 
      "Last connected location", 
      "Connections"
    ];
  
    // Process each row based on visible columns
    const csvData = data.map((customer) => {
      const baseData = [
        customer.email_address,
        customer.marketing_status
      ];
      
      const optionalData = [];
      if (showAgeColumn) optionalData.push(customer.age === 'field_disabled' ? '' : customer.age);
      if (showDobColumn) optionalData.push(customer.dob || '');
      if (showGenderColumn) optionalData.push(customer.gender === 'Field_disabled' ? '' : customer.gender);
      
      const finalData = [
        ...baseData,
        ...optionalData,
        new Date(customer.created).toLocaleString(),
        new Date(customer.last_seen).toLocaleString(),
        customer.last_connected_location || "",
        customer.connections || 0
      ];
      
      return finalData;
    });
  
    const csvContent = [finalHeaders.join(","), ...csvData.map((row) => row.join(","))].join("\n");
  
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [isExporting, setIsExporting] = useState(false);
  const [exportProgress, setExportProgress] = useState(0);

  const fetchAllPages = async (filtersToUse) => {
    let allCustomers = [];
    let currentPage = 1;
    let hasMore = true;

    while (hasMore) {
      // Use the hook function but await its result
      const response = await attemptGetCustomers({
        ...filtersToUse,
        page: currentPage,
      });

      const { data, has_more, total_count } = response.data;
      allCustomers = [...allCustomers, ...data];
      hasMore = has_more;

      // Calculate and update progress
      const progress = Math.round((allCustomers.length / total_count) * 100);
      setExportProgress(progress);

      currentPage++;
    }

    return allCustomers;
  };

  const handleExportAll = async () => {
    try {
      setIsExporting(true);
      setExportProgress(5);

      const allData = await fetchAllPages(allFilters);
      downloadCSV(allData, "all_customers.csv");
    } catch (error) {
      console.error("Export failed:", error);
    } finally {
      setIsExporting(false);
      setExportProgress(0);
    }
  };

  const handleExportFiltered = async () => {
    try {
      setIsExporting(true);
      setExportProgress(5);

      const filteredData = await fetchAllPages(filters);
      downloadCSV(filteredData, "filtered_customers.csv");
    } catch (error) {
      console.error("Export failed:", error);
    } finally {
      setIsExporting(false);
      setExportProgress(0);
    }
  };

  const sortData = (data, column, direction) => {
    // Return empty array if no data or data is not an array
    if (!data || !Array.isArray(data)) return [];

    // Return original data if no sorting needed
    if (!column || !direction) return data;

    return [...data].sort((a, b) => {
      if (column === "age") {
        const ageOrder = ageOptions.map((opt) => opt.value);
        const indexA = ageOrder.indexOf(a.age);
        const indexB = ageOrder.indexOf(b.age);
        return direction === "asc" ? indexA - indexB : indexB - indexA;
      }

      if (column === "created" || column === "last_seen") {
        return direction === "asc" ? new Date(a[column]) - new Date(b[column]) : new Date(b[column]) - new Date(a[column]);
      }

      // Handle connections (numeric sorting)
      if (column === "connections") {
        return direction === "asc" ? (a[column] || 0) - (b[column] || 0) : (b[column] || 0) - (a[column] || 0);
      }

      // Handle last_connected_location - place empty values at the end
      if (column === "last_connected_location") {
        const valueA = a[column] || "";
        const valueB = b[column] || "";

        // If one value is empty and the other isn't, sort empty values to the end
        if (valueA && !valueB) return direction === "asc" ? -1 : 1;
        if (!valueA && valueB) return direction === "asc" ? 1 : -1;

        // Normal string comparison for non-empty values
        return direction === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }

      // For other text fields (email, gender)
      return direction === "asc" ? (a[column] || "").localeCompare(b[column] || "") : (b[column] || "").localeCompare(a[column] || "");
    });
  };

  const handleHeaderClick = (column) => {
    setSortColumn((oldColumn) => {
      if (oldColumn !== column) return column;
      return column;
    });

    setSortDirection((oldDirection) => {
      if (!oldDirection) return "desc";
      if (oldDirection === "desc") return "asc";
      return null;
    });

    queryClient.invalidateQueries(["filteredCustomers"]);
  };

  const customers = React.useMemo(() => {
    if (!customerData?.pages) return [];
    // Flatten all pages into a single array
    return customerData.pages.flatMap((page) => page.data);
  }, [customerData?.pages]);

  const bottomRef = useRef(null);

  // Add this effect for intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 0.1 }
    );

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, [bottomRef, fetchNextPage, hasNextPage, isFetchingNextPage]);

  const areAllFiltersSelected = () => {
    return (
      ageSelection.length === ageOptions.length &&
      genderSelection.length === genderOptions.length &&
      marketingSelection.length === marketingOptions.length &&
      locationSelection.length === allLocations.length &&
      (zoneOptions.length === 0 || zoneSelection.length === zoneOptions.length)
    );
  };

  const [showAgeColumn, setShowAgeColumn] = useState(true);
  const [showDobColumn, setShowDobColumn] = useState(true);
  const [showGenderColumn, setShowGenderColumn] = useState(true);

  useEffect(() => {
    if (customers && customers.length > 0) {
      // Check if any customer has age data
      const hasAgeData = customers.some(customer => 
        customer.age && customer.age !== 'field_disabled' && customer.age !== '');
      setShowAgeColumn(hasAgeData);
      
      // Check if any customer has DOB data
      const hasDobData = customers.some(customer => 
        customer.dob !== null && customer.dob !== '');
      setShowDobColumn(hasDobData);
      
      // Check if any customer has gender data
      const hasGenderData = customers.some(customer => 
        customer.gender && customer.gender !== 'Field_disabled' && customer.gender !== '');
      setShowGenderColumn(hasGenderData);
    }
  }, [customers]);

  const calculateColumnWidth = () => {
    // Start with the always visible columns (email, marketing, first/last connected, location, connections)
    let visibleColumnCount = 6;
    
    // Add conditional columns
    if (showAgeColumn) visibleColumnCount++;
    if (showDobColumn) visibleColumnCount++;
    if (showGenderColumn) visibleColumnCount++;
    
    // Calculate percentage width (divide 100% by number of columns)
    return `${100 / visibleColumnCount}%`;
  };


  return (
    <Flex className="content-container" direction="column" gap="5" height="100%" style={{ alignItems: "flex-start", flex: "1 0 0", alignSelf: "stretch" }}>
      <Flex p="8" direction="column" gap="2" height="100%" style={{ alignSelf: "stretch", alignItems: "flex-start", flex: "1 0 0" }}>
        {/* Customers Page Header Section */}
        <Flex className="header-container" style={{ alignSelf: "stretch" }} justify="center" align="end">
          <Flex className="date-greeting-container" justify="between" style={{ flex: "1 0 0", alignItems: "center", alignSelf: "stretch" }}>
            <Text size="6" weight="bold">
              Customers
            </Text>
            <Flex display="flex" height="var(--space-6)" justify="center" align="center">
              {isExporting && (
                <div style={{ position: "fixed", top: 0, left: 0, width: "100%", zIndex: 1000}}>
                  <Progress value={exportProgress} max={100} size="1" radius="none" />
                </div>
              )}

              {/* Export and count in their own group with proper spacing */}
              <Flex align="center" gap="5">
                {/* Export dropdown */}
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger style={{ cursor: "pointer" }}>
                    <Button size="2" variant="ghost" color="gray">
                      Export
                      <ChevronDownIcon width="16" height="16" />
                    </Button>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content>
                    <DropdownMenu.Item style={{ cursor: "pointer" }} onClick={handleExportAll}>
                      Export all
                    </DropdownMenu.Item>
                    <DropdownMenu.Item style={{ cursor: "pointer" }} onClick={handleExportFiltered}>
                      Export current filter
                    </DropdownMenu.Item>
                    {!isMailchimpActive() && (
                      <>
                        <DropdownMenu.Separator />
                        <DropdownMenu.Item style={{ cursor: "pointer" }} onClick={() => navigate("/integrations/browse-all")}>
                          Integrate Mailchimp
                        </DropdownMenu.Item>
                      </>
                    )}
                    {!isAirshipActive() && (
                      <>
                        {isMailchimpActive() ? <DropdownMenu.Separator /> : null}
                        <DropdownMenu.Item style={{ cursor: "pointer" }} onClick={() => navigate("/integrations/browse-all")}>
                          Integrate Airship
                        </DropdownMenu.Item>
                      </>
                    )}
                  </DropdownMenu.Content>
                </DropdownMenu.Root>
                {/* No of Customers */}
                <Text size="1" weight="regular" color="gray" style={{ marginRight: "24px" }}>
                  {areAllFiltersSelected()
                    ? `${totalCustomers} total customer${totalCustomers === 1 ? "" : "s"}`
                    : customers.length > 0
                    ? `${filteredCustomers || 0} of ${totalCustomers} customer${totalCustomers === 1 ? "" : "s"} displayed`
                    : `0 of ${totalCustomers} customer${totalCustomers === 1 ? "" : "s"} displayed`}
                </Text>
              </Flex>
              {/* Filter options in their own group */}
              <Flex align="center" gap="2">
                {/* First: FilterByMultiSelect */}
                <FilterByMultiSelect options={filterOptions} defaultValue={selectedCategories} onValueChange={setSelectedCategories} />
                {zoneOptions.length > 0 && <ZonesMultiSelect options={zoneOptions} defaultValue={zoneSelection} onValueChange={setZoneSelection} />}
                {/* Second: Location Filter */}
                <LocationMultiSelect
                  options={initialItems}
                  defaultValue={locationSelection}
                  onValueChange={(value) => {
                    setLocationSelection(value);
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {/* Separate container for conditional filters - only renders if at least one category is selected */}
        {selectedCategories.length > 0 && (
          <Flex className="filter-bar-bottom" justify="end" align="center" gap="2" style={{ alignSelf: "stretch", paddingTop: "40px" }}>
            <Text weight="regular" size="2" color="gray">
              Filter
            </Text>
            {selectedCategories.includes("marketing_status") && (
              <MultiSelect
                options={marketingOptions}
                defaultValue={marketingOptions.map((option) => option.value)}
                onValueChange={(value) => {
                  setMarketingSelection(value);
                }}
                placeholder="Email marketing"
              />
            )}

            {selectedCategories.includes("age") && (
              <MultiSelect
                options={ageOptions}
                defaultValue={ageOptions.map((option) => option.value)}
                onValueChange={(value) => {
                  setAgeSelection(value);
                }}
                placeholder="Age"
              />
            )}

            {selectedCategories.includes("gender") && (
              <MultiSelect
                options={genderOptions}
                defaultValue={genderOptions.map((option) => option.value)}
                onValueChange={(value) => {
                  setGenderSelection(value);
                }}
                placeholder="Gender"
              />
            )}
          </Flex>
        )}
        {/* Customers Page Data Section */}
        <Flex direction="column" gap="5" style={{ alignItems: "flex-start", alignSelf: "stretch", flex: "1 0 0", height: "100%", marginTop: "16px" }}>
          {/* Customers Table */}
          {!isLoading && customers?.length === 0 ? (
            <NoResults />
          ) : (
            <Card p="4" gap="4" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", alignSelf: "stretch", overflow: "scroll", flex: "1 0 0", height: "100%" }}>
              <Table.Root style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", 
                alignSelf: "stretch", height: "100%", tableLayout: "fixed", width: "100%", 
                borderCollapse: "separate" }}>
                {/* Table Header */}
                <Table.Header style={{ position: "sticky", top: "0", zIndex: "1", 
                  backgroundColor: "var(--color-panel-solid)" }}>
                  <Table.Row align="center">
                    {/* Email address column - always visible */}
                    <Table.ColumnHeaderCell style={{ cursor: "pointer", width: calculateColumnWidth(), 
                      position: "sticky", top: "0", zIndex: "10" }}
                      onClick={() => handleHeaderClick("email_address")}>
                      <Flex align="center" gap="1">
                        <Text size="2" weight="medium" wrap="nowrap" truncate style={{ flexShrink: 1, overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 16px)" }}>
                          Email address
                        </Text>
                        {sortColumn === "email_address" && (sortDirection === "asc" ? <CaretUpIcon /> : sortDirection === "desc" ? <CaretDownIcon /> : <CaretSortIcon />)}
                      </Flex>
                    </Table.ColumnHeaderCell>
                    {/* Email marketing column - always visible */}
                    <Table.ColumnHeaderCell style={{ cursor: "pointer", width: calculateColumnWidth(), }} 
                      onClick={() => handleHeaderClick("marketing_status")}>
                      <Flex align="center" gap="1">
                        <Text size="2" weight="medium" wrap="nowrap" truncate style={{ flexShrink: 1, overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 16px)" }}>
                          Email marketing
                        </Text>
                        {sortColumn === "marketing_status" && (sortDirection === "asc" ? <CaretUpIcon /> : sortDirection === "desc" ? <CaretDownIcon /> : <CaretSortIcon />)}
                      </Flex>
                    </Table.ColumnHeaderCell>
                    {/* Conditional columns */}
                    {showAgeColumn && (
                    <Table.ColumnHeaderCell style={{ cursor: "pointer", width: calculateColumnWidth() }} 
                      onClick={() => handleHeaderClick("age")}>
                      <Flex align="center" gap="1">
                        <Text size="2" weight="medium" wrap="nowrap" truncate style={{ flexShrink: 1, overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 16px)" }}>
                          Age group
                        </Text>
                        {sortColumn === "age" && (sortDirection === "asc" ? <CaretUpIcon /> : sortDirection === "desc" ? <CaretDownIcon /> : <CaretSortIcon />)}
                      </Flex>
                    </Table.ColumnHeaderCell>
                    )}
                    {showDobColumn && (
                    <Table.ColumnHeaderCell style={{ cursor: "pointer", width: calculateColumnWidth() }} 
                      onClick={() => handleHeaderClick("dob")}>
                      <Flex align="center" gap="1">
                        <Text size="2" weight="medium" wrap="nowrap" truncate style={{ flexShrink: 1, overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 16px)" }}>
                          Date of birth
                        </Text>
                        {sortColumn === "dob" && (sortDirection === "asc" ? <CaretUpIcon /> : sortDirection === "desc" ? <CaretDownIcon /> : <CaretSortIcon />)}
                      </Flex>
                    </Table.ColumnHeaderCell>
                    )}
                    {showGenderColumn && (
                    <Table.ColumnHeaderCell style={{ cursor: "pointer", width: calculateColumnWidth() }} 
                      onClick={() => handleHeaderClick("gender")}>
                      <Flex align="center" gap="1">
                        <Text size="2" weight="medium" wrap="nowrap" truncate style={{ flexShrink: 1, overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 16px)" }}>
                          Gender
                        </Text>
                        {sortColumn === "gender" && (sortDirection === "asc" ? <CaretUpIcon /> : sortDirection === "desc" ? <CaretDownIcon /> : <CaretSortIcon />)}
                      </Flex>
                    </Table.ColumnHeaderCell>
                    )}
                    <Table.ColumnHeaderCell style={{ cursor: "pointer", width: calculateColumnWidth() }} 
                      onClick={() => handleHeaderClick("created")}>
                      <Flex align="center" gap="1">
                        <Text size="2" weight="medium" wrap="nowrap" truncate style={{ flexShrink: 1, overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 16px)" }}>
                          First connected
                        </Text>
                        {sortColumn === "created" && (sortDirection === "asc" ? <CaretUpIcon /> : sortDirection === "desc" ? <CaretDownIcon /> : <CaretSortIcon />)}
                      </Flex>
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell style={{ cursor: "pointer", width: calculateColumnWidth() }} 
                      onClick={() => handleHeaderClick("last_seen")}>
                      <Flex align="center" gap="1">
                        <Text size="2" weight="medium" wrap="nowrap" truncate style={{ flexShrink: 1, overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 16px)" }}>
                          Last connected
                        </Text>
                        {sortColumn === "last_seen" && (sortDirection === "asc" ? <CaretUpIcon /> : sortDirection === "desc" ? <CaretDownIcon /> : <CaretSortIcon />)}
                      </Flex>
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell style={{ cursor: "pointer", width: calculateColumnWidth() }} 
                      onClick={() => handleHeaderClick("last_connected_location")}>
                      <Flex align="center" gap="1">
                        <Text size="2" weight="medium" wrap="nowrap" truncate style={{ flexShrink: 1, overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 16px)" }}>
                          Last connected location
                        </Text>
                        {sortColumn === "last_connected_location" && (sortDirection === "asc" ? <CaretUpIcon /> : sortDirection === "desc" ? <CaretDownIcon /> : <CaretSortIcon />)}
                      </Flex>
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell style={{ cursor: "pointer", width: calculateColumnWidth() }} 
                      onClick={() => handleHeaderClick("connections")}>
                      <Flex align="center" gap="1">
                        <Text size="2" weight="medium" wrap="nowrap" truncate style={{ flexShrink: 1, overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 16px)" }}>
                          Connections
                        </Text>
                        {sortColumn === "connections" && (sortDirection === "asc" ? <CaretUpIcon /> : sortDirection === "desc" ? <CaretDownIcon /> : <CaretSortIcon />)}
                      </Flex>
                    </Table.ColumnHeaderCell>
                  </Table.Row>
                </Table.Header>

                {/* Table Body */}
                <Table.Body>
                  {!isLoading &&
                    customers?.map((customer, index) => (
                      <Table.Row key={index} align="center">
                        <Table.Cell style={{ width: calculateColumnWidth() }}>
                          <Text weight="regular" size="2" color="gray" wrap="nowrap" truncate>
                            {customer.email_address}
                          </Text>
                        </Table.Cell>
                        <Table.Cell style={{ width: calculateColumnWidth() }}>
                          <Text weight="regular" size="2" color="gray" wrap="nowrap" truncate>
                            {customer.marketing_status}
                          </Text>
                        </Table.Cell>
                        {showAgeColumn && (
                        <Table.Cell style={{ width: calculateColumnWidth() }}>
                          <Text weight="regular" size="2" color="gray" wrap="nowrap" truncate>
                            {customer.age === 'field_disabled' ? '' : customer.age}
                          </Text>
                        </Table.Cell>
                        )}
                        {showDobColumn && (
                        <Table.Cell style={{ width: calculateColumnWidth() }}>
                          <Text weight="regular" size="2" color="gray" wrap="nowrap" truncate>
                            {customer.dob === null ? "" : customer.dob}
                          </Text>
                        </Table.Cell>
                        )}
                        {showGenderColumn && (
                        <Table.Cell style={{ width: calculateColumnWidth() }}>
                          <Text weight="regular" size="2" color="gray" wrap="nowrap" truncate>
                            {customer.gender === 'Field_disabled' ? '' : customer.gender}
                          </Text>
                        </Table.Cell>
                        )}
                        <Table.Cell style={{ width: calculateColumnWidth() }}>
                          <Text weight="regular" size="2" color="gray" wrap="nowrap" truncate>
                            {formatDate(customer.created)}
                          </Text>
                        </Table.Cell>
                        <Table.Cell style={{ width: calculateColumnWidth() }}>
                          <Text weight="regular" size="2" color="gray" wrap="nowrap" truncate>
                            {formatDate(customer.last_seen)}
                          </Text>
                        </Table.Cell>
                        <Table.Cell style={{ width: calculateColumnWidth() }}>
                          <Text weight="regular" size="2" color="gray" wrap="nowrap" truncate>
                            {customer.last_connected_location || "-"}
                          </Text>
                        </Table.Cell>
                        <Table.Cell style={{ width: calculateColumnWidth() }}>
                          <Text weight="regular" size="2" color="gray" wrap="nowrap" truncate>
                            {customer.connections || 0}
                          </Text>
                        </Table.Cell>
                      </Table.Row>
                    ))}

                  {/* Infinite scroll trigger element */}
                  <div ref={bottomRef} style={{ height: "20px" }} />
                </Table.Body>
              </Table.Root>
            </Card>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
