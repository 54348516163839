import React, { useState, useEffect } from "react";
import { ActiveIntegrateCard } from "components";
import { useLocationContext } from "context";
import { StackedStatusBar } from "components/charts/StackedStatusBar";

export function ActiveCiscoMerakiIntegrations() {
  const { locationsData } = useLocationContext();

  // Initialize status bar data state
  const [stackedStatusBarData, setStackedStatusBarData] = useState({
    online: 0,
    offline: 0,
    partially: 0,
  });

  // Update status bar data when locations change
  useEffect(() => {
    if (!locationsData) return;

    // TODO: Replace this with actual Cisco Meraki status data
    // This is a placeholder that sets all locations as online
    const newStats = {
      online: locationsData.length,
      offline: 0,
      partially: 0,
    };

    setStackedStatusBarData(newStats);
  }, [locationsData]);

  return (
    <ActiveIntegrateCard.Root
      name="Cisco Meraki"
      subtitle="Wi-Fi and Presence analytics"
      image_url="cisco.png"
      option={{
        text: "Contact support",
        color: "red",
        onClick: () => {
          const supportEmail = "support@b-connect.co.uk";
          const subject = "Cisco Meraki Support Request";
          const body = "Hello, I need help with Cisco Meraki integration...";
          const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
          window.location.href = mailtoLink;
        },
      }}
    >
      <ActiveIntegrateCard.Section name="Locations" subtitle="Monitor your Cisco Meraki networks across different locations.">
        <StackedStatusBar stackedStatusBarData={stackedStatusBarData} />
      </ActiveIntegrateCard.Section>
    </ActiveIntegrateCard.Root>
  );
}
