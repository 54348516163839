import * as React from "react";
import { Select } from "@radix-ui/themes";

export function DisplayBySelect({ options, value, onValueChange, placeholder }) {
  const [open, setOpen] = React.useState(false);

  const getDisplayText = () => {
    if (!value) return placeholder;
    const selectedOption = options.find((option) => option.value === value);
    // Return just "Display by" if it's the default option or no option is found
    if (!selectedOption || selectedOption.value === "default") {
      return "Display by";
    }
    return `Display by ${selectedOption.label.toLowerCase()}`;
  };

  const handleSelect = (newValue) => {
    if (newValue === value) {
      onValueChange(null); // Reset selection
    } else {
      onValueChange(newValue);
    }
    setOpen(false); // Close dropdown after selection
  };

  return (
    <Select.Root open={open} onOpenChange={setOpen} value={value || undefined} onValueChange={handleSelect}>
      <Select.Trigger variant="soft" size="2" color="gray" style={{ color: "var(--gray-a11)", cursor: "pointer", fontWeight: "500" }}>
        {getDisplayText()}
      </Select.Trigger>
      <Select.Content>
        <Select.Group>
          {options.map((option) => (
            <Select.Item key={option.value} value={option.value}>
              {option.label}
            </Select.Item>
          ))}
        </Select.Group>
      </Select.Content>
    </Select.Root>
  );
}
