import React, {useState, useEffect} from 'react';
import { Flex, Text, Table, Avatar, DropdownMenu, Badge, Button, Select } from "@radix-ui/themes";
import { PersonIcon, DotsHorizontalIcon} from "@radix-ui/react-icons";
import { LocationMultiSelect } from "components";
import { useLocations, useUpdateUserInvite } from "hooks";
import { useToast } from "../../context/ToastContext";

export function OrgInvite({ email, token, role, locations, handleOrgInviteResend, handleOrgInviteDelete, viewerId }) {

  // OrgInvite specific handlers that pass email and token to the parent component.
  const handleInviteResendClick = (e) => handleOrgInviteResend(email, token);
  const handleInviteDeleteClick = (e) => handleOrgInviteDelete(token);
  const { initialItems, setLocationSelection } = useLocations(viewerId);
  const [selectedRole, setSelectedRole] = useState(role.toLowerCase());
  const [selectedLocations, setSelectedLocations] = useState(locations);
  const { mutate: updateUserInvite } = useUpdateUserInvite();
  const { setToastMessage } = useToast();

  const handleLocationClick = async (value) => {
    setLocationSelection(value);
    const updatedLocations = value; // Only use the current selection
    setSelectedLocations(updatedLocations);

    if (updatedLocations && updatedLocations.length > 0) {
      try{
        await updateUserInvite({
            email: email,
            locations_list: updatedLocations,
            invitation_type: "Manager",
            invitation_status: "Pending"
        });
        setToastMessage("Location Access Updated");
      } catch (error) {
        setToastMessage("Invite Update Failed");
      }
    } else {
      setToastMessage("Please select at least 1 location");
    }
  }

  useEffect(() => {
      if (selectedRole === "owner") {
        setSelectedLocations(initialItems);
    }
  }, [selectedRole, initialItems]);

  const handleUserRoleClick = async (value) => {
    setSelectedRole(value);
    if (value === "owner") {
      setSelectedLocations(initialItems);
      try{
      await updateUserInvite({
        email: email,
        locations_list: initialItems.map((item) => item.value),
        invitation_type: "Owner",
        invitation_status: "Pending"
      });
      setToastMessage("Role Updated");
      } catch (error) {
        setToastMessage("Invite Update Failed");
      }
    } else if (value === "manager") {
      setSelectedLocations([]);
      setToastMessage("Please select at least 1 location");
      return;
    }
  };

  return (
    <Table.Row align="center">
      <Table.Cell 
        p="3"
        style={{
          display: 'flex',
          minHeight: '68px',
          alignItems: 'center',
          gap: 'var(--space-3)',
        }}
      >
        <Avatar variant="ghost" size="2" fallback={<PersonIcon />} color="blue" />
        <Flex className="text-container" display="flex" direction="column" justify="center" align="start" gap="var(--space-1)" flexGrow="0" flexShrink="0" flexBasis="0">
          <Badge>Invite Sent</Badge>
          <Text 
          style={{
            color: "var(--gray-a11)",
            whiteSpace: "nowrap", 
            overflow: "hidden", 
            textOverflow: "ellipsis", 
            maxWidth: "200px"
            }} 
            weight="regular" 
            size="1">
            {email}
          </Text>
        </Flex>
      </Table.Cell>
       <Table.Cell  
        p="3"
        minWidth="160px"
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          gap: 'var(--space-3)',
        }}
      >
        <Select.Root 
          size="2" 
          defaultValue={selectedRole} 
          onValueChange={handleUserRoleClick}
        >
          <Select.Trigger variant="ghost" color="gray" style={{width: "100%"}}/>
          <Select.Content>
            <Select.Group>
              <Select.Item value="owner">Owner</Select.Item>
              <Select.Item value="manager" disabled>
                Manager&nbsp;&nbsp;
                <Badge radius="full" color="gray">Coming soon</Badge>
              </Select.Item>
            </Select.Group>
          </Select.Content>
        </Select.Root>
      </Table.Cell>
      <Table.Cell
        p="3"
        size="2"
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
          alignSelf: "stretch"
        }}
      >
        {selectedRole === "owner" ? 
        <span style={{ paddingLeft: '12px', color: 'var(--gray-a11)' }}>All locations</span> : 
          <LocationMultiSelect
            noShowBorder={true}
            options={initialItems}
            defaultValue={selectedLocations}
            onValueChange={handleLocationClick}
          />
        }
      </Table.Cell>
      <Table.Cell justify="end">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <Button variant='ghost' color='gray'>
              <DotsHorizontalIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            size="2"
            variant="solid"
            style={{
              position: 'absolute',
              top: '0',
              left: '100%',
              display: "flex",
              marginLeft: '-120px',
              justifyContent: 'space-between', 
              alignItems: 'center',
              width: '151px',
            }}          
          >
            <DropdownMenu.Item onClick={handleInviteResendClick} >
              Resend Invite
            </DropdownMenu.Item>
            <DropdownMenu.Item color="red" onClick={handleInviteDeleteClick}>
              Cancel Invite
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Table.Cell>
    </Table.Row>
  );
}