import React, { useState } from "react";
import { Card, Flex, Text, Button, Badge } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { AuthAirshipDialog } from "./AuthAirshipDialog";
import { useValidateAirship, useGetAirshipStatus } from "hooks";

export function BrowseAirshipCard({ userProfile }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [apiKey, setApiKey] = useState("");
  const [accountId, setAccountId] = useState("");
  const [isApiKeyValid, setIsApiKeyValid] = useState(true);
  const [isAccountIdValid, setIsAccountIdValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  const validateAirship = useValidateAirship();
  const { data: airshipStatusData, isLoading: isStatusLoading } = useGetAirshipStatus(userProfile.organisation_id);
  const [openDialog, setOpenDialog] = useState(false);

  const isAirshipActive = () => {
    if (isStatusLoading) return false;
    return airshipStatusData?.data?.is_active === true;
  };

  const handleOpenChange = (isOpen) => {
    setOpenDialog(isOpen);
    if (!isOpen) {
      handleCancelAuthDialog();
    }
  };

  const handleCancelAuthDialog = () => {
    setApiKey("");
    setAccountId("");
    setIsApiKeyValid(true);
    setIsAccountIdValid(true);
  };

  const handleApiKeyChange = (event) => {
    setApiKey(event.target.value);
    setIsApiKeyValid(true);
  };

  const handleAccountIdChange = (event) => {
    setAccountId(event.target.value);
    setIsAccountIdValid(true);
  };

  const handleSave = async () => {
    let isValid = true;

    // Check both fields for emptiness
    if (!apiKey) {
      setIsApiKeyValid(false);
      isValid = false;
    }

    if (!accountId) {
      setIsAccountIdValid(false);
      isValid = false;
    }

    // Only proceed if both fields are filled
    if (!isValid) {
      return;
    }

    setIsLoading(true);
    setProgressValue(10);

    try {
      const result = await validateAirship.mutateAsync({
        api_key: apiKey,
        account_id: accountId,
        organisation_id: userProfile.organisation_id,
      });

      setProgressValue(50);

      // If validation was successful, proceed to the next step
      if (result?.data?.status === "success") {
        // Navigate to setup page
        navigate("/airship-setup", {
          state: {
            apiKey,
            accountId,
            orgId: userProfile.organisation_id,
            units: result.data.units,
          },
        });
      } else {
        setIsApiKeyValid(false);
        setIsAccountIdValid(false);
      }
    } catch (error) {
      console.error("Validation error:", error);

      // Check which field is invalid based on the error response
      const invalidField = error.response?.data?.invalid;

      if (invalidField === "api_key") {
        // Only API key is invalid
        setIsApiKeyValid(false);
        setIsAccountIdValid(true);
      } else if (invalidField === "account_id") {
        // Only Account ID is invalid
        setIsApiKeyValid(true);
        setIsAccountIdValid(false);
      } else {
        // Both are invalid or can't determine
        setIsApiKeyValid(false);
        setIsAccountIdValid(false);
      }
    } finally {
      setIsLoading(false);
      setProgressValue(0);
    }
  };

  return (
    <>
      <Card
        style={{
          display: "flex",
          minWidth: "455px",
          padding: "var(--space-4)",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Flex align="center" gap="4">
          <Flex
            style={{
              borderRadius: "var(--space-1)",
              background: "#FFFFFF",
              border: "1px solid var(--gray-a6)",
            }}
            height="40px"
            width="40px"
            p="2"
            justify="center"
            align="center"
          >
            <img src={require("../../../assets/images/airship.png")} alt="airship" style={{ width: "24px" }} />
          </Flex>
          <Flex direction="column" justify="center" align="start">
            <Text size="4" weight="medium">
              Airship
            </Text>
            <Text style={{ color: "var(--gray-a11)" }} size="1">
              CRM & Email marketing
            </Text>
          </Flex>
        </Flex>

        <Flex align="center">
          {isAirshipActive() ? (
            <Badge size="1" variant="soft" color="green">
              Connected
            </Badge>
          ) : (
            <Button onClick={() => handleOpenChange(true)} size="2" variant="soft" color="gray" style={{ cursor: "pointer" }}>
              Connect
            </Button>
          )}
        </Flex>
      </Card>

      <AuthAirshipDialog
        isOpen={openDialog}
        onOpenChange={handleOpenChange}
        onCancel={handleCancelAuthDialog}
        onSave={handleSave}
        handleApiKeyChange={handleApiKeyChange}
        handleAccountIdChange={handleAccountIdChange}
        isApiKeyValid={isApiKeyValid}
        isAccountIdValid={isAccountIdValid}
        isLoading={isLoading}
        progressValue={progressValue}
      />
    </>
  );
}
