import React from 'react';
import { Flex, Card, Table, Text } from "@radix-ui/themes";
import { OrgUser } from './OrgUserRecords';
import { OrgInvite } from './OrgInvitationRecords';
import { useProfileContext } from "context";
import { useGetOrgUsers, useEditOrgUser, useDeleteOrgUser, useGetInviteUsers, useResendInviteUser, useDeleteInviteUser } from "hooks";
import { useToast } from '../../context/ToastContext';
import { ToastAlert } from "components";

const OrganisationTeam = ({}) => {

  const { setToastMessage } = useToast();
  const { userProfile } = useProfileContext();
  const viewerId = userProfile.user_id;
  const { data: orgUser, refetch: refetchOrgUsers } = useGetOrgUsers(viewerId);
  const orgUserList = orgUser?.map((entry) => ({
    id: entry.user.id,
    email: entry.user.email,
    first_name: entry.user.first_name,
    last_name: entry.user.last_name,
    user_role: entry.user.user_role,
    locations: entry.locations,
  }));

  const { data: invitedUser, refetch: refetchInvitedUsers } = useGetInviteUsers();
  const invitedUserList = invitedUser?.map((entry) => entry);

  const { mutate: editUser } = useEditOrgUser();
  const { mutate: deleteUser } = useDeleteOrgUser();

  const handleOrgUserChange = (value, orgUserId, type) => {
    if (value != null && (typeof value !== 'string' || value.trim() !== "")) {
      editUser({
        owner_id: viewerId,
        data: { id: orgUserId, [type]: value }
      });
    }
  };

  const handleOrgUserDelete = (viewerId, orgUserId) => {
    deleteUser({
      owner_id: viewerId,
      data: { id: orgUserId }
    });
    setToastMessage("User deleted");
    refetchOrgUsers();
    refetchInvitedUsers();
  };

  const { mutate: resendInvite } = useResendInviteUser();
  const handleOrgInviteResend = (email, token) => {
    let payload = { email: email, token: token }
    resendInvite(payload);
    setToastMessage("Invitation resent");
    refetchOrgUsers();
    refetchInvitedUsers();
  };

  const { mutate: deleteInvite } = useDeleteInviteUser();
  const handleOrgInviteDelete = (token) => {
    let payload = { token: token }
    deleteInvite(payload);
    setToastMessage("Invitation cancelled");
    refetchOrgUsers();
    refetchInvitedUsers();
  };


    return (
    <Flex className="team-details-container" direction="column" gap="5" 
      style={{alignItems: 'flex-start', flex: '1 0 0', alignSelf: 'stretch', overflow: 'scroll', 
      maxHeight: 'calc(100vh - 184px)'}}>
      {/* Team Details */}
      <Card style={{ display: 'flex', flexDirection: "column", alignItems: "flex-start", gap: "var(--space-4)",
        alignSelf: "stretch", flex: '1 0 0', padding: 'var(--space-4)', height: '100%'}}>
        <Table.Root style={{display: "flex", flexDirection: "column", alignItems: "flex-start", 
          alignSelf: "stretch", height:"100%"}}>
          <Table.Header style={{  position:"sticky", top: "0", zIndex: "1", backgroundColor: 'var(--color-panel-solid)'}}>
            <Table.Row align="center">
                <Table.ColumnHeaderCell style={{width: "30%"}}>
                  <Text size="2" weight="medium">Name</Text>
                </Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell style={{width: "30%"}}>
                  <Text size="2" weight="medium">Role</Text>
                </Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell style={{width: "30%"}}>
                  <Text size="2" weight="medium">Locations</Text>
                </Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell style={{width: "10%"}}>
                  <Text size="2" weight="medium"></Text>
                </Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {orgUserList
              ?.sort((a, b) => {
                const firstNameComparison = (a.first_name || "").localeCompare(b.first_name || "");
                if (firstNameComparison !== 0) {
                  return firstNameComparison;
                }
                return (a.last_name || "").localeCompare(b.last_name || "");
              })
              .map((entry, index) => (
                <OrgUser
                  key={index}
                  orgUserId={entry.id}
                  viewerId={viewerId}
                  entry={entry}
                  handleOrgUserChange={handleOrgUserChange}
                  handleOrgUserDelete={handleOrgUserDelete}
                />
              ))}
            {invitedUserList
              ?.sort((a, b) => (a.email || "").localeCompare(b.email || ""))
              .map((entry, index) => (
                <OrgInvite 
                  key={index}
                  email={entry.email}
                  token={entry.token}
                  role={entry.invitation_type}
                  locations={entry.locations_list}
                  handleOrgInviteResend={handleOrgInviteResend}
                  handleOrgInviteDelete={handleOrgInviteDelete}
                  viewerId={viewerId}
                />
              ))}
          </Table.Body>
    </Table.Root>
  </Card>
  {/* Toast Updates */}
  <ToastAlert />
</Flex>
  );
};

export default OrganisationTeam;
