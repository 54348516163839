import { useMutation } from "@tanstack/react-query";
import { saveAirshipMappings } from "../../api/bAirshipApi";

export const useSaveAirshipMappings = () => {
  return useMutation({
    mutationKey: ["save-airship-mappings"],
    mutationFn: (data) => saveAirshipMappings(data),
    onError: (error) => {
      console.error("Airship mapping save error:", error.response || error);
    },
  });
};
