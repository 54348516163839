import { useState, useEffect } from 'react';
import { attemptGetbChatHistory } from '../../api/bChatApi';

export const useGetbChatHistory = (userId) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setError(null);
                const response = await attemptGetbChatHistory(userId);
                setData(response.data);
            } catch (err) {
                setError(err.message || 'Failed to get chat history');
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchData();
        }
    }, [userId]);

    return { data, loading, error };
};
