import * as React from 'react';
import { Text } from '@radix-ui/themes';

export function MenuItem({flag, state, onClick, text}) {

  const color = state === flag ? "var(--gray-a3)" : null;

  return (
    <button 
      className="sidebar-item" 
      style={{
        display: "flex", 
        height: "var(--space-6)", 
        padding: "6px var(--space-3)", 
        alignItems: "center", 
        gap: "var(--space-2)", 
        alignSelf: "stretch", 
        borderRadius: "var(--space-1)", 
        background: color
      }}
      onClick={() => onClick(flag)}
    >
      <Text size="2" weight="regular">
        {text}
      </Text>
    </button>
  );
}