import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Flex,
  Text,
  Button,
  Select,
  Separator,
  Card,
  IconButton,
  Progress,
} from "@radix-ui/themes";
import { ArrowRightIcon, PlusIcon } from "@radix-ui/react-icons";
import { useLocationContext } from "context";
import {
  useUpdateIntegrations,
  useGetController,
  useAuthenticateIntegrations,
} from "hooks";
import { useQueryClient } from "@tanstack/react-query";
import { ActivatedIntegrationCard } from "components";
import { EditControllerDialog } from "./EditControllerDialog";
import { AuthUnifiDialog } from "./AuthUnifiDialog";
import { useToast } from "../../../context/ToastContext";
import { ToastAlert } from "components";

export function UnifiConfigSetupPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { initialItems } = useLocationContext();
  const { authData, orgId } = location.state || {};
  const { setToastMessage } = useToast();

  const [payloadTwo, setPayloadTwo] = useState([{ location: "", site: "" }]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const authenticateIntegrations = useAuthenticateIntegrations();

  const [showError, setShowError] = useState(false);

  const updateIntegrations = useUpdateIntegrations();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  // Add new state for active controller
  const [activeController, setActiveController] = useState(null);
  const [isActivateLoading, setIsActivateLoading] = useState(false);

  // Update the controller data hook to use activeController name
  const { data: controllerData } = useGetController(
    orgId,
    activeController?.name || "", // Use active controller name
    "unifi"
  );

  const [controllerMappings, setControllerMappings] = useState({
    "Controller 1": [{ location: "", site: "" }],
  });

  // Modify edit button handler to set active controller
  const handleEditClick = (controller) => {
    console.log("Editing controller:", controller);
    setActiveController(controller);
    setEditDialogOpen(true);
  };

  useEffect(() => {
    if (controllerData?.data) {
      console.log("Setting controller form data:", controllerData.data);
      setControllerFormData({
        unifi_controller_url: controllerData.data.controller_url,
        username: controllerData.data.username,
        password: controllerData.data.password,
      });

      // Also update validation state
      setValidAuthDetails({
        unifi_url_valid: true,
        username_valid: true,
        password_valid: true,
      });
    }
  }, [controllerData]);

  const handleDialogClose = () => {
    setEditDialogOpen(false);
    setActiveController(null);
  };

  // Add near the top with other state declarations
  const [validAuthDetails, setValidAuthDetails] = useState({
    unifi_url_valid: true,
    username_valid: true,
    password_valid: true,
  });

  const [controllerFormData, setControllerFormData] = useState({
    unifi_controller_url: "",
    username: "",
    password: "",
  });

  const [newControllerForm, setNewControllerForm] = useState({
    unifi_controller_url: "",
    username: "",
    password: "",
  });

  const handleTextFieldChange = (key) => (event) => {
    const value = event.target.value;

    // Update form values
    setNewControllerForm((prev) => ({
      ...prev,
      [key]: value,
    }));

    // Reset validation state for the field being typed in
    if (key === "unifi_controller_url") {
      setValidAuthDetails((prev) => ({
        ...prev,
        unifi_url_valid: true,
      }));
    } else if (key === "username") {
      setValidAuthDetails((prev) => ({
        ...prev,
        username_valid: true,
      }));
    } else if (key === "password") {
      setValidAuthDetails((prev) => ({
        ...prev,
        password_valid: true,
      }));
    }
  };

  // Add these handler functions after other handlers
  const handleEditTextFieldChange = (field) => (e) => {
    const value = e.target.value;
    setControllerFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Validate field
    setValidAuthDetails((prev) => ({
      ...prev,
      [`${field}_valid`]: value.length > 0,
    }));
  };

  // Update handlers
  // const handleAuthCancel = () => {
  //   setShowAuthDialog(false);
  //   setValidAuthDetails({
  //     unifi_url_valid: true,
  //     username_valid: true,
  //     password_valid: true
  //   });
  //   setFormValues({
  //     unifi_controller_url: '',
  //     username: '',
  //     password: ''
  //   });
  // };

  const handleEditCancel = (dialog_type, isOpen) => {
    setEditDialogOpen(false);
    setValidAuthDetails({
      unifi_url_valid: true,
      username_valid: true,
      password_valid: true,
    });
    queryClient.invalidateQueries(["unifi-controller"]);
  };

  const handleEditControllerSave = async () => {
    // Validate all fields
    const isValid = Object.values(validAuthDetails).every((v) => v);
    if (!isValid) return;

    setIsLoading(true);
    setProgressValue(10);

    try {
      setProgressValue(50);
      const result = await authenticateIntegrations.mutateAsync({
        timestamp: Date.now(),
        organisation_id: orgId,
        type: "unifi",
        sub_payload: {
          unifi_controller_url: controllerFormData.unifi_controller_url,
          username: controllerFormData.username,
          password: controllerFormData.password,
          controller_name: activeController?.name,
        },
      });

      setProgressValue(80);
      setEditDialogOpen(false);

      // Refresh data
      queryClient.invalidateQueries(["unifi-controller"]);
    } catch (error) {
      if (error.response?.status === 400) {
        setValidAuthDetails({
          unifi_url_valid: false,
          username_valid: false,
          password_valid: false,
        });
        // Keep dialog open for validation errors
      } else if (error.response?.status === 500) {
        setToastMessage(
          "Internal server error occurred. Please try again later."
        );
        // setEditDialogOpen(false);
      }
    } finally {
      setIsLoading(false);
      setProgressValue(0);
    }
  };

  const handleDropdownFieldChange = (controllerName, index, field) => {
    return (value) => {
      const newControllerMappings = { ...controllerMappings };

      // Initialize controller if not exists
      if (!newControllerMappings[controllerName]) {
        newControllerMappings[controllerName] = [{ location: "", site: "" }];
      }

      // Update the value
      newControllerMappings[controllerName][index][field] = value;

      // Check if this is the last row
      if (index === newControllerMappings[controllerName].length - 1) {
        const currentMapping = newControllerMappings[controllerName][index];

        // Check if both fields are filled in the current row
        if (currentMapping.location && currentMapping.site) {
          // Add new empty row for this controller
          newControllerMappings[controllerName] = [
            ...newControllerMappings[controllerName],
            { location: "", site: "" },
          ];
        }
      }

      // Check if ANY mapping is valid across all controllers
      const hasAnyValidMapping = Object.values(newControllerMappings).some(
        (mappings) => mappings.some((m) => m.location && m.site)
      );

      // Hide error if we have any valid mapping
      if (hasAnyValidMapping) {
        setShowError(false);
      }

      setControllerMappings(newControllerMappings);
    };
  };

  const handleActivate = async () => {
    try {
      // Check if any locations are mapped
      const hasMapping = Object.values(controllerMappings).some((mappings) =>
        mappings.some((m) => m.location && m.site)
      );

      if (!hasMapping) {
        setShowError(true);
        return;
      }
      setIsActivateLoading(true);
      setProgressValue(10);

      // Transform the controller mappings into the required payload structure
      const controllersPayload = controllers
        .map((controller) => {
          const controllerName = controller.name;
          const mappings = controllerMappings[controllerName] || [];

          // Create locations array with the new structure
          const locations = mappings
            .filter((mapping) => mapping.location && mapping.site)
            .map((mapping) => {
              const siteInfo = unifiSites.find(
                (site) => site.name === mapping.site
              );
              return {
                location_id: mapping.location,
                site: {
                  name: siteInfo?.name || "",
                  desc: siteInfo?.desc || "",
                },
              };
            });

          return {
            controller_name: controllerName,
            locations,
          };
        })
        .filter((controller) => controller.locations.length > 0);

      console.log("Controllers payload:", controllersPayload);

      const finalPayload = {
        timestamp: Date.now(),
        organisation_id: orgId,
        type: "unifi",
        state: "active",
        sub_payload: {
          controllers: controllersPayload,
        },
      };

      setProgressValue(50);
      await updateIntegrations.mutateAsync(finalPayload);

      setProgressValue(100);
      queryClient.invalidateQueries(["update-integrations"]);

      navigate("/activation-success", {
        state: {
          description:
            "Your Wi-Fi portal and networks are now active on your Unifi access points.",
        },
      });
    } catch (error) {
      console.error("Activation failed:", error);
      setToastMessage(
        "Internal server error occurred. Please try again later."
      );
    } finally {
      setIsActivateLoading(false);
      setProgressValue(0);
    }
  };

  // Add new state for multiple controllers
  const [controllers, setControllers] = useState([
    {
      id: 1,
      name: "Controller 1",
      unifi_controller_url: authData?.unifi_controller_url || "",
      username: authData?.username || "",
      sites:
        authData?.map((site) => ({
          name: site.name,
          desc: site.desc,
        })) || [],
    },
  ]);

  const [showAuthDialog, setShowAuthDialog] = useState(false);

  // Add handler for new controller
  const handleAddController = () => {
    setValidAuthDetails({
      unifi_url_valid: true,
      username_valid: true,
      password_valid: true,
    });
    setShowAuthDialog(true);
  };

  const resetFormValues = () => {
    setNewControllerForm({
      unifi_controller_url: "",
      username: "",
      password: "",
    });
  };

  // Handle save from AuthUnifiDialog
  const handleSaveNewController = async (e) => {
    e.preventDefault();

    const formValues = {
      unifi_controller_url: newControllerForm.unifi_controller_url,
      username: newControllerForm.username,
      password: newControllerForm.password,
    };

    // Check for empty fields first
    const isUrlEmpty = !formValues.unifi_controller_url?.trim();
    const isUsernameEmpty = !formValues.username?.trim();
    const isPasswordEmpty = !formValues.password?.trim();

    if (isUrlEmpty || isUsernameEmpty || isPasswordEmpty) {
      setValidAuthDetails({
        unifi_url_valid: !isUrlEmpty,
        username_valid: !isUsernameEmpty,
        password_valid: !isPasswordEmpty,
      });
      // Don't close dialog, just return
      return;
    }

    const isValid = Object.values(validAuthDetails).every((v) => v);
    if (!isValid) {
      // Don't close dialog if validation fails
      return;
    }

    try {
      setIsLoading(true);
      setProgressValue(30);

      const newControllerName = `Controller ${controllers.length + 1}`;

      const result = await authenticateIntegrations.mutateAsync({
        timestamp: Date.now(),
        organisation_id: orgId,
        type: "unifi",
        sub_payload: {
          unifi_controller_url: formValues.unifi_controller_url,
          username: formValues.username,
          password: formValues.password,
          controller_name: newControllerName,
        },
      });

      // Only close dialog on successful API call
      const newController = {
        id: controllers.length + 1,
        name: newControllerName,
        unifi_controller_url: formValues.unifi_controller_url,
        username: formValues.username,
        sites: result.data?.data?.data || [],
      };

      setProgressValue(100);
      setControllers((prev) => [...prev, newController]);
      setShowAuthDialog(false); // Close dialog only on success
      queryClient.invalidateQueries(["unifi-controller"]);
    } catch (error) {
      console.error("Failed to add controller:", error);
      if (error.response?.status === 400) {
        setValidAuthDetails({
          unifi_url_valid: false,
          username_valid: false,
          password_valid: false,
        });
        // Don't close dialog on 401
      } else if (error.response?.status === 500) {
        setToastMessage(
          "Internal server error occurred. Please try again later."
        );
        // setShowAuthDialog(false);
        // resetFormValues();
      }
    } finally {
      setIsLoading(false);
      setProgressValue(0);
    }
  };

  const unifiSites = authData?.map((site) => ({
    name: site.name,
    desc: site.desc,
  }));

  if (!location.state) {
    return (
      <div>
        No authentication data found. Please go back and authenticate first.
      </div>
    );
  }

  return (
    <>
      <Flex
        style={{
          minHeight: "100vh",
        }}
        justify="center"
        align="center"
        p="6"
      >
        <Card
          style={{
            maxWidth: "1000px",
            width: "100%",
          }}
          p="4"
        >
          <Flex
            style={{ alignSelf: "stretch" }}
            display="flex"
            align="start"
            direction="column"
          >
            {/* Header */}
            <Flex
              style={{ alignSelf: "stretch" }}
              display="flex"
              justify="between"
              align="center"
            >
              <Flex display="flex" align="center" gap="4">
                <Flex
                  style={{
                    borderRadius: "var(--space-1)",
                    background: "#FFFFFF",
                    border: "1px solid var(--gray-a6)",
                  }}
                  display="flex"
                  height="40px"
                  p="2"
                  justify="center"
                  align="center"
                  gap="2"
                >
                  <img
                    src={require("../../../assets/images/unifi.png")}
                    alt="icon"
                    style={{ width: "24px", borderRadius: "var(--space-1)" }}
                  />
                </Flex>
                <Flex
                  display="flex"
                  direction="column"
                  justify="center"
                  align="start"
                >
                  <Text size="4" weight="medium">
                    Unifi
                  </Text>
                  <Text
                    style={{ color: "var(--gray-a11)" }}
                    size="1"
                    weight="regular"
                  >
                    Wi-Fi
                  </Text>
                </Flex>
              </Flex>
              <Flex display="flex" align="center" gap="4">
                <Button
                  size="2"
                  variant="soft"
                  onClick={() => navigate(-1)}
                  color="gray"
                >
                  Cancel
                </Button>
                <Button
                  size="2"
                  variant="soft"
                  onClick={handleActivate}
                  loading={isActivateLoading}
                >
                  Save and finish
                </Button>
              </Flex>
            </Flex>

            {controllers.map((controller, controllerIndex) => (
              <React.Fragment key={controller.id}>
                <Separator orientation="horizontal" size="4" my="4" />

                {/* Locations Section */}
                <Flex
                  style={{ alignSelf: "stretch" }}
                  display="flex"
                  align="start"
                  gap="4"
                >
                  <Flex
                    style={{ alignSelf: "stretch" }}
                    display="flex"
                    width="296px"
                    direction="column"
                    align="start"
                    gap="2"
                  >
                    <Text size="2" weight="medium">
                      {controller.name}
                    </Text>
                    <Text size="1" weight="regular">
                      Select which Unifi Site should be associated with each of
                      your locations.
                    </Text>
                  </Flex>

                  <Flex
                    display="flex"
                    direction="column"
                    align="end"
                    gap="4"
                    flexGrow="1"
                  >
                    {(
                      controllerMappings[controller.name] || [
                        { location: "", site: "" },
                      ]
                    ).map((mapping, index) => {
                      // Get all selected sites from all controllers except the current row
                      const selectedSites = Object.entries(
                        controllerMappings
                      ).flatMap(
                        ([ctrlName, mappings]) =>
                          mappings
                            .filter(
                              (_, idx) =>
                                !(ctrlName === controller.name && idx === index)
                            ) // Exclude current row
                            .map((m) => m.site)
                            .filter((site) => site !== "") // Filter out empty selections
                      );

                      // Filter available sites excluding already selected ones
                      const availableSites = unifiSites?.filter(
                        (site) =>
                          !selectedSites.includes(site.name) ||
                          site.name === mapping.site
                      );

                      return (
                        <Flex
                          key={index}
                          style={{ alignSelf: "stretch" }}
                          display="flex"
                          justify="center"
                          align="end"
                          gap="2"
                        >
                          <Flex
                            display="flex"
                            direction="column"
                            align="start"
                            gap="4"
                            flexGrow="1"
                            maxWidth="300px"
                          >
                            {index === 0 ? (
                              <Text size="1" weight="medium">
                                Your locations
                              </Text>
                            ) : null}
                            <Select.Root
                              size="2"
                              value={mapping.location}
                              onValueChange={handleDropdownFieldChange(
                                controller.name,
                                index,
                                "location"
                              )}
                            >
                              <Select.Trigger
                                style={{
                                  display: "flex",
                                  direction: "column",
                                  alignSelf: "stretch",
                                  maxWidth: "300px",
                                }}
                                variant="surface"
                                placeholder="Select..."
                              />
                              <Select.Content>
                                <Select.Group>
                                  {initialItems.map((item) => (
                                    <Select.Item
                                      key={item.value}
                                      value={item.value}
                                    >
                                      {item.label}
                                    </Select.Item>
                                  ))}
                                </Select.Group>
                              </Select.Content>
                            </Select.Root>
                          </Flex>

                          <Flex
                            display="flex"
                            width="var(--space-6)"
                            height="var(--space-6)"
                            justify="center"
                            align="center"
                          >
                            <IconButton size="2" variant="ghost" color="gray">
                              <ArrowRightIcon width="16" height="16" />
                            </IconButton>
                          </Flex>

                          <Flex
                            display="flex"
                            direction="column"
                            align="start"
                            gap="4"
                            flexGrow="1"
                            maxWidth="300px"
                          >
                            {index === 0 ? (
                              <Text size="1" weight="medium">
                                Unifi Site
                              </Text>
                            ) : null}
                            <Select.Root
                              size="2"
                              value={mapping.site}
                              onValueChange={handleDropdownFieldChange(
                                controller.name,
                                index,
                                "site"
                              )}
                            >
                              <Select.Trigger
                                style={{
                                  display: "flex",
                                  direction: "column",
                                  alignSelf: "stretch",
                                  maxWidth: "300px",
                                }}
                                variant="surface"
                                placeholder="Select..."
                              />
                              <Select.Content>
                                <Select.Group>
                                  {availableSites?.map((item) => (
                                    <Select.Item
                                      key={item.name}
                                      value={item.name}
                                    >
                                      {item.desc}
                                    </Select.Item>
                                  ))}
                                </Select.Group>
                              </Select.Content>
                            </Select.Root>
                          </Flex>
                        </Flex>
                      );
                    })}
                    {showError && (
                      <Flex
                        style={{
                          alignSelf: "stretch",
                          marginTop: "8px",
                        }}
                        display="flex"
                        justify="start"
                        gap="4"
                      >
                        <Text
                          size="1"
                          color="red"
                          style={{
                            width: "100%",
                            textAlign: "left",
                            paddingLeft: "8px",
                          }}
                        >
                          You must map at least one location to a UniFi site.
                        </Text>
                      </Flex>
                    )}
                    <Flex gap="4" mt="3" align="center" mr="2">
                      <Button
                        size="2"
                        variant="ghost"
                        color="gray"
                        onClick={() => handleEditClick(controller)}
                      >
                        Edit controller
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </React.Fragment>
            ))}

            <Separator size="4" my="4" />
            <Flex justify="center" width="100%" gap="2">
              <Button
                size="2"
                variant="ghost"
                color="gray"
                onClick={handleAddController}
              >
                Add controller
                <PlusIcon width="16" height="16" />
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Flex>
      <EditControllerDialog
        isOpen={editDialogOpen}
        onOpenChange={handleEditCancel}
        onCancel={handleEditCancel}
        onSave={handleEditControllerSave}
        handleTextFieldChange={handleEditTextFieldChange}
        validAuthDetails={validAuthDetails}
        isLoading={isLoading}
        progressValue={progressValue}
        existingControllerData={{
          unifi_controller_url: controllerFormData.unifi_controller_url,
          username: controllerFormData.username,
          password: controllerFormData.password,
        }}
      />
      <AuthUnifiDialog
        isOpen={showAuthDialog}
        onOpenChange={setShowAuthDialog}
        onCancel={() => setShowAuthDialog(false)}
        onSave={handleSaveNewController}
        handleTextFieldChange={handleTextFieldChange}
        validAuthDetails={validAuthDetails}
        isLoading={isLoading}
        progressValue={progressValue}
        title="Add Controller"
      />
      <div
        style={{ position: "fixed", zIndex: 2147483647, pointerEvents: "none" }}
      >
        <ToastAlert />
      </div>
    </>
  );
}
