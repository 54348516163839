import React from "react";
import {
  Dialog,
  Flex,
  Text,
  TextField,
  Button,
  Progress,
} from "@radix-ui/themes";

export function AuthUnifiDialog({
  isOpen,
  onOpenChange,
  onCancel,
  onSave,
  handleTextFieldChange,
  validAuthDetails,
  isLoading,
  progressValue,
  title,
}) {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content maxWidth="600px" maxHeight="690px" align="center">
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          The URL could be a local address (e.g., https://192.168.1.100:8443), a
          remote server (e.g., https://controller.mybusiness.com:8443), or UniFi
          Cloud (https://unifi.ui.com).
        </Dialog.Description>

        <Flex
          className="slot-container"
          style={{ alignSelf: "stretch" }}
          display="flex"
          direction="column"
          align="start"
          gap="5"
        >
          <Flex
            className="controller-url-field"
            style={{ alignSelf: "stretch" }}
            display="flex"
            direction="column"
            align="start"
            gap="1"
          >
            <Text size="2" weight="medium">
              Controller URL
            </Text>
            <TextField.Root
              onChange={handleTextFieldChange("unifi_controller_url")}
              style={{ alignSelf: "stretch" }}
              size="2"
              variant="surface"
            />
            {validAuthDetails.unifi_url_valid ? null : (
              <Text size="1" weight="regular" color="red">
                Input a valid controller URL.
              </Text>
            )}
          </Flex>
          <Flex
            className="frame-528173524"
            style={{ alignSelf: "stretch" }}
            display="flex"
            align="center"
            gap="5"
          >
            <Flex
              className="username-field"
              display="flex"
              direction="column"
              align="start"
              gap="1"
              flexGrow="1"
            >
              <Text size="2" weight="medium">
                Username
              </Text>
              <TextField.Root
                onChange={handleTextFieldChange("username")}
                style={{ alignSelf: "stretch" }}
                size="2"
                variant="surface"
              />
              {validAuthDetails.username_valid ? null : (
                <Text size="1" weight="regular" color="red">
                  Input a valid username.
                </Text>
              )}
            </Flex>
            <Flex
              className="password-field"
              display="flex"
              direction="column"
              align="start"
              gap="1"
              flexGrow="1"
            >
              <Text size="2" weight="medium">
                Password
              </Text>
              <TextField.Root
                onChange={handleTextFieldChange("password")}
                style={{ alignSelf: "stretch" }}
                size="2"
                variant="surface"
              />
              {validAuthDetails.password_valid ? null : (
                <Text size="1" weight="regular" color="red">
                  Input a valid password.
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>

        <Flex
          style={{
            alignSelf: "stretch",
            width: "100%",
          }}
          display="flex"
          justify="between"
          align="center"
          mt="4"
        >
          <Flex display="flex" gap="3" justify="end" width="100%">
            <Dialog.Close>
              <Button onClick={onCancel} size="2" variant="soft" color="gray">
                Cancel
              </Button>
            </Dialog.Close>
            <Button
              onClick={onSave}
              size="2"
              variant="soft"
              color="accent"
              loading={isLoading}
            >
              Save and continue
            </Button>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}
