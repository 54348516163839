// ActiveMailchimpIntegration.jsx
import { useState, useEffect } from "react";
import { Flex, Text, Select, IconButton, Separator, TextField, Button, Progress } from "@radix-ui/themes";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { ActiveIntegrateCard } from "components";
import { useGetMailchimpAudiences, useGetMailchimpContactFields, useMapFieldsInMailchimp, useSyncGuestDataWithMailchimp, useGetActiveIntegrations } from "hooks";
import { useQueryClient } from "@tanstack/react-query";
import { useProfileContext } from "context"; // Add this import

export function ActiveMailchimpIntegration() {
  const queryClient = useQueryClient();
  const { userProfile } = useProfileContext(); // Add user profile context

  // Add missing state
  const [audiences, setAudiences] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedAgeGroup, setSelectedAgeGroup] = useState("");
  const [genderField, setGenderField] = useState(null);
  const [ageGroupField, setAgeGroupField] = useState(null);
  const [isLoadingAudiences, setIsLoadingAudiences] = useState(false);
  const [savedApiKey, setSavedApiKey] = useState("");
  const [initialGender, setInitialGender] = useState("");
  const [initialAgeGroup, setInitialAgeGroup] = useState("");
  const [selectedGenderTag, setSelectedGenderTag] = useState("");
  const [selectedAgeTag, setSelectedAgeTag] = useState("");

  const [defaultGenderTag, setDefaultGenderTag] = useState("");
  const [defaultAgeTag, setDefaultAgeTag] = useState("");

  // Hooks
  const getMailchimpAudiences = useGetMailchimpAudiences();
  const { data: fieldsData, isLoading: isLoadingFields } = useGetMailchimpContactFields(selectedAudience, userProfile?.organisation_id, {
    enabled: !!selectedAudience && !!userProfile?.organisation_id,
  });
  const mapFieldsMutation = useMapFieldsInMailchimp();
  const syncGuestsMutation = useSyncGuestDataWithMailchimp();
  const { data: activeIntegrationsData } = useGetActiveIntegrations(userProfile?.organisation_id);

  const [usedFields, setUsedFields] = useState(new Set());

  // Update gender selection handler
  const handleGenderSelect = (value) => {
    setSelectedGenderTag(value);
    if (selectedGender) {
      // Remove old value from used fields
      setUsedFields((prev) => {
        const next = new Set(prev);
        next.delete(selectedGender);
        next.add(value);
        return next;
      });
    } else {
      // Add new value to used fields
      setUsedFields((prev) => new Set([...prev, value]));
    }
    setSelectedGender(value);
  };

  // Update age group selection handler
  const handleAgeGroupSelect = (value) => {
    setSelectedAgeTag(value);
    if (selectedAgeGroup) {
      // Remove old value from used fields
      setUsedFields((prev) => {
        const next = new Set(prev);
        next.delete(selectedAgeGroup);
        next.add(value);
        return next;
      });
    } else {
      // Add new value to used fields
      setUsedFields((prev) => new Set([...prev, value]));
    }
    setSelectedAgeGroup(value);
  };

  // Effect to get saved API key from active integrations
  // Modify the effect to get saved API key
  useEffect(() => {
    const getApiKey = async () => {
      if (activeIntegrationsData?.data?.integrations) {
        // Get the first mailchimp integration
        const mailchimpIntegration = activeIntegrationsData.data.integrations.find(
          (integration) => integration.name === "mailchimp" // Remove state check since it's not in the data
        );

        if (mailchimpIntegration?.payload?.api_key) {
          // Changed from sub_payload to payload
          setSavedApiKey(mailchimpIntegration.payload.api_key);
        }
      }
    };

    getApiKey();
  }, [activeIntegrationsData]);

  useEffect(() => {
    console.log("Checking conditions:", { savedApiKey, userProfile }); // Debug log

    if (savedApiKey && userProfile) {
      fetchAudiences(savedApiKey);
    }
  }, [savedApiKey, userProfile]);

  // Effect for field mapping
  useEffect(() => {
    if (fieldsData?.data?.fields && selectedAudience) {
      const textFields = fieldsData.data.fields.filter((field) => field.type === "text");
      const foundGenderField = textFields.find((field) => field.tag === "GENDER");
      const foundAgeGroupField = textFields.find((field) => field.tag === "AGE GROUP");

      setGenderField(foundGenderField || null);
      setAgeGroupField(foundAgeGroupField || null);

      if (foundGenderField) {
        setSelectedGender(foundGenderField.tag);
        setInitialGender(foundGenderField.tag);
      }
      if (foundAgeGroupField) {
        setSelectedAgeGroup(foundAgeGroupField.tag);
        setInitialAgeGroup(foundAgeGroupField.tag);
      }
    }
  }, [fieldsData, selectedAudience]);

  // Effect for fetching audiences
  const fetchAudiences = async (apiKey) => {
    if (!apiKey || !userProfile) return;

    console.log("Fetching audiences..."); // Debug log
    setIsLoadingAudiences(true);

    try {
      const response = await getMailchimpAudiences.mutateAsync({
        user_id: userProfile.user_id,
        organisation_id: userProfile.organisation_id,
        type: "mailchimp",
        state: "active",
        sub_payload: { api_key: apiKey },
      });

      console.log("Audiences response:", response); // Debug log

      if (response?.audiences) {
        setAudiences(response.audiences);

        // Get active audience from integration data
        const mailchimpIntegration = activeIntegrationsData?.data?.integrations?.find((integration) => integration.name === "mailchimp");
        const savedActiveAudience = mailchimpIntegration?.payload?.active_audience;

        console.log("Saved active audience:", savedActiveAudience); // Debug log

        if (savedActiveAudience) {
          setActiveAudience(savedActiveAudience);
          setSelectedAudience(savedActiveAudience);
        } else if (response.audiences.length > 0) {
          setActiveAudience(response.audiences[0].id);
          setSelectedAudience(response.audiences[0].id);
        }
      }
    } catch (error) {
      console.error("Failed to fetch audiences:", error);
      setAudiences([]);
    } finally {
      setIsLoadingAudiences(false);
    }
  };

  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [isAudienceLoading, setIsAudienceLoading] = useState(false);
  const [fieldsProgressValue, setFieldsProgressValue] = useState(0);
  const [audienceProgressValue, setAudienceProgressValue] = useState(0);

  const handleSaveAudience = async () => {
    setIsFieldsLoading(true);
    try {
      setFieldsProgressValue(30);
      await syncGuestsMutation.mutateAsync({
        audienceId: selectedAudience,
        organisation_id: userProfile.organisation_id,
      });
      setFieldsProgressValue(80);

      queryClient.invalidateQueries(["integrations"]);
      setFieldsProgressValue(100);
    } catch (error) {
      console.error("Error syncing guests:", error);
    } finally {
      setIsFieldsLoading(false);
      setFieldsProgressValue(0);
    }
  };

  const handleSaveFields = async () => {
    setIsAudienceLoading(true);
    try {
      setAudienceProgressValue(30);
      await mapFieldsMutation.mutateAsync({
        audienceId: selectedAudience,
        organisation_id: userProfile.organisation_id,
        age_field_tag: selectedAgeGroup,
        gender_field_tag: selectedGender,
      });
      setAudienceProgressValue(80);

      setInitialGender(selectedGender);
      setInitialAgeGroup(selectedAgeGroup);
      queryClient.invalidateQueries(["mailchimp-fields"]);
      setAudienceProgressValue(100);
    } catch (error) {
      console.error("Error mapping fields:", error);
    } finally {
      setIsAudienceLoading(false);
      setAudienceProgressValue(0);
    }
  };

  useEffect(() => {
    // Log initial data
    console.log("Full activeIntegrationsData:", JSON.stringify(activeIntegrationsData, null, 2));

    if (activeIntegrationsData?.data?.integrations) {
      const mailchimpIntegration = activeIntegrationsData.data.integrations.find((integration) => integration.name === "mailchimp");

      // Log full integration object
      console.log("Full Mailchimp Integration:", JSON.stringify(mailchimpIntegration, null, 2));

      // Log payload specifically
      console.log("Integration payload:", JSON.stringify(mailchimpIntegration?.payload, null, 2));

      if (mailchimpIntegration?.payload) {
        const activeAudience = mailchimpIntegration.payload.active_audience;
        const mappings = mailchimpIntegration.payload.field_mappings?.[activeAudience];

        console.log("Active Audience ID:", activeAudience);
        console.log("Found Field Mappings:", mappings);

        if (mappings) {
          console.log("Gender Tag:", mappings.gender_field_tag);
          console.log("Age Tag:", mappings.age_field_tag);

          try {
            setDefaultGenderTag(mappings.gender_field_tag);
            setDefaultAgeTag(mappings.age_field_tag);
            setSelectedGender(mappings.gender_field_tag);
            setSelectedAgeGroup(mappings.age_field_tag);

            console.log("States updated:", {
              defaultGenderTag: mappings.gender_field_tag,
              defaultAgeTag: mappings.age_field_tag,
              selectedGender: mappings.gender_field_tag,
              selectedAgeGroup: mappings.age_field_tag,
            });
          } catch (error) {
            console.error("Error updating states:", error);
          }
        } else {
          console.warn("No mappings found for active audience:", activeAudience);
        }
      } else {
        console.warn("No payload found in mailchimp integration");
      }
    } else {
      console.warn("No integrations data available");
    }
  }, [activeIntegrationsData]);

  const [activeAudience, setActiveAudience] = useState("");

  // Add this effect to update activeAudience when integration data changes
  useEffect(() => {
    if (activeIntegrationsData?.data?.integrations) {
      const mailchimpIntegration = activeIntegrationsData.data.integrations.find((integration) => integration.name === "mailchimp");

      if (mailchimpIntegration?.payload?.active_audience) {
        setActiveAudience(mailchimpIntegration.payload.active_audience);
        console.log("Active Audience ID:", mailchimpIntegration.payload.active_audience);
      }
    }
  }, [activeIntegrationsData]);

  useEffect(() => {
    if (defaultGenderTag) {
      setSelectedGenderTag(defaultGenderTag);
      setUsedFields((prev) => new Set([...prev, defaultGenderTag]));
    }
    if (defaultAgeTag) {
      setSelectedAgeTag(defaultAgeTag);
      setUsedFields((prev) => new Set([...prev, defaultAgeTag]));
    }
  }, [defaultGenderTag, defaultAgeTag]);

  return (
    <>
      <ActiveIntegrateCard.Root
        name="Mailchimp"
        subtitle="Email marketing"
        image_url="mailchimp.png"
        option={{
          text: "Disconnect",
          color: "red",
          onClick: () => {
            const supportEmail = "support@b-connect.co.uk";
            const subject = "Support Request";
            const body = "Hello, I need help with...";
            const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            window.location.href = mailtoLink;
          },
        }}
      >
        <ActiveIntegrateCard.Section name="Linked audience" subtitle="Select the Mailchimp audience associated with your organisation.">
          <Flex className="frame-mailchimp-audience" display="flex" direction="column" align="start" gap="2" flexGrow="1">
            <Text size="2" weight="regular">
              Mailchimp audience
            </Text>
            <Select.Root value={selectedAudience} onValueChange={setSelectedAudience} defaultValue={activeAudience}>
              <Select.Trigger
                disabled={isLoadingAudiences}
                style={{
                  display: "flex",
                  alignSelf: "stretch",
                }}
              />
              <Select.Content>
                {isLoadingAudiences ? (
                  <Select.Item value="loading">Loading audiences...</Select.Item>
                ) : audiences.length > 0 ? (
                  audiences.map((audience) => (
                    <Select.Item key={audience.id} value={audience.id}>
                      {audience.name}
                    </Select.Item>
                  ))
                ) : (
                  <Select.Item value="no-audience">No audiences found</Select.Item>
                )}
              </Select.Content>
            </Select.Root>

            <Flex display="flex" justify="end" style={{ alignSelf: "stretch", marginTop: "8px" }}>
              <Button
                size="2"
                variant="solid"
                color="accent"
                onClick={handleSaveAudience}
                disabled={!selectedAudience || selectedAudience === activeIntegrationsData?.data?.integrations?.find((i) => i.name === "mailchimp")?.payload?.active_audience}
                loading={isFieldsLoading}
              >
                Save changes
              </Button>
            </Flex>
          </Flex>
        </ActiveIntegrateCard.Section>

        <ActiveIntegrateCard.Section name="Data fields" subtitle="Confirm the data fields that are being mapped from b connect to Mailchimp.">
          <Flex display="flex" direction="column" gap="4" style={{ flex: 1 }}>
            {/* Email Row */}
            <Flex style={{ alignSelf: "stretch" }} display="flex" justify="between" align="start" gap="2">
              <Flex display="flex" direction="column" gap="2" style={{ flex: 1 }}>
                <Text
                  size="2"
                  weight="regular"
                  style={{
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  b connect
                </Text>
                <TextField.Root variant="surface" value="Email address" size="2" disabled style={{ width: "100%" }} />
              </Flex>

              <Flex display="flex" direction="column" gap="2" style={{ paddingTop: "40px" }}>
                <IconButton size="2" variant="ghost" color="gray" style={{ pointerEvents: "none" }}>
                  <ArrowRightIcon width="16" height="16" />
                </IconButton>
              </Flex>

              <Flex display="flex" direction="column" gap="2" style={{ flex: 1 }}>
                <Text
                  size="2"
                  weight="regular"
                  style={{
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Mailchimp
                </Text>
                <Select.Root value="email" disabled size="2" style={{ width: "100%" }}>
                  <Select.Trigger style={{ width: "100%" }} variant="surface" />
                  <Select.Content>
                    <Select.Item value="email">Email address</Select.Item>
                  </Select.Content>
                </Select.Root>
              </Flex>
            </Flex>

            {/* Email marketing Row */}

            <Flex style={{ alignSelf: "stretch" }} display="flex" justify="between" align="start" gap="2">
              <Flex display="flex" direction="column" gap="2" style={{ flex: 1 }}>
                <TextField.Root variant="surface" value="Email marketing" size="2" disabled style={{ width: "100%" }} />
              </Flex>

              <Flex display="flex" direction="column" gap="2" style={{ paddingTop: "8px" }}>
                <IconButton size="2" variant="ghost" color="gray" style={{ pointerEvents: "none" }}>
                  <ArrowRightIcon width="16" height="16" />
                </IconButton>
              </Flex>

              <Flex display="flex" direction="column" gap="2" style={{ flex: 1 }}>
                <Select.Root value="email" disabled size="2" style={{ width: "100%" }}>
                  <Select.Trigger style={{ width: "100%" }} variant="surface" />
                  <Select.Content>
                    <Select.Item value="email">Email marketing</Select.Item>
                  </Select.Content>
                </Select.Root>
              </Flex>
            </Flex>

            {/* Gender Row */}
            <Flex style={{ alignSelf: "stretch" }} display="flex" justify="between" align="center" gap="2">
              <Flex style={{ flex: 1 }}>
                <TextField.Root variant="surface" value="Gender" size="2" disabled style={{ width: "100%" }} />
              </Flex>

              <Flex display="flex">
                <IconButton size="2" variant="ghost" color="gray" style={{ pointerEvents: "none" }}>
                  <ArrowRightIcon width="16" height="16" />
                </IconButton>
              </Flex>

              <Flex style={{ flex: 1 }}>
                <Select.Root value={selectedGenderTag || ""} onValueChange={handleGenderSelect} disabled={!selectedAgeTag} size="2" style={{ width: "100%" }}>
                  <Select.Trigger style={{ width: "100%" }} variant="surface" />
                  <Select.Content>
                    {fieldsData?.data?.fields
                      ?.filter((field) => field.type === "text")
                      .map((field) => (
                        <Select.Item key={field.tag} value={field.tag}>
                          {field.name}
                        </Select.Item>
                      ))}
                  </Select.Content>
                </Select.Root>
              </Flex>
            </Flex>

            {/* Age Group Row */}
            <Flex style={{ alignSelf: "stretch" }} display="flex" justify="between" align="center" gap="2">
              <Flex style={{ flex: 1 }}>
                <TextField.Root variant="surface" value="Age group" size="2" disabled style={{ width: "100%" }} />
              </Flex>

              <Flex display="flex">
                <IconButton size="2" variant="ghost" color="gray" style={{ pointerEvents: "none" }}>
                  <ArrowRightIcon width="16" height="16" />
                </IconButton>
              </Flex>

              <Flex style={{ flex: 1 }}>
                <Select.Root value={selectedAgeTag || ""} onValueChange={handleAgeGroupSelect} disabled={!selectedGenderTag} size="2" style={{ width: "100%" }}>
                  <Select.Trigger style={{ width: "100%" }} variant="surface" />
                  <Select.Content>
                    {fieldsData?.data?.fields
                      ?.filter((field) => field.type === "text")
                      .map((field) => (
                        <Select.Item key={field.tag} value={field.tag}>
                          {field.name}
                        </Select.Item>
                      ))}
                  </Select.Content>
                </Select.Root>
              </Flex>
            </Flex>

            <Flex display="flex" justify="end">
              <Button
                size="2"
                variant="solid"
                color="accent"
                onClick={handleSaveFields}
                disabled={
                  !selectedGenderTag || // Disable if gender not selected
                  !selectedAgeTag || // Disable if age not selected
                  selectedGenderTag === selectedAgeTag || // Disable if same field selected
                  (selectedGenderTag === defaultGenderTag && selectedAgeTag === defaultAgeTag) // Disable if matches default values
                }
                loading={isAudienceLoading}
              >
                Save changes
              </Button>
            </Flex>
          </Flex>
        </ActiveIntegrateCard.Section>
      </ActiveIntegrateCard.Root>
    </>
  );
}
