import React, { useState, useEffect } from "react";
import { Flex, Text, IconButton, Select, Button, Callout } from "@radix-ui/themes";
import { ArrowRightIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import { ActiveIntegrateCard } from "components";
import { useLocationContext, useProfileContext } from "context";
import { useGetActiveIntegrations, useAuthenticateIntegrations, useUpdateIntegrations } from "hooks";
import { useQueryClient } from "@tanstack/react-query"; // Import useQueryClient

export function ActiveUnifiIntegrations() {
  // Hooks and contexts here
  const { initialItems } = useLocationContext();
  const { userProfile } = useProfileContext();
  const { data: activeIntegrationsData, error: activeIntegrationsError } = useGetActiveIntegrations(userProfile?.organisation_id);
  const queryClient = useQueryClient();
  const authenticateIntegrations = useAuthenticateIntegrations();
  const updateIntegrations = useUpdateIntegrations();

  // States and effects here!
  const [unifiSiteMapping, setUnifiSiteMapping] = useState([{ location: "", site: "" }]);

  const [error, setError] = useState(null);

  useEffect(() => {
    if (activeIntegrationsData?.data?.integrations) {
      try {
        const unifiIntegrations = activeIntegrationsData?.data?.integrations?.filter((item) => item.name === "unifi");

        if (!unifiIntegrations || unifiIntegrations.length === 0) {
          throw new Error("No UniFi integrations found");
        }

        const url = unifiIntegrations[0]?.payload?.unifi_controller_url;
        const username = unifiIntegrations[0]?.payload?.username;
        const password = unifiIntegrations[0]?.payload?.password;
        const controller_name = unifiIntegrations[0]?.payload?.controller_name;

        if (!url || !username || !password || !controller_name) {
          throw new Error("Missing required UniFi configuration");
        }

        const initialUnifiSiteMapping = unifiIntegrations.map((item) => ({
          location: item.location_id,
          site: item.payload.unifi_site_id,
        }));

        setUnifiSiteMapping(initialUnifiSiteMapping);
        setError(null);

        // Wrap authentication in try-catch
        authenticateIntegrations
          .mutateAsync({
            timestamp: Date.now(),
            organisation_id: userProfile.organisation_id,
            type: "unifi",
            sub_payload: {
              unifi_controller_url: url,
              username,
              password,
              controller_name,
            },
          })
          .catch((error) => {
            setError(`Authentication failed: ${error.message}`);
          });
      } catch (err) {
        setError(err.message);
      }
    }
  }, [activeIntegrationsData?.data?.integrations]);

  const handleDropdownFieldChange = (index, field) => {
    return (value) => {
      const newUnifiSiteMapping = [...unifiSiteMapping];
      newUnifiSiteMapping[index][field] = value;
      setUnifiSiteMapping(newUnifiSiteMapping);
    };
  };

  const handleSave = async () => {
    try {
      if (!unifiSites) {
        throw new Error("UniFi sites data is not available");
      }

      const transUnifiSiteMapping = Object.fromEntries(unifiSiteMapping.map((item) => [item.location, unifiSites.find((obj) => obj.name === item.site)]));

      await updateIntegrations.mutateAsync({
        timestamp: Date.now(),
        organisation_id: userProfile.organisation_id,
        type: "unifi",
        state: "active",
        sub_payload: transUnifiSiteMapping,
      });

      queryClient.invalidateQueries(["update-integrations"]);
      setError(null);
    } catch (err) {
      setError(`Failed to save changes: ${err.message}`);
    }
  };

  const unifiSites = authenticateIntegrations?.data?.data?.data.map((site) => ({
    name: site.name,
    desc: site.desc,
  }));

  return (
    <ActiveIntegrateCard.Root
      name="UniFi"
      subtitle="Wi-Fi"
      image_url="unifi.png"
      option={{
        text: "Disconnect",
        color: "red",
        onClick: () => {
          const supportEmail = "support@b-connect.co.uk";
          const subject = "Support Request";
          const body = "Hello, I need help with...";
          const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
          window.location.href = mailtoLink;
        },
      }}
    >
      <ActiveIntegrateCard.Section name="Locations" subtitle="Select which Unifi Site should be associated with each of your locations.">
        <Flex className="frame-528173481" display="flex" direction="column" align="end" gap="4" flexGrow="1">
          {unifiSiteMapping.map((integrate_item, index) => (
            <Flex key={index} className="frame-528173433" style={{ alignSelf: "stretch" }} display="flex" justify="space-between" align="end" gap="2">
              <Flex className="frame-528173429" display="flex" direction="column" align="start" gap="4" flexGrow="1" maxWidth="1000px" style={{ width: "45%" }}>
                {index === 0 ? (
                  <Text size="1" weight="medium">
                    Your locations
                  </Text>
                ) : null}
                <Select.Root disabled={true} size="2" defaultValue={integrate_item.location} onValueChange={handleDropdownFieldChange(index, "location")}>
                  <Select.Trigger
                    style={{
                      display: "flex",
                      direction: "column",
                      alignSelf: "stretch",
                      maxWidth: "1000px",
                    }}
                    variant="surface"
                  />
                  <Select.Content>
                    <Select.Group>
                      {initialItems.map((item) => (
                        <Select.Item key={item.value} value={item.value}>
                          {item.label}
                        </Select.Item>
                      ))}
                    </Select.Group>
                  </Select.Content>
                </Select.Root>
              </Flex>
              <Flex display="flex" width="var(--space-6)" height="var(--space-6)" justify="center" align="center" style={{ flexShrink: 0 }}>
                <IconButton size="2" variant="ghost" color="gray">
                  <ArrowRightIcon width="16" height="16" />
                </IconButton>
              </Flex>
              <Flex className="frame-528173431" display="flex" direction="column" align="start" gap="4" flexGrow="1" style={{ width: "45%" }}>
                {index === 0 ? (
                  <Text size="1" weight="medium">
                    Unifi site
                  </Text>
                ) : null}
                <Select.Root disabled={true} size="2" defaultValue={integrate_item.site} onValueChange={handleDropdownFieldChange(index, "site")}>
                  <Select.Trigger
                    style={{
                      display: "flex",
                      direction: "column",
                      alignSelf: "stretch",
                      maxWidth: "1000px",
                    }}
                    variant="surface"
                  />
                  <Select.Content>
                    <Select.Group>
                      {unifiSites?.map((item) => (
                        <Select.Item key={item.name} value={item.name}>
                          {item.desc}
                        </Select.Item>
                      ))}
                    </Select.Group>
                  </Select.Content>
                </Select.Root>
              </Flex>
            </Flex>
          ))}
          <Flex display="flex" style={{ alignSelf: "stretch" }} direction="column" align="start">
            <Callout.Root size="1" color="gray">
              <Callout.Icon>
                <InfoCircledIcon />
              </Callout.Icon>
              <Callout.Text>Location re-mapping will be available soon.</Callout.Text>
            </Callout.Root>
          </Flex>
          <Button disabled={true} onClick={handleSave} size="2" variant="solid" color="accent">
            Save changes
          </Button>
        </Flex>
      </ActiveIntegrateCard.Section>
    </ActiveIntegrateCard.Root>
  );
}
