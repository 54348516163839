import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTheme } from '../../context/ThemeContext';
import { useTheme as useShadTheme } from "next-themes";
import { useProfile } from 'hooks';
import { setLogout } from '../../features/auth/authSlice';
import { PersonIcon, DotFilledIcon } from '@radix-ui/react-icons';
import { Avatar, Text, DropdownMenu, Flex, Box, Button } from '@radix-ui/themes';

export function AvatarDropdownMenu({size = "2"}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  const { appearance, setAppearance } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(localStorage.getItem('theme') || 'system');
  const [hover, setHover] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userInitials, setUserInitials] = useState('');
  const [userRole, setUserRole] = useState('');

  const { data: profile } = useProfile();
  // This theme logic is responsible for changing the theme of ShadCN specific components!
  const { setTheme } = useShadTheme();

  useEffect(() => {
    if (profile) {
      setUserName(`${profile.first_name} ${profile.last_name}`);
      setUserEmail(profile.email);
      setUserInitials(`${profile.first_name.charAt(0)}${profile.last_name.charAt(0)}`);
      setUserRole(profile.user_role);
    }
  }, [profile]);

  const submenu = {
    title: "Appearance",
    options: [
      { label: "Dark", onClick: () => setAppearance("dark") },
      { label: "Light", onClick: () => setAppearance("light") },
      { label: "System Theme", onClick: () => setAppearance("system") }
    ]
  };

  useEffect(() => {
    if (selectedTheme === 'system') {
      setAppearance('system');
    } else {
      setAppearance(selectedTheme);
    }
  }, [selectedTheme, setAppearance]);

  const handleLogout = () => {
    dispatch(setLogout());
    localStorage.clear();
    navigate("/login");
  };

  const handleThemeChange = (theme) => {
    setSelectedTheme(theme);
    localStorage.setItem('theme', theme);
    setTheme(theme);
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button variant='soft' style={{width: '100%', height: '40px', padding: '0px'}}>
          <Avatar variant="soft" size={size}
            fallback={userInitials ? userInitials : <PersonIcon />} color="blue"/>
        </Button>
      </DropdownMenu.Trigger>
        <DropdownMenu.Content size="2" variant="solid">
          <DropdownMenu.Item style={{height: "52px", gap: "var(--space-1)"}} 
            onClick={() => navigate("/profile")}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <Flex display="flex" direction="column" align="start">
              <Text style={{whiteSpace: "nowrap"}} size="2" weight="medium">
                {userName?.length >= 18 ? `${userName.slice(0, 18)}...` : userName}
              </Text>
              <Text size="1" weight="regular" truncate style={{whiteSpace: "nowrap", 
                color: hover ? "var(--gray-contrast)" : "var(--gray-11)"}}>
                {userEmail?.length >= 18 ? `${userEmail.slice(0, 18)}...` : userEmail}
              </Text>
            </Flex>
          </DropdownMenu.Item>
          <DropdownMenu.Separator />
          {/* Appearance Section - Start */}
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger asChild>
                {submenu.title}
            </DropdownMenu.SubTrigger>
              <DropdownMenu.SubContent size="2" variant="solid">
                {submenu.options.map((option, index) => (
                  <DropdownMenu.Item key={index}
                    onClick={() => handleThemeChange(option.label.split(' ')[0].toLowerCase())}
                  >
                    {selectedTheme === option.label.split(' ')[0].toLowerCase() ? 
                    <DotFilledIcon/> : <Box width="16px"></Box>}
                    {option.label}
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.SubContent>
          </DropdownMenu.Sub>
          {/* Appearance Section - End */}
          <DropdownMenu.Separator />
          <DropdownMenu.Item onClick={() => {
              const supportEmail = 'support@b-connect.co.uk';
              const subject = 'Support Request';
              const body = 'Hello, I need help with...';
              const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
              window.location.href = mailtoLink;
            }}>
            Support
          </DropdownMenu.Item>
          <DropdownMenu.Item color='red' onClick={handleLogout}>
            Log Out
          </DropdownMenu.Item>
        </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}