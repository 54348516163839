// hooks/useMetricsData.js
import { useState, useEffect } from "react";
import { useGetOrgLocationsMetrics } from "../app/useGetOrgLocationsByUser";

export const useMetricsData = (
  selectedDateRange,
  locationSelection,
  userId,
  isLocationsSuccess,
  loyaltySelection,
  ageSelection,
  genderSelection,
  zoneSelection
) => {
  const [metricsDataPayload, setMetricsDataPayload] = useState(null);
  const [chartDataPayload, setChartDataPayload] = useState(null);

  useEffect(() => {
    if (locationSelection && locationSelection.length > 0) {
      const payload = {
        location_ids: locationSelection,
        start_time: selectedDateRange.from?.valueOf(),
        end_time: selectedDateRange.to?.valueOf(),
        user_id: userId,
        guest_status: loyaltySelection,
        age: ageSelection,
        gender: genderSelection,
        zone_ids: zoneSelection,
      };

      setMetricsDataPayload({ payload });
      setChartDataPayload({ payload });
    } else {
      setMetricsDataPayload(null);
      setChartDataPayload(null);
    }
  }, [
    selectedDateRange,
    locationSelection,
    userId,
    loyaltySelection,
    ageSelection,
    genderSelection,
    zoneSelection,
  ]);

  const { data: metricsData } = useGetOrgLocationsMetrics(
    metricsDataPayload,
    "connection_metric_card",
    isLocationsSuccess && metricsDataPayload !== null
  );
  const { data: guestConnectionsChartData } = useGetOrgLocationsMetrics(
    chartDataPayload,
    "total_new_return_connect",
    isLocationsSuccess && chartDataPayload !== null
  );
  const { data: guestConnectionsByLocationsChartData } =
    useGetOrgLocationsMetrics(
      chartDataPayload,
      "new_return_connect_location",
      isLocationsSuccess && chartDataPayload !== null
    );

  return {
    metricsData,
    guestConnectionsChartData,
    guestConnectionsByLocationsChartData,
  };
};
