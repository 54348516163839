import { useQuery } from "@tanstack/react-query";
import { getAirshipMappings } from "../../api/bAirshipApi";

export const useGetAirshipMappings = (organisationId) => {
  return useQuery({
    queryKey: ["airship-mappings", organisationId],
    queryFn: () => getAirshipMappings(organisationId),
    enabled: !!organisationId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 10 * 60 * 1000, // 10 minutes
    onError: (error) => {
      console.error("Error fetching Airship mappings:", error.response || error);
    },
  });
};
