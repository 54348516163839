import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { differenceInDays, startOfDay, isEqual } from "date-fns";
import { Text, Flex, Separator, Badge, Tooltip, Button } from "@radix-ui/themes";
import { GearIcon } from "@radix-ui/react-icons";
import {
  Header,
  LiveConnectionButton,
  AvatarDropdownMenu,
  SnackInsights,
  DatePickerWithRange,
  LiveStatCard,
  MetricCard,
  ChartCard,
  LocationMultiSelect,
  LiveConnectionsCard,
  FilterDropdownMenu,
} from "components";
import { useDateRange, useLiveConnectionStats, useMetricsData, useWeekMetrics } from "hooks";
import { useProfileContext, useLocationContext, useDateRangeContext } from "context";
import { GuestConnectionsByTimeOfDayChart } from "components/charts/GuestConnectionsByTimeOfDayChart";
import { GuestConnectionsByDayChart } from "components/charts/GuestConnectionsByDayChart";
import { GuestConnectionsByDayOfWeekChart } from "components/charts/GuestConnectionsByDayOfWeekChart";
import { GuestConnectionsByMonthChart } from "components/charts/GuestConnectionsByMonthChart";
import { GuestConnectionsByLocationsChart } from "components/charts/GuestConnectionsByLocationsChart";
import { UniqueGuestsByAgeChart } from "components/charts/UniqueGuestsByAgeChart";
import { UniqueGuestsByGenderChart } from "components/charts/UniqueGuestsByGenderChart";
import { UsersIcon, UserIcon, ArrowUturnLeftIcon, PlusIcon, ClockIcon } from "@heroicons/react/16/solid";
import { useTheme } from "../../context/ThemeContext";
import NewReturningLegend from "components/charts/NewReturningLegend";
import { getDateTimeConnectionsData } from "../../helpers/metricsUtils";
import { calculateHighestAndLowestGenderGroups } from "../../helpers/metricsUtils";
import { chartConfig } from "../../configs/ChartConfig";
import { MultiSelect } from "components/multiSelect/MultiSelect";

export default function Dashboard() {
  const { userProfile } = useProfileContext();
  const { initialItems, locationSelection, setLocationSelection, locationsData, isLocationsSuccess, allLocations } = useLocationContext();
  const { selectedDateRange, setSelectedDateRange, dateRangeDifference } = useDateRangeContext();

  const { filterSelection, setFilterSelection, selectedCategories, setSelectedCategories, zoneSelection, displayBy } = useOutletContext();
  const { showSnackInsights, setShowSnackInsights } = useOutletContext();
  // Custom hooks
  const { liveConnectionStat } = useLiveConnectionStats(locationSelection);

  // Inside your component:
  const [bottomOffset, setBottomOffset] = useState(260);

  // Update selection based on changes to selectedCategories
  useEffect(() => {
    const deselectedCategories = filterSelection.filter((cat) => !selectedCategories.includes(cat));

    if (deselectedCategories.length > 0) {
      // Remove deselected filters from the UI
      const remainingSelections = filterSelection.filter((selection) => {
        if (deselectedCategories.includes("guest_status")) {
          return !selection.includes("guest_status");
        }
        return !deselectedCategories.includes(selection);
      });

      // Reset deselected filters to their default values
      deselectedCategories.forEach((category) => {
        switch (category) {
          case "guest_status":
            setLoyaltySelection(loyaltyOptions.map((option) => option.value));
            break;
          case "age":
            setAgeSelection(ageOptions.map((option) => option.value));
            break;
          case "gender":
            setGenderSelection(genderOptions.map((option) => option.value));
            break;
        }
      });

      setFilterSelection(remainingSelections);
    } else {
      setFilterSelection(selectedCategories);
    }
  }, [selectedCategories]);

  useEffect(() => {
    const calculateOffset = () => {
      // Get the viewport height
      const vh = window.innerHeight;
      // Calculate dynamic offset (e.g. 20% of viewport height)
      const dynamicOffset = Math.floor(vh * 0.2); // 20% of viewport height
      // Set minimum and maximum bounds
      const minOffset = 200;
      const maxOffset = 300;
      // Clamp the value
      const clampedOffset = Math.min(Math.max(dynamicOffset, minOffset), maxOffset);
      setBottomOffset(clampedOffset);
    };

    // Calculate initially
    calculateOffset();

    // Recalculate on window resize
    window.addEventListener("resize", calculateOffset);

    // Cleanup
    return () => window.removeEventListener("resize", calculateOffset);
  }, []);

  const filterOptions = [
    { label: "Customer loyalty", value: "guest_status" },
    { label: "Age", value: "age" },
    { label: "Gender", value: "gender" },
  ];

  const loyaltyOptions = [
    { label: "New customers", value: "new" },
    { label: "Returning customers", value: "returning" },
  ];

  const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "others" },
    { label: "No Response", value: "prefer not to respond" },
  ];

  const ageOptions = [
    { label: "Under 18", value: "Under 18" },
    { label: "18-24", value: "18-24" },
    { label: "25-34", value: "25-34" },
    { label: "35-44", value: "35-44" },
    { label: "45-54", value: "45-54" },
    { label: "55-64", value: "55-64" },
    { label: "65+", value: "65+" },
    { label: "No Response", value: "prefer not to respond" },
  ];

  // const [selectedCategories, setSelectedCategories] = useState(filterOptions.map(opt => opt.value));

  // Helper functions
  const getAllOptionsForCategory = (category) => {
    switch (category) {
      case "guest_status":
        return ["new", "returning"].map((opt) => `guest_status_${opt}`);
      case "age":
        return ["Under 18", "18-24", "25-34", "35-44", "45-54", "55-64", "65+", "prefer not to respond"].map((opt) => `age_${opt}`);
      case "gender":
        return ["male", "female", "others", "prefer not to respond"].map((opt) => `gender_${opt}`);
      default:
        return [];
    }
  };

  // Update states that feed into useMetricsData when filters change
  const handleFilterChange = (newValue) => {
    const deselectedCategories = selectedCategories.filter((cat) => !newValue.includes(cat));

    if (deselectedCategories.length > 0) {
      // Remove deselected filters from the UI
      const remainingSelections = filterSelection.filter((selection) => {
        // Handle guest_status separately since it has a different format
        if (deselectedCategories.includes("guest_status")) {
          return !selection.includes("guest_status");
        }
        return !deselectedCategories.includes(selection);
      });

      // Reset deselected filters to their default values
      deselectedCategories.forEach((category) => {
        switch (category) {
          case "guest_status":
            setLoyaltySelection(["new", "returning"]);
            break;
          case "age":
            setAgeSelection(["Under 18", "18-24", "25-34", "35-44", "45-54", "55-64", "65+", "prefer not to respond"]);
            break;
          case "gender":
            setGenderSelection(["male", "female", "others", "prefer not to respond"]);
            break;
        }
      });

      setFilterSelection(remainingSelections);
    } else {
      setFilterSelection(newValue);
    }

    setSelectedCategories(newValue);
  };

  const [loyaltySelection, setLoyaltySelection] = useState(loyaltyOptions.map((option) => option.value));

  const [ageSelection, setAgeSelection] = useState(ageOptions.map((option) => option.value));
  const [genderSelection, setGenderSelection] = useState(genderOptions.map((option) => option.value));

  // const [filterSelection, setFilterSelection] = useState(filterOptions.map(option => option.value));

  const { metricsData, guestConnectionsChartData, guestConnectionsByLocationsChartData } = useMetricsData(
    selectedDateRange,
    locationSelection,
    userProfile.user_id,
    isLocationsSuccess,
    loyaltySelection,
    ageSelection,
    genderSelection,
    zoneSelection
  );

  const { setAppearance } = useTheme();
  const [showLiveConnectionCards, setShowLiveConnectionCards] = useState(true);

  const toggleLiveConnectionCards = () => setShowLiveConnectionCards(locationSelection.length > 0 ? !showLiveConnectionCards : false);

  // Use useEffect to set toggleLiveConnectionCards to false when locationSelection is empty

  useEffect(() => {
    if (locationSelection.length === 0) {
      setShowLiveConnectionCards(false);
    } else {
      setShowLiveConnectionCards(true);
    }
  }, [locationSelection]);

  var metricTileDataOne = [
    {
      name: "Guest connections",
      icon: "",
      metric: metricsData?.guest_connections ?? "Loading...",
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? "The last period does not have data"
        : `${metricsData?.last_period_gc_change >= 0 ? "+" : ""}${metricsData?.last_period_gc_change ?? "..."}% since last period`,
      tooltip: "Total guest connections in the set filters.",
    },
    {
      name: "Unique guests",
      icon: "",
      metric: metricsData?.unique_guests ?? "Loading...",
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? "The last period does not have data"
        : `${metricsData?.last_period_ug_change >= 0 ? "+" : ""}${metricsData?.last_period_ug_change ?? "..."}% since last period`,
      tooltip: "Distinct guests connected in the set filters.",
    },
    {
      name: "Avg. duration",
      icon: "",
      metric: `${metricsData?.average_connection_duration ?? "Loading..."} mins`,
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? "The last period does not have data"
        : `${metricsData?.last_period_acd_change >= 0 ? "+" : ""}${metricsData?.last_period_acd_change ?? "..."}% since last period`,
      tooltip: "Average connection time per guest.",
    },
  ];

  var metricTileDataTwo = [
    {
      name: "Avg. time of guest connection",
      icon: <ClockIcon style={{ width: "16px", height: "16px", color: "gray" }} />,
      metric: `${metricsData?.average_connection_duration ?? "Loading..."} mins`,
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? "The last period does not have data"
        : `${metricsData?.last_period_acd_change >= 0 ? "+" : ""}${metricsData?.last_period_acd_change ?? "..."}% since last period`,
      tooltip: "Average connection time per guest.",
    },
    {
      name: "Avg. hourly guest connections",
      metric: metricsData?.average_hourly_connections ?? "Loading...",
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? "The last period does not have data"
        : `${metricsData?.last_period_ahc_change >= 0 ? "+" : ""}${metricsData?.last_period_ahc_change ?? "..."}% since last period`,
      tooltip: "Average connections per hour, based on 24 hours in a day.",
    },
    {
      name: "Avg. daily guest connections",
      metric: dateRangeDifference > 1 ? metricsData?.average_daily_connections ?? "Loading..." : "",
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? "The last period does not have data"
        : dateRangeDifference > 1
        ? `${metricsData?.last_period_adc_change >= 0 ? "+" : ""}${metricsData?.last_period_adc_change ?? "..."}% since last period`
        : "Select a multi-day range to see Avg. Daily Connections",
      tooltip: "Average guest connections per day.",
    },
    {
      name: "Avg. weekly guest connections",
      metric: dateRangeDifference > 6 ? metricsData?.average_weekly_connections ?? "Loading..." : "",
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? "The last period does not have data"
        : dateRangeDifference > 6
        ? `${metricsData?.last_period_awc_change >= 0 ? "+" : ""}${metricsData?.last_period_awc_change ?? "..."}% since last period`
        : "Select a multi-week range to see Avg. Weekly Connections",
      tooltip: "Average guest connections per week.",
    },
  ];

  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

  // Normalize the dates to the start of the day
  const normalizedFrom = startOfDay(selectedDateRange.from);
  const normalizedTo = startOfDay(selectedDateRange.to);

  // Calculate the number of days in the selected date range (inclusive)
  const selectedDateRangeInDays = Math.ceil((normalizedTo.getTime() - normalizedFrom.getTime()) / oneDayInMilliseconds) + 1;

  // const [showSnackInsights, setShowSnackInsights] = useState(false);
  const [greeting, setGreeting] = useState(localStorage.getItem("greeting") || "");
  const [dialogShownRecently, setDialogShownRecently] = useState(false);

  const determineGreeting = () => {
    const currentHour = new Date().getHours();
    let newGreeting = "";
    if (currentHour >= 0 && currentHour < 12) {
      newGreeting = "Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      newGreeting = "Afternoon";
    } else if (currentHour >= 17 && currentHour < 24) {
      newGreeting = "Evening";
    }
    setGreeting(newGreeting);
    localStorage.setItem("greeting", newGreeting);
  };

  useEffect(() => {
    const checkTimeAndShowDialog = () => {
      const now = new Date();
      const currentHour = now.getHours();
      let timeRange = "";

      if (currentHour >= 0 && currentHour < 11) {
        timeRange = "Morning";
      } else if (currentHour >= 11 && currentHour < 17) {
        timeRange = "Afternoon";
      } else if (currentHour >= 17 && currentHour < 24) {
        timeRange = "Evening";
      }

      const dialogShown = localStorage.getItem(`dialogShown_${timeRange}`);
      if (!showSnackInsights && !dialogShownRecently && !dialogShown) {
        determineGreeting();
        // setShowSnackInsights(true);
        // setDialogShownRecently(true);
        // localStorage.setItem(`dialogShown_${timeRange}`, 'true');
        // setTimeout(() => setDialogShownRecently(false), 60000); // Reset after 1 minute
      }
    };

    // checkTimeAndShowDialog(); // Show dialog when the dashboard is loaded

    const intervalId = setInterval(() => {
      checkTimeAndShowDialog();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [showSnackInsights, dialogShownRecently]);

  // useEffect(() => {
  //   const resetDialogShown = () => {
  //     const now = new Date();
  //     const currentHour = now.getHours();
  //     const currentMinute = now.getMinutes();
  //     if (currentHour === 0 && currentMinute === 0) {
  //       localStorage.removeItem('dialogShown_Morning');
  //       localStorage.removeItem('dialogShown_Afternoon');
  //       localStorage.removeItem('dialogShown_Evening');
  //     }
  //   };

  //   const resetIntervalId = setInterval(resetDialogShown, 60000); // Check every minute

  //   return () => clearInterval(resetIntervalId);
  // }, []);

  // Figure out what kind of data we need to grab

  const { tabOneMainConnectionsMetricsData, tabOneSubConnectionsMetricsData, tabTwoMainConnectionsMetricsData, tabTwoSubConnectionsMetricsData } = useWeekMetrics(
    allLocations,
    userProfile.user_id,
    isLocationsSuccess
  );

  const demographicsDataTabOne = calculateHighestAndLowestGenderGroups(tabOneMainConnectionsMetricsData?.metricsData);
  const demographicsDataTabTwo = calculateHighestAndLowestGenderGroups(tabTwoMainConnectionsMetricsData?.metricsData);

  const { guestConnectionsChartData: insightsDayTimeDataTabOne } = tabOneMainConnectionsMetricsData || {};
  const { guestConnectionsChartData: insightsDayTimeDataTabTwo } = tabTwoMainConnectionsMetricsData || {};
  const dateTimeConnectionDataTabOne = getDateTimeConnectionsData(insightsDayTimeDataTabOne, "week");
  const dateTimeConnectionDataTabTwo = getDateTimeConnectionsData(insightsDayTimeDataTabTwo, "day");

  const data = {
    tabOne: {
      guestConnect: {
        current: tabOneMainConnectionsMetricsData?.metricsData?.guest_connections ?? 0,
        previous: tabOneSubConnectionsMetricsData?.metricsData?.guest_connections ?? 0,
      },
      uniqueConnect: {
        current: tabOneMainConnectionsMetricsData?.metricsData?.unique_guests ?? 0,
        previous: tabOneSubConnectionsMetricsData?.metricsData?.unique_guests ?? 0,
      },
      demo: {
        largest: `${demographicsDataTabOne.highestGender ?? undefined}, aged ${demographicsDataTabOne.highestGenderAgeGroup ?? undefined}`,
        largestCount: demographicsDataTabOne.highestGenderCount ?? 0,
        secondLargest: `${demographicsDataTabOne.secondHighestGender ?? undefined}, aged ${demographicsDataTabOne.secondHighestGenderAgeGroup ?? undefined}`,
        secondLargestCount: demographicsDataTabOne.secondHighestGenderCount ?? 0,
      },
      busy: {
        time: dateTimeConnectionDataTabOne.highestConnectionsPeriod ?? undefined,
        unique: dateTimeConnectionDataTabOne.totalConnectionsOnPeriod ?? undefined,
        average: dateTimeConnectionDataTabOne.averageConnectionsPerPeriod ?? undefined,
      },
    },
    tabTwo: {
      guestConnect: {
        current: tabTwoMainConnectionsMetricsData?.metricsData?.guest_connections ?? 0,
        previous: tabTwoSubConnectionsMetricsData?.metricsData?.guest_connections ?? 0,
      },
      uniqueConnect: {
        current: tabTwoMainConnectionsMetricsData?.metricsData?.unique_guests ?? 0,
        previous: tabTwoSubConnectionsMetricsData?.metricsData?.unique_guests ?? 0,
      },
      demo: {
        largest: `${demographicsDataTabTwo.highestGender ?? undefined}, aged ${demographicsDataTabTwo.highestGenderAgeGroup ?? undefined}`,
        largestCount: demographicsDataTabTwo.highestGenderCount ?? 0,
        secondLargest: `${demographicsDataTabTwo.secondHighestGender ?? undefined}, aged ${demographicsDataTabTwo.secondHighestGenderAgeGroup ?? undefined}`,
        secondLargestCount: demographicsDataTabTwo.secondHighestGenderCount ?? 0,
      },
      busy: {
        time: dateTimeConnectionDataTabTwo.highestConnectionsPeriod ?? undefined,
        unique: dateTimeConnectionDataTabTwo.totalConnectionsOnPeriod ?? undefined,
        average: dateTimeConnectionDataTabTwo.averageConnectionsPerPeriod ?? undefined,
      },
    },
  };

  const allMetricsReceived =
    data.tabOne.guestConnect.current !== undefined &&
    data.tabOne.guestConnect.previous !== undefined &&
    data.tabOne.uniqueConnect.current !== undefined &&
    data.tabOne.uniqueConnect.previous !== undefined &&
    data.tabOne.demo.largest !== undefined &&
    data.tabOne.demo.largestCount !== undefined &&
    data.tabOne.demo.secondLargest !== undefined &&
    data.tabOne.demo.secondLargestCount !== undefined &&
    data.tabOne.busy.time !== undefined &&
    data.tabOne.busy.unique !== undefined &&
    data.tabOne.busy.average !== undefined &&
    data.tabTwo.guestConnect.current !== undefined &&
    data.tabTwo.guestConnect.previous !== undefined &&
    data.tabTwo.uniqueConnect.current !== undefined &&
    data.tabTwo.uniqueConnect.previous !== undefined &&
    data.tabTwo.demo.largest !== undefined &&
    data.tabTwo.demo.largestCount !== undefined &&
    data.tabTwo.demo.secondLargest !== undefined &&
    data.tabTwo.demo.secondLargestCount !== undefined &&
    data.tabTwo.busy.time !== undefined &&
    data.tabTwo.busy.unique !== undefined &&
    data.tabTwo.busy.average !== undefined;

  // 1. Decide which chart types we want to show
  let chartTypesToShow = [];

  if (selectedDateRangeInDays === 1) {
    // Show only timeOfDay
    chartTypesToShow = ["timeOfDay"];
  } else if (selectedDateRangeInDays > 1 && selectedDateRangeInDays < 62) {
    // Show day, timeOfDay, dayOfWeek
    chartTypesToShow = ["day", "timeOfDay", "dayOfWeek"];
  } else if (selectedDateRangeInDays >= 62) {
    // Show month, day, timeOfDay, dayOfWeek
    chartTypesToShow = ["month", "day", "timeOfDay", "dayOfWeek"];
  }

  const noFiltersSelected = !filterSelection || filterSelection.length === 0;

  const { setDisableBefore } = useOutletContext();

  useEffect(() => {
    if (metricsData?.disable_before_date) {
      setDisableBefore(metricsData.disable_before_date);
    }
  }, [metricsData]);

  const { setInsightsReady, triggerInsights } = useOutletContext();

  const handleOriginalInsightLogic = () => {
    // Toggle the snack insights visibility
    setShowSnackInsights((prev) => !prev);
  };

  // Hide original button but keep its ready state
  useEffect(() => {
    if (userProfile.user_first_name && allMetricsReceived) {
      setInsightsReady(true);
    }
  }, [userProfile.user_first_name, allMetricsReceived]);

  useEffect(() => {
    if (triggerInsights) {
      // Original insight button click logic here
      handleOriginalInsightLogic();
    }
  }, [triggerInsights]);

  return (
    <Flex
      className="content-container"
      direction="column"
      gap="5"
      style={{
        alignSelf: "stretch",
        width: "100%",
        height: "100%",
        alignItems: "center",
        flex: "1 0 0",
      }}
    >
      {/* Filter Section */}
      {userProfile.user_first_name && allMetricsReceived && (
        <SnackInsights show={showSnackInsights} onClose={() => setShowSnackInsights(false)} greeting={greeting} firstName={userProfile.user_first_name} data={data} />
      )}
      {/* <FilterDropdownMenu
          options={filterOptions}
          defaultValue={filterOptions.map(option => option.value)}
          onValueChange={handleFilterChange}
          placeholder="Filter by"
          selectedValues={filterSelection}
        />     <Flex className="filter-bar" style={{alignSelf: "stretch"}} display="flex" justify="end" align="center" gap="2">
        <DatePickerWithRange 
          value={selectedDateRange} 
          onDateChange={setSelectedDateRange}
          disableBeforeDate={metricsData?.disable_before_date}
        />
        <LocationMultiSelect
          options={initialItems}
          defaultValue={locationSelection}
          onValueChange={setLocationSelection}
        /> */}
      {filterSelection.length > 0 && (
        <Flex className="filter-bar" style={{ alignSelf: "stretch" }} display="flex" justify="end" align="center" gap="2">
          <Text weight="regular" size="2" color="gray">
            Filter
          </Text>
          {filterSelection.includes("guest_status") && (
            <MultiSelect
              options={loyaltyOptions}
              defaultValue={loyaltyOptions.map((option) => option.value)}
              onValueChange={(value) => {
                setLoyaltySelection(value);
              }}
              placeholder="Customer loyalty"
            />
          )}
          {filterSelection.includes("age") && (
            <MultiSelect
              options={ageOptions}
              defaultValue={ageOptions.map((option) => option.value)}
              onValueChange={(value) => {
                setAgeSelection(value);
              }}
              placeholder="Age"
            />
          )}
          {filterSelection.includes("gender") && (
            <MultiSelect
              options={genderOptions}
              defaultValue={genderOptions.map((option) => option.value)}
              onValueChange={(value) => {
                setGenderSelection(value);
              }}
              placeholder="Gender"
            />
          )}
        </Flex>
      )}
      {/* {locationSelection.length === 0 ? (
        <Flex display="flex" direction="column" align="center" justify="center" gap="2" height="70vh">
          <Text size="5" weight="medium">
            No Location Selected     <Flex className="filter-bar" style={{alignSelf: "stretch"}} display="flex" justify="end" align="center" gap="2">
          </Text>
          <Text size="2" weight="regular">
            Select a Location to see results
          </Text>
        </Flex>
      ) : ( */}
      {/* Analytics Section */}

      {!metricsData?.connection_details?.length && !guestConnectionsChartData?.chart?.length ? (
        <Flex
          width="100%"
          style={{
            display: "flex",
            flexDirection: "column",
            height: `calc(100vh - ${bottomOffset}px)`,
            flex: "1 0 0",
          }}
        >
          <Flex
            p="5"
            direction="column"
            gap="5"
            style={{
              alignSelf: "stretch",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              borderRadius: "8px",
              border: "1px dashed var(--gray-a6)",
              flex: 1,
              display: "flex",
              position: "relative",
              backgroundColor: "var(--color-background)",
            }}
          >
            <Flex direction="column" gap="2" style={{ alignItems: "center" }}>
              <Text size="5" weight="medium">
                No results
              </Text>
              <Text size="2" weight="regular" color="gray" style={{ width: "296px", textAlign: "center" }}>
                Adjust your filters or try again later.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Flex
          direction="column"
          gap="5"
          style={{
            alignSelf: "stretch",
            flex: "1 0 0",
            alignItems: "center",
            overflowY: "scroll",
          }}
        >
          {/* Metric Cards */}
          <Flex className="card-container" style={{ alignSelf: "stretch" }} align="start" gap="5">
            <LiveConnectionsCard metric={liveConnectionStat?.value} metricIcon="" />
            {metricTileDataOne.map((entry, index) => (
              <MetricCard key={index} metricName={entry.name} metricIcon={entry.icon} metric={entry.metric} subMetric={entry.subMetric} updates={entry.updates} tooltip={entry.tooltip} />
            ))}
          </Flex>
          {/* )} */}
          {/* Charts */}
          <Flex
            direction="column"
            gap="5"
            style={{
              alignSelf: "stretch",
              // flex: "1 0 0",
              alignItems: "center",
            }}
          >
            {chartTypesToShow.map((chartType) => {
              const { text, tooltip, ChartComponent } = chartConfig[chartType];

              return (
                <ChartCard
                  key={chartType}
                  text={text}
                  tooltip={tooltip}
                  // legend={
                  //   <NewReturningLegend
                  //     payload={[
                  //       { value: 'new', color: 'var(--grass-9)' },
                  //       { value: 'returning', color: 'var(--amber-9)' },
                  //     ]}
                  //   />
                  // }
                >
                  <ChartComponent
                    data={{
                      data: metricsData,
                    }}
                    startTimeMilliseconds={selectedDateRange.from.getTime()}
                    endTimeMilliseconds={selectedDateRange.to.getTime()}
                    displayBy={displayBy}
                  />
                </ChartCard>
              );
            })}
          </Flex>
          <Flex display="flex" direction="row" align="start" gap="5" width="100%">
            <ChartCard text="Unique guests by age" tooltip="Breakdown of unique guests by age.">
              <UniqueGuestsByAgeChart metricsData={metricsData} displayBy={displayBy} />
            </ChartCard>
            <ChartCard text="Unique guests by gender" tooltip="Breakdown of unique guests by gender.">
              <UniqueGuestsByGenderChart metricsData={metricsData} displayBy={displayBy} />
            </ChartCard>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
