import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, Text, Button, Flex } from "@radix-ui/themes";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useQueryClient } from "@tanstack/react-query";

export function ActivationSuccessPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { description, integrationType } = location.state || {};

  const handleDoneClick = () => {
    // For Airship specifically, invalidate status queries again
    if (integrationType === "airship") {
      queryClient.invalidateQueries(["airship-status"]);
    }

    // Navigate back
    navigate("/integrations/browse-all", { replace: true });
  };

  return (
    <Flex
      style={{
        minHeight: "100vh",
        padding: "32px 16px",
      }}
      justify="center"
      align="center"
    >
      <Card
        style={{
          width: "296px",
          height: "222px",
          padding: "24px",
          gap: "24px",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Flex direction="column" align="center" gap="4">
          <CheckCircleIcon
            style={{
              width: "24px",
              height: "24px",
              color: "var(--gray-12)",
            }}
          />
          <Text
            style={{
              fontSize: "24px",
              fontWeight: "700",
            }}
          >
            Connected
          </Text>
          <Text
            style={{
              fontSize: "12px",
              textAlign: "center",
              color: "var(--gray-11)",
            }}
          >
            {description}
          </Text>
          <Button variant="solid" color="blue" size="2" onClick={handleDoneClick} style={{ width: "100%", cursor: "pointer" }}>
            Done
          </Button>
        </Flex>
      </Card>
    </Flex>
  );
}
