import { useMutation, useQueryClient } from "@tanstack/react-query";
import { attemptUpdateIntegrations } from '../../api/bIntegrationsApi';

export const useUpdateIntegrations = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationKey: 'update-integrations',
    mutationFn: (data) => attemptUpdateIntegrations(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["update-integrations"],
      });
    },
  });
};