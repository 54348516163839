import { useQuery } from "@tanstack/react-query";
import { attemptGetZones } from "../../api/OrgApi";
import { toast } from "react-toastify";

const fetchZones = async (locationIds) => {
  return attemptGetZones(locationIds).then((res) => res.data);
};

export const useGetZones = (locationIds) => {
  return useQuery({
    queryKey: ["zones", locationIds],
    queryFn: () => fetchZones(locationIds),
    enabled: !!locationIds && locationIds.length > 0,
  });
};
