"use client";

import * as React from "react";
import { Text } from "@radix-ui/themes";
import { format, isAfter, isBefore, startOfDay, endOfDay } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { Cn } from "components/utils/Cn";
import { ShadButton } from "components/buttons/ShadButton";
import { Calendar } from "components/calendar/Calendar";
import { Popover, PopoverContent, PopoverTrigger } from "components/popover/Popover";
import "../../tailwind.css";
import { ChevronDownIcon } from "@radix-ui/react-icons";

export function DatePickerWithRange({ className, value, onDateChange, disableBeforeDate = new Date("2024-09-01") }) {
  const today = startOfDay(new Date());
  const before = startOfDay(disableBeforeDate);
  const [date, setDate] = React.useState(value || { from: null, to: null });

  React.useEffect(() => {
    if (value) {
      setDate(value);
    }
  }, [value]);

  const handleDateChange = (newDate) => {
    if (newDate?.from) {
      if (!newDate.to || newDate.from.getTime() === newDate.to.getTime()) {
        newDate = {
          from: startOfDay(newDate.from),
          to: endOfDay(newDate.from),
        };
      } else {
        newDate = {
          from: startOfDay(newDate.from),
          to: endOfDay(newDate.to),
        };
      }

      if (newDate.to.getTime() === endOfDay(today).getTime()) {
        newDate.to = new Date();
      }
    } else {
      setDate({ from: null, to: null });
      return;
    }

    setDate(newDate);
    if (onDateChange) {
      onDateChange(newDate);
    }
  };

  return (
    <>
      <style>{`
        .date-picker-button {
          background-color: var(--gray-a3);
          transition: background-color 200ms;
          cursor: default;
        }
        .date-picker-button:hover {
          background-color: var(--gray-a4);
        }
      `}</style>
      <div className={Cn("grid gap-2", className)}>
        <Popover>
          <PopoverTrigger asChild>
            <ShadButton id="date" variant={"outline"} className={Cn("h-8 justify-start text-left date-picker-button", !date && "text-muted-foreground")} style={{ cursor: "pointer" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Text weight="medium" style={{ color: "var(--gray-11)" }}>
                  {date?.from ? (
                    date.to ? (
                      <React.Fragment>
                        {format(date.from, "dd LLL y")} - {format(date.to, "dd LLL y")}
                      </React.Fragment>
                    ) : (
                      format(date.from, "LLL dd, y")
                    )
                  ) : (
                    <span>Pick a date</span>
                  )}
                </Text>
                <ChevronDownIcon style={{ color: "var(--gray-11)" }} />
              </div>
            </ShadButton>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start" size="2">
            <Calendar initialFocus mode="range" selected={date} onSelect={handleDateChange} numberOfMonths={2} disabled={(date) => isAfter(date, today) || isBefore(date, before)} />
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
}
