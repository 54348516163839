import { useMutation } from "@tanstack/react-query";
import { validateAirshipCredentials } from "../../api/bAirshipApi";

export const useValidateAirship = () => {
  return useMutation({
    mutationKey: ["validate-airship"],
    mutationFn: (data) => validateAirshipCredentials(data),
    onError: (error) => {
      console.error("Airship validation error details:", error.response || error);
    },
  });
};
