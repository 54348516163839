import { useInfiniteQuery } from "@tanstack/react-query";
import { attemptGetCustomers } from "../../api/UsersApi";

export const useGetCustomers = (filters, enabled = true, customQueryKey = null) => {
  // Use the provided custom key or default to ['customers', stringified filters]
  const queryKey = customQueryKey || ["customers", JSON.stringify(filters)];

  return useInfiniteQuery({
    queryKey: queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const response = await attemptGetCustomers({
        ...filters,
        page: pageParam,
      });

      return {
        ...response.data,
        currentPage: pageParam,
      };
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.data && lastPage.data.length > 0) {
        return lastPage.currentPage + 1;
      }
      return undefined;
    },
    enabled: enabled,
    keepPreviousData: true,
  });
};
