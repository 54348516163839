// BrowseMailchimpCard.jsx
import React, { useState } from "react";
import { Card, Flex, Text, Button, Badge, TextField, Progress } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import { useAuthenticateIntegrations, useGetActiveIntegrations, useCreateMailchimpFields } from "hooks";
import { useQueryClient } from "@tanstack/react-query";
import { AuthMailchimpDialog } from "./AuthMailchimpDialog"; // Add import

export function BrowseMailchimpCard({ userProfile }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [apiKey, setApiKey] = useState("");
  const [isApiKeyValid, setIsApiKeyValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const createMailchimpFields = useCreateMailchimpFields();

  const activateIntegrations = useAuthenticateIntegrations();
  const { data: activeIntegrationsData } = useGetActiveIntegrations(userProfile.organisation_id);
  const [openDialog, setOpenDialog] = useState(false);

  const isMailchimpActive = () => {
    if (!activeIntegrationsData?.data?.integrations) return false;
    return activeIntegrationsData.data.integrations.some((integration) => integration.name === "mailchimp");
  };

  const handleOpenChange = (isOpen) => {
    setOpenDialog(isOpen);
    if (!isOpen) {
      handleCancelAuthDialog();
    }
  };

  // Add missing handleCancelAuthDialog function
  const handleCancelAuthDialog = () => {
    setApiKey("");
    setIsApiKeyValid(true);
  };

  const handleTextFieldChange = (event) => {
    setApiKey(event.target.value);
    setIsApiKeyValid(true);
  };

  const handleSave = async () => {
    if (!apiKey) {
      setIsApiKeyValid(false);
      return;
    }

    setIsLoading(true);
    setProgressValue(10);

    try {
      const result = await activateIntegrations.mutateAsync({
        timestamp: Date.now(),
        user_id: userProfile.user_id,
        organisation_id: userProfile.organisation_id,
        type: "mailchimp",
        sub_payload: { api_key: apiKey },
      });

      setProgressValue(50);
      queryClient.invalidateQueries(["integrations"]);

      await createMailchimpFields.mutateAsync({
        organisation_id: userProfile.organisation_id,
      });

      // Navigate to setup page
      navigate("/mailchimp-setup", {
        state: {
          apiKey,
          authData: result?.data,
          orgId: userProfile.organisation_id,
        },
      });
    } catch (error) {
      console.error("Validation error:", error);
      setIsApiKeyValid(false);
    } finally {
      setIsLoading(false);
      setProgressValue(0);
    }
  };

  return (
    <>
      <Card
        style={{
          display: "flex",
          minWidth: "455px",
          padding: "var(--space-4)",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Flex align="center" gap="4">
          <Flex
            style={{
              borderRadius: "var(--space-1)",
              background: "#FFFFFF",
              border: "1px solid var(--gray-a6)",
            }}
            height="40px"
            width="40px"
            p="2"
            justify="center"
            align="center"
          >
            <img src={require("../../../assets/images/mailchimp.png")} alt="mailchimp" style={{ width: "24px" }} />
          </Flex>
          <Flex direction="column" justify="center" align="start">
            <Text size="4" weight="medium">
              Mailchimp
            </Text>
            <Text style={{ color: "var(--gray-a11)" }} size="1">
              Email marketing
            </Text>
          </Flex>
        </Flex>

        <Flex align="center">
          {isMailchimpActive() ? (
            <Badge size="1" variant="soft" color="green">
              Connected
            </Badge>
          ) : (
            <Button onClick={() => handleOpenChange(true)} size="2" variant="soft" color="gray" style={{ cursor: "pointer" }}>
              Connect
            </Button>
          )}
        </Flex>
      </Card>

      <AuthMailchimpDialog
        isOpen={openDialog}
        onOpenChange={handleOpenChange}
        onCancel={handleCancelAuthDialog}
        onSave={handleSave}
        handleTextFieldChange={handleTextFieldChange}
        isApiKeyValid={isApiKeyValid}
        isLoading={isLoading}
        progressValue={progressValue}
      />
    </>
  );
}
