import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Flex, Text, Button, Select, IconButton, Separator, TextField, Dialog, Checkbox } from "@radix-ui/themes";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { useQueryClient } from "@tanstack/react-query";
import { useToast } from "../../../context/ToastContext";
import { ToastAlert } from "components";
import { useProfileContext, useLocationContext, useDateRangeContext } from "context";
import { useGetAirshipUnits, useSaveAirshipMappings } from "hooks";

export function AirshipConfigSetupPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setToastMessage } = useToast();
  const { apiKey, orgId } = location.state || {};
  const { userProfile } = useProfileContext();
  const { initialItems, locationSelection, setLocationSelection, locationsData, isLocationsSuccess, allLocations } = useLocationContext();

  // States
  const [savedApiKey, setSavedApiKey] = useState(apiKey);
  const [airshipUnits, setAirshipUnits] = useState([]);
  const [locationMappings, setLocationMappings] = useState({});
  const [groupMappings, setGroupMappings] = useState({});
  const [customGroups, setCustomGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [isCreateGroupDialogOpen, setIsCreateGroupDialogOpen] = useState(false);
  const [currentLocationForGroup, setCurrentLocationForGroup] = useState(null);
  const [newGroupName, setNewGroupName] = useState("");
  const [applyToAllUnits, setApplyToAllUnits] = useState(false);

  const [sameGroupForAll, setSameGroupForAll] = useState(false);
  const [isGroupForAllDialogOpen, setIsGroupForAllDialogOpen] = useState(false);
  const [groupForAllName, setGroupForAllName] = useState("");
  const [areGroupFieldsReadOnly, setAreGroupFieldsReadOnly] = useState(false);

  // Fetch Airship units
  const { data: airshipUnitsData, isLoading: isUnitsLoading } = useGetAirshipUnits(orgId);

  const saveAirshipMappings = useSaveAirshipMappings();

  // Update units when API data is available
  useEffect(() => {
    if (airshipUnitsData?.data?.units?.data) {
      setAirshipUnits(
        airshipUnitsData.data.units.data.map((unit) => ({
          id: unit.id.toString(),
          name: unit.name,
        }))
      );
    }
  }, [airshipUnitsData]);

  const handleAirshipUnitChange = (locationId, unitId) => {
    setLocationMappings({
      ...locationMappings,
      [locationId]: unitId,
    });
  };

  const handleCreateGroup = () => {
    if (!newGroupName.trim()) {
      return;
    }

    // Add the new group to the list of custom groups if it doesn't exist
    if (!customGroups.includes(newGroupName)) {
      setCustomGroups([...customGroups, newGroupName]);
    }

    if (applyToAllUnits) {
      // Create logic to apply to all units
      const allGroups = {};
      initialItems.forEach((item) => {
        allGroups[item.value] = newGroupName;
      });
      setGroupMappings({
        ...groupMappings,
        ...allGroups,
      });
    } else {
      // Apply just to the current location
      setGroupMappings({
        ...groupMappings,
        [currentLocationForGroup]: newGroupName,
      });
    }

    // Reset and close dialog
    setNewGroupName("");
    setApplyToAllUnits(false);
    setIsCreateGroupDialogOpen(false);
  };

  const handleActivate = async () => {
    try {
      setIsLoading(true);
      setProgressValue(10);

      // Prepare location mappings data
      const mappingsToSave = initialItems
        .map((loc) => {
          const unitId = locationMappings[loc.value];
          // Skip locations without unit mappings
          if (!unitId) return null;

          // Get unit name from the units array
          const unit = airshipUnits.find((u) => u.id === unitId);

          return {
            location_id: loc.value,
            location_name: loc.label,
            unit_id: unitId,
            unit_name: unit?.name || "Unknown Unit",
            group_name: groupMappings[loc.value] || "",
          };
        })
        .filter(Boolean); // Remove null entries

      setProgressValue(30);

      // Call the save-mappings API using the mutation hook
      const result = await saveAirshipMappings.mutateAsync({
        organisation_id: orgId,
        location_mappings: mappingsToSave,
      });

      setProgressValue(70);

      if (result?.data?.status !== "success") {
        throw new Error(result?.data?.message || "Failed to save mappings");
      }

      // IMPORTANT: Invalidate the Airship status query before navigating
      queryClient.invalidateQueries(["airship-status"]);

      // Navigate to success page
      navigate("/activation-success", {
        state: {
          description: "Your customers will now sync with your selected Airship units.",
          integrationType: "airship", // Add this to identify we're coming from Airship
        },
      });
    } catch (error) {
      console.error("Error during setup:", error);
      setToastMessage(error.message || "Failed to complete Airship setup");
    } finally {
      setIsLoading(false);
      setProgressValue(0);
    }
  };

  if (!location.state) {
    return <div>No authentication data found. Please go back and authenticate first.</div>;
  }

  const handleGroupTextChange = (locationId, groupName) => {
    setGroupMappings({
      ...groupMappings,
      [locationId]: groupName,
    });
  };

  const handleSameGroupForAllChange = (checked) => {
    setSameGroupForAll(checked);
    if (checked) {
      setIsGroupForAllDialogOpen(true);
    } else {
      setAreGroupFieldsReadOnly(false);
    }
  };

  const handleApplyGroupForAll = () => {
    if (!groupForAllName.trim()) {
      return;
    }

    // Apply the same group name to all locations
    const allGroups = {};
    initialItems.forEach((item) => {
      allGroups[item.value] = groupForAllName;
    });

    setGroupMappings(allGroups);
    setAreGroupFieldsReadOnly(true);
    setIsGroupForAllDialogOpen(false);
  };

  return (
    <Flex
      style={{
        minHeight: "100vh",
      }}
      justify="center"
      align="center"
      p="6"
    >
      <Card
        style={{
          maxWidth: "1000px",
          width: "100%",
        }}
        p="4"
      >
        <Flex style={{ alignSelf: "stretch" }} display="flex" direction="column" align="start">
          {/* Header */}
          <Flex style={{ alignSelf: "stretch" }} display="flex" justify="between" align="center">
            <Flex display="flex" align="center" gap="4">
              <Flex
                style={{
                  borderRadius: "var(--space-1)",
                  background: "#FFFFFF",
                  border: "1px solid var(--gray-a6)",
                }}
                height="40px"
                width="40px"
                p="2"
                justify="center"
                align="center"
              >
                <img src={require("../../../assets/images/airship.png")} alt="airship" style={{ width: "24px" }} />
              </Flex>
              <Flex direction="column" justify="center" align="start">
                <Text size="4" weight="medium">
                  Airship
                </Text>
                <Text style={{ color: "var(--gray-a11)" }} size="1">
                  CRM & Email marketing
                </Text>
              </Flex>
            </Flex>
            <Flex display="flex" align="center" gap="4">
              <Button size="2" variant="soft" onClick={() => navigate(-1)} color="gray" style={{ cursor: "pointer" }}>
                Cancel
              </Button>
              <Button size="2" variant="soft" onClick={handleActivate} loading={isLoading} style={{ cursor: "pointer" }}>
                Save and finish
              </Button>
            </Flex>
          </Flex>

          <Separator orientation="horizontal" size="4" my="4" />

          {/* Locations Section */}
          <Flex style={{ alignSelf: "stretch" }} display="flex" align="start" gap="5">
            <Flex style={{ alignSelf: "stretch" }} display="flex" width="296px" direction="column" align="start" gap="2">
              <Text size="2" weight="medium">
                Locations
              </Text>
              <Text size="1" weight="regular">
                Map each b connect location to its corresponding Airship unit. After that, select the group where your contacts will be imported.
              </Text>
            </Flex>

            <Flex direction="column" gap="3" style={{ width: "100%" }}>
              {/* Header row */}
              <Flex direction="row" justify="between" style={{ width: "100%" }}>
                <Text size="2" weight="medium" style={{ width: "32%" }}>
                  b connect location
                </Text>
                <Text size="2" weight="medium" style={{ width: "32%" }}>
                  Airship unit
                </Text>
                <Flex justify="between" align="center" style={{ width: "32%", paddingLeft: "18px" }}>
                  <Text size="2" weight="medium">
                    Airship group
                  </Text>
                  {initialItems && initialItems.length > 1 && (
                    <Flex align="center">
                      <Checkbox checked={sameGroupForAll} onCheckedChange={handleSameGroupForAllChange} />
                      <Text size="1" style={{ marginLeft: "4px" }}>
                        Same for all
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>

              {/* Location mappings */}
              {(initialItems || []).map((loc) => (
                <Flex key={loc.value} direction="row" justify="between" style={{ width: "100%" }}>
                  <Flex style={{ width: "32%", minWidth: 0 }}>
                    {" "}
                    <Flex
                      style={{
                        width: "100%",
                        backgroundColor: "var(--gray-3)",
                        border: "1px solid var(--gray-a7)",
                        borderRadius: "var(--radius-2)",
                        padding: "0 var(--space-2)",
                        height: "34px",
                        alignItems: "center",
                        overflow: "hidden",
                      }}
                    >
                      <Text
                        size="2"
                        title={loc.label}
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          width: "100%",
                          color: "var(--gray-a11)",
                        }}
                      >
                        {loc.label}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex align="center" style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    <IconButton size="2" variant="ghost" color="gray" style={{ pointerEvents: "none" }}>
                      <ArrowRightIcon width="16" height="16" />
                    </IconButton>
                  </Flex>

                  <Flex style={{ width: "32%", minWidth: 0, maxWidth: "32%", flex: "0 0 32%" }}>
                    <Select.Root value={locationMappings[loc.value] || ""} onValueChange={(value) => handleAirshipUnitChange(loc.value, value)} style={{ width: "100%" }}>
                      <Select.Trigger
                        placeholder="Select..."
                        style={{
                          width: "100%",
                          maxWidth: "100%",
                          overflow: "hidden",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            width: "100%",
                            display: "block",
                            maxWidth: "100%",
                          }}
                          title={locationMappings[loc.value] ? airshipUnits.find((unit) => unit.id === locationMappings[loc.value])?.name || "" : "Select a unit"}
                        >
                          {locationMappings[loc.value] ? airshipUnits.find((unit) => unit.id === locationMappings[loc.value])?.name || "" : "Select a unit"}
                        </div>
                      </Select.Trigger>
                      <Select.Content side="bottom" align="start" sideOffset={4}>
                        {airshipUnits.map((unit) => (
                          <Select.Item key={unit.id} value={unit.id} title={unit.name}>
                            {unit.name}
                          </Select.Item>
                        ))}
                      </Select.Content>
                    </Select.Root>
                  </Flex>

                  <Flex align="center" style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    <IconButton size="2" variant="ghost" color="gray" style={{ pointerEvents: "none" }}>
                      <ArrowRightIcon width="16" height="16" />
                    </IconButton>
                  </Flex>

                  <Flex style={{ width: "32%", minWidth: 0, maxWidth: "32%" }}>
                    <TextField.Root
                      value={groupMappings[loc.value] || ""}
                      onChange={(e) => handleGroupTextChange(loc.value, e.target.value)}
                      size="2"
                      style={{
                        width: "100%",
                        backgroundColor: areGroupFieldsReadOnly ? "var(--gray-3)" : undefined,
                        cursor: areGroupFieldsReadOnly ? "not-allowed" : "text",
                      }}
                      disabled={areGroupFieldsReadOnly}
                    />
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Card>

      <Dialog.Root
        open={isGroupForAllDialogOpen}
        onOpenChange={(open) => {
          if (!open) setSameGroupForAll(false);
          setIsGroupForAllDialogOpen(open);
        }}
      >
        <Dialog.Content maxWidth="600px" maxHeight="690px" align="center">
          <Dialog.Title>Airship Group</Dialog.Title>

          <Flex style={{ alignSelf: "stretch" }} display="flex" direction="column" align="start" gap="3">
            <Text size="2" weight="medium">
              Group name
            </Text>
            <TextField.Root value={groupForAllName} onChange={(e) => setGroupForAllName(e.target.value)} placeholder="Wi-Fi" style={{ width: "100%" }} size="2" variant="surface" />
          </Flex>

          <Flex
            style={{
              alignSelf: "stretch",
              width: "100%",
            }}
            display="flex"
            justify="end"
            align="center"
            mt="4"
            gap="3"
          >
            <Dialog.Close>
              <Button size="2" variant="soft" color="gray" style={{ cursor: "pointer" }} onClick={() => setSameGroupForAll(false)}>
                Cancel
              </Button>
            </Dialog.Close>
            <Button onClick={handleApplyGroupForAll} size="2" variant="solid" color="accent" style={{ cursor: "pointer" }}>
              Confirm
            </Button>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
      <div style={{ position: "fixed", zIndex: 2147483647, pointerEvents: "none" }}>
        <ToastAlert />
      </div>
    </Flex>
  );
}
