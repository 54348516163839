import * as React from "react";
import { Flex, Text, Card, Switch, TextField, Button, Badge, IconButton, DropdownMenu, Dialog, Checkbox } from "@radix-ui/themes";
import { ToastAlert } from "components";
import { ReactComponent as LiveWirelessLANIcon } from "../../assets/icons/live_wlan.svg";
import { ReactComponent as OfflineWirelessLANIcon } from "../../assets/icons/offline_wlan.svg";
import { useGetWirelessLAN, useUpdateWirelessLAN } from "hooks";
import { useProfileContext } from "context";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query"; // Import useQueryClient
import { DownloadIcon, CopyIcon } from "@radix-ui/react-icons";
import { set } from "date-fns";
import { useTheme } from "../../context/ThemeContext";

const Networks = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userProfile } = useProfileContext();
  const organisationID = userProfile.organisation_id;
  const { appearance } = useTheme();

  const getQrBackgroundColor = () => {
    return appearance === "dark" ? "1a191c" : "ffffff";
  };

  const getQrUrl = (data, color = "666666", format = "png") => {
    return `https://api.qrserver.com/v1/create-qr-code/?data=${data}&size=128x128&bgcolor=${getQrBackgroundColor()}&color=${color}&format=${format}`;
  };

  // Grabbing the WLAN Data using the useGetWirelessLAN hook
  const { data: wirelessLanData } = useGetWirelessLAN(organisationID);

  // Handles dirty changes in the Networks Tab
  const [isDirty, setIsDirty] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  // Call the hook at the component level, and get the function to execute the mutation
  const updateWirelessLAN = useUpdateWirelessLAN();

  // Creates the state that tracks changes in the network name, password.
  // Also creates the state that checks if the passworld field is visible, and if the save changes button is enabled or disabled.
  const [networkPageState, setNetworkPageState] = React.useState([]);
  const [interfaceState, setInterfaceState] = React.useState([]);

  // Creates the state that tracks the colour of the QR code
  // Also creates the state that checks to see if the "I understand that..." checkbox is ticked off!
  const [colourSelection, setColourSelection] = React.useState("666666");
  console.log("Colour Selection", colourSelection);
  const [wifiDisclaimer, setWifiDisclaimer] = React.useState(false);

  React.useEffect(() => {
    if (wirelessLanData?.data) {
      const initialNetworkPageState =
        Array.isArray(networkPageState) && networkPageState.length === 0
          ? wirelessLanData?.data.map((entry, index) => ({ id: index, enabled: entry.enabled }))
          : wirelessLanData?.data.map((_, index) => networkPageState[index]);

      const initialInterfaceState =
        Array.isArray(interfaceState) && interfaceState.length === 0
          ? wirelessLanData?.data.map((_, index) => ({ id: index, disabled: true, textfield_type: "password" }))
          : wirelessLanData?.data.map((_, index) => ({ id: index, disabled: interfaceState[index].disabled, textfield_type: "password" }));

      setNetworkPageState(initialNetworkPageState);
      setInterfaceState(initialInterfaceState);
      setIsLoading(false);
    }
  }, [wirelessLanData]);

  // Creates the event handlers that will be used to update the network name and password
  const handleChanges = (event, id) => {
    // Sets the state of the textfield type to "text" if the password field is focused, and "password" if the password field is blurred.
    setInterfaceState((prevInterfaceState) =>
      prevInterfaceState.map((item) => {
        if (item.id === id) {
          let updatedItem = { ...item };
          if (event.type === "focus") {
            updatedItem.textfield_type = "text";
          } else if (event.type === "blur") {
            updatedItem.textfield_type = "password";
          } else if (event.type === "change") {
            updatedItem.disabled = false;
          }
          return updatedItem;
        }
        return item;
      })
    );

    if (event.type === "change") {
      // Sets the state of the "Save Changes" button to be enabled
      setInterfaceState((prevInterfaceState) => prevInterfaceState.map((item) => (item.id === id ? { ...item, disabled: false } : item)));

      // Sets the state of the network page name and password
      setNetworkPageState((prevNetworkPageState) => prevNetworkPageState.map((item) => (item.id === id ? { ...item, [event.target.name]: event.target.value } : item)));
      setIsDirty(true);
    }
  };

  // Creates the event handlers that will be used to send the enabled status to the backend.
  const sendPayload = async (checkedOrEvent, id) => {
    if (typeof checkedOrEvent === "boolean") {
      setNetworkPageState((prevNetworkPageState) => prevNetworkPageState.map((item) => (item.id === id ? { ...item, enabled: checkedOrEvent } : item)));

      await updateWirelessLAN.mutateAsync({
        organisationID,
        data: {
          id,
          payload: {
            enabled: checkedOrEvent,
          },
        },
      });

      queryClient.invalidateQueries(["wireless-lan", organisationID]);
    } else if (typeof checkedOrEvent === "object") {
      const payload = networkPageState.find((item) => item.id === id);
      await updateWirelessLAN.mutateAsync({
        organisationID,
        data: {
          id,
          payload,
        },
      });

      queryClient.invalidateQueries(["wireless-lan", organisationID]);
    }

    // Reset all states to their initial values
    setNetworkPageState((prevNetworkPageState) => prevNetworkPageState.map((item) => (item.id === id ? { id } : item)));

    setInterfaceState((prevInterfaceState) => prevInterfaceState.map((item) => (item.id === id ? { ...item, disabled: true } : item)));

    setColourSelection("666666");
    setWifiDisclaimer(false);
  };

  // Creates the event handlers that will be used to download the QR code.
  const handleDownload = (url, filename) => {
    return async () => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        console.error("Download failed:", error);
      }
    };
  };

  // Creates the event handlers that will be used to copy the QR code.
  // Add this function at the component level
  const handleCopy = (url) => {
    return async () => {
      try {
        // Fetch the image
        const response = await fetch(url);
        const blob = await response.blob();

        // Create a ClipboardItem
        const item = new ClipboardItem({
          [blob.type]: blob,
        });

        // Copy to clipboard
        await navigator.clipboard.write([item]);

        // Optional: Show success message
        console.log("QR code copied to clipboard");
      } catch (error) {
        console.error("Failed to copy QR code:", error);
      }
    };
  };

  const handleColourField = (event) => {
    if (event.target.value.slice(0, 1) === "#") {
      setColourSelection(event.target.value.slice(1, event.target.value.length));
    } else {
      setColourSelection(event.target.value);
    }
  };

  const getQrForegroundColor = () => {
    return appearance === "dark" ? "1a191c" : "ffffff";
  };

  React.useEffect(() => {
    if (interfaceState.every((item) => item.disabled === true)) {
      setIsDirty(false);
    }
  }, [interfaceState]);

  return (
    <React.Fragment>
      <Flex className="main-content-container" style={{ alignItems: "flex-start", justifyContent: "center", flex: "1 0 0", alignSelf: "stretch", overflow: "auto", maxHeight: "calc(100vh - 184px)" }}>
        <Flex direction="column" gap="5" style={{ maxWidth: "975px", alignItems: "flex-start", flex: "1 0 0", overflow: "auto" }}>
          {wirelessLanData?.data.map((entry, index) =>
            entry?.x_passphrase === null ? (
              <React.Fragment key={index}>
                <Card style={{ display: "flex", padding: "var(--space-4)", alignSelf: "stretch", flexDirection: "row", alignItems: "flex-start", gap: "var(--space-7)" }}>
                  <Flex className="frame-528172570" maxWidth="451px" style={{ alignSelf: "stretch" }} direction="column" align="start" gap="5" flexGrow="1">
                    <Flex className="frame-528172582" style={{ alignSelf: "stretch" }} direction="column" align="start" gap="2">
                      <Flex className="frame-528172580" style={{ alignSelf: "stretch" }} justify="between" align="center">
                        <Flex className="frame-528172775" display="flex" align="center" gap="2">
                          {entry?.enabled === true ? (
                            <LiveWirelessLANIcon style={{ width: "var(--space-2)", height: "var(--space-2)" }} />
                          ) : (
                            <OfflineWirelessLANIcon style={{ width: "var(--space-2)", height: "var(--space-2)" }} />
                          )}
                          <Text size="3" weight="medium">
                            {entry?.type}
                          </Text>
                          <Badge size="1" variant="soft" color="gray">
                            Portal
                          </Badge>
                        </Flex>
                        <Switch size="1" variant="surface" defaultChecked={entry?.enabled} onCheckedChange={(checked) => sendPayload(checked, index)} />
                      </Flex>
                      <Text style={{ alignSelf: "stretch", color: "var(--gray-11)" }} size="1" weight="regular">
                        {entry?.description}
                      </Text>
                    </Flex>
                    <Flex className="frame-528172771" style={{ alignSelf: "stretch" }} display="flex" direction="column" align="start" gap="5">
                      <Flex className="frame-528172774" style={{ alignSelf: "stretch" }} display="flex" direction="column" align="end" gap="4">
                        <label className="text-field" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "var(--space-1)", alignSelf: "stretch" }}>
                          <Text size="2" weight="medium">
                            Network Name
                          </Text>
                          <Flex gap="4" style={{ alignSelf: "stretch", alignItems: "flex-end", justifyContent: "center" }}>
                            <TextField.Root
                              name="name"
                              style={{ width: "50%", alignSelf: "stretch" }}
                              defaultValue={entry.name}
                              placeholder="Enter network name"
                              onChange={(event) => handleChanges(event, index)}
                              size="2"
                              disabled={!entry.enabled}
                            />
                            <Button size="2" variant="surface" color="gray" onClick={() => navigate("/wifi/guest-portal")} style={{ width: "50%", cursor: "pointer" }}>
                              Edit Portal Design
                            </Button>
                          </Flex>
                        </label>
                        <Dialog.Root>
                          <Dialog.Trigger>
                            <Button size="2" variant="solid" disabled={interfaceState[index]?.disabled} style={{ width: "100%" }}>
                              Save changes
                            </Button>
                          </Dialog.Trigger>
                          <Dialog.Content width="1280px">
                            <Dialog.Title>Adjust Guest Network</Dialog.Title>
                            <Dialog.Description size="2" mb="4">
                              Before changing the{" "}
                              <Text size="2" weight="bold">
                                Network Name
                              </Text>{" "}
                              or{" "}
                              <Text size="2" weight="bold">
                                Password
                              </Text>
                              , keep in mind:
                            </Dialog.Description>
                            <Flex style={{ alignSelf: "stretch" }} display="flex" direction="column" align="start" gap="5">
                              <Text style={{ padding: "0px var(--space-5)" }} size="1" weight="regular">
                                <ul style={{ listStyleType: "disc", alignItems: "center" }}>
                                  <li>A new QR code will be generated.</li>
                                  <li>Previously shared QR codes will stop working.</li>
                                  <li>Returning users will need to sign in again via the portal to access the network.</li>
                                  <li>Users currently connected to this network will be disconnected.</li>
                                  <li>The update takes effect immediately.</li>
                                </ul>
                              </Text>
                              <Flex
                                className="checkbox-container"
                                style={{ borderRadius: "var(--space-1)", border: "1px solid var(--gray-a3)", background: "var(--color-panel-solid)" }}
                                display="flex"
                                width="552px"
                                p="4"
                                align="start"
                                gap="2"
                              >
                                <Text as="label" size="2" weight="regular">
                                  <Flex as="span" gap="2">
                                    <Checkbox checked={wifiDisclaimer} onCheckedChange={(checked) => setWifiDisclaimer(checked)} size="2" variant="surface" /> I understand that changing the network
                                    name or password will affect QR codes, guest access, and device connections.
                                  </Flex>
                                </Text>
                              </Flex>
                            </Flex>
                            <Flex gap="3" mt="4" justify="end">
                              <Dialog.Close
                                onClick={() => {
                                  setWifiDisclaimer(false);
                                }}
                              >
                                <Button variant="soft" color="gray">
                                  Cancel
                                </Button>
                              </Dialog.Close>
                              <Dialog.Close disabled={!wifiDisclaimer} onClick={(event) => sendPayload(event, index)}>
                                <Button variant="solid">Save changes</Button>
                              </Dialog.Close>
                            </Flex>
                          </Dialog.Content>
                        </Dialog.Root>
                      </Flex>
                    </Flex>
                  </Flex>
                  <div
                    className="qr-code-card"
                    style={{
                      display: "flex",
                      maxWidth: "451px",
                      padding: "var(--space-4)",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "var(--space-4)",
                      flexGrow: "1",
                      alignSelf: "stretch",
                      borderRadius: "var(--space-1)",
                      border: "1px dashed var(--gray-a6)",
                      background: "var(--color-panel-solid)",
                    }}
                  >
                    <Flex className="qr-code-wifi" display="flex" width="148px" height="148px" justify="center" align="center" gap="2">
                      {interfaceState[index]?.disabled ? (
                        <img
                          alt="QR code of guest wifi"
                          src={getQrUrl(`WIFI:T:nopass;S:${entry?.name};`)}
                          style={{ display: "flex", width: "128px", height: "128px", justifyContent: "center", alignItems: "center", gap: "var(--space-2)" }}
                        />
                      ) : (
                        <div style={{ width: "128px", height: "128px", background: "var(--gray-a3)", borderRadius: "var(--space-1)" }}></div>
                      )}
                    </Flex>
                    <Flex className="qr-code-options" display="flex" direction="column" justify="center" align="start" gap="2">
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger>
                          <IconButton size="2" variant="surface" color="gray">
                            <DownloadIcon width="16" height="16" />
                          </IconButton>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content side="right">
                          <DropdownMenu.Item
                            onSelect={handleDownload(
                              `https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};;&size=128x128&bgcolor=ffffff&color=000000&format=png`,
                              `${entry?.name}_qr_code.png`
                            )}
                          >
                            Download PNG in black
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onSelect={handleDownload(
                              `https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};;&size=128x128&bgcolor=000000&color=ffffff&format=png`,
                              `${entry?.name}_qr_code.png`
                            )}
                          >
                            Download PNG in white
                          </DropdownMenu.Item>
                          <DropdownMenu.Item onSelect={(event) => event.preventDefault()}>
                            <Dialog.Root>
                              <Dialog.Trigger>
                                <Text size="2" weight="regular">
                                  Download PNG in colour...
                                </Text>
                              </Dialog.Trigger>
                              <Dialog.Content width="1280px">
                                <Dialog.Title>Download PNG in colour</Dialog.Title>
                                <Dialog.Description size="2" mb="4">
                                  Choose a colour for your QR code
                                </Dialog.Description>
                                <Flex style={{ alignSelf: "stretch" }} display="flex" direction="column" align="start" gap="5">
                                  <div
                                    className="qr-code-card"
                                    style={{
                                      display: "flex",
                                      maxWidth: "1000px",
                                      padding: "var(--space-4)",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      gap: "var(--space-4)",
                                      flex: "1 0 0",
                                      alignSelf: "stretch",
                                      borderRadius: "var(--space-1)",
                                      border: "1px dashed var(--gray-a6)",
                                      background: "var(--color-panel-solid)",
                                    }}
                                  >
                                    <Flex className="qr-code-wifi" display="flex" width="148px" height="148px" justify="center" align="center" gap="2">
                                      <img
                                        alt="QR code of guest wifi"
                                        src={`https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};P:${
                                          entry?.x_passphrase
                                        };;&size=128x128&bgcolor=${colourSelection}&color=${getQrForegroundColor()}&format=png`}
                                        style={{ display: "flex", width: "128px", height: "128px", justifyContent: "center", alignItems: "center", gap: "var(--space-2)" }}
                                      />
                                    </Flex>
                                  </div>
                                  <Flex className="qr-code-color-picker" style={{ alignSelf: "stretch" }} display="flex" align="center" gap="2">
                                    <label htmlFor="button_colour_picker" style={{ position: "relative", display: "inline-block", cursor: "pointer" }}>
                                      <input
                                        type="color"
                                        id="button_colour_picker"
                                        value={`#${colourSelection}`}
                                        onChange={handleColourField}
                                        style={{ opacity: 0, position: "absolute", width: "100%", height: "100%", border: "none", padding: 0, margin: 0, cursor: "pointer" }}
                                      />
                                      <div
                                        style={{
                                          width: "32px",
                                          height: "32px",
                                          backgroundColor: `#${colourSelection}`,
                                          cursor: "pointer",
                                          borderRadius: "var(--radius-2)",
                                          border: "1px solid var(--gray-a6)",
                                        }}
                                      ></div>
                                    </label>
                                    <TextField.Root onChange={handleColourField} style={{ width: "100%" }} defaultValue={`#${colourSelection}`} placeholder="Enter hex colour code" size="2" />
                                  </Flex>
                                </Flex>
                                <Flex gap="3" mt="4" justify="end">
                                  <Dialog.Close onClick={() => setColourSelection("666666")}>
                                    <Button variant="soft" color="gray">
                                      Cancel
                                    </Button>
                                  </Dialog.Close>
                                  <Dialog.Close
                                    onClick={handleDownload(
                                      `https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};P:${
                                        entry?.x_passphrase
                                      };;&size=128x128&bgcolor=${getQrBackgroundColor()}&color=${colourSelection}&format=png`,
                                      `${entry?.name}_qr_code.png`
                                    )}
                                  >
                                    <Button>Download</Button>
                                  </Dialog.Close>
                                </Flex>
                              </Dialog.Content>
                            </Dialog.Root>
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onSelect={handleDownload(
                              `https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};;&size=128x128&bgcolor=1a191c&color=666666&format=svg`,
                              `${entry?.name}_qr_code.svg`
                            )}
                          >
                            Download SVG
                          </DropdownMenu.Item>
                        </DropdownMenu.Content>
                      </DropdownMenu.Root>
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger>
                          <IconButton size="2" variant="surface" color="gray">
                            <CopyIcon width="16" height="16" />
                          </IconButton>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content side="right">
                          <DropdownMenu.Item
                            onSelect={handleCopy(`https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};;&size=128x128&bgcolor=ffffff&color=000000&format=png`)}
                          >
                            Copy PNG in black
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onSelect={handleCopy(`https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};;&size=128x128&bgcolor=000000&color=ffffff&format=png`)}
                          >
                            Copy PNG in white
                          </DropdownMenu.Item>
                          <DropdownMenu.Item onSelect={(event) => event.preventDefault()}>
                            <Dialog.Root>
                              <Dialog.Trigger>
                                <Text size="2" weight="regular">
                                  Copy PNG in colour...
                                </Text>
                              </Dialog.Trigger>
                              <Dialog.Content width="1280px">
                                <Dialog.Title>Copy PNG in colour</Dialog.Title>
                                <Dialog.Description size="2" mb="4">
                                  Choose a colour for your QR code
                                </Dialog.Description>
                                <Flex style={{ alignSelf: "stretch" }} display="flex" direction="column" align="start" gap="5">
                                  <div
                                    className="qr-code-card"
                                    style={{
                                      display: "flex",
                                      maxWidth: "1000px",
                                      padding: "var(--space-4)",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      gap: "var(--space-4)",
                                      flexGrow: "1",
                                      alignSelf: "stretch",
                                      borderRadius: "var(--space-1)",
                                      border: "1px dashed var(--gray-a6)",
                                      background: "var(--color-panel-solid)",
                                    }}
                                  >
                                    <Flex className="qr-code-wifi" display="flex" width="148px" height="148px" justify="center" align="center" gap="2">
                                      <img
                                        alt="QR code of guest wifi"
                                        src={`https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};;&size=128x128&bgcolor=1a191c&color=${colourSelection}&format=png`}
                                        style={{ display: "flex", width: "128px", height: "128px", justifyContent: "center", alignItems: "center", gap: "var(--space-2)" }}
                                      />
                                    </Flex>
                                  </div>
                                  <Flex className="qr-code-color-picker" style={{ alignSelf: "stretch" }} display="flex" align="center" gap="2">
                                    <label htmlFor="button_colour_picker" style={{ position: "relative", display: "inline-block", cursor: "pointer" }}>
                                      <input
                                        type="color"
                                        id="button_colour_picker"
                                        value={`#${colourSelection}`}
                                        onChange={handleColourField}
                                        style={{ opacity: 0, position: "absolute", width: "100%", height: "100%", border: "none", padding: 0, margin: 0, cursor: "pointer" }}
                                      />
                                      <div
                                        style={{
                                          width: "32px",
                                          height: "32px",
                                          backgroundColor: `#${colourSelection}`,
                                          cursor: "pointer",
                                          borderRadius: "var(--radius-2)",
                                          border: "1px solid var(--gray-a6)",
                                        }}
                                      ></div>
                                    </label>
                                    <TextField.Root onChange={handleColourField} style={{ width: "100%" }} defaultValue={`#${colourSelection}`} placeholder="Enter hex colour code" size="2" />
                                  </Flex>
                                </Flex>
                                <Flex gap="3" mt="4" justify="end">
                                  <Dialog.Close onClick={() => setColourSelection("666666")}>
                                    <Button variant="soft" color="gray">
                                      Cancel
                                    </Button>
                                  </Dialog.Close>
                                  <Dialog.Close
                                    onClick={handleCopy(
                                      `https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};;&size=128x128&bgcolor=1a191c&color=${colourSelection}&format=png`
                                    )}
                                  >
                                    <Button>Copy</Button>
                                  </Dialog.Close>
                                </Flex>
                              </Dialog.Content>
                            </Dialog.Root>
                          </DropdownMenu.Item>
                        </DropdownMenu.Content>
                      </DropdownMenu.Root>
                    </Flex>
                  </div>
                </Card>
              </React.Fragment>
            ) : (
              <React.Fragment key={index}>
                <Card style={{ display: "flex", padding: "var(--space-4)", alignSelf: "stretch", flexDirection: "row", alignItems: "flex-start", gap: "var(--space-7)" }}>
                  <Flex className="frame-528172570" maxWidth="451px" style={{ alignSelf: "stretch" }} display="flex" direction="column" align="start" gap="4" flexGrow="1">
                    <Flex className="frame-528172582" style={{ alignSelf: "stretch" }} display="flex" direction="column" align="start" gap="2">
                      <Flex className="frame-528172580" style={{ alignSelf: "stretch" }} display="flex" justify="between" align="center">
                        <Flex className="frame-528172775" display="flex" align="center" gap="2">
                          {entry?.enabled === true ? (
                            <LiveWirelessLANIcon style={{ width: "var(--space-2)", height: "var(--space-2)" }} />
                          ) : (
                            <OfflineWirelessLANIcon style={{ width: "var(--space-2)", height: "var(--space-2)" }} />
                          )}
                          <Text size="3" weight="medium">
                            {entry?.type}
                          </Text>
                          {entry?.type === "Work Wi-Fi Network" && (
                            <Badge size="1" variant="soft" color="gray">
                              Portal
                            </Badge>
                          )}
                        </Flex>
                        <Switch size="1" variant="surface" defaultChecked={entry?.enabled} onCheckedChange={(checked) => sendPayload(checked, index)} />
                      </Flex>
                      <Text style={{ alignSelf: "stretch", color: "var(--gray-11)" }} size="1" weight="regular">
                        {entry?.description}
                      </Text>
                    </Flex>
                    <Flex className="frame-528172771" style={{ alignSelf: "stretch" }} display="flex" direction="column" align="stretch" gap="5">
                      <Flex className="frame-528172863" gap="4">
                        <label className="text-field" style={{ flexDirection: "column", gap: "var(--space-1)", width: "50%" }}>
                          <Text size="2" weight="medium">
                            Network Name
                          </Text>
                          <TextField.Root
                            name="name"
                            style={{
                              alignSelf: "stretch",
                            }}
                            defaultValue={entry.name}
                            placeholder="Enter network name"
                            onChange={(event) => handleChanges(event, index)}
                            size="2"
                            disabled={!entry.enabled}
                          />
                        </label>
                        <label className="text-field" style={{ flexDirection: "column", gap: "var(--space-1)", width: "50%" }}>
                          <Text size="2" weight="medium">
                            Password
                          </Text>
                          <TextField.Root
                            name="x_passphrase"
                            style={{
                              alignSelf: "stretch",
                            }}
                            type={interfaceState[index]?.textfield_type}
                            onFocus={(event) => handleChanges(event, index)}
                            onBlur={(event) => handleChanges(event, index)}
                            defaultValue={entry?.x_passphrase}
                            placeholder="Enter password"
                            onChange={(event) => handleChanges(event, index)}
                            size="2"
                            disabled={!entry.enabled}
                          />
                        </label>
                      </Flex>
                      <Flex className="frame-528172774" style={{ alignSelf: "stretch" }} display="flex" direction="column" align="end" gap="4">
                        <Dialog.Root>
                          <Dialog.Trigger>
                            <Button size="2" variant="solid" disabled={interfaceState[index]?.disabled} style={{ width: "100%" }}>
                              Save changes
                            </Button>
                          </Dialog.Trigger>
                          <Dialog.Content width="1280px">
                            <Dialog.Title>{index === 1 ? "Adjust Work Network" : "Adjust Staff Network"}</Dialog.Title>
                            <Dialog.Description size="2" mb="4">
                              Before changing the{" "}
                              <Text size="2" weight="bold">
                                Network Name
                              </Text>{" "}
                              or{" "}
                              <Text size="2" weight="bold">
                                Password
                              </Text>
                              , keep in mind:
                            </Dialog.Description>
                            <Flex style={{ alignSelf: "stretch" }} display="flex" direction="column" align="start" gap="5">
                              <Text style={{ padding: "0px var(--space-5)" }} size="1" weight="regular">
                                <ul style={{ listStyleType: "disc", alignItems: "center" }}>
                                  <li>A new QR code will be generated.</li>
                                  <li>Previously shared QR codes will stop working.</li>
                                  {index === 1 ? <li>Returning users will need to sign in again via the portal to access the network.</li> : null}
                                  <li>Users currently connected to this network will be disconnected.</li>
                                  <li>The update takes effect immediately.</li>
                                </ul>
                              </Text>
                              <Flex
                                className="checkbox-container"
                                style={{ borderRadius: "var(--space-1)", border: "1px solid var(--gray-a3)", background: "var(--color-panel-solid)" }}
                                display="flex"
                                width="552px"
                                p="4"
                                align="start"
                                gap="2"
                              >
                                <Text as="label" size="2" weight="regular">
                                  <Flex as="span" gap="2">
                                    <Checkbox checked={wifiDisclaimer} onCheckedChange={(checked) => setWifiDisclaimer(checked)} size="2" variant="surface" /> I understand that changing the network
                                    name or password will affect QR codes, guest access, and device connections.
                                  </Flex>
                                </Text>
                              </Flex>
                            </Flex>
                            <Flex gap="3" mt="4" justify="end">
                              <Dialog.Close
                                onClick={() => {
                                  setWifiDisclaimer(false);
                                }}
                              >
                                <Button variant="soft" color="gray">
                                  Cancel
                                </Button>
                              </Dialog.Close>
                              <Dialog.Close disabled={!wifiDisclaimer} onClick={(event) => sendPayload(event, index)}>
                                <Button variant="solid">Save changes</Button>
                              </Dialog.Close>
                            </Flex>
                          </Dialog.Content>
                        </Dialog.Root>
                      </Flex>
                    </Flex>
                  </Flex>
                  <div
                    className="qr-code-card"
                    style={{
                      display: "flex",
                      maxWidth: "451px",
                      padding: "var(--space-4)",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "var(--space-4)",
                      flex: "1 0 0",
                      alignSelf: "stretch",
                      borderRadius: "var(--space-1)",
                      border: "1px dashed var(--gray-a6)",
                      background: "var(--color-panel-solid)",
                    }}
                  >
                    <Flex className="qr-code-wifi" display="flex" width="148px" height="148px" justify="center" align="center" gap="2">
                      {interfaceState[index]?.disabled ? (
                        <img
                          alt="QR code of work/guest wifi"
                          src={getQrUrl(`WIFI:T:nopass;S:${entry?.name};`)}
                          style={{ display: "flex", width: "128px", height: "128px", justifyContent: "center", alignItems: "center", gap: "var(--space-2)" }}
                        />
                      ) : (
                        <div style={{ width: "128px", height: "128px", background: "var(--gray-a3)", borderRadius: "var(--space-1)" }}></div>
                      )}
                    </Flex>
                    <Flex className="qr-code-options" display="flex" direction="column" justify="center" align="start" gap="2">
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger>
                          <IconButton size="2" variant="surface" color="gray">
                            <DownloadIcon width="16" height="16" />
                          </IconButton>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content side="right">
                          <DropdownMenu.Item
                            onSelect={handleDownload(
                              `https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};P:${entry?.x_passphrase};;&size=128x128&bgcolor=ffffff&color=000000&format=png`,
                              `${entry?.name}_qr_code.png`
                            )}
                          >
                            Download PNG in black
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onSelect={handleDownload(
                              `https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};P:${entry?.x_passphrase};;&size=128x128&bgcolor=000000&color=ffffff&format=png`,
                              `${entry?.name}_qr_code.png`
                            )}
                          >
                            Download PNG in white
                          </DropdownMenu.Item>
                          <DropdownMenu.Item onSelect={(event) => event.preventDefault()}>
                            <Dialog.Root>
                              <Dialog.Trigger>
                                <Text size="2" weight="regular">
                                  Download PNG in colour...
                                </Text>
                              </Dialog.Trigger>
                              <Dialog.Content width="1280px">
                                <Dialog.Title>Download PNG in colour</Dialog.Title>
                                <Dialog.Description size="2" mb="4">
                                  Choose a colour for your QR code
                                </Dialog.Description>
                                <Flex style={{ alignSelf: "stretch" }} display="flex" direction="column" align="start" gap="5">
                                  <div
                                    className="qr-code-card"
                                    style={{
                                      display: "flex",
                                      maxWidth: "1000px",
                                      padding: "var(--space-4)",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      gap: "var(--space-4)",
                                      flex: "1 0 0",
                                      alignSelf: "stretch",
                                      borderRadius: "var(--space-1)",
                                      border: "1px dashed var(--gray-a6)",
                                      background: "var(--color-panel-solid)",
                                    }}
                                  >
                                    <Flex className="qr-code-wifi" display="flex" width="148px" height="148px" justify="center" align="center" gap="2">
                                      <img
                                        alt="QR code of guest wifi"
                                        src={`https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};P:${
                                          entry?.x_passphrase
                                        };;&size=128x128&bgcolor=${colourSelection}&color=${getQrForegroundColor()}&format=png`}
                                        style={{ display: "flex", width: "128px", height: "128px", justifyContent: "center", alignItems: "center", gap: "var(--space-2)" }}
                                      />
                                    </Flex>
                                  </div>
                                  <Flex className="qr-code-color-picker" style={{ alignSelf: "stretch" }} display="flex" align="center" gap="2">
                                    <label htmlFor="button_colour_picker" style={{ position: "relative", display: "inline-block", cursor: "pointer" }}>
                                      <input
                                        type="color"
                                        id="button_colour_picker"
                                        value={`#${colourSelection}`}
                                        onChange={handleColourField}
                                        style={{ opacity: 0, position: "absolute", width: "100%", height: "100%", border: "none", padding: 0, margin: 0, cursor: "pointer" }}
                                      />
                                      <div
                                        style={{
                                          width: "32px",
                                          height: "32px",
                                          backgroundColor: `#${colourSelection}`,
                                          cursor: "pointer",
                                          borderRadius: "var(--radius-2)",
                                          border: "1px solid var(--gray-a6)",
                                        }}
                                      ></div>
                                    </label>
                                    <TextField.Root onChange={handleColourField} style={{ width: "100%" }} defaultValue={`#${colourSelection}`} placeholder="Enter hex colour code" size="2" />
                                  </Flex>
                                </Flex>
                                <Flex gap="3" mt="4" justify="end">
                                  <Dialog.Close onClick={() => setColourSelection("666666")}>
                                    <Button variant="soft" color="gray">
                                      Cancel
                                    </Button>
                                  </Dialog.Close>
                                  <Dialog.Close
                                    onClick={handleDownload(
                                      `https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};P:${
                                        entry?.x_passphrase
                                      };;&size=128x128&bgcolor=${getQrBackgroundColor()}&color=${colourSelection}&format=png`,
                                      `${entry?.name}_qr_code.png`
                                    )}
                                  >
                                    <Button>Download</Button>
                                  </Dialog.Close>
                                </Flex>
                              </Dialog.Content>
                            </Dialog.Root>
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onSelect={handleDownload(
                              `https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};P:${entry?.x_passphrase};;&size=128x128&bgcolor=1a191c&color=666666&format=svg`,
                              `${entry?.name}_qr_code.svg`
                            )}
                          >
                            Download SVG
                          </DropdownMenu.Item>
                        </DropdownMenu.Content>
                      </DropdownMenu.Root>
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger>
                          <IconButton size="2" variant="surface" color="gray">
                            <CopyIcon width="16" height="16" />
                          </IconButton>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content side="right">
                          <DropdownMenu.Item
                            onSelect={handleCopy(
                              `https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};P:${entry?.x_passphrase};;&size=128x128&bgcolor=ffffff&color=000000&format=png`
                            )}
                          >
                            Copy PNG in black
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onSelect={handleCopy(
                              `https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};P:${entry?.x_passphrase};;&size=128x128&bgcolor=000000&color=ffffff&format=png`
                            )}
                          >
                            Copy PNG in white
                          </DropdownMenu.Item>
                          <DropdownMenu.Item onSelect={(event) => event.preventDefault()}>
                            <Dialog.Root>
                              <Dialog.Trigger>
                                <Text size="2" weight="regular">
                                  Copy PNG in colour...
                                </Text>
                              </Dialog.Trigger>
                              <Dialog.Content width="1280px">
                                <Dialog.Title>Copy PNG in colour</Dialog.Title>
                                <Dialog.Description size="2" mb="4">
                                  Choose a colour for your QR code
                                </Dialog.Description>
                                <Flex style={{ alignSelf: "stretch" }} display="flex" direction="column" align="start" gap="5">
                                  <div
                                    className="qr-code-card"
                                    style={{
                                      display: "flex",
                                      maxWidth: "1000px",
                                      padding: "var(--space-4)",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      gap: "var(--space-4)",
                                      flex: "1 0 0",
                                      alignSelf: "stretch",
                                      borderRadius: "var(--space-1)",
                                      border: "1px dashed var(--gray-a6)",
                                      background: "var(--color-panel-solid)",
                                    }}
                                  >
                                    <Flex className="qr-code-wifi" display="flex" width="148px" height="148px" justify="center" align="center" gap="2">
                                      <img
                                        alt="QR code of guest wifi"
                                        src={`https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};P:${entry?.x_passphrase};;&size=128x128&bgcolor=1a191c&color=${colourSelection}&format=png`}
                                        style={{ display: "flex", width: "128px", height: "128px", justifyContent: "center", alignItems: "center", gap: "var(--space-2)" }}
                                      />
                                    </Flex>
                                  </div>
                                  <Flex className="qr-code-color-picker" style={{ alignSelf: "stretch" }} display="flex" align="center" gap="2">
                                    <label htmlFor="button_colour_picker" style={{ position: "relative", display: "inline-block", cursor: "pointer" }}>
                                      <input
                                        type="color"
                                        id="button_colour_picker"
                                        value={`#${colourSelection}`}
                                        onChange={handleColourField}
                                        style={{ opacity: 0, position: "absolute", width: "100%", height: "100%", border: "none", padding: 0, margin: 0, cursor: "pointer" }}
                                      />
                                      <div
                                        style={{
                                          width: "32px",
                                          height: "32px",
                                          backgroundColor: `#${colourSelection}`,
                                          cursor: "pointer",
                                          borderRadius: "var(--radius-2)",
                                          border: "1px solid var(--gray-a6)",
                                        }}
                                      ></div>
                                    </label>
                                    <TextField.Root onChange={handleColourField} style={{ width: "100%" }} defaultValue={`#${colourSelection}`} placeholder="Enter hex colour code" size="2" />
                                  </Flex>
                                </Flex>
                                <Flex gap="3" mt="4" justify="end">
                                  <Dialog.Close onClick={() => setColourSelection("666666")}>
                                    <Button variant="soft" color="gray">
                                      Cancel
                                    </Button>
                                  </Dialog.Close>
                                  <Dialog.Close
                                    onClick={handleCopy(
                                      `https://api.qrserver.com/v1/create-qr-code/?data=WIFI:T:nopass;S:${entry?.name};P:${entry?.x_passphrase};;&size=128x128&bgcolor=1a191c&color=${colourSelection}&format=png`
                                    )}
                                  >
                                    <Button>Copy</Button>
                                  </Dialog.Close>
                                </Flex>
                              </Dialog.Content>
                            </Dialog.Root>
                          </DropdownMenu.Item>
                        </DropdownMenu.Content>
                      </DropdownMenu.Root>
                    </Flex>
                  </div>
                </Card>
              </React.Fragment>
            )
          )}
        </Flex>
      </Flex>
      <ToastAlert />
    </React.Fragment>
  );
};

export default Networks;
