import React, { useState, useEffect } from 'react';
import { ActiveIntegrateCard } from 'components';
import { useLiveAccessPoints } from 'hooks';
import { useLocationContext } from 'context';
import { StackedStatusBar } from 'components/charts/StackedStatusBar';


export function ActiveBconnectIntegrations() {
  // Hooks and contexts here

  const { locationsData } = useLocationContext();
  const { liveAccessPointsStatus: liveAccessPointsStatus } = useLiveAccessPoints(locationsData);

  // Sort the liveAccessPointsStatus array alphabetically by name
  const sortedAccessPointsStatus = liveAccessPointsStatus ? [...liveAccessPointsStatus].sort((a, b) => (a.name || "").localeCompare(b.name || "")) : [];
  const [stackedStatusBarData, setStackedStatusBarData] = useState({
    online: 0,
    offline: 0,
    partially: 0
  });

  useEffect(() => {
    if (!sortedAccessPointsStatus) return;
  
    const newStats = sortedAccessPointsStatus.reduce((acc, ap) => {
      if (ap.online_access_points_count > 0 && ap.offline_access_points_count === 0) {
        acc.online += 1;
      } else if (ap.online_access_points_count === 0 && ap.offline_access_points_count > 0) {
        acc.offline += 1;
      } else {
        acc.partially += 1;
      }
      return acc;
    }, { online: 0, offline: 0, partially: 0 });
  
    setStackedStatusBarData(newStats);
  }, [sortedAccessPointsStatus]);

  return (
    <ActiveIntegrateCard.Root name="b connect Wi-Fi" subtitle="Wi-Fi" image_url="favicon-light.png" 
      option={{text: "Contact support", color: "red", onClick: () => {
      const supportEmail = 'support@b-connect.co.uk';
      const subject = 'Support Request';
      const body = 'Hello, I need help with...';
      const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
    }}} >
      <ActiveIntegrateCard.Section name="Locations" subtitle="Select which Unifi Site should be associated with each of your locations.">
        <StackedStatusBar stackedStatusBarData={stackedStatusBarData}/>
      </ActiveIntegrateCard.Section>
    </ActiveIntegrateCard.Root>
  );
}