import { Logo } from 'components';
import { useNavigate } from 'react-router-dom';
import React, { useTransition, useState, useEffect } from 'react';
import useForm from "../../hooks/validation/useForm";
import { validateEmail } from "../../components/utils/validationUtils";
import { useLogin } from 'hooks';
import { Flex, Text, Button, Link, TextField } from '@radix-ui/themes';
import * as Form from "@radix-ui/react-form";
import { getProfile } from "api/UsersApi";

export default function Login() {
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();
  const userLogin = useLogin();
  
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.src = '/LoginPageBG.png';
    img.onload = () => {
      setIsImageLoaded(true);
    };
  }, []);

  const initialFormState = {
    email: "",
    password: "",
  };

  const validations = {
    email: (value) =>
      !value
        ? "Input your email address."
        : !validateEmail(value)
        ? "Invalid email address"
        : "",
    password: (value) =>
      !value
        ? "Input your password."
        : "",
  };

  const { formState, errors, handleChange, handleSubmit, setErrors  } = useForm(
    initialFormState,
    validations
  );

  const handleLoginSubmit = async (formState) => {
    try {
      await userLogin.mutateAsync(formState); 
      const profile = await getProfile();

      const userRole = profile.data.user_role;
      const lastLogin = profile.data.last_login;
      const isFirstLogin = profile.data.is_first_login;
  
      if (userRole === 'admin') {
        navigate('/customers');
      } else {
        if (isFirstLogin === true && (lastLogin === null || lastLogin === 'null')) {
          navigate('/first-time-login');
        } else {
          navigate('/wifi/analytics');
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrors({
          password: error.response.data.error
        });
      }
    }
  };

  const handleClick = () => {
    startTransition(() => {
      navigate('/forgot-password');
    });
  };

  return (
    <Flex style={{ height: '100vh', width: '100vw'}}>
      {/* Sign In Section */}
      <Flex direction="column" align="stretch" px="7" py="8" style={{alignItems: 'center', flex: '1 1 50%'}}>
        <Flex justify="center" align="stretch" width="100%" style={{alignItems: 'center', flex: '1 0 0'}}>
          <Flex direction="column" gap="7" maxWidth="296px" style={{alignItems: 'center', justifyContent: 'center',
              width: '100%'
          }}>
            <Text size="4" weight="bold" align="center">Sign In</Text>
            <Form.Root onSubmit={(e) => handleSubmit(e, handleLoginSubmit)} style={{width: '100%'}}>
              <Flex direction="column" gap="5" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                <Flex direction="column" gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                  <Form.Field name="email" style={{width: '100%'}}>
                    <Form.Label style={{display: 'flex', flexDirection: 'column', marginBottom: '4px'}}>
                      <Text size="2" weight="medium">Email address</Text>
                    </Form.Label>
                    <Form.Control asChild>
                      <TextField.Root
                        type="email"
                        value={formState.email}
                        onChange={handleChange}
                        className={`input ${errors.email ? 'input-error' : ''}`}
                      />
                    </Form.Control>
                    {errors.email &&
                    <Form.Message>
                      <Text size="1" weight="regular" color="red">
                        {errors.email}
                      </Text>
                    </Form.Message>}
                  </Form.Field>
                  <Form.Field name="password" style={{width: '100%'}}>
                    <Form.Label style={{display: 'flex', flexDirection: 'column', marginBottom: '4px'}}>
                      <Text size="2" weight="medium">Password</Text>
                    </Form.Label>
                    <Form.Control asChild>
                      <TextField.Root
                        type="password"
                        value={formState.password}
                        onChange={handleChange}
                        className={`input ${errors.password ? 'input-error' : ''}`}
                      />
                    </Form.Control>
                    {errors.password &&
                    <Form.Message>
                      <Text size="1" weight="regular" color="red">
                        {errors.password}
                      </Text>
                    </Form.Message>}
                  </Form.Field>
                </Flex>
                <Flex direction="column" gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                  <Button type="submit" size="2" variant="solid" style={{alignSelf: 'stretch'}}>
                    Sign in
                  </Button>
                  <Button type="button" onClick={handleClick} variant="soft" color="gray"
                    size="2" disabled={isPending} style={{alignSelf: 'stretch'}}>
                    Forgotten your password?
                  </Button>
                </Flex>
              </Flex>
            </Form.Root>
            {/* For new account website link */}
            <Text size="2" weight="regular" style={{display: 'flex', alignSelf: 'center'}} color="gray">
              Don't have an account?&nbsp;
              <Link color="gray" underline="always" href='https://b-connect.co.uk'>
                Try for free
              </Link>
            </Text>
          </Flex>
        </Flex>
        {/* Footer links */}
        <Flex gap="7" justify="center" width="100%">
          <Link size="1" weight="regular" color="gray" href="https://b-connect.co.uk/terms" target='_blank'>
            Terms of Service
          </Link>
          <Link size="1" weight="regular" color="gray" href="https://b-connect.co.uk/privacy" target='_blank'>
            Privacy Notice
          </Link>
          <Link size="1" weight="regular" color="gray" href="mailto:support@b-connect.co.uk">Support</Link>
        </Flex>
      </Flex>
      {/* Background Image and  Logo Section */}
      <Flex direction="column" align="stretch" style={{ padding: '80px 120px 0 120px',
        background: isImageLoaded
        ? 'url(/LoginPageBG.png) no-repeat center center / cover'
        : 'var(--color-background)',
        transition: 'background 0.3s ease-in-out',
        backgroundBlendMode: 'soft-light', flex: '1 1 50%', alignItems: 'center'}}>
        <Logo icon="LogoBWifi" width="40px" height="32px" color="white"/>
      </Flex>
    </Flex>
  )
}
