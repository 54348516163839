import { useMutation } from "@tanstack/react-query";
import { deactivateAirship } from "../../api/bAirshipApi";

export const useDeactivateAirship = () => {
  return useMutation({
    mutationKey: ["deactivate-airship"],
    mutationFn: (data) => deactivateAirship(data),
    onError: (error) => {
      console.error("Airship deactivation error:", error.response || error);
    },
  });
};
