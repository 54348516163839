import React, { useState, useEffect, useRef } from 'react';
import { Flex, Card, Text, TextField, Button, Select } from '@radix-ui/themes';
import {  useGetOrganisationDetails, useUpdateOrganisationDetails, useUpdateOrg } from 'hooks';
import { useProfileContext } from "context";
import { useToast } from '../../context/ToastContext';
import { ToastAlert } from "components";

// Helper function to add https:// to a URL if it doesn't have it
const addHttpsIfNeeded = (url) => {
    if (url && !url.match(/^https?:\/\//)) {
        return `https://${url}`;
    }
    return url;
};

const OrganisationDetails = () => {

    const { userProfile } = useProfileContext();
    const { setToastMessage } = useToast();

    const { data: orgDetails } = useGetOrganisationDetails(userProfile.organisation_id);
    const { mutate: updateOrganisationDetails } = useUpdateOrganisationDetails();
    const { mutate: updateOrg, isLoading: isOrgNameLoading } = useUpdateOrg();

    const [ isLoading, setIsLoading] = useState(false);
    const [ errors, setErrors ] = useState({});
    const [ isDirty, setIsDirty ] = useState(false);
    const [ orgNameDirty, setOrgNameDirty ] = useState(false);
    
    const [ organisationName, setOrganisationName ] = useState('');
    const [ companyName, setCompanyName ] = useState('');
    const [ tradingName, setTradingName ] = useState('');
    const [ contactEmail, setContactEmail ] = useState('');
    const [ website, setWebsite ] = useState('');
    const [ address, setAddress ] = useState('');
    const [ dataRetentionPeriod, setDataRetentionPeriod ] = useState('');
    const [ dataRetentionPeriodUnit, setDataRetentionPeriodUnit ] = useState('months');

    // Set form fields with original data
    useEffect(() => {
        if (orgDetails?.data) {
            setOrganisationName(userProfile.organisation_name);
            setCompanyName(orgDetails.data.company_name);
            setTradingName(orgDetails.data.trading_name);
            setContactEmail(orgDetails.data.contact_email);
            setWebsite(orgDetails.data.website);
            setAddress(orgDetails.data.address);
            setDataRetentionPeriod(orgDetails.data.data_retention_period);
            setDataRetentionPeriodUnit(orgDetails.data.data_retention_period_unit);
        }
    }, [orgDetails, userProfile.organisation_name]);

    // Validate form fields
    const validateForm = () => {
        let errors = {};
        if (!companyName.trim()) errors.companyName = 'Input a company name.';
        if (!tradingName.trim()) errors.tradingName = 'Input a trading name.';
        if (!contactEmail.trim()) {
            errors.contactEmail = 'Input a business contact email.'
        } else if (!/\S+@\S+\.\S+/.test(contactEmail)) {
            errors.contactEmail = 'Please enter a valid contact email.';
        }
        if (!website.trim()) errors.website = 'Input a business website.';
        if (!address.trim()) errors.address = 'Input a business address.';
        const retentionValue = Number(dataRetentionPeriod);
        if (!dataRetentionPeriod || isNaN(retentionValue) || retentionValue <= 0) {
            errors.dataRetentionPeriod = 'What is the duration for which you retain customer data in compliance with GDPR regulations?';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Check if form values have changed
    useEffect(() => {
        // Only run if original data exists
        if (!orgDetails?.data) return;
        
        // Compare current form values with original data
        const isFormDirty = 
            companyName !== orgDetails.data.company_name ||
            tradingName !== orgDetails.data.trading_name ||
            contactEmail !== orgDetails.data.contact_email ||
            website !== orgDetails.data.website ||
            address !== orgDetails.data.address ||
            dataRetentionPeriod !== orgDetails.data.data_retention_period ||
            dataRetentionPeriodUnit !== orgDetails.data.data_retention_period_unit;
        
        setIsDirty(isFormDirty);
    }, [
        companyName, tradingName, contactEmail, website, address,
        dataRetentionPeriod, dataRetentionPeriodUnit, orgDetails?.data
    ]);

    const handleSaveOrgName = async() => {
        if (!organisationName.trim()) {
            setErrors({ ...errors, organisationName: 'Organisation name is required' });
            return;
        }
        
        try {
            await updateOrg({
                id: userProfile.organisation_id,
                name: organisationName,
                email: userProfile.email, // Keep existing email if available
                status: 'active' // Assuming the org is active
            });
            setOrgNameDirty(false);
            setOrganisationName(organisationName);
            setToastMessage('Changes saved');
        } catch (error) {
            console.error('Error updating organization name', error);
        }
    };

    // Check if organisation name has changed
    useEffect(() => {
        if (userProfile?.organisation_name) {setOrgNameDirty(organisationName !== userProfile.organisation_name);}
    }, [organisationName, userProfile?.organisation_name]);

    // Save organisation details
    const handleSaveOrgDetails = async() => {
        if (!validateForm()) return;
        const organisationDetailsFormData = new FormData();
        try {
            setIsLoading(true);
            if (companyName !== orgDetails.data.company_name) {organisationDetailsFormData.append('company_name', companyName);}
            if (tradingName !== orgDetails.data.trading_name) {organisationDetailsFormData.append('trading_name', tradingName);}
            if (contactEmail !== orgDetails.data.contact_email) {organisationDetailsFormData.append('contact_email', contactEmail);}
            if (website !== orgDetails.data.website) {organisationDetailsFormData.append('website', addHttpsIfNeeded(website));}
            if (address !== orgDetails.data.address) {organisationDetailsFormData.append('address', address);}
            if (dataRetentionPeriod !== orgDetails.data.data_retention_period) {organisationDetailsFormData.append('data_retention_period', dataRetentionPeriod);}
            if (dataRetentionPeriodUnit !== orgDetails.data.data_retention_period_unit) {organisationDetailsFormData.append('data_retention_period_unit', dataRetentionPeriodUnit);}
            
            await updateOrganisationDetails({orgID: userProfile.organisation_id, data: organisationDetailsFormData});
            setIsLoading(false);
            resetFormChanges();
            setToastMessage('Changes saved');
        } catch (error) {
            console.error('Error creating form data', error);
        }
    };

    // Reset form changes to original data
    const resetFormChanges = () => {
        setCompanyName(orgDetails.data.company_name);
        setTradingName(orgDetails.data.trading_name);
        setContactEmail(orgDetails.data.contact_email);
        setWebsite(orgDetails.data.website);
        setAddress(orgDetails.data.address);
        setDataRetentionPeriod(orgDetails.data.data_retention_period);
        setDataRetentionPeriodUnit(orgDetails.data.data_retention_period_unit);
        setIsDirty(false);
    };

    // Resize observer to detect container width
    const formContainerRef = useRef(null);
    const [isNarrowContainer, setIsNarrowContainer] = useState(false);
    useEffect(() => {
        if (!formContainerRef.current) return;
        
        const resizeObserver = new ResizeObserver(entries => {
          for (const entry of entries) {
            const width = entry.contentRect.width;
            setIsNarrowContainer(width < 600); // Adjust threshold as needed
          }
        });
        
        resizeObserver.observe(formContainerRef.current);
        return () => resizeObserver.disconnect();
      }, []);

    return (
        <React.Fragment>
            <Flex direction="column" gap="5" height="100%" style={{flex: '1 0 0', alignSelf: 'stretch'}}>
                <Flex direction="column" gap="5" style={{alignItems: 'flex-start', flex: '1 0 0',
                    alignSelf: 'stretch', overflow: 'scroll', maxHeight: 'calc(100vh - 184px)'}}>
                    {/* Organisation details section */}
                    <Card style={{display: 'flex', flex: '0 0 auto', alignItems: 'flex-start', alignSelf: 'stretch', 
                        gap: 'var(--space-4)', padding: 'var(--space-4)'}}>
                        <Flex gap="2" direction="column" style={{maxWidth: '296px', alignItems: 'flex-start'}}>
                            <Text size="3">Organisation details</Text>
                            <Text size="1" color="gray">
                                Provide details about your organisation for use across the b connect platform interface.
                            </Text>
                        </Flex>
                        <Flex gap="4" direction="column" style={{alignItems: 'flex-end', flex: '1 0 0'}}>
                            <Text as="label" size="2" style={{alignSelf: 'stretch'}}>
                                <Flex gap="1" direction="column">
                                    <Text size="2" weight="medium">Organisation name</Text>
                                    <TextField.Root size="2"
                                        value={organisationName}
                                        onChange={(e) => {
                                            setOrganisationName(e.target.value);
                                            if (errors.organisationName) setErrors({...errors, organisationName: ''});
                                        }}
                                    />
                                </Flex>
                            </Text>
                            <Button
                                variant="solid"
                                disabled={!orgNameDirty}
                                loading={isOrgNameLoading}
                                onClick={handleSaveOrgName}
                            >
                                Save changes
                            </Button>
                        </Flex>
                    </Card>
                    {/* Legal details section */}
                    <Card style={{display: 'flex', flex: '0 0 auto', alignItems: 'flex-start', alignSelf: 'stretch', 
                        gap: 'var(--space-4)', padding: 'var(--space-4)'}}>
                        <Flex gap="2" direction="column" style={{maxWidth: '296px', alignItems: 'flex-start'}}>
                            <Text size="3">Legal details</Text>
                            <Text size="1" color="gray">
                                Provide details about your organisation to auto-generate a privacy notice for your guest Wi-Fi portal.
                            </Text>
                        </Flex>
                        <Flex gap="4" direction="column" style={{alignItems: 'flex-end', flex: '1 0 0'}}>
                            <Flex ref={formContainerRef} gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch',
                                flexDirection: isNarrowContainer ? 'column' : 'row'}}>
                                {/* Company name input field */}
                                <Text as="label" size="2" style={{width: isNarrowContainer ? '100%' : '50%'}}>
                                    <Flex gap="1" direction="column">
                                        <Text size="2" weight="medium">Company name (Legal name)</Text>
                                        <TextField.Root size="2"
                                            value={companyName}
                                            onChange={(e) => {
                                                setCompanyName(e.target.value);
                                                if (errors.companyName) setErrors({...errors, companyName: ''});
                                            }}
                                        />
                                        {errors.companyName && <Text size="1" color="red">{errors.companyName}</Text>}
                                    </Flex>
                                </Text>
                                {/* Trading name input field */}
                                <Text as="label" size="2" style={{width: isNarrowContainer ? '100%' : '50%'}}>
                                    <Flex gap="1" direction="column">
                                        <Text size="2" weight="medium">Trading name (Customer-facing name)</Text>
                                        <TextField.Root size="2"
                                            value={tradingName}
                                            onChange={(e) => {
                                                setTradingName(e.target.value);
                                                if (errors.tradingName) setErrors({...errors, tradingName: ''});
                                            }}
                                        />
                                        {errors.tradingName && <Text size="1" color="red">{errors.tradingName}</Text>}
                                    </Flex>
                                </Text>
                            </Flex>
                            <Flex ref={formContainerRef} gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch',
                                flexDirection: isNarrowContainer ? 'column' : 'row'}}>
                                {/* Business contact email input field*/}
                                <Text as="label" size="2" style={{width: isNarrowContainer ? '100%' : '50%'}}>
                                    <Flex gap="1" direction="column">
                                        <Text size="2" weight="medium">Business contact email</Text>
                                        <TextField.Root size="2"
                                            value={contactEmail}
                                            onChange={(e) => {
                                                setContactEmail(e.target.value);
                                                if (errors.contactEmail) setErrors({...errors, contactEmail: ''});
                                            }}
                                        />
                                        {errors.contactEmail && <Text size="1" color="red">{errors.contactEmail}</Text>}
                                    </Flex>
                                </Text>
                                {/* Business website input field */}
                                <Text as="label" size="2" style={{width: isNarrowContainer ? '100%' : '50%'}}>
                                    <Flex gap="1" direction="column">
                                        <Text size="2" weight="medium">Business website</Text>
                                        <TextField.Root size="2"
                                            value={website}
                                            onChange={(e) => {
                                                setWebsite(e.target.value);
                                                if (errors.website) setErrors({...errors, website: ''});
                                            }}
                                        />
                                        {errors.website && <Text size="1" color="red">{errors.website}</Text>}
                                    </Flex>
                                </Text>
                            </Flex>
                            <Flex ref={formContainerRef} gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch',
                                flexDirection: isNarrowContainer ? 'column' : 'row'}}>
                                {/* Business address input field */}
                                <Text as="label" size="2" style={{width: isNarrowContainer ? '100%' : '50%'}}>
                                    <Flex gap="1" direction="column">
                                        <Text size="2" weight="medium">Business address</Text>
                                        <TextField.Root size="2"
                                            value={address}
                                            onChange={(e) => {
                                                setAddress(e.target.value);
                                                if (errors.address) setErrors({...errors, address: ''});
                                            }}
                                        />
                                        {errors.address && <Text size="1" color="red">{errors.address}</Text>}
                                    </Flex>
                                </Text>
                                {/* Data retention period input field */}
                                <Text as="label" size="2" style={{width: isNarrowContainer ? '100%' : '50%'}}>
                                    <Flex gap="1" direction="column" style={{alignItems: 'flex-start', flex: '1 0 0'}}>
                                        <Text size="2" weight="medium">Data retention period</Text>
                                        <Flex gap="1" style={{justifyContent: 'flex-start', alignItems: 'center', 
                                                alignSelf: 'stretch'}}>
                                            <TextField.Root size="2" style={{width: '50%'}}
                                                value={dataRetentionPeriod}
                                                onChange={(e) => {
                                                    setDataRetentionPeriod(e.target.value);
                                                    if (errors.dataRetentionPeriod) setErrors({...errors, dataRetentionPeriod: ''});
                                                }}
                                            />
                                            <Select.Root 
                                                value={dataRetentionPeriodUnit}
                                                onValueChange={(value) => {
                                                    setDataRetentionPeriodUnit(value);
                                                    if (errors.dataRetentionPeriod) setErrors({...errors, dataRetentionPeriod: ''});
                                                }}>
                                                <Select.Trigger style={{width: '50%'}} />
                                                <Select.Content position="popper" side="bottom">
                                                    <Select.Group>
                                                        <Select.Item value="months">Months</Select.Item>
                                                        <Select.Item value="years">Years</Select.Item>
                                                    </Select.Group>
                                                </Select.Content>
                                            </Select.Root>
                                        </Flex>
                                        {errors.dataRetentionPeriod 
                                        ? <Text size="1" color="red">{errors.dataRetentionPeriod}</Text>
                                        : <Text size="1" color='gray'>What is the duration for which you retain customer data in compliance with GDPR regulations?</Text>
                                        }
                                    </Flex>
                                </Text>
                            </Flex>
                            <Button variant="solid"
                                disabled={!isDirty}
                                loading={isLoading}
                                onClick={() => handleSaveOrgDetails()}>
                                Save changes
                            </Button>
                        </Flex>
                    </Card>
                </Flex>
            </Flex>
            {/* Toast updates */}
            <ToastAlert />
        </React.Fragment>
    );
}

export default OrganisationDetails;
