import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Flex, Text, Button, Select, IconButton, Separator, TextField } from "@radix-ui/themes";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { useQueryClient } from "@tanstack/react-query";
import { useToast } from "../../../context/ToastContext";
import { ToastAlert } from "components";
import {
  useGetMailchimpAudiences,
  useGetMailchimpContactFields,
  useMapFieldsInMailchimp,
  useSyncGuestDataWithMailchimp,
  useCreateMailchimpFields,
  useAuthenticateIntegrations, // Added
  useGetActiveIntegrations,
} from "hooks";
import { useProfileContext } from "context";

export function MailchimpConfigSetupPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setToastMessage } = useToast();
  const { apiKey, orgId } = location.state || {};
  const { userProfile } = useProfileContext();

  // States
  const [savedApiKey, setSavedApiKey] = useState(apiKey);
  const [audiences, setAudiences] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedAgeGroup, setSelectedAgeGroup] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [usedFields, setUsedFields] = useState(new Set());
  const [isLoadingAudiences, setIsLoadingAudiences] = useState(false);

  // Hooks
  const getMailchimpAudiences = useGetMailchimpAudiences();
  const createMailchimpFields = useCreateMailchimpFields();
  const { data: fieldsData } = useGetMailchimpContactFields(selectedAudience, orgId);
  const mapFieldsMutation = useMapFieldsInMailchimp();
  const syncGuestsMutation = useSyncGuestDataWithMailchimp();

  const [openDialog, setOpenDialog] = useState({
    auth: false,
    detail: false,
  });
  const [isApiKeyValid, setIsApiKeyValid] = useState(true);

  const [genderField, setGenderField] = useState(null);
  const [ageGroupField, setAgeGroupField] = useState(null);

  const activateIntegrations = useAuthenticateIntegrations();

  const [showSuccess, setShowSuccess] = useState(false);

  const { data: activeIntegrationsData } = useGetActiveIntegrations(userProfile.organisation_id);

  // Fetch audiences on component mount
  useEffect(() => {
    const fetchAudiences = async () => {
      setIsLoadingAudiences(true);
      try {
        const response = await getMailchimpAudiences.mutateAsync({
          organisation_id: orgId,
          type: "mailchimp",
          state: "active",
          sub_payload: { api_key: apiKey },
        });

        if (response?.audiences) {
          setAudiences(response.audiences);
          if (response.audiences.length > 0) {
            setSelectedAudience(response.audiences[0].id);
          }
        }
      } catch (error) {
        console.error("Failed to fetch audiences:", error);
        setToastMessage("Failed to fetch Mailchimp audiences");
      } finally {
        setIsLoadingAudiences(false);
      }
    };

    if (apiKey && orgId) {
      fetchAudiences();
    }
  }, [apiKey, orgId]);

  // Add handlers for selection
  const handleGenderSelect = (value) => {
    if (selectedGender) {
      // Remove old value from used fields
      setUsedFields((prev) => {
        const next = new Set(prev);
        next.delete(selectedGender);
        next.add(value);
        return next;
      });
    } else {
      // Add new value to used fields
      setUsedFields((prev) => new Set([...prev, value]));
    }
    setSelectedGender(value);
  };

  const handleAgeGroupSelect = (value) => {
    if (selectedAgeGroup) {
      // Remove old value from used fields
      setUsedFields((prev) => {
        const next = new Set(prev);
        next.delete(selectedAgeGroup);
        next.add(value);
        return next;
      });
    } else {
      // Add new value to used fields
      setUsedFields((prev) => new Set([...prev, value]));
    }
    setSelectedAgeGroup(value);
  };

  // Add this function to check integration status
  const isMailchimpActive = () => {
    if (!activeIntegrationsData?.data?.integrations) return false;
    return activeIntegrationsData.data.integrations.some((integration) => integration.name === "mailchimp");
  };

  const handleSave = async () => {
    console.log("Save button clicked");
    if (!apiKey) {
      setIsApiKeyValid(false);
      return;
    }

    setIsLoading(true);
    setProgressValue(10);
    try {
      await activateIntegrations.mutateAsync({
        timestamp: Date.now(),
        user_id: userProfile.user_id,
        organisation_id: userProfile.organisation_id,
        type: "mailchimp",
        state: "active",
        sub_payload: { api_key: apiKey },
      });

      setSavedApiKey(apiKey);
      setProgressValue(50);

      await createMailchimpFields.mutateAsync({
        organisation_id: userProfile.organisation_id,
      });
      setProgressValue(80);

      queryClient.invalidateQueries(["integrations"]);
    } catch (error) {
      console.error("Validation error:", error);
      setIsApiKeyValid(false);
    } finally {
      setProgressValue(100);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (fieldsData?.data.fields) {
      const textFields = fieldsData?.data.fields.filter((field) => field.type === "text");
      console.log("textFields:", textFields);
      const foundGenderField = textFields.find((field) => field.tag === "GENDER");
      const foundAgeGroupField = textFields.find((field) => field.tag === "AGE GROUP");

      setGenderField(foundGenderField || null);
      setAgeGroupField(foundAgeGroupField || null);

      // Only set selections if fields are found
      if (foundGenderField) setSelectedGender(foundGenderField.tag);
      if (foundAgeGroupField) setSelectedAgeGroup(foundAgeGroupField.tag);
    }
  }, [openDialog.detail, fieldsData, selectedAudience]);

  // Handle audiences fetch
  useEffect(() => {
    const fetchAudiences = async () => {
      if (openDialog.detail && savedApiKey) {
        setIsLoadingAudiences(true);
        try {
          const response = await getMailchimpAudiences.mutateAsync({
            user_id: userProfile.user_id,
            organisation_id: userProfile.organisation_id,
            type: "mailchimp",
            state: "active",
            sub_payload: { api_key: savedApiKey },
          });

          if (response?.audiences) {
            setAudiences(response.audiences);
            if (response.audiences.length > 0) {
              setSelectedAudience(response.audiences[0].id);
            }
          }
        } catch (error) {
          console.error("Failed to fetch audiences:", error);
          setAudiences([]);
        } finally {
          setIsLoadingAudiences(false);
        }
      }
    };

    fetchAudiences();
  }, [openDialog.detail, savedApiKey, userProfile.user_id, userProfile.organisation_id]);

  const handleActivate = async () => {
    try {
      setIsLoading(true);
      setProgressValue(10);
      // First map the fields
      await mapFieldsMutation.mutateAsync({
        audienceId: selectedAudience,
        organisation_id: userProfile.organisation_id,
        age_field_tag: selectedAgeGroup,
        gender_field_tag: selectedGender,
      });

      setProgressValue(50);

      // Then sync guest data
      await syncGuestsMutation.mutateAsync({
        audienceId: selectedAudience,
        organisation_id: userProfile.organisation_id,
      });

      setProgressValue(80);

      // Invalidate queries to refresh data
      queryClient.invalidateQueries(["integrations"]);
      queryClient.invalidateQueries(["mailchimp-fields"]);

      // Navigate to success page
      navigate("/activation-success", {
        state: {
          description: "Your customers will now sync with your selected Mailchimp audience.",
        },
      });
    } catch (error) {
      console.error("Error during field mapping:", error);
      setToastMessage("Failed to complete Mailchimp setup");
    } finally {
      setIsLoading(false);
    }
  };

  if (!location.state) {
    return <div>No authentication data found. Please go back and authenticate first.</div>;
  }

  return (
    <Flex
      style={{
        minHeight: "100vh",
      }}
      justify="center"
      align="center"
      p="6"
    >
      <Card
        style={{
          maxWidth: "1000px",
          width: "100%",
        }}
        p="4"
      >
        <Flex style={{ alignSelf: "stretch" }} display="flex" direction="column" align="start">
          {/* Header */}
          <Flex style={{ alignSelf: "stretch" }} display="flex" justify="between" align="center">
            <Flex display="flex" align="center" gap="4">
              <Flex
                style={{
                  borderRadius: "var(--space-1)",
                  background: "#FFFFFF",
                  border: "1px solid var(--gray-a6)",
                }}
                height="40px"
                width="40px"
                p="2"
                justify="center"
                align="center"
              >
                <img src={require("../../../assets/images/mailchimp.png")} alt="mailchimp" style={{ width: "24px" }} />
              </Flex>
              <Flex direction="column" justify="center" align="start">
                <Text size="4" weight="medium">
                  Mailchimp
                </Text>
                <Text style={{ color: "var(--gray-a11)" }} size="1">
                  Email marketing
                </Text>
              </Flex>
            </Flex>
            <Flex display="flex" align="center" gap="4">
              <Button size="2" variant="soft" onClick={() => navigate(-1)} color="gray" style={{ cursor: "pointer" }}>
                Cancel
              </Button>
              <Button size="2" variant="soft" onClick={handleActivate} loading={isLoading} style={{ cursor: "pointer" }}>
                Save and finish
              </Button>
            </Flex>
          </Flex>

          <Separator orientation="horizontal" size="4" my="4" />

          {/* Audience Section */}
          <Flex style={{ alignSelf: "stretch" }} display="flex" align="start" gap="5">
            <Flex style={{ alignSelf: "stretch" }} display="flex" width="296px" direction="column" align="start" gap="2">
              <Text size="2" weight="medium">
                Linked audience
              </Text>
              <Text size="1" weight="regular">
                Select the Mailchimp audience you want to associate with your b connect organisation.
              </Text>
            </Flex>

            <Flex direction="column" gap="1" style={{ width: "100%" }}>
              <Text size="2" weight="regular">
                Mailchimp audience
              </Text>
              <Select.Root value={selectedAudience} onValueChange={setSelectedAudience}>
                <Select.Trigger style={{ width: "100%" }} />
                <Select.Content>
                  {isLoadingAudiences ? (
                    <Select.Item value="loading">Loading audiences...</Select.Item>
                  ) : audiences.length > 0 ? (
                    audiences.map((audience) => (
                      <Select.Item key={audience.id} value={audience.id}>
                        {audience.name}
                      </Select.Item>
                    ))
                  ) : (
                    <Select.Item value="no-audience">No audiences found</Select.Item>
                  )}
                </Select.Content>
              </Select.Root>
            </Flex>
          </Flex>

          <Separator orientation="horizontal" size="4" my="4" />

          {/* Data Fields Section */}
          <Flex style={{ alignSelf: "stretch" }} display="flex" align="start" gap="5">
            <Flex style={{ alignSelf: "stretch" }} display="flex" width="296px" direction="column" align="start" gap="2">
              <Text size="2" weight="medium">
                Data fields
              </Text>
              <Text size="1" weight="regular">
                Confirm the data fields that are being mapped from b connect to Mailchimp. We've introduced custom Mailchimp fields for "Age group" and "Gender". You can create different custom fields
                in Mailchimp.
              </Text>
            </Flex>

            <Flex direction="column" gap="1" style={{ width: "100%" }}>
              {/* Email Field */}
              <Flex direction="row" justify="between" style={{ width: "100%" }}>
                {/* Left Column */}
                <Flex direction="column" gap="1" style={{ width: "100%" }}>
                  <Text size="2" weight="regular">
                    b connect
                  </Text>
                  <TextField.Root
                    variant="surface"
                    value="Email address"
                    size="2"
                    disabled
                    style={{
                      width: "100%",
                      backgroundColor: "var(--gray-3)",
                      cursor: "not-allowed",
                    }}
                  />
                </Flex>

                <Flex
                  align="center"
                  style={{
                    paddingTop: "24px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  <IconButton size="2" variant="ghost" color="gray" style={{ pointerEvents: "none" }}>
                    <ArrowRightIcon width="16" height="16" />
                  </IconButton>
                </Flex>

                {/* Right Column */}
                <Flex direction="column" gap="1" style={{ width: "100%" }}>
                  <Text size="2" weight="regular">
                    Mailchimp
                  </Text>
                  <Select.Root value="email" disabled style={{ width: "100%" }}>
                    <Select.Trigger />
                    <Select.Content>
                      <Select.Item value="email">Email address</Select.Item>
                    </Select.Content>
                  </Select.Root>
                </Flex>
              </Flex>
              {/* Add Email Marketing Row */}
              <Flex direction="row" justify="between" style={{ width: "100%", marginTop: "16px" }}>
                {/* Left Column */}
                <Flex direction="column" gap="1" style={{ width: "100%" }}>
                  <TextField.Root
                    variant="surface"
                    value="Email marketing"
                    size="2"
                    disabled
                    style={{
                      width: "100%",
                      backgroundColor: "var(--gray-3)",
                      cursor: "not-allowed",
                    }}
                  />
                </Flex>

                <Flex
                  align="center"
                  style={{
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  <IconButton size="2" variant="ghost" color="gray" style={{ pointerEvents: "none" }}>
                    <ArrowRightIcon width="16" height="16" />
                  </IconButton>
                </Flex>

                {/* Right Column */}
                <Flex direction="column" gap="1" style={{ width: "100%" }}>
                  <Select.Root value="status" disabled style={{ width: "100%" }}>
                    <Select.Trigger />
                    <Select.Content>
                      <Select.Item value="status">Email marketing</Select.Item>
                    </Select.Content>
                  </Select.Root>
                </Flex>
              </Flex>

              <Flex align="center" style={{ margin: "16px 0" }}>
                <Separator style={{ flex: 1 }} />
                <Text size="2" weight="regular" style={{ margin: "0 16px" }}>
                  Custom fields
                </Text>
                <Separator style={{ flex: 1 }} />
              </Flex>

              {/* Gender Field */}
              <Flex direction="row" justify="between" style={{ width: "100%" }}>
                {/* Left Column */}
                <Flex direction="column" gap="1" style={{ width: "100%" }}>
                  <TextField.Root
                    variant="surface"
                    value="Gender"
                    size="2"
                    disabled
                    style={{
                      width: "100%",
                      backgroundColor: "var(--gray-3)",
                      cursor: "not-allowed",
                    }}
                  />
                </Flex>

                <Flex
                  align="center"
                  style={{
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  <IconButton size="2" variant="ghost" color="gray" style={{ pointerEvents: "none" }}>
                    <ArrowRightIcon width="16" height="16" />
                  </IconButton>
                </Flex>

                {/* Right Column */}
                <Flex direction="column" gap="1" style={{ width: "100%" }}>
                  <Select.Root value={selectedGender} disabled={!genderField} style={{ width: "100%" }} onValueChange={handleGenderSelect}>
                    <Select.Trigger />
                    <Select.Content>
                      {fieldsData?.data?.fields
                        ?.filter((field) => field.type === "text" && (!usedFields.has(field.tag) || field.tag === selectedGender))
                        .map((field) => (
                          <Select.Item key={field.tag} value={field.tag}>
                            {field.name}
                          </Select.Item>
                        ))}
                    </Select.Content>
                  </Select.Root>
                </Flex>
              </Flex>

              <Flex direction="row" justify="between" style={{ width: "100%", paddingTop: "16px" }}>
                {/* Left Column */}
                <Flex direction="column" gap="1" style={{ width: "100%" }}>
                  <TextField.Root
                    variant="surface"
                    value="Age group"
                    size="2"
                    disabled
                    style={{
                      width: "100%",
                      backgroundColor: "var(--gray-3)",
                      cursor: "not-allowed",
                    }}
                  />
                </Flex>

                <Flex
                  align="center"
                  style={{
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  <IconButton size="2" variant="ghost" color="gray" style={{ pointerEvents: "none" }}>
                    <ArrowRightIcon width="16" height="16" />
                  </IconButton>
                </Flex>

                {/* Right Column */}
                <Flex direction="column" gap="1" style={{ width: "100%" }}>
                  <Select.Root value={selectedAgeGroup} disabled={!ageGroupField} style={{ width: "100%" }} onValueChange={handleAgeGroupSelect}>
                    <Select.Trigger />
                    <Select.Content>
                      {fieldsData?.data?.fields
                        ?.filter((field) => field.type === "text" && (!usedFields.has(field.tag) || field.tag === selectedAgeGroup))
                        .map((field) => (
                          <Select.Item key={field.tag} value={field.tag}>
                            {field.name}
                          </Select.Item>
                        ))}
                    </Select.Content>
                  </Select.Root>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Card>

      <div style={{ position: "fixed", zIndex: 2147483647, pointerEvents: "none" }}>
        <ToastAlert />
      </div>
    </Flex>
  );
}
