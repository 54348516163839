import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { attemptGetGuestPortalSettings, attemptGetGuestPortalSettingsBanner, 
  attemptGetGuestPortalSettingsLinks } from 'api/OrgApi';
import { attemptCreateGuestPortalSettingsBanner, attemptCreateGuestPortalSettingsLinks } from 'api/OrgApi';
import { attemptUpdateGuestPortalSettings, attemptUpdateGuestPortalSettingsBanner, 
  attemptUpdateGuestPortalSettingsLinks } from 'api/OrgApi';
import { attemptDeleteGuestPortalSettingsLinks } from 'api/OrgApi';

// Getting the guest portal settings
export const useGetOrgGuestPortalSettings = (orgId) => {
  return useQuery({
    queryKey: ['org-guest-portal-settings', orgId],
    enabled: !!orgId,
    queryFn: () => attemptGetGuestPortalSettings(orgId),
  });
}

// Updating the guest portal settings
export const useUpdateOrgGuestPortalSettings = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ orgID, data }) => attemptUpdateGuestPortalSettings(orgID, data),
    onSuccess: () => {
      // Invalidate and refetch the guest portal settings to show updated data in the UI
      queryClient.invalidateQueries('org-guest-portal-settings');
    }
  });
}

// Getting the guest portal settings banner
export const useGetOrgGuestPortalSettingsBanner = (guestPortalId) => {
  return useQuery({
    queryKey: ['org-guest-portal-settings-banner', guestPortalId],
    enabled: !!guestPortalId,
    queryFn: () => attemptGetGuestPortalSettingsBanner(guestPortalId),
  });
}

// Creating the guest portal settings banner
export const useCreateOrgGuestPortalSettingsBanner = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ guestPortalID, data }) => {
      const formData = new FormData();
      formData.append('title', data.title);
      formData.append('description', data.description);
      formData.append('banner_url', data.banner_url);
      // if there's an image, append it to the form data
      if (data.image) {
        formData.append('image', data.image);
      }
      return attemptCreateGuestPortalSettingsBanner(guestPortalID, formData);
    },
    onSuccess: () => {
      // Invalidate and refetch the guest portal settings banner to show updated data in the UI
      queryClient.invalidateQueries('org-guest-portal-settings-banner');
    }
  });
}

// Updating the guest portal settings banner
export const useUpdateOrgGuestPortalSettingsBanner = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ guestPortalID, data }) => attemptUpdateGuestPortalSettingsBanner(guestPortalID, data),
    onSuccess: () => {
      // Invalidate and refetch the guest portal settings banner to show updated data in the UI
      queryClient.invalidateQueries('org-guest-portal-settings-banner');
    }
  });
}

// Getting the guest portal settings links
export const useGetOrgGuestPortalSettingsLinks = (guestPortalId) => {
  return useQuery({
    queryKey: ['org-guest-portal-settings-links', guestPortalId],
    enabled: !!guestPortalId,
    queryFn: () => attemptGetGuestPortalSettingsLinks(guestPortalId),
  });
}

// Creating the guest portal settings links
export const useCreateOrgGuestPortalSettingsLinks = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ guestPortalID, data }) => attemptCreateGuestPortalSettingsLinks(guestPortalID, data),
    onSuccess: () => {
      // Invalidate and refetch the guest portal settings links to show updated data in the UI
      queryClient.invalidateQueries('org-guest-portal-settings-links');
    }
  });
}

// Updating the guest portal settings links
export const useUpdateOrgGuestPortalSettingsLinks = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ guestPortalID, data }) => attemptUpdateGuestPortalSettingsLinks(guestPortalID, data),
    onSuccess: () => {
      // Invalidate and refetch the guest portal settings links to show updated data in the UI
      queryClient.invalidateQueries('org-guest-portal-settings-links');
    }
  });
}

// Deleting the guest portal settings links
export const useDeleteOrgGuestPortalSettingsLinks = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ guestPortalID, linkID }) => 
      attemptDeleteGuestPortalSettingsLinks(guestPortalID, linkID),
    onSuccess: () => {
      // Invalidate and refetch the guest portal settings links to show updated data in the UI
      queryClient.invalidateQueries('org-guest-portal-settings-links');
    }
  });
}
