import { useState, useRef, useEffect } from 'react';

export const useImageUpload = (initialPreview, initialFileName) => {
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [fileName, setFileName] = useState('');
    const [isDeleted, setIsDeleted] = useState(false);
    const inputRef = useRef(null);

    // Setting initial preview and file name
    useEffect(() => {
      if (initialPreview && initialFileName) {
        setPreview(initialPreview);
        setFileName(initialFileName);
      }
    }
    , [initialPreview, initialFileName]);
  
    const handleUpload = (event) => {
      const uploadedFile = event.target.files[0];
      if (!uploadedFile) {
        return { success: false, reason: 'no-file' }; // No file selected
      }

      // File size validation (1MB = 1048576 bytes)
      const maxSizeInBytes = 1048576;
      if (uploadedFile.size > maxSizeInBytes) {
        // Clear the input to allow re-uploading
        if (inputRef.current) {
          inputRef.current.value = '';
        }
        return { success: false, reason: 'size-exceeded' }; // File too large
      }

      setFile(uploadedFile);
      setFileName(uploadedFile.name);
      setIsDeleted(false);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(uploadedFile);
      return { success: true }; // Upload successful
    };
  
    const handleClear = () => {
      setFile(null);
      setPreview(null);
      setFileName('');
      setIsDeleted(true);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    };
  
    const triggerInput = () => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    };

    const reset = () => {
      setFile(null);
      setPreview(initialPreview);
      setFileName(initialFileName);
      setIsDeleted(false);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    };

    const setExistingImage = (imageUrl, imageName) => {
      setFile(null);       // No file for existing images
      setPreview(imageUrl);
      setFileName(imageName || '');
      setIsDeleted(false);
      if (inputRef.current) {
          inputRef.current.value = '';
      }
    };
  
    return {
      file,
      preview,
      fileName,
      isDeleted,
      inputRef,
      handleUpload,
      handleClear,
      triggerInput,
      reset,
      setExistingImage,
    };
  };
