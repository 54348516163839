import { useQuery } from "@tanstack/react-query";
import { attemptOrgUsers } from "../../api/OrgApi";

const fetchOrgUsers = async (id) => {
    return attemptOrgUsers(id).then((res) => res.data);
};

export const useGetOrgUsers = (id) => {
    return useQuery({ 
        queryKey: ['org-users'], 
        queryFn: () => fetchOrgUsers(id),
        staleTime: 0, // Data is considered stale immediately
        cacheTime: 0, // Cache is cleared immediately after unmount
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        refetchOnReconnect: true,
    });
};
