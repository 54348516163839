import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text, Button, Link, TextField, Select } from '@radix-ui/themes';
import { useProfileContext } from "context";
import { Logo } from 'components';
import { useCreateOrganisationDetails, useGetOrganisationDetails, useUpdateOrganisationDetails } from 'hooks';

// Helper function to add https:// to a URL if it doesn't have it
const addHttpsIfNeeded = (url) => {
    if (url && !url.match(/^https?:\/\//)) {
        return `https://${url}`;
    }
    return url;
};

export default function OrganisationDetailsLoginPage() {
  const navigate = useNavigate();
  const { userProfile } = useProfileContext();
  const organisationName = userProfile.organisation_name;
  const organisationImageUrl = userProfile.organisation_image_url || '/LoginPageBG.png';
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  
  useEffect(() => {
    const img = new Image();
    img.src = organisationImageUrl;
    img.onload = () => setIsImageLoaded(true);
  }, [organisationImageUrl]);

  const { mutate: createOrganisationDetails } = useCreateOrganisationDetails();
  const { mutate: updateOrganisationDetails } = useUpdateOrganisationDetails();
  const { data: orgDetails, isLoading: isLoadingDetails } = useGetOrganisationDetails(userProfile.organisation_id);
  const [hasExistingDetails, setHasExistingDetails] = useState(false);

  // Load existing data if available
  useEffect(() => {
    if (orgDetails?.data && 
        orgDetails.data.company_name && 
        orgDetails.data.trading_name) {
      // Only consider details as "existing" if core fields have values
      setHasExistingDetails(true);
      setCompanyName(orgDetails.data.company_name || '');
      setTradingName(orgDetails.data.trading_name || '');
      setContactEmail(orgDetails.data.contact_email || '');
      setWebsite(orgDetails.data.website || '');
      setAddress(orgDetails.data.address || '');
      setDataRetentionPeriod(orgDetails.data.data_retention_period || '3');
      setDataRetentionPeriodUnit(orgDetails.data.data_retention_period_unit || 'months');
    }
  }, [orgDetails]);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // Step state: 1 for first step, 2 for second step
  const [step, setStep] = useState(1);

  // Form fields
  const [companyName, setCompanyName] = useState('');
  const [tradingName, setTradingName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [address, setAddress] = useState('');
  const [dataRetentionPeriod, setDataRetentionPeriod] = useState('3');
  const [dataRetentionPeriodUnit, setDataRetentionPeriodUnit] = useState('months');

  // Validate only company name and trading name (Step 1)
  const validateStep1 = () => {
    let newErrors = {};
    if (!companyName.trim()) newErrors.companyName = 'Input a company name.';
    if (!tradingName.trim()) newErrors.tradingName = 'Input a trading name.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Validate remaining fields (Step 2)
  const validateStep2 = () => {
    let newErrors = {};
    if (!contactEmail.trim()) {
      newErrors.contactEmail = 'Input a business contact email.';
    } else if (!/\S+@\S+\.\S+/.test(contactEmail)) {
      newErrors.contactEmail = 'Please enter a valid contact email.';
    }
    if (!website.trim()) newErrors.website = 'Input a business website.';
    if (!address.trim()) newErrors.address = 'Input a business address.';
    
    const retentionValue = Number(dataRetentionPeriod);
    if (!dataRetentionPeriod || isNaN(retentionValue) || retentionValue <= 0) {
      newErrors.dataRetentionPeriod = 'What is the duration for which you retain customer data in compliance with GDPR regulations?';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle "Next" button for Step 1
  const handleStep1 = (e) => {
    e.preventDefault();
    if (validateStep1()) {
      setStep(2);
    }
  };

  // Handle final submission for Step 2
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep2()) return;
    try {
      setIsLoading(true);
      const data = {
        company_name: companyName,
        trading_name: tradingName,
        contact_email: contactEmail,
        website: addHttpsIfNeeded(website),
        address,
        data_retention_period: dataRetentionPeriod,
        data_retention_period_unit: dataRetentionPeriodUnit
      };
      
      if (hasExistingDetails) {
        // Update existing organisation details
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
          formData.append(key, value);
        });
        await updateOrganisationDetails({ orgID: userProfile.organisation_id, data: formData });
      } else {
        // Create new organisation details
        await createOrganisationDetails({ orgID: userProfile.organisation_id, data });
      }
      
      setIsLoading(false);
      navigate('/wifi/analytics');
    } catch (error) {
      setIsLoading(false);
      // Log error details or use error.response.data for further troubleshooting
      if (error.response && error.response.status === 400) {
        setErrors(prev => ({ ...prev, contactEmail: error.response.data.error }));
      }
    }
  };

  return (
    <React.Fragment>
      <Flex style={{ height: '100vh', width: '100vw' }}>
        {/* First Login Section */}
        <Flex direction="column" align="stretch" px="7" py="8" style={{ alignItems: 'center', flex: '1 1 50%' }}>
          <Flex justify="center" align="stretch" width="100%" style={{ alignItems: 'center', flex: '1 0 0' }}>
            <Flex direction="column" gap="7" maxWidth="296px" style={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              {/* Sign up header */}
              <Flex direction="column" gap="2" maxWidth="240px" style={{ alignItems: 'center', width: '100%' }}>
                <Text size="1" weight="regular" color="gray" align="center" style={{ width: '100%' }}>
                  {organisationName}
                </Text>
                <Text size="4" weight="bold" align="center" style={{ width: '100%' }}>
                  Confirm organisation details
                </Text>
              </Flex>
              {/* Form Section */}
              <form onSubmit={step === 1 ? handleStep1 : handleSubmit} style={{ width: '100%' }}>
                <Flex direction="column" gap="5" style={{ alignItems: 'flex-start', width: '100%' }}>
                  {/* Render Step 1 fields only if step is 1 */}
                  {step === 1 && (
                    <Flex direction="column" gap="4" style={{ alignItems: 'flex-start', width: '100%' }}>
                      {/* Company name */}
                      <Flex direction="column" gap="1" style={{ width: '100%' }}>
                        <Text as="label" size="2" weight="medium" style={{ width: '100%' }}>
                          Business name (Legal name)
                        </Text>
                        <TextField.Root
                          style={{ width: '100%' }}
                          value={companyName}
                          onChange={(e) => {
                            setCompanyName(e.target.value);
                            if (errors.companyName) setErrors({ ...errors, companyName: '' });
                          }}
                        />
                        {errors.companyName && <Text size="1" color="red">{errors.companyName}</Text>}
                      </Flex>
                      {/* Trading name */}
                      <Flex direction="column" gap="1" style={{ width: '100%' }}>
                        <Text as="label" size="2" weight="medium" style={{ width: '100%' }}>
                          Trading name (Customer-facing name)
                        </Text>
                        <TextField.Root
                          style={{ width: '100%' }}
                          value={tradingName}
                          onChange={(e) => {
                            setTradingName(e.target.value);
                            if (errors.tradingName) setErrors({ ...errors, tradingName: '' });
                          }}
                        />
                        {errors.tradingName && <Text size="1" color="red">{errors.tradingName}</Text>}
                      </Flex>
                    </Flex>
                  )}

                  {/* Render Step 2 fields only if step is 2 */}
                  {step === 2 && (
                    <Flex direction="column" gap="4" style={{ alignItems: 'flex-start', width: '100%' }}>
                      {/* Business contact email */}
                      <Flex direction="column" gap="1" style={{ width: '100%' }}>
                        <Text as="label" size="2" weight="medium" style={{ width: '100%' }}>
                          Business contact email
                        </Text>
                        <TextField.Root
                          style={{ width: '100%' }}
                          value={contactEmail}
                          onChange={(e) => {
                            setContactEmail(e.target.value);
                            if (errors.contactEmail) setErrors({ ...errors, contactEmail: '' });
                          }}
                        />
                        {errors.contactEmail && <Text size="1" color="red">{errors.contactEmail}</Text>}
                      </Flex>
                      {/* Business website */}
                      <Flex direction="column" gap="1" style={{ width: '100%' }}>
                        <Text as="label" size="2" weight="medium" style={{ width: '100%' }}>
                          Business website
                        </Text>
                        <TextField.Root
                          style={{ width: '100%' }}
                          value={website}
                          onChange={(e) => {
                            setWebsite(e.target.value);
                            if (errors.website) setErrors({ ...errors, website: '' });
                          }}
                        />
                        {errors.website && <Text size="1" color="red">{errors.website}</Text>}
                      </Flex>
                      {/* Business address */}
                      <Flex direction="column" gap="1" style={{ width: '100%' }}>
                        <Text as="label" size="2" weight="medium" style={{ width: '100%' }}>
                          Business address
                        </Text>
                        <TextField.Root
                          style={{ width: '100%' }}
                          value={address}
                          onChange={(e) => {
                            setAddress(e.target.value);
                            if (errors.address) setErrors({ ...errors, address: '' });
                          }}
                        />
                        {errors.address && <Text size="1" color="red">{errors.address}</Text>}
                      </Flex>
                      {/* Data retention period */}
                      <Text as="label" size="2" style={{ width: '100%' }}>
                        <Flex direction="column" gap="1">
                          <Text size="2" weight="medium">
                            Data retention period
                          </Text>
                          <Flex gap="1" style={{ alignItems: 'center', width: '100%' }}>
                            <TextField.Root
                              size="2"
                              style={{ width: '50%' }}
                              value={dataRetentionPeriod}
                              onChange={(e) => {
                                setDataRetentionPeriod(e.target.value);
                                if (errors.dataRetentionPeriod) setErrors({ ...errors, dataRetentionPeriod: '' });
                              }}
                            />
                            <Select.Root 
                              value={dataRetentionPeriodUnit}
                              onValueChange={(value) => {
                                setDataRetentionPeriodUnit(value);
                                if (errors.dataRetentionPeriod) setErrors({ ...errors, dataRetentionPeriod: '' });
                              }}>
                              <Select.Trigger style={{ width: '50%' }} />
                              <Select.Content position="popper" side="bottom">
                                <Select.Group>
                                  <Select.Item value="months">Months</Select.Item>
                                  <Select.Item value="years">Years</Select.Item>
                                </Select.Group>
                              </Select.Content>
                            </Select.Root>
                          </Flex>
                          {errors.dataRetentionPeriod 
                            ? <Text size="1" color="red">{errors.dataRetentionPeriod}</Text>
                            : <Text size="1" color="gray">
                                What is the duration for which you retain customer data in compliance with GDPR regulations?
                              </Text>
                          }
                        </Flex>
                      </Text>
                    </Flex>
                  )}

                  {/* Buttons Section */}
                  <Flex gap="4" direction="column" style={{ alignItems: 'center', width: '100%' }}>
                    <Button type="submit" size="2" variant="solid" loading={isLoading} style={{ width: '100%' }}>
                      {step === 1 ? 'Save and continue' : 'Save and finish'}
                    </Button>
                    <Text size="1" color="gray" align="center">
                      You can update your details later.
                    </Text>
                  </Flex>
                </Flex>
              </form>
            </Flex>
          </Flex>
          {/* Footer links */}
            <Flex gap="7" justify="center" width="100%">
                <Link size="1" weight="regular" color="gray" href="https://b-connect.co.uk/terms" target='_blank'>
                    Terms of Service
                </Link>
                <Link size="1" weight="regular" color="gray" href="https://b-connect.co.uk/privacy" target='_blank'>
                    Privacy Notice
                </Link>
                <Link size="1" weight="regular" color="gray" href="mailto:support@b-connect.co.uk">Support</Link>
            </Flex>
        </Flex>
        {/* Background Image and Logo Section */}
        <Flex direction="column" align="stretch" style={{ 
          padding: '80px 120px 0 120px', 
          background: isImageLoaded 
            ? `url(${organisationImageUrl}) no-repeat center center / cover`
            : 'var(--color-background)',
          transition: 'background 0.3s ease-in-out',
          backgroundBlendMode: 'soft-light', 
          flex: '1 1 50%', 
          alignItems: 'center'
        }}>
          <Logo icon="LogoBWifi" width="40px" height="32px" color="white" />
        </Flex>
      </Flex>
    </React.Fragment>
  );
}
