import React, { useState, useEffect } from "react";
import { Flex, Text, Button, Box } from "@radix-ui/themes";
import { ActiveUnifiIntegrations } from "./unifi/ActiveUnifiIntegrations";
import { ActiveBconnectIntegrations } from "./bconnect/ActiveBconnectIntegrations";
import { ActiveMailchimpIntegration } from "./mailchimp/ActiveMailchimpIntegration";
import { ActiveAirshipIntegration } from "./airship/ActiveAirshipIntegration";
import { useLocationContext, useProfileContext } from "context";
import { useGetActiveIntegrations, useCheckCiscoIntegration, useGetAirshipStatus } from "hooks";
import { useNavigate } from "react-router-dom";
import { ActiveCiscoMerakiIntegrations } from "./ciscomeraki/ActiveCiscoMerakiIntegrations";

export default function ActiveIntegrations() {
  const { userProfile } = useProfileContext();
  const navigate = useNavigate();
  const { data: activeIntegrationsData } = useGetActiveIntegrations(userProfile.organisation_id);
  const [activeIntegrations, setActiveIntegrations] = useState(false);
  const { data: ciscoIntegrationData } = useCheckCiscoIntegration(userProfile.organisation_id);
  const { data: airshipStatusData } = useGetAirshipStatus(userProfile.organisation_id);

  const isIntegrationActive = (integrationType) => {
    if (integrationType === "cisco") {
      return ciscoIntegrationData?.data?.is_active || false;
    }
    if (integrationType === "airship") {
      return airshipStatusData?.data?.is_active || false;
    }
    if (!activeIntegrationsData?.data?.integrations) return false;
    return activeIntegrationsData.data.integrations.some((integration) => integration.name === integrationType);
  };

  useEffect(() => {
    setActiveIntegrations(isIntegrationActive("mailchimp") || isIntegrationActive("unifi") || isIntegrationActive("bconnect") || isIntegrationActive("cisco") || isIntegrationActive("airship"));
  }, [activeIntegrationsData, ciscoIntegrationData, airshipStatusData]);

  if (!activeIntegrations) {
    return (
      <Flex
        className="frame-528173501"
        style={{
          alignSelf: "stretch",
          borderRadius: "var(--space-2)",
          border: "1px dashed var(--gray-a6)",
        }}
        display="flex"
        height="calc(100vh - 185px)"
        minHeight="0"
        direction="column"
        justify="center"
        align="center"
        gap="5"
        flexGrow="1"
      >
        <Flex className="frame-528173502" display="flex" direction="column" align="center" gap="2">
          <Text size="4" weight="medium">
            No connected integrations
          </Text>
          <Text size="2" weight="regular">
            After you connect and set up integrations, they'll show up here for you to manage.
          </Text>
        </Flex>
        <Button size="2" variant="soft" color="gray" style={{ cursor: "pointer" }} onClick={() => navigate("/integrations/browse-all")}>
          Browse integrations
        </Button>
      </Flex>
    );
  }

  return (
    <Box
      style={{
        height: "calc(100vh - 184px)",
        overflowY: "auto",
        width: "100%",
      }}
    >
      <Flex className="frame-528173401" direction="column" align="end" gap="5">
        {isIntegrationActive("unifi") && <ActiveUnifiIntegrations />}
        {isIntegrationActive("bconnect") && <ActiveBconnectIntegrations />}
        {isIntegrationActive("mailchimp") && <ActiveMailchimpIntegration />}
        {isIntegrationActive("cisco") && <ActiveCiscoMerakiIntegrations />}
        {isIntegrationActive("airship") && <ActiveAirshipIntegration />}
      </Flex>
    </Box>
  );
}
