import { useMutation, useQueryClient } from "@tanstack/react-query";
import { attemptAuthenticateIntegrations } from '../../api/bIntegrationsApi';

export const useAuthenticateIntegrations = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationKey: 'authenticate-integrations',
    mutationFn: (data) => attemptAuthenticateIntegrations(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["authenticate-integrations"],
      });
    },
  });
};