import React, { createContext, useContext } from "react";
import { Flex, Card, Text, Button, Badge, Dialog, Separator, Inset, AlertDialog, Box, Progress } from "@radix-ui/themes";
import { createPortal } from "react-dom";
const BrowseIntegrateCardContext = createContext(null);

function Root({ name, subtitle, image_url, status, openDialog, handleOpenChange, children }) {
  // Put your image inside src/assets/images!
  // const [openDialog, setOpenDialog] = useState({
  //   auth: false,
  //   detail: false
  // });

  // Convert children to an array for easy manipulation
  const childrenArray = React.Children.toArray(children);

  // Extract the first and second dialog
  const [firstDialog, secondDialog] = childrenArray;

  return (
    <BrowseIntegrateCardContext.Provider
      value={{
        name,
        subtitle,
        image_url,
        status,
        openDialog,
        handleOpenChange,
        secondDialog,
      }}
    >
      <Card
        style={{
          display: "flex",
          minWidth: "455px",
          padding: "var(--space-4)",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Flex className="frame-528173423" display="flex" align="center" gap="4">
          {status === "request" ? (
            <Flex
              style={{
                borderRadius: "var(--space-1)",
                border: "1px dashed var(--gray-a6)",
                background: "var(--gray-a2)",
              }}
              display="flex"
              width="40px"
              height="40px"
              p="2"
              justify="center"
              align="center"
              gap="2"
            />
          ) : (
            <Flex
              className="frame-528173424"
              style={{
                borderRadius: "var(--space-1)",
                background: "#FFFFFF",
                border: "1px solid var(--gray-a6)",
              }}
              display="flex"
              height="40px"
              width="40px"
              p="2"
              justify="center"
              align="center"
              gap="2"
            >
              <img src={require(`../../assets/images/${image_url}`)} alt="icon" style={{ width: "24px", borderRadius: "var(--space-1)" }} />
            </Flex>
          )}
          <Flex className="frame-528173402" display="flex" direction="column" justify="center" align="start">
            <Text size="3" weight="medium">
              {name}
            </Text>
            <Text style={{ color: "var(--gray-a11)" }} size="1" weight="regular">
              {subtitle}
            </Text>
          </Flex>
        </Flex>
        {firstDialog}
      </Card>
    </BrowseIntegrateCardContext.Provider>
  );
}

function AuthDialog({ dialog_description, children, onCancel, leftButton, isLoading, progressValue }) {
  const { name, status, openDialog, handleOpenChange, secondDialog } = useContext(BrowseIntegrateCardContext);

  const handleEmailRedirect = () => {
    const supportEmail = "support@b-connect.co.uk";
    const subject = "Support Request";
    const body = "Hello, I need help with...";
    const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  let statusButton;

  if (status === "active") {
    statusButton = (
      <Badge size="1" variant="soft" color="green" asChild={false} style={{ pointerEvents: "none", userSelect: "none" }}>
        Connected
      </Badge>
    );
  } else if (status === "contact-sales") {
    statusButton = (
      <Button onClick={handleEmailRedirect} size="2" variant="soft" color="gray">
        Contact sales
      </Button>
    );
  } else if (status === "inactive") {
    statusButton = (
      <Dialog.Trigger asChild>
        <Button style={{ cursor: "pointer" }} size="2" variant="soft" color="gray">
          Connect
        </Button>
      </Dialog.Trigger>
    );
  } else if (status === "request") {
    statusButton = (
      <Button onClick={handleEmailRedirect} size="2" variant="soft" color="gray">
        Request
      </Button>
    );
  } else if (status === "coming-soon") {
    statusButton = (
      <Badge
        size="1"
        variant="soft"
        color="gray"
        asChild={false}
        style={{
          pointerEvents: "none",
          userSelect: "none",
        }}
      >
        Coming soon
      </Badge>
    );
  } else if (status === "contact-support") {
    statusButton = (
      <Button onClick={handleEmailRedirect} size="2" variant="soft" color="gray">
        Contact support
      </Button>
    );
  }

  // Only render Dialog.Root and its content for statuses that should show the dialog
  if (status === "inactive") {
    return (
      <>
        <Dialog.Root open={openDialog.auth} onOpenChange={(isOpen) => handleOpenChange("auth", isOpen)}>
          {statusButton}
          <Dialog.Content maxWidth="600px" maxHeight="690px" align="center">
            {isLoading && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  zIndex: 10000,
                }}
              >
                <Progress value={progressValue} max={100} size="1" radius="none" />
              </div>
            )}
            <Dialog.Title>Activate {name.charAt(0).toUpperCase() + name.slice(1)}</Dialog.Title>
            <Dialog.Description size="2" mb="4">
              {dialog_description}
            </Dialog.Description>
            {children}
            <Flex
              style={{
                alignSelf: "stretch",
                width: "100%",
              }}
              display="flex"
              justify="between"
              align="center"
              mt="4"
            >
              {leftButton}
              <Flex display="flex" gap="3" justify="end" width="100%">
                <Dialog.Close>
                  <Button onClick={onCancel} size="2" variant="soft" color="gray">
                    Cancel
                  </Button>
                </Dialog.Close>
                {secondDialog}
              </Flex>
            </Flex>
          </Dialog.Content>
        </Dialog.Root>
      </>
    );
  }

  // For all other statuses, just render the button/badge
  return statusButton;
}

function DetailDialog({ children, onSave, onActivate, isLoading, progressValue }) {
  const { name, subtitle, image_url, openDialog, handleOpenChange } = useContext(BrowseIntegrateCardContext);

  return (
    <>
      {openDialog.detail && (
        <>
          <Box
            position="fixed"
            inset="0"
            style={{
              backgroundColor: "var(--color-background)",
              zIndex: 9997,
            }}
          />
          {isLoading &&
            createPortal(
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  zIndex: 999999, // Much higher z-index
                }}
              >
                <Progress
                  value={progressValue}
                  max={100}
                  size="1"
                  radius="none"
                  style={{
                    backgroundColor: "transparent", // Ensure no background color
                  }}
                />
              </div>,
              document.body
            )}
        </>
      )}
      <AlertDialog.Root open={openDialog.detail}>
        <AlertDialog.Action>
          <Button onClick={onSave} size="2" variant="soft" color="accent">
            Save and continue
          </Button>
        </AlertDialog.Action>
        <AlertDialog.Content
          maxWidth="1000px"
          align="center"
          style={{
            position: "relative",
            zIndex: 9998,
            boxShadow: "none !important",
            "--shadow": "none",
            "--overlay-shadow": "none",
            "--dialog-shadow": "none",
            filter: "none",
            WebkitBoxShadow: "none",
            MozBoxShadow: "none",
          }}
        >
          <AlertDialog.Title hidden={true} />
          <AlertDialog.Description hidden={true} />
          <Inset>
            <Flex style={{ alignSelf: "stretch" }} display="flex" align="start" direction="column" p="4" gap="4" maxWidth="1000px">
              <Flex className="frame-528173404" style={{ alignSelf: "stretch" }} display="flex" justify="between" align="center">
                <Flex className="frame-528173423" display="flex" align="center" gap="4">
                  <Flex
                    className="frame-528173424"
                    style={{
                      borderRadius: "var(--space-1)",
                      background: "#FFFFFF",
                      border: "1px solid var(--gray-a6)",
                    }}
                    display="flex"
                    height="40px"
                    p="2"
                    justify="center"
                    align="center"
                    gap="2"
                  >
                    <img src={require(`../../assets/images/${image_url}`)} alt="icon" style={{ width: "24px", borderRadius: "var(--space-1)" }} />
                  </Flex>
                  <Flex className="frame-528173402" display="flex" direction="column" justify="center" align="start" gap="1">
                    <Text size="4" weight="medium">
                      {name}
                    </Text>
                    <Text style={{ color: "var(--gray-a11)" }} size="1" weight="regular">
                      {subtitle}
                    </Text>
                  </Flex>
                </Flex>
                <Flex display="flex" align="center" gap="4">
                  <AlertDialog.Cancel>
                    <Button size="2" variant="soft" onClick={() => handleOpenChange("detail", false)} color="gray">
                      Cancel
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <Button size="2" variant="soft" onClick={onActivate}>
                      Save and finish
                    </Button>
                  </AlertDialog.Action>
                </Flex>
              </Flex>
              {children}
            </Flex>
          </Inset>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </>
  );
}

function DetailDialogSection({ name, subtitle, children }) {
  return (
    <React.Fragment>
      <Separator orientation="horizontal" size="4" />
      <Flex className="frame-528173428" style={{ alignSelf: "stretch" }} display="flex" align="start" gap="5">
        <Flex className="frame-528173428" style={{ alignSelf: "stretch" }} display="flex" width="296px" direction="column" align="start" gap="2">
          <Text size="2" weight="medium">
            {name}
          </Text>
          <Text size="1" weight="regular">
            {subtitle}
          </Text>
        </Flex>
        {children}
      </Flex>
    </React.Fragment>
  );
}

export const BrowseIntegrateCard = {
  Root,
  AuthDialog,
  DetailDialog,
  DetailDialogSection,
};
