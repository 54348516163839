import * as React from "react";
import { Flex, Text, Card, Table, Badge, Tooltip } from "@radix-ui/themes";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { useLiveAccessPoints } from "hooks";
import { useLocationContext } from "context";
import { StackedStatusBar } from "components/charts/StackedStatusBar";

export default function Locations() {
  const { locationsData } = useLocationContext();
  const { liveAccessPointsStatus: liveAccessPointsStatus } = useLiveAccessPoints(locationsData);

  // Sort the liveAccessPointsStatus array alphabetically by name
  const sortedAccessPointsStatus = liveAccessPointsStatus ? [...liveAccessPointsStatus].sort((a, b) => (a.name || "").localeCompare(b.name || "")) : [];
  const [stackedStatusBarData, setStackedStatusBarData] = React.useState({
    online: 0,
    offline: 0,
    partially: 0,
  });

  React.useEffect(() => {
    if (!sortedAccessPointsStatus) return;

    const newStats = sortedAccessPointsStatus.reduce(
      (acc, ap) => {
        if (ap.online_access_points_count > 0 && ap.offline_access_points_count === 0) {
          acc.online += 1;
        } else if (ap.online_access_points_count === 0 && ap.offline_access_points_count > 0) {
          acc.offline += 1;
        } else {
          acc.partially += 1;
        }
        return acc;
      },
      { online: 0, offline: 0, partially: 0 }
    );

    setStackedStatusBarData(newStats);
  }, [sortedAccessPointsStatus]);

  return (
    <Flex direction="column" gap="5" style={{ alignSelf: "stretch", alignItems: "flex-start", flex: "1 0 0", height: "100%" }}>
      {/* Location Status */}
      <Card style={{ display: "flex", alignSelf: "stretch", padding: "var(--space-4)", flexDirection: "column", alignItems: "flex-start", gap: "var(--space-4)" }}>
        <Flex direction="column" gap="4" style={{ alignItems: "flex-start", alignSelf: "stretch", flex: "1 0 0" }}>
          <Text size="2" weight="medium">
            Location status
          </Text>
          <StackedStatusBar stackedStatusBarData={stackedStatusBarData} />
        </Flex>
      </Card>
      {/* Access Points Details */}
      <Card
        style={{
          display: "flex",
          alignSelf: "stretch",
          padding: "var(--space-4)",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "var(--space-4)",
          overflow: "scroll",
          flex: "1 0 0",
          height: "100%",
        }}
      >
        <Table.Root style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", alignSelf: "stretch", height: "100%" }}>
          <Table.Header style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "var(--color-panel-solid)" }}>
            <Table.Row align="center">
              <Table.ColumnHeaderCell style={{ width: "33.33%" }}>
                <Text size="2" weight="medium">
                  Location
                </Text>
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell style={{ width: "33.33%" }}>
                <Text size="2" weight="medium">
                  Access points
                </Text>
              </Table.ColumnHeaderCell>
              {/* <Table.ColumnHeaderCell style={{width: "33.33%"}}>
                  <Text size="2" weight="medium">Provider</Text>
                </Table.ColumnHeaderCell> */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedAccessPointsStatus.map((location, index) => (
              <Table.Row key={index} align="center">
                {/* Location's Name Cell */}
                <Table.Cell>
                  <Flex className="text-container" direction="column" align="start" gap="var(--space-1)">
                    <Text size="2" weight="regular">
                      {location.name}
                    </Text>
                    <Text style={{ color: "var(--gray-a11)" }} weight="regular" size="1">
                      {location.address}
                    </Text>
                  </Flex>
                </Table.Cell>
                {/* Access Point Status Cell */}
                <Table.Cell>
                  <Flex gap="3">
                    {location.online_access_points_count > 0 ? (
                      <Badge variant="soft" color="green" size="1">
                        <Text size="1" weight="medium">
                          {`${location.online_access_points_count} Online`}
                        </Text>
                      </Badge>
                    ) : null}
                    {location.offline_access_points_count > 0 ? (
                      <Tooltip content="Ensure your Wi-Fi Access Point is securely connected to your main router using a PoE injector.">
                        <Badge variant="soft" color="red" size="1">
                          <InfoCircledIcon style={{ width: "12px", height: "12px", color: "var(--red-a11)" }} />
                          <Text size="1" weight="medium">
                            {`${location.offline_access_points_count} Offline`}
                          </Text>
                        </Badge>
                      </Tooltip>
                    ) : null}
                  </Flex>
                </Table.Cell>
                {/* Provider's Name Cell */}
                {/* <Table.Cell>
                  <Text size="2" weight="regular" color="gray">
                    b connect Wi-Fi
                  </Text>
                </Table.Cell> */}
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      </Card>
    </Flex>
  );
}
