import React, { useState, useEffect } from "react";
import { Flex, Text, Select, IconButton, TextField, Button, Dialog } from "@radix-ui/themes";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { ActiveIntegrateCard } from "components";
import { useGetAirshipMappings, useGetAirshipUnits, useSaveAirshipMappings, useDeactivateAirship } from "hooks";
import { useQueryClient } from "@tanstack/react-query";
import { useProfileContext, useLocationContext } from "context";
import { useToast } from "../../../context/ToastContext";

export function ActiveAirshipIntegration() {
  const queryClient = useQueryClient();
  const { userProfile } = useProfileContext();
  const { initialItems } = useLocationContext();
  const { setToastMessage } = useToast();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isDeactivating, setIsDeactivating] = useState(false);

  // State for mappings
  const [locationMappings, setLocationMappings] = useState({});
  const [groupMappings, setGroupMappings] = useState({});
  const [airshipUnits, setAirshipUnits] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [mappedLocationIds, setMappedLocationIds] = useState(new Set());

  // Get existing mappings and units
  const { data: airshipMappingsData, isLoading: isMappingsLoading } = useGetAirshipMappings(userProfile?.organisation_id);
  const { data: airshipUnitsData, isLoading: isUnitsLoading } = useGetAirshipUnits(userProfile?.organisation_id);

  // Save mappings mutation
  const saveAirshipMappings = useSaveAirshipMappings();
  const deactivateAirship = useDeactivateAirship();

  // Handle deactivation
  const handleDeactivate = async () => {
    try {
      setIsDeactivating(true);

      const result = await deactivateAirship.mutateAsync({
        organisation_id: userProfile.organisation_id,
      });

      if (result?.data?.status === "success") {
        // Invalidate the status query to refresh the active integrations list
        queryClient.invalidateQueries(["airship-integration-status"]);
        // setToastMessage("Airship integration disconnected successfully");
      } else {
        throw new Error(result?.data?.message || "Failed to disconnect Airship integration");
      }
    } catch (error) {
      console.error("Error deactivating Airship:", error);
      setToastMessage(error.message || "Failed to disconnect Airship integration");
    } finally {
      setIsDeactivating(false);
      setShowConfirmDialog(false);
    }
  };

  // Effect to set units when API data is available
  useEffect(() => {
    if (airshipUnitsData?.data?.units?.data) {
      setAirshipUnits(
        airshipUnitsData.data.units.data.map((unit) => ({
          id: unit.id.toString(),
          name: unit.name,
        }))
      );
    }
  }, [airshipUnitsData]);

  // Effect to process existing mappings
  useEffect(() => {
    if (airshipMappingsData?.data?.mappings && initialItems) {
      // Create mappings objects
      const mappings = airshipMappingsData.data.mappings;
      const locToUnit = {};
      const locToGroup = {};
      const mappedIds = new Set();

      // Process each mapping
      mappings.forEach((mapping) => {
        locToUnit[mapping.location_id] = mapping.unit_id;
        locToGroup[mapping.location_id] = mapping.group_name;
        mappedIds.add(mapping.location_id.toString());
      });

      // Set the state
      setLocationMappings(locToUnit);
      setGroupMappings(locToGroup);
      setMappedLocationIds(mappedIds);

      // Combine all locations with mapping information
      const locations = initialItems.map((loc) => {
        const mapping = mappings.find((m) => m.location_id.toString() === loc.value.toString());
        return {
          ...loc,
          isMapped: !!mapping,
          unit_id: mapping?.unit_id || "",
          unit_name: mapping?.unit_name || "",
          group_name: mapping?.group_name || "",
        };
      });

      setAllLocations(locations);
    }
  }, [airshipMappingsData, initialItems]);

  // Handle unit selection change
  const handleAirshipUnitChange = (locationId, unitId) => {
    setLocationMappings({
      ...locationMappings,
      [locationId]: unitId,
    });
  };

  // Handle group name change
  const handleGroupTextChange = (locationId, groupName) => {
    setGroupMappings({
      ...groupMappings,
      [locationId]: groupName,
    });
  };

  // Save updated mappings
  const handleSaveMappings = async () => {
    try {
      setIsUpdating(true);

      // Prepare location mappings data - only for unmapped locations
      const mappingsToSave = allLocations
        .filter((loc) => !loc.isMapped && locationMappings[loc.value])
        .map((loc) => {
          const unitId = locationMappings[loc.value];
          // Get unit name from the units array
          const unit = airshipUnits.find((u) => u.id === unitId);

          return {
            location_id: loc.value,
            location_name: loc.label,
            unit_id: unitId,
            unit_name: unit?.name || "Unknown Unit",
            group_name: groupMappings[loc.value] || "",
          };
        });

      // Skip if no mappings to save
      if (mappingsToSave.length === 0) {
        setToastMessage("No changes to save");
        setIsUpdating(false);
        return;
      }

      // Call the save-mappings API
      const result = await saveAirshipMappings.mutateAsync({
        organisation_id: userProfile.organisation_id,
        location_mappings: mappingsToSave,
      });

      if (result?.data?.status !== "success") {
        throw new Error(result?.data?.message || "Failed to save mappings");
      }

      // Refresh data
      queryClient.invalidateQueries(["airship-mappings"]);
      setToastMessage("Mappings saved successfully");
    } catch (error) {
      console.error("Error saving mappings:", error);
      setToastMessage(error.message || "Failed to save mappings");
    } finally {
      setIsUpdating(false);
    }
  };

  // Loading state
  if (isMappingsLoading || isUnitsLoading) {
    return (
      <Flex direction="column" align="center" justify="center" style={{ height: "200px" }}>
        <Text>Loading Airship integration data...</Text>
      </Flex>
    );
  }

  return (
    <>
      <ActiveIntegrateCard.Root
        name="Airship"
        subtitle="CRM & Email marketing"
        image_url="airship.png"
        option={{
          text: "Disconnect",
          color: "red",
          onClick: () => setShowConfirmDialog(true),
        }}
      >
        {/* Single Locations & Groups section */}
        <ActiveIntegrateCard.Section
          name="Locations & Groups"
          subtitle="Map each b connect location to its corresponding Airship unit. After that, select the group where your contacts will be imported."
        >
          <Flex direction="column" gap="4" style={{ width: "100%" }}>
            {/* Header row */}
            <Flex direction="row" justify="between" style={{ width: "100%" }}>
              <Text size="2" weight="medium" style={{ width: "32%" }}>
                b connect location
              </Text>
              <Text size="2" weight="medium" style={{ width: "32%", paddingLeft: "3px" }}>
                Airship unit
              </Text>
              <Text size="2" weight="medium" style={{ width: "32%", paddingLeft: "5px" }}>
                Airship group
              </Text>
            </Flex>

            {/* All locations */}
            {allLocations.length > 0 ? (
              allLocations.map((loc) => (
                <Flex key={loc.value} direction="row" justify="between" style={{ width: "100%" }}>
                  {/* Location name - always read-only */}
                  <Flex style={{ width: "32%", minWidth: 0 }}>
                    <Flex
                      style={{
                        width: "100%",
                        backgroundColor: "var(--gray-3)",
                        border: "1px solid var(--gray-a7)",
                        borderRadius: "var(--radius-2)",
                        padding: "0 var(--space-2)",
                        height: "34px",
                        alignItems: "center",
                        overflow: "hidden",
                      }}
                    >
                      <Text
                        size="2"
                        title={loc.label}
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          width: "100%",
                          color: "var(--gray-a11)",
                        }}
                      >
                        {loc.label}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex align="center" style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    <IconButton size="2" variant="ghost" color="gray" style={{ pointerEvents: "none" }}>
                      <ArrowRightIcon width="16" height="16" />
                    </IconButton>
                  </Flex>

                  {/* Unit - either read-only or dropdown */}
                  <Flex style={{ width: "32%", minWidth: 0 }}>
                    {loc.isMapped ? (
                      // Read-only for mapped locations
                      <Flex
                        style={{
                          width: "100%",
                          backgroundColor: "var(--gray-3)",
                          border: "1px solid var(--gray-a7)",
                          borderRadius: "var(--radius-2)",
                          padding: "0 var(--space-2)",
                          height: "34px",
                          alignItems: "center",
                          overflow: "hidden",
                        }}
                      >
                        <Text
                          size="2"
                          title={loc.unit_name}
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            width: "100%",
                            color: "var(--gray-a11)",
                          }}
                        >
                          {loc.unit_name}
                        </Text>
                      </Flex>
                    ) : (
                      // Dropdown for unmapped locations
                      <Select.Root value={locationMappings[loc.value] || ""} onValueChange={(value) => handleAirshipUnitChange(loc.value, value)} style={{ width: "100%" }}>
                        <Select.Trigger
                          placeholder="Select..."
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                            overflow: "hidden",
                            boxSizing: "border-box",
                          }}
                        >
                          <div
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              width: "100%",
                              display: "block",
                              maxWidth: "100%",
                            }}
                            title={locationMappings[loc.value] ? airshipUnits.find((unit) => unit.id === locationMappings[loc.value])?.name || "" : "Select a unit"}
                          >
                            {locationMappings[loc.value] ? airshipUnits.find((unit) => unit.id === locationMappings[loc.value])?.name || "" : "Select a unit"}
                          </div>
                        </Select.Trigger>
                        <Select.Content side="bottom" align="start" sideOffset={4}>
                          {airshipUnits.map((unit) => (
                            <Select.Item key={unit.id} value={unit.id} title={unit.name}>
                              {unit.name}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select.Root>
                    )}
                  </Flex>

                  <Flex align="center" style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    <IconButton size="2" variant="ghost" color="gray" style={{ pointerEvents: "none" }}>
                      <ArrowRightIcon width="16" height="16" />
                    </IconButton>
                  </Flex>

                  {/* Group - either read-only or text field */}
                  <Flex style={{ width: "32%", minWidth: 0 }}>
                    {loc.isMapped ? (
                      // Read-only for mapped locations
                      <Flex
                        style={{
                          width: "100%",
                          backgroundColor: "var(--gray-3)",
                          border: "1px solid var(--gray-a7)",
                          borderRadius: "var(--radius-2)",
                          padding: "0 var(--space-2)",
                          height: "34px",
                          alignItems: "center",
                          overflow: "hidden",
                        }}
                      >
                        <Text
                          size="2"
                          title={loc.group_name}
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            width: "100%",
                            color: "var(--gray-a11)",
                          }}
                        >
                          {loc.group_name || "Default"}
                        </Text>
                      </Flex>
                    ) : (
                      // Text field for unmapped locations
                      <TextField.Root
                        value={groupMappings[loc.value] || ""}
                        onChange={(e) => handleGroupTextChange(loc.value, e.target.value)}
                        size="2"
                        style={{ width: "100%" }}
                        placeholder="Group name"
                      />
                    )}
                  </Flex>
                </Flex>
              ))
            ) : (
              <Flex justify="center" style={{ padding: "20px 0" }}>
                <Text>No locations found.</Text>
              </Flex>
            )}

            <Flex justify="end">
              <Button
                size="2"
                variant="solid"
                color="blue"
                onClick={handleSaveMappings}
                loading={isUpdating}
                disabled={isUpdating || !allLocations.some((loc) => !loc.isMapped && locationMappings[loc.value])}
              >
                Save changes
              </Button>
            </Flex>
          </Flex>
        </ActiveIntegrateCard.Section>
      </ActiveIntegrateCard.Root>

      {/* Confirmation Dialog */}
      <Dialog.Root open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <Dialog.Content size="3" style={{ maxWidth: 450 }}>
          <Dialog.Title>Disconnect Airship</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            Customer contacts will no longer sync with Airship.
          </Dialog.Description>

          <Flex gap="3" justify="end">
            <Dialog.Close>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </Dialog.Close>
            <Button variant="solid" color="red" onClick={handleDeactivate} loading={isDeactivating} disabled={isDeactivating}>
              Disconnect
            </Button>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
