import React, { useEffect, useState } from "react";
import { Flex, Text } from "@radix-ui/themes";
import { useLocation } from 'react-router-dom';

export function StackedStatusBar({ stackedStatusBarData }) {
  const location = useLocation();
  const [partiallyOnlineColour, setPartiallyOnlineColour] = useState('var(--violet-8)');
  const [onlineColour, setOnlineColour] = useState('var(--violet-11)');

  // Colour of the status bar depends on the location
  const getStatusBarColor = () => {
    if (location.pathname.includes('/wifi/locations')){
      setPartiallyOnlineColour("var(--amber-9)");
      setOnlineColour("var(--grass-9)");
    }
  }

  useEffect(() => {
    getStatusBarColor();
  }, [location.pathname]);

  return (
    <Flex height="48px" direction="column" align="start" gap="4" width="100%" style={{flex: '1 0 0'}}>
      {/* Colour Graph */}
      <Flex height="16px" align="center" 
        style={{alignSelf: "stretch", borderRadius: 'var(--radius-2)', overflow: 'hidden'}} >
        <div className="offline" style={{backgroundColor: "var(--red-9)",flexGrow: stackedStatusBarData.offline, alignSelf: "stretch"}}/>
        <div className="partially-online" style={{backgroundColor: `${partiallyOnlineColour}`, flexGrow: stackedStatusBarData.partially, alignSelf: "stretch"}}/>
        <div className="online" style={{backgroundColor: `${onlineColour}`, flexGrow: stackedStatusBarData.online, alignSelf: "stretch"}}/>
      </Flex>
      {/* Legends for the graph */}
      <Flex className="frame-528173339" align="center" gap="4">
        <Flex className="frame-528173286" align="center" gap="1">
          <div className="offline-ellipse "style={{background: "var(--red-9)", height: "8px", width: "8px", borderRadius: "4px"}}/>
          <Text size="1" weight="regular">
            Offline
          </Text>
          <Text size="1" weight="bold">
            {stackedStatusBarData.offline}
          </Text>
        </Flex>
        <Flex className="frame-528173244" display="flex" align="center" gap="1">
          <div className="partially-online-ellipse "style={{background: `${partiallyOnlineColour}`, height: "8px", width: "8px", borderRadius: "4px"}}/>
          <Text size="1" weight="regular">
            Partially online
          </Text>
          <Text size="1" weight="bold">
            {stackedStatusBarData.partially}
          </Text>
        </Flex>
        <Flex className="frame-528173245" display="flex" align="center" gap="1">
          <div className="online-ellipse "style={{background: `${onlineColour}`, height: "8px", width: "8px", borderRadius: "4px"}}/>
          <Text size="1" weight="regular">
            Online
          </Text>
          <Text size="1" weight="bold">
            {stackedStatusBarData.online}
          </Text>
        </Flex>
      </Flex>
    </Flex>

  );
}

