import * as React from "react";
import { Separator } from "components/separator/Separator";
import { Text, Flex, Button, Checkbox, CheckboxGroup, TextField, Popover, IconButton } from "@radix-ui/themes";
import { CaretSortIcon, Cross1Icon, MagnifyingGlassIcon, ChevronDownIcon } from "@radix-ui/react-icons";

export function ZonesMultiSelect({ width = null, noShowBorder, options, onValueChange, defaultValue }) {
  const [selectedValues, setSelectedValues] = React.useState(defaultValue);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [displayText, setDisplayText] = React.useState("All zones");
  const [checkBoxBool, setCheckBoxBool] = React.useState();
  const [topCheckBoxBool, setTopCheckBoxBool] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState("");

  // Transform zones data to match the expected format
  const transformedOptions = options.map((zone) => ({
    value: zone.id.toString(),
    label: zone.name,
  }));

  React.useEffect(() => {
    setSelectedValues(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    if (transformedOptions && transformedOptions.length > 0) {
      setCheckBoxBool(new Array(transformedOptions.length).fill(true));
    }
  }, [transformedOptions]);

  const updateDisplayAndCheckboxes = (newSelectedValues) => {
    setDisplayText(
      newSelectedValues.length === 0
        ? "Select zones"
        : newSelectedValues.length === transformedOptions.length
        ? "All zones"
        : `${newSelectedValues.length} zone${newSelectedValues.length > 1 ? "s" : ""}`
    );
    setCheckBoxBool(transformedOptions.map((option) => newSelectedValues.includes(option.value)));
    setTopCheckBoxBool(newSelectedValues.length === transformedOptions.length);
  };

  const handleClear = () => {
    setSelectedValues([]);
    onValueChange([]);
    updateDisplayAndCheckboxes([]);
  };

  const toggleAll = () => {
    if (selectedValues.length === transformedOptions.length) {
      handleClear();
    } else {
      const allValues = transformedOptions.map((option) => option.value);
      setSelectedValues(allValues);
      onValueChange(allValues);
      updateDisplayAndCheckboxes(allValues);
    }
  };

  const clearSearchQuery = () => {
    setSearchQuery("");
  };

  const filteredOptions = transformedOptions.filter((option) => option.label.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <Popover.Trigger asChild>
        <Button
          style={{
            cursor: "pointer",
            width: width,
            marginLeft: noShowBorder ? "3px" : null,
          }}
          size="2"
          variant={noShowBorder ? "ghost" : "soft"}
          color={selectedValues.length === 0 ? "red" : "gray"}
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          <Text color="gray" weight="medium">
            {displayText}
          </Text>
          {width ? <Flex flexGrow="1" /> : null}
          <ChevronDownIcon style={{ color: "var(--gray-11)" }} />
        </Button>
      </Popover.Trigger>

      <Popover.Content
        style={{
          display: "flex",
          width: "291px",
          flexDirection: "column",
          alignItems: "start",
          padding: "0",
        }}
        maxWidth="480px"
      >
        <Flex className="slot-container" style={{ alignSelf: "stretch" }} display="flex" direction="column" align="start">
          <Flex
            className="search-row"
            style={{
              alignSelf: "stretch",
              padding: "var(--space-2) 8px var(--space-2) 20px",
            }}
            justify="start"
            align="center"
            gap="2"
          >
            <Checkbox size="2" variant="surface" checked={topCheckBoxBool} onCheckedChange={toggleAll} style={{ cursor: "pointer" }} />
            <TextField.Root style={{ flexGrow: "1" }} size="2" variant="soft" color="gray" placeholder="Search zones" value={searchQuery} onChange={(event) => setSearchQuery(event.target.value)}>
              <TextField.Slot>
                <MagnifyingGlassIcon height="16" width="16" />
              </TextField.Slot>
              <TextField.Slot>
                <IconButton size="1" variant="ghost" color="gray" onClick={clearSearchQuery} style={{ cursor: "pointer" }}>
                  <Cross1Icon height="16" width="16" />
                </IconButton>
              </TextField.Slot>
            </TextField.Root>
          </Flex>
          <Separator color="gray" size="1" />
          <Flex className="zones-list-container" style={{ alignSelf: "stretch" }} display="flex" p="2" direction="column" justify="center" align="start" gap="2">
            <CheckboxGroup.Root
              size="2"
              variant="surface"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                alignSelf: "stretch",
                gap: "0px",
              }}
              value={selectedValues}
              onValueChange={(newValues) => {
                setSelectedValues(newValues);
                onValueChange(newValues);
                updateDisplayAndCheckboxes(newValues);
              }}
            >
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <Flex
                    key={option.value}
                    display="flex"
                    style={{
                      alignSelf: "stretch",
                      maxWidth: "100%",
                      height: "var(--space-6)",
                      padding: "0 var(--space-3)",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <CheckboxGroup.Item value={option.value} style={{ cursor: "pointer" }} />
                    <span
                      style={{
                        fontSize: "var(--font-size-2)",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "280px",
                        display: "inline-block",
                        lineHeight: "var(--line-height-2)",
                        color: "var(--gray-12)",
                      }}
                    >
                      {option.label}
                    </span>
                  </Flex>
                ))
              ) : (
                <Text px="3">No Zones Found</Text>
              )}
            </CheckboxGroup.Root>
          </Flex>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
}
