import { PeoplePasserbyByDayChart } from "../components/charts/presence/PeoplePasserbyByDayChart";
import { PeoplePasserbyByTimeOfDayChart } from "../components/charts/presence/PeoplePasserbyByTimeOfDayChart";
import { PeoplePasserbyByDayOfWeekChart } from "../components/charts/presence/PeoplePasserbyByDayOfWeekChart";
import { PeoplePasserbyByMonthChart } from "../components/charts/presence/PeoplePasserbyByMonthChart";
import { DwellByDayChart } from "../components/charts/presence/DwellByDayChart";
import { DwellByTimeOfDayChart } from "../components/charts/presence/DwellByTimeOfDayChart";
import { DwellByDayOfWeekChart } from "../components/charts/presence/DwellByDayOfWeekChart";
import { DwellByMonthChart } from "../components/charts/presence/DwellByMonthChart";

export const presenceChartConfig = {
  presence: {
    timeOfDay: {
      text: "Est. guest & passerby by time of day",
      tooltip: "Breakdown of estimated people and passersby over time of day",
      ChartComponent: PeoplePasserbyByTimeOfDayChart,
    },
    day: {
      text: "Est. guest & passerby by day",
      tooltip: "Breakdown of estimated people and passersby by day",
      ChartComponent: PeoplePasserbyByDayChart,
    },
    dayOfWeek: {
      text: "Est. guest & passerby by day of week",
      tooltip: "Breakdown of estimated people and passersby by day of week",
      ChartComponent: PeoplePasserbyByDayOfWeekChart,
    },
    month: {
      text: "Est. guest & passerby by month",
      tooltip: "Breakdown of estimated people and passersby by month",
      ChartComponent: PeoplePasserbyByMonthChart,
    },
  },
  dwell: {
    timeOfDay: {
      text: "Avg. dwell by time of day",
      tooltip: "Breakdown of average dwell time over time of day",
      ChartComponent: DwellByTimeOfDayChart,
    },
    day: {
      text: "Avg. dwell by day",
      tooltip: "Breakdown of average dwell time by day",
      ChartComponent: DwellByDayChart,
    },
    dayOfWeek: {
      text: "Avg. dwell by day of week",
      tooltip: "Breakdown of average dwell time by day of week",
      ChartComponent: DwellByDayOfWeekChart,
    },
    month: {
      text: "Avg. dwell by month",
      tooltip: "Breakdown of average dwell time by month",
      ChartComponent: DwellByMonthChart,
    },
  },
};
