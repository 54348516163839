import { useState } from 'react';
import { attemptGetbChatResponse } from '../../api/bChatApi';

export const useGetbChatResponse = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getBotResponse = async (message, user_id, conversation_id) => {
        try {
            setLoading(true);
            setError(null);
            
            const response = await attemptGetbChatResponse(message, user_id, conversation_id);
            return response.data;
        } catch (err) {
            setError(err.message || 'Failed to get response');
            return null;
        } finally {
            setLoading(false);
        }
    };

    return {
        getBotResponse,
        loading,
        error
    };
};