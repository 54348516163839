import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { DateTime } from "luxon";
import { Badge } from "@radix-ui/themes";
import { useTheme } from "../../../context/ThemeContext";
import "../ChartColours.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const getCSSVariable = (variable) => getComputedStyle(document.documentElement).getPropertyValue(variable).trim();

export const PeoplePasserbyByDayOfWeekChart = ({ data, startTimeMilliseconds, endTimeMilliseconds }) => {
  const { appearance } = useTheme();
  const [fontColor, setFontColor] = useState("");
  const [gridColour, setGridColour] = useState("");
  const [chartPointsBgColour, setChartPointsBgColour] = useState("");
  const [chartContainerRef, setChartContainerRef] = useState(null);
  const [badgePosition, setBadgePosition] = useState({ left: 0, top: 0, display: "none" });
  const [chartGuestBorderColour, setChartGuestBorderColour] = useState("");
  const [chartGuestFillColour, setChartGuestFillColour] = useState("");
  const [chartPasserbyBorderColour, setChartPasserbyBorderColour] = useState("");
  const [chartPasserbyFillColour, setChartPasserbyFillColour] = useState("");

  useEffect(() => {
    const fontColourLight = getCSSVariable("--chart-font-colour-light");
    const fontColourDark = getCSSVariable("--chart-font-colour-dark");
    const gridColourLight = getCSSVariable("--chart-grid-colour-light");
    const gridColourDark = getCSSVariable("--chart-grid-colour-dark");
    const chartPointsBgColourLight = getCSSVariable("--chart-points-bg-colour-light");
    const chartPointsBgColourDark = getCSSVariable("--chart-points-bg-colour-dark");
    const guestBorderColour = getCSSVariable("--chart-guest-border-colour");
    const guestFillColour = getCSSVariable("--chart-guest-fill-colour");
    const passerbyBorderColour = getCSSVariable("--chart-passerby-border-colour");
    const passerbyFillColour = getCSSVariable("--chart-passerby-fill-colour");
    const passerbyFillColourLight = getCSSVariable("--chart-passerby-fill-colour-light");
    const guestFillColourLight = getCSSVariable("--chart-guest-fill-colour-light");
    const passerbyBorderColourLight = getCSSVariable("--chart-passerby-border-colour-light");
    const guestBorderColourLight = getCSSVariable("--chart-guest-border-colour-light");

    if (appearance === "dark") {
      setFontColor(fontColourDark);
      setGridColour(gridColourDark);
      setChartPointsBgColour(chartPointsBgColourDark);
      setChartPasserbyFillColour(passerbyFillColour);
      setChartGuestFillColour(guestFillColour);
      setChartGuestBorderColour(guestBorderColour);
      setChartPasserbyBorderColour(passerbyBorderColour);
    } else {
      setFontColor(fontColourLight);
      setGridColour(gridColourLight);
      setChartPointsBgColour(chartPointsBgColourLight);
      setChartPasserbyFillColour(passerbyFillColourLight);
      setChartGuestFillColour(guestFillColourLight);
      setChartGuestBorderColour(guestBorderColourLight);
      setChartPasserbyBorderColour(passerbyBorderColourLight);
    }
  }, [appearance]);

  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const today = new Date().getDay();
  const adjustedToday = today === 0 ? 6 : today - 1;
  const currentDayLabel = daysOfWeek[adjustedToday];

  const isCurrentDayInRange = () => {
    const endDate = DateTime.fromMillis(endTimeMilliseconds);
    const now = DateTime.now();
    return now.hasSame(endDate, "day");
  };

  // Process the data for the chart
  let processed_chart_data = Array.from({ length: 7 }, (_, day) => ({
    day,
    visitors: 0,
    passersby: 0,
    total: 0,
  }));

  if (data?.data?.chart_data?.day_of_week) {
    data.data.chart_data.day_of_week.forEach((entry) => {
      const dayIndex = daysOfWeek.indexOf(entry.time_period.substring(0, 3));
      if (dayIndex !== -1) {
        processed_chart_data[dayIndex] = {
          day: dayIndex,
          visitors: entry.visitors,
          passersby: entry.passersby,
          total: entry.visitors + entry.passersby,
        };
      }
    });
  }

  const hasNonZeroData = (data) => {
    return data.some((value) => value > 0);
  };

  const chartData = {
    labels: daysOfWeek.map((day, index) => (index === adjustedToday && isCurrentDayInRange() ? "" : day)),
    datasets: [
      {
        label: "Est. guests",
        data: processed_chart_data.map((entry) => entry.visitors),
        backgroundColor: chartGuestFillColour,
        borderColor: chartGuestBorderColour,
        borderWidth: 1,
        borderRadius: 4,
        hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry.visitors)),
      },
      {
        label: "Est. passersby",
        data: processed_chart_data.map((entry) => entry.passersby),
        backgroundColor: chartPasserbyFillColour,
        borderColor: chartPasserbyBorderColour,
        borderWidth: 0,
        borderRadius: 4,
        borderDash: [5, 5],
        hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry.passersby)),
      },
    ],
  };

  const dashedBorderPlugin = {
    id: "dashedBorder",
    beforeDatasetsDraw: (chart) => {
      const { ctx } = chart;

      chart.data.datasets.forEach((dataset, datasetIndex) => {
        if (dataset.borderDash && dataset.borderDash.length > 0) {
          const meta = chart.getDatasetMeta(datasetIndex);
          if (!meta.hidden) {
            ctx.save();

            // Match the border style of the line chart
            ctx.strokeStyle = dataset.borderColor;
            ctx.lineWidth = 1;
            ctx.setLineDash(dataset.borderDash);

            meta.data.forEach((bar, index) => {
              // Only draw borders for bars with actual data
              if (dataset.data[index] > 0) {
                const { x, y, width, height, base } = bar.getProps(["x", "y", "width", "height", "base"]);
                const radius = dataset.borderRadius || 0;

                // Draw top edge with rounded corners
                ctx.beginPath();
                ctx.moveTo(x - width / 2 + radius, y);
                ctx.lineTo(x + width / 2 - radius, y);
                ctx.arcTo(x + width / 2, y, x + width / 2, y + radius, radius);
                ctx.stroke();

                // Draw right edge (straight line)
                ctx.beginPath();
                ctx.moveTo(x + width / 2, y + radius);
                ctx.lineTo(x + width / 2, base);
                ctx.stroke();

                // Draw left edge (straight line)
                ctx.beginPath();
                ctx.moveTo(x - width / 2, y + radius);
                ctx.lineTo(x - width / 2, base);
                ctx.stroke();

                // Top left corner arc
                ctx.beginPath();
                ctx.arc(x - width / 2 + radius, y + radius, radius, Math.PI, Math.PI * 1.5);
                ctx.stroke();
              }
            });

            ctx.restore();
            ctx.setLineDash([]);
          }
        }
      });
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      onComplete: function (animation) {
        if (chartContainerRef) {
          const chart = animation.chart;
          const xAxis = chart.scales.x;
          const currentDayX = xAxis.getPixelForValue(adjustedToday);
          const currentDayY = xAxis.top + xAxis.options.ticks.padding;

          setBadgePosition({
            left: currentDayX,
            top: currentDayY - 6,
            display: isCurrentDayInRange() ? "block" : "none",
          });
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        usePointStyle: true,
        itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
        boxWidth: 6,
        callbacks: {
          title: function (tooltipItems) {
            return tooltipItems[0].label || currentDayLabel;
          },
          label: function (context) {
            const dataPoint = processed_chart_data[context.dataIndex];
            const total = dataPoint.total;
            const percentage = total > 0 ? ((context.parsed.y / total) * 100).toFixed(1) : 0;
            return `${context.dataset.label}: ${percentage}% (${context.parsed.y})`;
          },
          afterBody: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            const total = processed_chart_data[index].total;
            return [`Total: ${total}`];
          },
          labelColor: function (context) {
            return {
              borderColor: context.dataset.backgroundColor,
              backgroundColor: context.dataset.backgroundColor,
            };
          },
          labelPointStyle: function (context) {
            return {
              pointStyle: "circle",
              rotation: 0,
            };
          },
        },
        bodyFont: {
          family: "Figtree",
        },
        titleFont: {
          family: "Figtree",
        },
      },
      dashedBorder: true,
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: fontColor,
          font: {
            family: "Figtree",
            size: 12,
          },
          padding: 8,
          maxRotation: 0,
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        position: "right",
        ticks: {
          color: fontColor,
          font: {
            family: "Figtree",
            size: 12,
          },
          padding: 8,
          stepSize: 1, // Force integer steps
          callback: function (value) {
            if (Number.isInteger(value)) {
              return value;
            }
            return null; // Hide non-integer ticks
          },
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        },
      },
    },
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }} ref={setChartContainerRef}>
      <Bar data={chartData} options={options} plugins={[dashedBorderPlugin]} style={{ width: "100%", height: "100%" }} />
      <div
        style={{
          position: "absolute",
          left: `${badgePosition.left}px`,
          top: `${badgePosition.top}px`,
          transform: "translate(-50%, 0)",
          display: badgePosition.display,
          pointerEvents: "none",
          zIndex: 10,
        }}
      >
        <Badge variant="soft" size="1">
          {currentDayLabel}
        </Badge>
      </div>
    </div>
  );
};
