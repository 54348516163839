import { useMutation} from "@tanstack/react-query";
import { attemptChangeEmailVerify } from '../../api/AuthApi';
import { toast } from 'react-toastify'; 

export const useVerifyChangeEmailRequest = () => {
    return useMutation({
        mutationKey: ['change-email-verify'],
        mutationFn: async (values) => {
            try {
                const res = await attemptChangeEmailVerify(values);
                return res.data;
            } catch (error) {
                console.error("Mutation Error:", error);
                throw error;
            }
        },
        onSuccess: () => {
            toast.success("Email change request success")
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to verify email")
        }
    });
};
