import { useState, useEffect, lazy, Suspense } from 'react';
import { Flex, Text, Button } from '@radix-ui/themes';
import { MenuItem } from 'components';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useProfileContext } from "context";

const CreateLocationModal = lazy(() => import('pages/organisation/CreateLocationModal'));
const InviteUserModal = lazy(() => import('pages/organisation/InviteUserModal'));

export function OrganisationLayout() {
  
  const navigate = useNavigate();
  const location = useLocation();
  const { userProfile } = useProfileContext();
  const [ organisationName, setOrganisationName ] = useState(userProfile.organisation_name);

  // Helper function to get menu state from path
  const getMenuStateFromPath = (path) => {
    if (path.includes('/organisation/locations')) return 'locations';
    if (path.includes('/organisation/team')) return 'team';
    if (path.includes('/organisation/details')) return 'details';
    return 'locations'; // default path
  }

  // State to manage menu selection
  const [wifiMenuSelection, setWifiMenuSelection] = useState(
    getMenuStateFromPath(location.pathname)
  );

  // Function to handle menu selection
  const handleWifiMenuSelection = (flag) => {
    setWifiMenuSelection(flag);
    navigate(flag);
  }

  // Update menu selection on path change
  useEffect(() => {
    setWifiMenuSelection(getMenuStateFromPath(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    setOrganisationName(userProfile.organisation_name);
  }, [userProfile.organisation_name]);

  return (
    <Flex className="organisation-layout-container" direction="column" height="100%"
      style={{alignItems: 'flex-start', flex: '1 0 0', alignSelf: 'stretch'}}>
      <Flex className="organisation-layout" p="8" direction="column" gap="8" height="100%" 
        style={{alignSelf: "stretch", alignItems: 'flex-start', flex: '1 0 0'}}>
        <Flex className="header-container" style={{alignSelf: "stretch"}} justify="center" align="end" gap="2" >
          <Flex gap="2" className="header-content" justify="between" style={{flex: '1 0 0', alignItems: 'center', alignSelf: 'stretch'}}>
            <Flex direction="column" gap="2" style={{alignItems: 'flex-start', flex: '1 0 0', alignSelf: 'stretch'}}>
              <Text size="2" weight="regular" color="gray">Organisation</Text>
              <Text size="6" weight="bold">{organisationName}</Text>
            </Flex>
            <Flex direction="column" style={{height: '100%', alignItems: 'end', justifyContent: 'end'}}>
              <Flex gap="4" style={{alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center'}}>
                <Suspense>
                  <CreateLocationModal buttontype="ghost"/>
                </Suspense>
                <Suspense>
                  <InviteUserModal />
                </Suspense>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex className="main-content-container" height="100%" gap="8" 
          style={{alignSelf: "stretch", flex: '1 0 0', alignItems: 'flex-start'}}> 
          <Flex className="main-content-sidebar-menu" width="144px" direction="column" gap="1" height="100%" 
            style={{alignItems: 'flex-start'}}>
            <MenuItem flag="locations" state={wifiMenuSelection} onClick={handleWifiMenuSelection} text="Locations" />
            <MenuItem flag="team" state={wifiMenuSelection} onClick={handleWifiMenuSelection} text="Team" />
            <MenuItem flag="details" state={wifiMenuSelection} onClick={handleWifiMenuSelection} text="Details" />
          </Flex>
          <Flex style={{alignSelf: 'stretch', flex: '1 0 0', alignItems: 'flex-start', height: '100%'}}>
            <Outlet />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}