import { useMutation } from "@tanstack/react-query";
import { syncGuestDataWithMailchimp } from '../../api/bIntegrationsApi';

export const useSyncGuestDataWithMailchimp = () => {
  return useMutation({
    mutationKey: ['sync-mailchimp-guests'],
    mutationFn: async ({ audienceId, ...data }) => {
      try {
        const response = await syncGuestDataWithMailchimp(audienceId, data);
        return response.data;
      } catch (error) {
        console.error('API Error:', error);
        throw error;
      }
    }
  });
};