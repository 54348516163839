import instance from "./BaseApi";

export function attemptGetOrgUsers() {
  return instance.request({
    url: `organisations/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptInviteOrg(data) {
  return instance.request({
    url: `organisation/${data?.organisation}/invite/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
}

export function attemptGetOrgDetails(id) {
  return instance.request({
    url: `organisations/${id}/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptDeleteOrg(id) {
  return instance.request({
    url: `organisations/${id}/`,
    method: "DELETE",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptCreateOrg(data) {
  return instance.request({
    url: `organisations/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptUpdateOrg(parmas) {
  const data = {
    name: parmas?.name,
    email: parmas?.email,
    type: parmas?.type,
    status: parmas?.status,
  };
  return instance.request({
    url: `organisations/${parmas?.id}/`,
    method: "PATCH",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptGetOrganisationDetails(orgID) {
  return instance.request({
    url: `organisations/${orgID}/details/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptCreateOrganisationDetails(orgID, data) {
  return instance.request({
    url: `organisations/${orgID}/details/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attempUpdateOrganisationDetails(orgID, data) {
  return instance.request({
    url: `organisations/${orgID}/details/`,
    method: "PATCH",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptGetOrgLocations(id) {
  return instance.request({
    url: `organisations/${id}/locations/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptGetOrgLocationsByUser(userId) {
  return instance.request({
    url: `user/${userId}/organisation/locations/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptGetOrgLocationsMetrics(data, metricType) {
  return instance.request({
    url: `dashboard/connection-metrics/${metricType}/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptGetLiveConnectionStats(data) {
  return instance.request({
    url: `dashboard/live-connection-metrics/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptGetLocations(id) {
  return instance.request({
    url: `locations/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptCreateOrgLocations(data) {
  return instance.request({
    url: `locations/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptDeleteOrgLocations(data) {
  return instance.request({
    url: `locations/${data.id}/`,
    method: "DELETE",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptEditOrgLocations(data) {
  const editData = {
    name: data.name,
    address: data.address,
    location_type: data.location_type,
  };

  return instance.request({
    url: `locations/${data.id}/`,
    method: "PATCH",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: editData,
  });
}

export function attemptOrgUsers(id) {
  return instance.request({
    url: `users/organisation/${id}/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptEditOrgUser(owner_id, data) {
  return instance.request({
    url: `users/organisation/${owner_id}/update/`,
    method: "PUT",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptDeleteOrgUser(owner_id, data) {
  return instance.request({
    url: `users/organisation/${owner_id}/delete/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptGetLiveAccessPointsStatus(data) {
  return instance.request({
    url: `guest-wifi-settings/access-points/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptGetWirelessLAN(orgID) {
  return instance.request({
    url: `guest-wifi-settings/networks/organisation/${orgID}`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptUpdateWirelessLAN(orgID, data) {
  return instance.request({
    url: `guest-wifi-settings/networks/organisation/${orgID}`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function attemptGetGuestPortalSettings(orgID) {
  return instance.request({
    url: `guest-wifi-settings/guest-portal/organisation/${orgID}`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptUpdateGuestPortalSettings(orgID, data) {
  return instance.request({
    url: `guest-wifi-settings/guest-portal/organisation/${orgID}`,
    method: "PATCH",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
}

export function attemptGetGuestPortalSettingsBanner(guestPortalID) {
  return instance.request({
    url: `guest-wifi-settings/guest-portal/${guestPortalID}/banner/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptCreateGuestPortalSettingsBanner(guestPortalID, data) {
  return instance.request({
    url: `guest-wifi-settings/guest-portal/${guestPortalID}/banner/`,
    method: "POST",
    withCredentials: false,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
}

export function attemptUpdateGuestPortalSettingsBanner(guestPortalID, data) {
  return instance.request({
    url: `guest-wifi-settings/guest-portal/${guestPortalID}/banner/`,
    method: "PATCH",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
}

export function attemptGetGuestPortalSettingsLinks(guestPortalID) {
  return instance.request({
    url: `guest-wifi-settings/guest-portal/${guestPortalID}/links/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function attemptCreateGuestPortalSettingsLinks(guestPortalID, data) {
  return instance.request({
    url: `guest-wifi-settings/guest-portal/${guestPortalID}/links/`,
    method: "POST",
    withCredentials: false,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
}

export function attemptUpdateGuestPortalSettingsLinks(guestPortalID, data) {
  return instance.request({
    url: `guest-wifi-settings/guest-portal/${guestPortalID}/links/`,
    method: "PATCH",
    withCredentials: false,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
}

export function attemptDeleteGuestPortalSettingsLinks(guestPortalID, linkID) {
  return instance.request({
    url: `guest-wifi-settings/guest-portal/${guestPortalID}/links/`,
    method: "DELETE",
    withCredentials: false,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      id: linkID
    },
  });
}

export function attemptGetZones(data) {
  return instance.request({
    url: "zones/list/",
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: {
      location_ids: data,
    },
  });
}
