// ChartConfig.js (you can put this inline or in a separate file)
import { GuestConnectionsByTimeOfDayChart } from '../components/charts/GuestConnectionsByTimeOfDayChart';
import { GuestConnectionsByDayChart } from '../components/charts/GuestConnectionsByDayChart';
import { GuestConnectionsByDayOfWeekChart } from '../components/charts/GuestConnectionsByDayOfWeekChart';
import { GuestConnectionsByMonthChart } from '../components/charts/GuestConnectionsByMonthChart';

export const chartConfig = {
  timeOfDay: {
    text: "Guest connections by time of day",
    tooltip: "Breakdown of guest connections over time of day.",
    ChartComponent: GuestConnectionsByTimeOfDayChart,
  },
  day: {
    text: "Guest connections by day",
    tooltip: "Breakdown of guest connections by day.",
    ChartComponent: GuestConnectionsByDayChart,
  },
  dayOfWeek: {
    text: "Guest connections by day of the week",
    tooltip: "Breakdown of guest connections over day of week.",
    ChartComponent: GuestConnectionsByDayOfWeekChart,
  },
  month: {
    text: "Guest connections by month",
    tooltip: "Breakdown of guest connections by month.",
    ChartComponent: GuestConnectionsByMonthChart,
  },
};
