import React, { useEffect, useState, lazy, Suspense, useRef } from 'react';
import { Flex, Separator, Text, Button, SegmentedControl, Link, Tooltip, TextField, Tabs, CheckboxGroup,
    TextArea, Checkbox, Popover, IconButton, RadioGroup
 } from '@radix-ui/themes';
import * as Form from "@radix-ui/react-form";
import { UploadIcon, InfoCircledIcon, ExclamationTriangleIcon, PlusIcon, Cross1Icon } from '@radix-ui/react-icons';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/16/solid';
import { IPhoneMockup } from 'react-device-mockup';
import { debounce } from 'lodash';
import { useGetOrgGuestPortalSettings, useUpdateOrgGuestPortalSettings, useImageUpload,
    useGetActiveIntegrations, useGetOrgGuestPortalSettingsBanner, useUpdateOrgGuestPortalSettingsBanner,
    useGetOrgGuestPortalSettingsLinks, useUpdateOrgGuestPortalSettingsLinks, useCreateOrgGuestPortalSettingsBanner,
    useCreateOrgGuestPortalSettingsLinks, useDeleteOrgGuestPortalSettingsLinks, useGetAirshipStatus,
    useGetOrganisationDetails
  } from 'hooks';
import { useToast } from '../../context/ToastContext';
import { ToastAlert } from "components";
import { useProfileContext } from "context";
import { useNavigate } from "react-router-dom";

const CaptivePortalMockup = lazy(() => import('./CaptivePortalMockup'));

// File size validation helper
const validateFileSize = (file) => {
    const maxSizeInBytes = 1048576; // 1MB
    if (file && file.size > maxSizeInBytes) {
        return false;
    }
    return true;
};

const GuestPortal = ( ) => {
    const navigate = useNavigate();
    const { userProfile } = useProfileContext();
    const organisationID = userProfile.organisation_id;
    const organisationName = userProfile.organisation_name;
    const { data: activeIntegrationsData } = useGetActiveIntegrations(organisationID);
    const { data: orgDetails } = useGetOrganisationDetails(userProfile.organisation_id);
    
    const { data: airshipStatusData, isLoading: isStatusLoading } = useGetAirshipStatus(userProfile.organisation_id);
    const isAirshipActive = () => {
        if (isStatusLoading) return false;
        return airshipStatusData?.data?.is_active === true;
    };

    const { setToastMessage } = useToast();
    const [view, setView] = useState('mobile');
    const [screenWidthMobile, setScreenWidthMobile] = useState(250); // Default screenWidth for IPhoneMockup
    const [scaleFactorMobile, setScaleFactorMobile] = useState(1); // Default scale factor for IPhoneMockup
    const [isSaveChangesLoading, setIsSaveChangesLoading] = useState(false);

    // Mobile view calculations for IPhoneMockup
    useEffect(() => {
        const handleResize = debounce(() => {
            const windowHeight = window.innerHeight;
            const windowWidth = window.innerWidth;

            // Mobile view calculations
            const aspectRatio = 19.5 / 9;
            const maxDesiredWidth = 390; // The maximum desired screenWidth for IPhoneMockup
            const offset = 250;
            const availableHeight = windowHeight - offset;

            const calculatedScreenWidth = Math.min(
                maxDesiredWidth,
                availableHeight / aspectRatio,
                windowWidth // Ensure it doesn't exceed the viewport width
            );
            const calculatedScaleFactor = calculatedScreenWidth / maxDesiredWidth;

            setScreenWidthMobile(calculatedScreenWidth);
            setScaleFactorMobile(calculatedScaleFactor);
        }, 100);

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    },[]);

    const { data: guestPortalSettings } = useGetOrgGuestPortalSettings(organisationID);
    const { data: guestPortalSettingsBanner } = useGetOrgGuestPortalSettingsBanner(guestPortalSettings?.data?.id);
    const { data: guestPortalSettingsLinks } = useGetOrgGuestPortalSettingsLinks(guestPortalSettings?.data?.id);
    
    // Functions to update the guest portal settings, banner and links
    const { mutate: updateGuestPortalSettings } = useUpdateOrgGuestPortalSettings();
    const { mutate: createGuestPortalSettingsBanner } = useCreateOrgGuestPortalSettingsBanner();
    const { mutate: updateGuestPortalSettingsBanner } = useUpdateOrgGuestPortalSettingsBanner();
    const { mutate: createGuestPortalSettingsLinks } = useCreateOrgGuestPortalSettingsLinks();
    const { mutate: updateGuestPortalSettingsLinks } = useUpdateOrgGuestPortalSettingsLinks();
    const { mutate: deleteGuestPortalSettingsLinks } = useDeleteOrgGuestPortalSettingsLinks();

    const [ activeTab, setActiveTab ] = useState('portal');

    const [ emailFlag, setEmailFlag ] = useState(null);
    const [ ageFlag, setAgeFlag ] = useState(null);
    const [ genderFlag, setGenderFlag ] = useState(null);
    const [ dobFlag, setDobFlag ] = useState(null);

    const handleCpFormFieldsChange = (values) => {
        setEmailFlag(values.includes('email'));
        setAgeFlag(values.includes('age'));
        setGenderFlag(values.includes('gender'));
    };

    const [logoUpload, setLogoUpload] = useState(null); // Logo image upload
    const [logoPreview, setLogoPreview] = useState(null); // Logo image URL for preview
    const [logoFileName, setLogoFileName] = useState(''); // Logo image file name
    const logoInputRef = useRef(null);

    const [backgroundImageUpload, setBackgroundImageUpload] = useState(null); // Background image upload
    const [backgroundPreview, setBackgroundPreview] = useState(null); // Background image URL for preview
    const [backgroundFileName, setBackgroundFileName] = useState(''); // Background image file name
    const backgroundInputRef = useRef(null);

    function colorConversion(inputColour, opacity) {
        const alpha = Math.round(opacity * 2.55).toString(16).padStart(2, '0').toUpperCase();
        return `${inputColour}${alpha}`;
    }

    const [backgroundOverlayColor, setBackgroundOverlayColor] = useState('#000000'); // Default Background overlay color
    const [backgroundOverlayOpacity, setBackgroundOverlayOpacity] = useState(50); // Default Background overlay opacity

    useEffect(() => {
        colorConversion(backgroundOverlayColor, backgroundOverlayOpacity);
    }, [backgroundOverlayColor, backgroundOverlayOpacity]);

    const [buttonColor, setButtonColor] = useState('#3807D9');
    function getButtonTextColorBasedOnBackground(buttonBackgroundColor) {
        buttonBackgroundColor = buttonBackgroundColor.replace('#', '');
        // Converting the hex color to RGB
        const r = parseInt(buttonBackgroundColor.substring(0, 2), 16) / 255;
        const g = parseInt(buttonBackgroundColor.substring(2, 4), 16) / 255;
        const b = parseInt(buttonBackgroundColor.substring(4, 6), 16) / 255;
    
        // Calculating luminance using the formula
        const luminance = 0.2126 * (r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4)) +
                          0.7152 * (g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4)) +
                          0.0722 * (b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4));
    
        // If luminance is higher than 0.5, using black text; otherwise, white text
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    }

    const [buttonTextColor, setButtonTextColor] = useState(getButtonTextColorBasedOnBackground(buttonColor));
    useEffect(() => {
        setButtonTextColor(getButtonTextColorBasedOnBackground(buttonColor));
    }, [buttonColor]);

    const [deleteLogo, setDeleteLogo] = useState(false);
    const [deleteBackgroundImage, setDeleteBackgroundImage] = useState(false);

    const [ message, setMessage ] = useState('');
    const [ privacyPolicy, setPrivacyPolicy] = useState('');
    const [ redirectUrl, setRedirectUrl ] = useState('');

    const [ segmentView, setSegmentView ] = useState('page');

    const [ showLinks, setShowLinks ] = useState(false); // Link flag
    const [ links, setLinks ] = useState([]);
    const [ isLinksDirty, setIsLinksDirty ] = useState(false);
    const [ deletedLinks, setDeletedLinks] = useState([]);
    const [ currentEditingLink, setCurrentEditingLink] = useState(null);
    const [ isAddingLink, setIsAddingLink] = useState(false);
    const linkIconUpload = useImageUpload(null, null);
    const [ linkIconUploadFailed, setLinkIconUploadFailed] = useState(false);

    // Open link editor - works for both new and existing links
    const openLinkEditor = (link, index) => {
        if (link) {
            // Editing existing link
            setCurrentEditingLink({ ...link, index });
            setIsAddingLink(false);
            
            // Set the icon preview if it exists
            if (link.icon) {
                linkIconUpload.setExistingImage(link.icon, link.icon_filename || '');
            } else {
                linkIconUpload.handleClear();
            }
        } else {
            // Adding new link
            setCurrentEditingLink({ 
                label: '', 
                link_url: '', 
                icon: null, 
                icon_filename: null 
            });
            setIsAddingLink(true);
            linkIconUpload.handleClear();
        }
    };

    // Close link editor and reset state
    const closeLinkEditor = () => {
        setCurrentEditingLink(null);
        setIsAddingLink(false);
        linkIconUpload.handleClear();
    };

    // Save link changes
    const saveLinkChanges = () => {
        if (isAddingLink) {
            // Add new link
            const newLink = {
                ...currentEditingLink,
                icon: linkIconUpload.preview,
                icon_filename: linkIconUpload.fileName,
                icon_file: linkIconUpload.file
            };
            setLinks([...links, newLink]);
            setIsLinksDirty(true);
        } else {
            // Update existing link
            const updatedLinks = [...links];
            updatedLinks[currentEditingLink.index] = {
                ...currentEditingLink,
                icon: linkIconUpload.preview || currentEditingLink.icon,
                icon_filename: linkIconUpload.fileName || currentEditingLink.icon_filename,
                icon_file: linkIconUpload.file
            };
            setLinks(updatedLinks);
            setIsLinksDirty(true);
        }
        closeLinkEditor();
    };

    // Handle link removal
    const handleLinkRemove = (index) => {
        if (links[index].id) {
            setDeletedLinks([...deletedLinks, links[index].id]);
        }
        const updatedLinks = links.filter((_, i) => i !== index);
        setLinks(updatedLinks);
        setIsLinksDirty(true);
        closeLinkEditor();
    };

    // Update current editing link field
    const updateLinkField = (field, value) => {
        setCurrentEditingLink({
            ...currentEditingLink,
            [field]: value
        });
    };
    
    const [ showBanner, setShowBanner ] = useState(false); // Banner flag
    const [ bannerTitle, setBannerTitle ] = useState('');
    const [ bannerDescription, setBannerDescription ] = useState('');
    const [ bannerLinkURL, setBannerLinkURL ] = useState('');
    const bannerImage = useImageUpload(
        guestPortalSettingsBanner?.data?.image || null,
        guestPortalSettingsBanner?.data?.image_filename || ''
    );

    const [ privacyType, setPrivacyType ] = useState('auto'); // Default to 'auto' for privacy type
    const [landingType, setLandingType] = useState('page'); // Default to 'page' for landing type

    // Helper function to add https:// to a URL if it doesn't have it
    const addHttpsIfNeeded = (url) => {
        if (url && !url.match(/^https?:\/\//)) {
            return `https://${url}`;
        }
        return url;
    };
    
    // Fetching the Guest Portal settings
    useEffect(() => {
        if (guestPortalSettings?.data) {
            // Setting the initial values for the form fields
            setLogoPreview(guestPortalSettings.data.logo);
            setLogoFileName(guestPortalSettings.data.logo_filename);            
            setBackgroundPreview(guestPortalSettings.data.background_image);
            setBackgroundFileName(guestPortalSettings.data.background_image_filename);
            setBackgroundOverlayColor(guestPortalSettings.data.background_overlay_colour);
            setBackgroundOverlayOpacity(guestPortalSettings.data.background_overlay_opacity * 100);
            setButtonColor(guestPortalSettings.data.button_colour);
            setButtonTextColor(guestPortalSettings.data.button_text_colour);
            setMessage(guestPortalSettings.data.message);
            setPrivacyPolicy(guestPortalSettings.data.privacy_policy);
            setRedirectUrl(guestPortalSettings.data.redirect_url);
            setEmailFlag(guestPortalSettings.data.email_flag);
            setAgeFlag(guestPortalSettings.data.age_flag);
            setDobFlag(guestPortalSettings.data.dob_flag);
            setGenderFlag(guestPortalSettings.data.gender_flag);
            setShowLinks(guestPortalSettings.data.links_flag);
            setShowBanner(guestPortalSettings.data.banner_flag);
            setLandingType(guestPortalSettings.data.landing_type || 'page');
            setSegmentView(guestPortalSettings.data.landing_type || 'page');
            setPrivacyType(guestPortalSettings.data.privacy_type || 'auto');
            // Setting the existing values for the banner if it exists
            if (guestPortalSettingsBanner?.data) {
                setBannerTitle(guestPortalSettingsBanner.data.title);
                setBannerDescription(guestPortalSettingsBanner.data.description);
                setBannerLinkURL(guestPortalSettingsBanner.data.banner_url);
            }
            // Setting the existing values for the links if they exist
            if (guestPortalSettingsLinks?.data) {
                setLinks(guestPortalSettingsLinks.data);
            }
            resetForm();
        }
    }, [guestPortalSettings?.data, guestPortalSettingsBanner?.data, guestPortalSettingsLinks?.data]);

    // Handling Logo image upload
    const handleLogoUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        
        if (!validateFileSize(file)) {
            setToastMessage('Maximum file size of 1MB');
            return;
        }
        
        setLogoUpload(file);
        setLogoFileName(file.name);
    
        const reader = new FileReader();
        reader.onloadend = () => {
            setLogoPreview(reader.result);
        };
        reader.readAsDataURL(file);
        setToastMessage('Uploaded');
        setDeleteLogo(false);
    };

    const handleLogoClear = () => {
        setLogoUpload(null);
        setLogoPreview(null);
        setLogoFileName('');
        if (logoInputRef.current) {
            logoInputRef.current.value = '';
        }
        setDeleteLogo(true);
    };

    // Triggering the hidden file input click for logo
    const triggerLogoInput = () => {
        if (logoInputRef.current) {
            logoInputRef.current.click();
        }
    };

    // Handling Background image upload
    const handleBackgroundImageUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        
        if (!validateFileSize(file)) {
            setToastMessage('Maximum file size of 1MB');
            return;
        }
    
        setBackgroundImageUpload(file);
        setBackgroundFileName(file.name);
    
        const reader = new FileReader();
        reader.onloadend = () => {
            setBackgroundPreview(reader.result);
        };
        reader.readAsDataURL(file);
        setToastMessage('Uploaded');
        setDeleteBackgroundImage(false);
    };

    const handleBackgroundImageClear = () => {
        setBackgroundImageUpload(null);
        setBackgroundPreview(null);
        setBackgroundFileName('');
        if (backgroundInputRef.current) {
            backgroundInputRef.current.value = '';
        }
        setDeleteBackgroundImage(true); 
    };

    // Triggering the hidden file input click for background image
    const triggerBackgroundInput = () => {
        if (backgroundInputRef.current) {
            backgroundInputRef.current.click();
        }
    };

    // Resetting the form
    const resetForm = () => {
        setLogoUpload(null);
        setLogoPreview(guestPortalSettings.data.logo);
        setLogoFileName(guestPortalSettings.data.logo_filename);
        if (logoInputRef.current) {
            logoInputRef.current.value = '';
        }
        setBackgroundImageUpload(null);
        setBackgroundPreview(guestPortalSettings.data.background_image);
        setBackgroundFileName(guestPortalSettings.data.background_image_filename);
        if (backgroundInputRef.current) {
            backgroundInputRef.current.value = '';
        }
        setBackgroundOverlayColor(guestPortalSettings.data.background_overlay_colour);
        setBackgroundOverlayOpacity(guestPortalSettings.data.background_overlay_opacity * 100);
        setButtonColor(guestPortalSettings.data.button_colour);
        setButtonTextColor(getButtonTextColorBasedOnBackground(guestPortalSettings.data.button_colour));
        setDeleteLogo(false);
        setDeleteBackgroundImage(false);
        setMessage(guestPortalSettings.data.message);
        setPrivacyPolicy(guestPortalSettings.data.privacy_policy);
        setRedirectUrl(guestPortalSettings.data.redirect_url);
        setEmailFlag(guestPortalSettings.data.email_flag);
        setAgeFlag(guestPortalSettings.data.age_flag);
        setDobFlag(guestPortalSettings.data.dob_flag);
        setGenderFlag(guestPortalSettings.data.gender_flag);
        setShowLinks(guestPortalSettings.data.links_flag);
        setShowBanner(guestPortalSettings.data.banner_flag);
        if (guestPortalSettingsBanner?.data) {
            setBannerTitle(guestPortalSettingsBanner.data.title);
            setBannerDescription(guestPortalSettingsBanner.data.description);
            setBannerLinkURL(guestPortalSettingsBanner.data.banner_url);
            bannerImage.reset();
        }
        if (guestPortalSettingsLinks?.data) {
            setLinks(guestPortalSettingsLinks.data);
        }
        setDeletedLinks([]);
        setIsLinksDirty(false);
        setIsDirty(false);
    };

    // Submitting the form
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData();

        try {
            setIsSaveChangesLoading(true);

            if (logoUpload) {data.append('logo', logoUpload);}
            if (backgroundImageUpload) {data.append('background_image', backgroundImageUpload);}
            if (backgroundOverlayColor !== guestPortalSettings.data.background_overlay_colour) {
                data.append('background_overlay_colour', backgroundOverlayColor);
            }
            if (backgroundOverlayOpacity !== guestPortalSettings.data.background_overlay_opacity) {
                data.append('background_overlay_opacity', backgroundOverlayOpacity/100);
            }
            if (buttonColor !== guestPortalSettings.data.button_colour) {
                data.append('button_colour', buttonColor);
            }
            if (buttonTextColor !== guestPortalSettings.data.button_text_colour) {
                data.append('button_text_colour', buttonTextColor);
            }
            if (deleteLogo) {data.append('delete_logo', true);}
            if (deleteBackgroundImage) {data.append('delete_background_image', true);}
            if (message !== guestPortalSettings.data.message) {data.append('message', message);}
            if (privacyPolicy !== guestPortalSettings.data.privacy_policy) {data.append('privacy_policy', privacyPolicy);}
            if (redirectUrl !== guestPortalSettings.data.redirect_url) {data.append('redirect_url', addHttpsIfNeeded(redirectUrl));}
            if (emailFlag !== guestPortalSettings.data.email_flag) {data.append('email_flag', emailFlag);}
            if (ageFlag !== guestPortalSettings.data.age_flag) {data.append('age_flag', ageFlag);}
            if (dobFlag !== guestPortalSettings.data.dob_flag) {data.append('dob_flag', dobFlag);}
            if (genderFlag !== guestPortalSettings.data.gender_flag) {data.append('gender_flag', genderFlag);}
            if (showLinks !== guestPortalSettings.data.links_flag) {data.append('links_flag', showLinks);}
            if (showBanner !== guestPortalSettings.data.banner_flag) {data.append('banner_flag', showBanner);}
            if (privacyType !== guestPortalSettings.data.privacy_type) {data.append('privacy_type', privacyType);}
            if (landingType !== guestPortalSettings.data.landing_type) {data.append('landing_type', landingType);}
            if (showBanner) {
                try {
                    if (guestPortalSettingsBanner?.data) {
                        updateGuestPortalSettingsBanner({ 
                            guestPortalID: guestPortalSettings.data.id, 
                            data: {
                                title: bannerTitle,
                                description: bannerDescription,
                                banner_url: addHttpsIfNeeded(bannerLinkURL),
                                image: bannerImage.file,
                                delete_image: bannerImage.isDeleted
                            }
                        });
                    } else {
                        createGuestPortalSettingsBanner({
                            guestPortalID: guestPortalSettings.data.id,
                            data: {
                                title: bannerTitle,
                                description: bannerDescription,
                                banner_url: addHttpsIfNeeded(bannerLinkURL),
                                image: bannerImage.file,
                                delete_image: false
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error updating banner:', error);
                    setToastMessage('Failed to update banner');
                    return;
                }
            }
            if (showLinks !== guestPortalSettings.data.links_flag) {data.append('links_flag', showLinks);}
            if (showLinks) {
                // Handle link deletions first
                for (const linkID of deletedLinks) {
                    await deleteGuestPortalSettingsLinks({
                        guestPortalID: guestPortalSettings.data.id,
                        linkID: linkID
                    });
                }
                
                // Handle existing links updates sequentially
                const existingLinks = links.filter(link => link.id);
                for (const link of existingLinks) {
                    const linksFormData = new FormData();
                    linksFormData.append('id', link.id);
                    linksFormData.append('label', link.label);
                    linksFormData.append('link_url', addHttpsIfNeeded(link.link_url));
                    if (link.icon_file) {
                        linksFormData.append('icon', link.icon_file);
                    }
                    await updateGuestPortalSettingsLinks({
                        guestPortalID: guestPortalSettings.data.id,
                        data: linksFormData
                    });
                }
                
                // Create new links without IDs sequentially
                const newLinks = links.filter(link => !link.id);
                for (const link of newLinks) {
                    const linkFormData = new FormData();
                    linkFormData.append('label', link.label);
                    linkFormData.append('link_url', addHttpsIfNeeded(link.link_url));
                    if (link.icon_file) {
                        linkFormData.append('icon', link.icon_file);
                    }
                    await createGuestPortalSettingsLinks({
                        guestPortalID: guestPortalSettings.data.id,
                        data: linkFormData
                    });
                }
            }
            
            await updateGuestPortalSettings({ orgID: organisationID, data });
            resetForm();
            setIsLinksDirty(false);
            setToastMessage('Changes saved');
        } catch (error) {
            console.error('Error saving changes:', error);
            setToastMessage('Failed to save changes');
        } finally {
            setIsSaveChangesLoading(false);
        }
    };

    const handleInputMessageChange = (value) => {
        setMessage(value);
    };

    const handlePrivacyPolicyChange = (value) => {
        setPrivacyPolicy(value);
    };

    const handleInputRedirectUrlChange = (value) => {
        setRedirectUrl(value);
    };

    // Check if there are any changes in the form fields
    const [isDirty, setIsDirty] = useState(false);
    useEffect(() => {
        const hasChanges = 
            logoUpload !== null || 
            backgroundImageUpload !== null ||
            backgroundOverlayColor !== guestPortalSettings?.data?.background_overlay_colour ||
            backgroundOverlayOpacity !== (guestPortalSettings?.data?.background_overlay_opacity * 100) ||
            buttonColor !== guestPortalSettings?.data?.button_colour ||
            deleteLogo ||
            deleteBackgroundImage ||
            message !== (guestPortalSettings?.data?.message) ||
            privacyPolicy !== (guestPortalSettings?.data?.privacy_policy) ||
            redirectUrl !== guestPortalSettings?.data?.redirect_url ||
            emailFlag !== guestPortalSettings?.data?.email_flag ||
            ageFlag !== guestPortalSettings?.data?.age_flag ||
            dobFlag !== guestPortalSettings?.data?.dob_flag ||
            genderFlag !== guestPortalSettings?.data?.gender_flag ||
            showLinks !== guestPortalSettings?.data?.links_flag ||
            showBanner !== guestPortalSettings?.data?.banner_flag ||
            privacyType !== guestPortalSettings?.data?.privacy_type ||
            landingType !== guestPortalSettings?.data?.landing_type ||
            bannerTitle !== guestPortalSettingsBanner?.data?.title ||
            bannerDescription !== guestPortalSettingsBanner?.data?.description ||
            bannerLinkURL !== guestPortalSettingsBanner?.data?.banner_url ||
            bannerImage.file !== null ||
            bannerImage.isDeleted ||
            isLinksDirty ||
            deletedLinks.length > 0 ||
            links.length !== guestPortalSettingsLinks?.data?.length ||
            JSON.stringify(links) !== JSON.stringify(guestPortalSettingsLinks?.data);
    
        setIsDirty(hasChanges);
    }, [
        logoUpload,
        backgroundImageUpload,
        backgroundOverlayColor,
        backgroundOverlayOpacity,
        buttonColor,
        deleteLogo,
        deleteBackgroundImage,
        message,
        privacyPolicy,
        redirectUrl,
        emailFlag,
        ageFlag,
        dobFlag,
        genderFlag,
        showLinks,
        showBanner,
        privacyType,
        landingType,
        bannerTitle,
        bannerDescription,
        bannerLinkURL,
        bannerImage.file,
        bannerImage.isDeleted,
        guestPortalSettings?.data,
        guestPortalSettingsBanner?.data,
        guestPortalSettingsLinks?.data,
        links,
        isLinksDirty,
        deletedLinks,
    ]);

  return (
    <React.Fragment>
        {/* No active integrations to make enable portal settings */}
        { activeIntegrationsData?.data?.integrations.length === 0 ? 
        (<Flex className="nointegrations-container" direction="column" style={{justifyContent: 'center', 
            alignItems: 'center', flex: '1 0 0', alignSelf: 'stretch', height: '100%',
            border: '1px dashed var(--gray-a6)', borderRadius: 'var(--radius-4)'}}>
            <Flex direction="column" gap="5" maxWidth="296px" style={{alignItems: 'center', width: '100%'}}>
                <Text size="2" weight="regular" align="center" color='gray'>
                    To edit your Wi-Fi portal you must connect a Wi-Fi integration.
                </Text>
                <Button size="2" variant="soft" color="gray"
                    onClick={() => navigate('/integrations/active')}>
                    View integrations
                </Button>
            </Flex>
        </Flex>
        ) : (
        // Active integrations exist so enable portal settings
        <Flex gap="7" style={{alignSelf: 'stretch', width: '100%', height: '100%', alignItems: 'center', flex: '1 0 0'}}>
            {/* Mockup Preview Section */}
            <Flex gap="5" direction="column" style={{alignItems: 'center', alignSelf: 'stretch', flex: '1 0 0'}}>
                <SegmentedControl.Root size="1" variant='surface' defaultValue="mobile" onValueChange={setView}>
                    <SegmentedControl.Item value="mobile">Mobile</SegmentedControl.Item>
                    <SegmentedControl.Item value="desktop">Desktop</SegmentedControl.Item>
                </SegmentedControl.Root>
                {/* Mobile / Desktop */}
                {view === 'mobile' ? (
                    <IPhoneMockup screenWidth={screenWidthMobile} frameColor='var(--gray-12)' 
                        statusbarColor='var(--gray-contrast)' transparentNavBar='true' hideStatusBar='true' 
                        screenType='notch'>
                        <Suspense>
                            <CaptivePortalMockup 
                                device={view} 
                                scaleFactor={scaleFactorMobile}
                                logoPreview={logoPreview} 
                                backgroundPreview={backgroundPreview}
                                backgroundOverlayColor={colorConversion(backgroundOverlayColor, backgroundOverlayOpacity)}
                                buttonColor={buttonColor}
                                buttonTextColor={buttonTextColor}
                                email={emailFlag}
                                age={ageFlag}
                                dob={dobFlag}
                                gender={genderFlag}
                                message={message}
                                activeTab={activeTab}
                                organisationName={organisationName}
                                links_data={showLinks ? {
                                    links: links
                                } : null}
                                banner_data={showBanner ? {
                                    title: bannerTitle,
                                    description: bannerDescription,
                                    banner_url: bannerLinkURL,
                                    image: bannerImage.preview,
                                    image_filename: bannerImage.fileName
                                } : null}
                                segmentView={segmentView}
                                redirectUrl={redirectUrl}
                                orgDetails={orgDetails?.data}
                            />
                        </Suspense>
                    </IPhoneMockup>
                ) : (
                // Desktop View
                <Suspense>
                    <Flex style={{flex: '1 0 0', alignSelf: 'stretch', justifyContent: 'center'}}>
                        <CaptivePortalMockup 
                            device={view}
                            logoPreview={logoPreview} 
                            backgroundPreview={backgroundPreview}
                            backgroundOverlayColor={colorConversion(backgroundOverlayColor, backgroundOverlayOpacity)}
                            buttonColor={buttonColor}
                            buttonTextColor={buttonTextColor}
                            email={emailFlag}
                            age={ageFlag}
                            dob={dobFlag}
                            gender={genderFlag}
                            message={message}
                            activeTab={activeTab}
                            organisationName={organisationName}
                            links_data={showLinks ? {
                                links: links
                            } : null}
                            banner_data={showBanner ? {
                                title: bannerTitle,
                                description: bannerDescription,
                                banner_url: bannerLinkURL,
                                image: bannerImage.preview,
                                image_filename: bannerImage.fileName
                            } : null}
                            segmentView={segmentView}
                            redirectUrl={redirectUrl}
                            orgDetails={orgDetails?.data}
                        />
                    </Flex>
                </Suspense>
                )}
            </Flex>
            {/* Settings Section */}
            <Flex style={{alignItems: 'flex-start', alignSelf: 'stretch'}} height="100%">
                <Form.Root onSubmit={handleSubmit} style={{height: '100%', width: '100%'}}>
                    <Flex justify="between" width="296px" direction="column" style={{alignItems: 'flex-start',
                        alignSelf: 'stretch', flex: '1 0 0', borderRadius: 'var(--radius-4)', height: '100%',
                        border: '1px solid var(--gray-a6)', backgroundColor: 'var(--color-panel-solid)' }}>
                        <Flex direction="column" style={{alignSelf: 'stretch', overflow: 'scroll', flex: '1 0 0'}}>
                            {/* Settings Header */}
                            <Tabs.Root defaultValue='portal' value={activeTab} onValueChange={setActiveTab}>
                                <Tabs.List style={{padding: 'var(--space-2) var(--space-4) 0px var(--space-4)',
                                    position: 'sticky', top: '0', zIndex: '1', backgroundColor: 'var(--color-panel-solid)',
                                    borderTopLeftRadius: 'var(--radius-4)', borderTopRightRadius: 'var(--radius-4)'}}>
                                    <Tabs.Trigger value="portal">Form</Tabs.Trigger>
                                    {emailFlag && <Tabs.Trigger value="policies">Privacy</Tabs.Trigger>}
                                    <Tabs.Trigger value="landing">Landing</Tabs.Trigger>
                                </Tabs.List>
                                {/* Portal Tab */}
                                <Tabs.Content value="portal">
                                    <Flex p="4" gap="2" direction="column" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                                        <Text size="1" weight="regular" color="gray">
                                            Your portal will appear on your guests' device when they connect to your 
                                            guest Wi-Fi for the first time.
                                        </Text>
                                    </Flex>
                                    {/* Email, Age and Gender Selects */}
                                    <Separator size="4" orientation="horizontal" style={{ minHeight: '1px' }}/>
                                    <Flex p="4" gap="2" direction="column" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                                    <CheckboxGroup.Root style={{width: '100%', gap: 'var(--space-2)'}}
                                        value={[emailFlag ? 'email' : '', ageFlag ? 'age' : '', genderFlag ? 'gender': '' ]}
                                        onValueChange={handleCpFormFieldsChange}>
                                        <Flex p="2" style={{justifyContent: 'space-between', alignSelf: 'stretch',
                                            alignItems: 'center', width: '100%', borderRadius: 'var(--radius-2)',
                                            border: '1px solid var(--gray-a6)'}}>
                                            <CheckboxGroup.Item value="email">Email</CheckboxGroup.Item>
                                            <Tooltip width="210px" content="Make sure to select your privacy notice method in the privacy tab."
                                                style={{textAlign: 'center'}}>
                                                <ExclamationTriangleIcon color="gray"/>
                                            </Tooltip>
                                        </Flex>
                                        <Flex p="2" style={{justifyContent: 'space-between', alignSelf: 'stretch',
                                            alignItems: 'center', width: '100%', borderRadius: 'var(--radius-2)',
                                            border: '1px solid var(--gray-a6)'}}>
                                            <CheckboxGroup.Item value="age">Age</CheckboxGroup.Item>
                                            <Text as="label" size="1" color="gray">
                                                <Flex gap="2">
                                                    {isAirshipActive() ? (
                                                        <Tooltip width="210px" content="Your Airship integration is compatible only with Date of birth."
                                                            style={{textAlign: 'center'}}>
                                                            <Flex style={{ display: 'inline-flex' }}>
                                                            <Checkbox
                                                                name="dob" value="dob"
                                                                disabled={!ageFlag || isAirshipActive()}
                                                                checked={dobFlag}
                                                            />
                                                            </Flex>
                                                        </Tooltip>
                                                    ) : (
                                                        <Flex style={{ display: 'inline-flex' }}>
                                                            <Checkbox
                                                                name="dob" value="dob"
                                                                disabled={!ageFlag}
                                                                checked={dobFlag}
                                                                onCheckedChange={() => setDobFlag(!dobFlag)}
                                                            />
                                                        </Flex>
                                                    )}
                                                    Date of birth
                                                </Flex>
                                            </Text>
                                        </Flex>
                                        <Flex p="2" style={{justifyContent: 'flex-start', alignSelf: 'stretch',
                                            alignItems: 'center', width: '100%', borderRadius: 'var(--radius-2)',
                                            border: '1px solid var(--gray-a6)'}}>
                                            <CheckboxGroup.Item value="gender">Gender</CheckboxGroup.Item>
                                        </Flex>
                                    </CheckboxGroup.Root>
                                    </Flex>
                                    {/* Logo Input Field */}
                                    <Separator size="4" orientation="horizontal" style={{ minHeight: '1px' }}/>
                                    <Flex gap="1" p="4" direction="column" style={{alignSelf: 'stretch'}}>
                                        <Form.Field name="logo_image">
                                            <Form.Label style={{display: 'flex', flexDirection: 'column', marginBottom: '4px'}}>
                                                <Text size="2" weight="medium">Logo</Text>
                                            </Form.Label>
                                            <Flex gap="2">
                                                <input type="file" style={{ display: 'none' }} ref={logoInputRef}
                                                    onChange={handleLogoUpload} accept="image/*" />
                                                <Button type="button" size="2" variant="surface" color="gray"
                                                    style={{display: 'flex', padding: '0px var(--space-2)', 
                                                    justifyContent: 'center', flex: '1'}}
                                                    onClick={triggerLogoInput}>
                                                    {logoPreview ? (
                                                        <Tooltip content="Upload new to replace">
                                                            <Text>
                                                                {logoFileName?.length >= 18 ? `${logoFileName.slice(0, 18)}...` : logoFileName}
                                                            </Text>
                                                        </Tooltip>
                                                    ) : (
                                                        <>
                                                            <UploadIcon />
                                                            <Text>Upload</Text>
                                                        </>
                                                    )}
                                                </Button>
                                                {logoPreview && 
                                                <Button type="button" size="2" variant="surface" color="gray" 
                                                    onClick={() => handleLogoClear()}>
                                                    Clear
                                                </Button>}
                                            </Flex>
                                        </Form.Field>
                                        <Text size="1" weight="regular" color="gray">Recommended size of 200px by 96px. Maximum file size of 1MB.</Text>
                                    </Flex>
                                    <Separator size="4" orientation="horizontal" style={{ minHeight: '1px' }}/>
                                    {/* Background Image & Background Overlay */}
                                    <Flex direction="column" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                                        <Flex gap="4" p="4" direction="column" style={{alignSelf: 'stretch'}}>
                                            {/* Background Image */}
                                            <Flex gap="1" direction="column" style={{alignSelf: 'stretch'}}>
                                                <Form.Field name="background_image">
                                                    <Form.Label style={{display: 'flex', flexDirection: 'column', marginBottom: '4px'}}>
                                                        <Text size="2" weight="medium">Background image</Text>
                                                    </Form.Label>
                                                    <Flex gap="2" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                                                        <input type="file" style={{ display: 'none' }} ref={backgroundInputRef}
                                                        onChange={handleBackgroundImageUpload} id="background-upload" 
                                                        accept="image/*"
                                                        name="background_image" />
                                                        <Button type="button" size="2" variant="surface" color="gray"
                                                            style={{display: 'flex', padding: '0px var(--space-2)', 
                                                            justifyContent: 'center', flex: '1'}}
                                                            onClick={triggerBackgroundInput}>
                                                            {backgroundPreview ? (
                                                                <Tooltip content="Upload new to replace">
                                                                    <Text>
                                                                        {backgroundFileName?.length >= 18 ? `${backgroundFileName.slice(0, 18)}...` : backgroundFileName}
                                                                    </Text>
                                                                </Tooltip>
                                                            ) : (
                                                                <>
                                                                    <UploadIcon />
                                                                    <Text>Upload</Text>
                                                                </>
                                                            )}
                                                        </Button>
                                                        {backgroundPreview &&
                                                        <Button type="button" size="2" variant="surface" color="gray"
                                                        onClick={() => handleBackgroundImageClear()}>
                                                            Clear
                                                        </Button>}
                                                    </Flex>
                                                </Form.Field>
                                                <Text size="1" weight="regular" color="gray">Recommended size of 900px by 600px. Maximum file size of 1MB.</Text>
                                            </Flex>
                                            {/* Background Overlay */}
                                            <Flex gap="1" direction="column" style={{alignSelf: 'stretch'}}>
                                                <Form.Field name="background_overlay">
                                                    <Form.Label style={{display: 'flex', flexDirection: 'column', marginBottom: '4px'}}>
                                                        <Text size="2" weight="medium">Background overlay</Text>
                                                    </Form.Label>
                                                    <Form.Control asChild>
                                                        <input type="hidden" name="background_overlay" value={backgroundOverlayColor}/>
                                                    </Form.Control>
                                                    <Flex gap="2" style={{alignSelf: 'stretch', alignItems: 'flex-start'}}>
                                                        <label htmlFor="background_overlay_colour_picker" 
                                                            style={{position: 'relative', display: 'inline-block', width: '100%'}}>
                                                            <input  type="color" id="background_overlay_colour_picker"
                                                            value= {backgroundOverlayColor}
                                                            onChange={(e) => {setBackgroundOverlayColor(e.target.value);}}
                                                            style={{ opacity: 0, position: 'absolute', width: '100%',
                                                                height: '100%', border: 'none', padding: 0, margin: 0}}/>
                                                            {/* Custom color display */}
                                                            <div style={{ width: '100%', height: '32px', display: 'flex',
                                                                backgroundColor: colorConversion(backgroundOverlayColor, backgroundOverlayOpacity),
                                                                borderRadius: 'var(--radius-2)',
                                                                border: '1px solid var(--gray-a6)'}}>
                                                            </div>
                                                        </label>
                                                        <TextField.Root size="2"
                                                            type = "number"
                                                            min={0} max={100} step={1}
                                                            value={backgroundOverlayOpacity}
                                                            onChange={(e) => {setBackgroundOverlayOpacity(e.target.value);}}
                                                            style={{textAlign: 'center', width: '70px'}}>
                                                            <TextField.Slot side="right" style={{padding: '4px' }}>
                                                                %
                                                            </TextField.Slot>
                                                        </TextField.Root>
                                                    </Flex>
                                                    <Text size="1" weight="regular" color="gray">Recommended for logo visibility.</Text>
                                                </Form.Field>
                                            </Flex>
                                        </Flex>
                                        <Separator size="4" orientation="horizontal" style={{ minHeight: '1px' }}/>
                                        {/* Button Colour Input Field */}
                                        <Flex gap="1" p="4" direction="column" style={{alignSelf: 'stretch'}}>
                                            <Form.Field name="button_color">
                                                <Form.Label style={{display: 'flex', flexDirection: 'column', marginBottom: '4px'}}>
                                                    <Text size="2" weight="medium">Button colour</Text>
                                                </Form.Label>
                                                <Form.Control asChild>
                                                    <input type="hidden" name="button_color" value={buttonColor} />
                                                </Form.Control>
                                                <Flex direction="column" style={{alignSelf: 'stretch'}}>
                                                    <label htmlFor="button_colour_picker" style={{position: 'relative',
                                                        display: 'inline-block',width: '100%'}}>
                                                        <input type="color" id="button_colour_picker"
                                                            value={buttonColor}
                                                            onChange={(e) => {setButtonColor(e.target.value);}}
                                                            style={{ opacity: 0, position: 'absolute', width: '100%',
                                                            height: '100%', border: 'none', padding: 0, margin: 0}}/>
                                                        <div style={{ width: '100%', height: '32px',
                                                            backgroundColor: buttonColor,
                                                            borderRadius: 'var(--radius-2)',
                                                            border: '1px solid var(--gray-a6)'}}>
                                                        </div>
                                                    </label>      
                                                </Flex>
                                            </Form.Field>
                                            <Text size="1" weight="regular" color="gray">
                                                Recommended contrast of 3:1, against white. Visit &nbsp;
                                                <Link color="blue" underline='always' target='_blank'
                                                    href='https://webaim.org/resources/contrastchecker/'>
                                                    WebAim's Contrast Checker
                                                </Link> 
                                                &nbsp;to test your button colour.
                                            </Text>
                                        </Flex>
                                    </Flex>
                                    {/* Custom Message */}
                                    <Separator size="4" orientation="horizontal" style={{ minHeight: '1px' }}/>
                                    <Flex p="4" gap="1" direction="column" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                                        <Text size="2" weight="medium">Message</Text>
                                        <TextField.Root size="2"
                                            value={message}
                                            onChange={(e) => handleInputMessageChange(e.target.value)}
                                            maxLength={48}
                                            style={{alignSelf: 'stretch'}}/>
                                    </Flex>
                                </Tabs.Content>
                                {/* Privacy Policies Tab */}
                                <Tabs.Content value="policies">
                                    <Flex p="4" direction="column" gap="4" style={{ alignSelf: 'stretch', flex: '1 0 0'}}>
                                    <RadioGroup.Root
                                        value={privacyType}
                                        onValueChange={(value) => setPrivacyType(value)}
                                        style={{width: '100%'}}
                                    >
                                        <Flex direction="row" gap="2" style={{width: '100%'}}>
                                            <Text as="label" size="2" style={{width: '100%'}}>
                                                <Flex p="2" gap="2" style={{alignItems: 'center', flex: '1 0 0',
                                                    border: '1px solid var(--gray-a6)', borderRadius: 'var(--radius-2)'}}>
                                                    <RadioGroup.Item value="auto" />
                                                    Auto
                                                </Flex>
                                            </Text>
                                            <Text as="label" size="2" style={{width: '100%'}}>
                                                <Flex p="2" gap="2" style={{alignItems: 'center', flex: '1 0 0',
                                                    border: '1px solid var(--gray-a6)', borderRadius: 'var(--radius-2)'}}>
                                                    <RadioGroup.Item value="custom" />
                                                    Custom
                                                </Flex>
                                            </Text>
                                        </Flex>
                                    </RadioGroup.Root>
                                        { privacyType === 'auto' ? (
                                            <Flex direction="column" gap="4"
                                                style={{alignItems: 'flex-start', alignSelf: 'stretch', flex: '1 0 0'}}>
                                                <Text size="1" weight="regular" color="gray">
                                                    Your privacy notice has been created using your legal information and will be
                                                    displayed on your captive portal.
                                                </Text>
                                                <Button size="2" variant="soft" color="gray" style={{alignSelf: 'stretch'}}
                                                    onClick={() => navigate('/organisation/details')}>
                                                    Edit legal details
                                                    <ArrowTopRightOnSquareIcon width="16" height="16"/>
                                                </Button>
                                            </Flex>
                                        ) : (
                                            <Flex direction="column" gap="4"
                                                style={{alignItems: 'flex-start', alignSelf: 'stretch', flex: '1 0 0'}}>
                                                <Text size="1" color="gray">
                                                    Provide a custom privacy notice below. You can style it using&nbsp;
                                                    <Link color="gray" underline='always'
                                                        href='https://www.markdownguide.org/cheat-sheet/'>
                                                        markdown
                                                    </Link>.
                                                </Text>
                                                <Flex direction="column" gap="1" style={{alignItems: 'flex-start', 
                                                    alignSelf: 'stretch', flex: '1 0 0'}}>
                                                    <Text size="2" weight="medium" style={{width: '100%'}}>Privacy Profile</Text>
                                                    <TextArea resize="vertical" size="2"
                                                        style={{width: '100%'}}
                                                        rows='13'
                                                        value={privacyPolicy}
                                                        onChange={(e) => handlePrivacyPolicyChange(e.target.value)}
                                                        placeholder="Paste here" />
                                                    <Text size="1" color="gray">Auto will be used as a fallback if no privacy notice is provided.</Text>
                                                </Flex>
                                            </Flex>
                                        )}
                                    </Flex>
                                </Tabs.Content>
                                {/* Landing Tab */}
                                <Tabs.Content value="landing">
                                    {/* Page / Redirect */}
                                    <Flex p="4" direction="column" gap="4"
                                        style={{alignItems: 'center', alignSelf: 'stretch', flex: '1 0 0'}}>
                                        <RadioGroup.Root
                                            value={segmentView}
                                            onValueChange={(value) => {
                                                setSegmentView(value);
                                                setLandingType(value); // Updating landing type when switching tabs
                                            }}
                                            style={{width: '100%'}}
                                        >
                                            <Flex direction="row" gap="2" style={{width: '100%'}}>
                                                <Text as="label" size="2" style={{width: '100%'}}>
                                                    <Flex p="2" gap="2" style={{alignItems: 'center', flex: '1 0 0',
                                                        border: '1px solid var(--gray-a6)', borderRadius: 'var(--radius-2)',
                                                    }}>
                                                        <RadioGroup.Item value="page" />
                                                        Page
                                                    </Flex>
                                                </Text>
                                                <Text as="label" size="2" style={{width: '100%'}}>
                                                    <Flex p="2" gap="2" style={{alignItems: 'center', flex: '1 0 0',
                                                        border: '1px solid var(--gray-a6)', borderRadius: 'var(--radius-2)',
                                                    }}>
                                                        <RadioGroup.Item value="redirect" />
                                                        Redirect
                                                    </Flex>
                                                </Text>
                                            </Flex>
                                        </RadioGroup.Root>
                                        <Flex gap="2" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                                            <Text size="1" weight="regular" color="gray">
                                                Your landing page will appear after a guest has been connected to the internet.
                                            </Text>
                                            <Tooltip content="This applies mostly to iOS, MacOS & Windows devices. 
                                                Most Android devices auto-close the portal upon connection.">
                                                <InfoCircledIcon color="gray" width="24" height="24" />
                                            </Tooltip>
                                        </Flex>
                                    </Flex>
                                    <Separator size="4" orientation="horizontal" style={{ minHeight: '1px' }}/>
                                    {/* Page Tab */}
                                    {segmentView === 'page' ? (
                                        <Flex direction="column" style={{alignItems: 'flex-start',
                                            alignSelf: 'stretch', flex: '1 0 0'}}>
                                            {/* Link Section under Page Tab */}
                                            <Flex p="4" gap="4" direction="column" style={{alignItems: 'flex-start',
                                                alignSelf: 'stretch', flex: '1 0 0'}}>
                                                <Text as="label" size="2" style={{ width: '100%' }}>
                                                    <Flex gap="2" style={{alignItems: 'center', alignSelf: 'stretch'}}>
                                                        <Checkbox
                                                            checked={showLinks}
                                                            onCheckedChange={(e) => setShowLinks(e)}
                                                            />
                                                            <Text size="3" weight="medium">Links</Text>
                                                    </Flex>
                                                </Text>
                                                {showLinks && (
                                                <Flex direction="column" gap="2" style={{width: '100%'}}>
                                                {/* Rendering existing links */}
                                                {links.map((link, index) => (
                                                <Popover.Root key={index} open={currentEditingLink && currentEditingLink.index === index} 
                                                    onOpenChange={(open) => {
                                                        if (!open) {
                                                            if (currentEditingLink && currentEditingLink.index === index && !linkIconUploadFailed) {
                                                                saveLinkChanges();
                                                            } else if (linkIconUploadFailed) {
                                                                closeLinkEditor();
                                                                setLinkIconUploadFailed(false);
                                                            }
                                                        }
                                                    }}>
                                                    <Popover.Trigger asChild>
                                                        <Button type="button" size="2" variant="surface"
                                                            color={currentEditingLink && currentEditingLink.index === index ? 'blue' : 'gray'}
                                                            style={{width: '100%', alignSelf: 'stretch', justifyContent: 'center'}}
                                                            onClick={() => openLinkEditor(link, index)}>
                                                            {link.label || `Link ${index + 1}`}
                                                        </Button>
                                                    </Popover.Trigger>
                                                    <Popover.Content side="left">
                                                        <Flex direction="column" gap="4">
                                                            <Flex style={{alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'center'}}>
                                                                <Text size="3">Link settings</Text>
                                                                <Popover.Close>
                                                                    <IconButton size="1" variant="ghost" color="gray">
                                                                        <Cross1Icon/>
                                                                    </IconButton>
                                                                </Popover.Close>
                                                            </Flex>
                                                            
                                                            {/* Link form fields */}
                                                            <Flex direction="column" gap="4">
                                                                <Text as="label" size="2" style={{alignSelf: 'stretch'}}>
                                                                    <Flex gap="1" direction="column">
                                                                        <Text size="2" weight="medium">Label</Text>
                                                                        <TextField.Root 
                                                                            size="2" 
                                                                            value={currentEditingLink?.label || ''}
                                                                            onChange={(e) => updateLinkField('label', e.target.value)}
                                                                        />
                                                                    </Flex>
                                                                </Text>
                                                                
                                                                <Text as="label" size="2" style={{alignSelf: 'stretch'}}>
                                                                    <Flex gap="1" direction="column">
                                                                        <Text size="2" weight="medium">Icon</Text>
                                                                        <Flex gap="2">
                                                                            <input 
                                                                                type="file" 
                                                                                style={{ display: 'none' }}
                                                                                ref={linkIconUpload.inputRef}
                                                                                onChange={(e) => {
                                                                                    const result = linkIconUpload.handleUpload(e);
                                                                                    if (!result.success) {
                                                                                        if (result.reason === 'size-exceeded') {
                                                                                            setToastMessage('Maximum file size of 1MB');
                                                                                            setLinkIconUploadFailed(true);
                                                                                        }
                                                                                    } else {
                                                                                        setToastMessage('Uploaded');
                                                                                        setLinkIconUploadFailed(false);
                                                                                    }
                                                                                }}
                                                                                accept="image/*" 
                                                                            />
                                                                            <Button 
                                                                                type="button" 
                                                                                size="2" 
                                                                                variant="surface" 
                                                                                color="gray"
                                                                                style={{display: 'flex', padding: '0px var(--space-2)', justifyContent: 'center', flex: '1'}}
                                                                                onClick={linkIconUpload.triggerInput}
                                                                            >
                                                                                {linkIconUpload.preview ? (
                                                                                    <Tooltip content="Upload new to replace">
                                                                                        <Text>
                                                                                            {linkIconUpload.fileName?.length >= 18 ? 
                                                                                            `${linkIconUpload.fileName.slice(0, 18)}...` : 
                                                                                            linkIconUpload.fileName}
                                                                                        </Text>
                                                                                    </Tooltip>
                                                                                ) : (
                                                                                    <>
                                                                                        <UploadIcon />
                                                                                        <Text>Upload</Text>
                                                                                    </>
                                                                                )}
                                                                            </Button>
                                                                            
                                                                            {linkIconUpload.preview && 
                                                                            <Button 
                                                                                type="button" 
                                                                                size="2" 
                                                                                variant="surface" 
                                                                                color="gray"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();
                                                                                    linkIconUpload.handleClear();
                                                                                }}
                                                                            >
                                                                                Clear
                                                                            </Button>
                                                                            }
                                                                        </Flex>
                                                                        <Text size="1" weight="regular" color="gray">Recommended size of 40px by 40px. Maximum file size of 1MB.</Text>
                                                                    </Flex>
                                                                </Text>
                                                                
                                                                <Text as="label" size="2" style={{alignSelf: 'stretch'}}>
                                                                    <Flex gap="1" direction="column">
                                                                        <Text size="2" weight="medium">Link URL</Text>
                                                                        <TextField.Root 
                                                                            size="2" 
                                                                            value={currentEditingLink?.link_url || ''}
                                                                            onChange={(e) => updateLinkField('link_url', e.target.value)}
                                                                        />
                                                                    </Flex>
                                                                </Text>
                                                            </Flex>
                                                            
                                                            {/* Only keep the Remove link button */}
                                                            <Flex style={{alignSelf: 'stretch', marginTop: 'var(--space-2)'}}>
                                                                <Button 
                                                                    type="button" 
                                                                    size="2" 
                                                                    variant="soft" 
                                                                    color="gray"
                                                                    onClick={() => handleLinkRemove(currentEditingLink.index)}
                                                                    style={{width: '100%'}}
                                                                >
                                                                    Remove link
                                                                </Button>
                                                            </Flex>
                                                        </Flex>
                                                    </Popover.Content>
                                                </Popover.Root>
                                                ))}

                                                {/* Add new link button with its own popover */}
                                                {links.length < 3 && (
                                                    <Popover.Root open={isAddingLink} onOpenChange={(open) => {
                                                        if (!open) {
                                                            // If popover is closing, check if any field has a value
                                                            const hasContent = 
                                                                (currentEditingLink?.label && currentEditingLink.label.trim() !== '') || 
                                                                (currentEditingLink?.link_url && currentEditingLink.link_url.trim() !== '') || 
                                                                linkIconUpload.preview;
                                                            
                                                            if (hasContent) {
                                                                // At least one field has content, save the link
                                                                saveLinkChanges();
                                                            } else {
                                                                // No content, just close without saving
                                                                closeLinkEditor();
                                                            }
                                                        }
                                                    }}>
                                                        <Popover.Trigger asChild>
                                                            <Button size="2" variant="soft" color="gray"
                                                                style={{width: '100%', alignSelf: 'stretch', gap: 'var(--space-2)', justifyContent: 'center'}}
                                                                onClick={() => openLinkEditor(null)}>
                                                                <PlusIcon/>
                                                                Add link
                                                            </Button>
                                                        </Popover.Trigger>
                                                        <Popover.Content side="left">
                                                            <Flex direction="column" gap="4">
                                                                <Flex style={{alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'center'}}>
                                                                    <Text size="3">Link settings</Text>
                                                                    <Popover.Close>
                                                                        <IconButton size="1" variant="ghost" color="gray">
                                                                            <Cross1Icon/>
                                                                        </IconButton>
                                                                    </Popover.Close>
                                                                </Flex>
                                                                
                                                                {/* Link form fields */}
                                                                <Flex direction="column" gap="4">
                                                                    <Text as="label" size="2" style={{alignSelf: 'stretch'}}>
                                                                        <Flex gap="1" direction="column">
                                                                            <Text size="2" weight="medium">Label</Text>
                                                                            <TextField.Root 
                                                                                size="2" 
                                                                                value={currentEditingLink?.label || ''}
                                                                                onChange={(e) => updateLinkField('label', e.target.value)}
                                                                            />
                                                                        </Flex>
                                                                    </Text>
                                                                    
                                                                    <Text as="label" size="2" style={{alignSelf: 'stretch'}}>
                                                                        <Flex gap="1" direction="column">
                                                                            <Text size="2" weight="medium">Icon</Text>
                                                                            <Flex gap="2">
                                                                                <input 
                                                                                    type="file" 
                                                                                    style={{ display: 'none' }}
                                                                                    ref={linkIconUpload.inputRef}
                                                                                    onChange={(e) => {
                                                                                        const result = linkIconUpload.handleUpload(e);
                                                                                        if (!result.success) {
                                                                                            if (result.reason === 'size-exceeded') {
                                                                                                setToastMessage('Maximum file size of 1MB');
                                                                                            }
                                                                                        } else {
                                                                                            setToastMessage('Uploaded');
                                                                                        }
                                                                                    }}
                                                                                    accept="image/*" 
                                                                                />
                                                                                <Button 
                                                                                    type="button" 
                                                                                    size="2" 
                                                                                    variant="surface" 
                                                                                    color="gray"
                                                                                    style={{display: 'flex', padding: '0px var(--space-2)', justifyContent: 'center', flex: '1'}}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        e.stopPropagation();
                                                                                        linkIconUpload.triggerInput();
                                                                                    }}
                                                                                >
                                                                                    {linkIconUpload.preview ? (
                                                                                        <Tooltip content="Upload new to replace">
                                                                                            <Text>
                                                                                                {linkIconUpload.fileName?.length >= 18 ? 
                                                                                                `${linkIconUpload.fileName.slice(0, 18)}...` : 
                                                                                                linkIconUpload.fileName}
                                                                                            </Text>
                                                                                        </Tooltip>
                                                                                    ) : (
                                                                                        <>
                                                                                            <UploadIcon />
                                                                                            <Text>Upload</Text>
                                                                                        </>
                                                                                    )}
                                                                                </Button>
                                                                                
                                                                                {linkIconUpload.preview && 
                                                                                <Button 
                                                                                    type="button" 
                                                                                    size="2" 
                                                                                    variant="surface" 
                                                                                    color="gray"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        e.stopPropagation();
                                                                                        linkIconUpload.handleClear();
                                                                                    }}
                                                                                >
                                                                                    Clear
                                                                                </Button>
                                                                                }
                                                                            </Flex>
                                                                            <Text size="1" weight="regular" color="gray">Recommended size of 40px by 40px. Maximum file size of 1MB.</Text>
                                                                        </Flex>
                                                                    </Text>
                                                                    
                                                                    <Text as="label" size="2" style={{alignSelf: 'stretch'}}>
                                                                        <Flex gap="1" direction="column">
                                                                            <Text size="2" weight="medium">Link URL</Text>
                                                                            <TextField.Root 
                                                                                size="2" 
                                                                                value={currentEditingLink?.link_url || ''}
                                                                                onChange={(e) => updateLinkField('link_url', e.target.value)}
                                                                            />
                                                                        </Flex>
                                                                    </Text>
                                                                </Flex>
                                                            </Flex>
                                                        </Popover.Content>
                                                    </Popover.Root>
                                                )}
                                                </Flex>)}
                                            </Flex>
                                            <Separator size="4" orientation="horizontal" style={{ minHeight: '1px' }}/>
                                            {/* Banner section under Page Tab */}
                                            <Flex p="4" gap="4" direction="column" style={{alignItems: 'flex-start',
                                                alignSelf: 'stretch', flex: '1 0 0'}}>
                                                <Text as="label" size="2">
                                                    <Flex gap="2" style={{alignItems: 'center', alignSelf: 'stretch'}}>
                                                        <Checkbox
                                                            checked={showBanner}
                                                            onCheckedChange={(e) => setShowBanner(e)}/>
                                                            <Text size="3" weight="medium">Banner</Text>
                                                    </Flex>
                                                </Text>
                                                {showBanner && 
                                                <Flex gap="4" direction="column" style={{alignItems: 'flex-start',
                                                    alignSelf: 'stretch', flex: '1 0 0'}}>
                                                    {/* Banner title input field */}
                                                    <Text as="label" size="2" style={{alignSelf: 'stretch'}}>
                                                        <Flex gap="1" direction="column">
                                                            <Text size="2" weight="medium">Title</Text>
                                                            <TextField.Root size="2"
                                                                value={bannerTitle}
                                                                onChange={(e) => {
                                                                    setBannerTitle(e.target.value);
                                                                }}
                                                            />
                                                        </Flex>
                                                    </Text>
                                                    {/* Banner description input field */}
                                                    <Text as="label" size="2" style={{alignSelf: 'stretch'}}>
                                                        <Flex gap="1" direction="column">
                                                            <Text size="2" weight="medium">Description</Text>
                                                            <TextField.Root size="2"
                                                                value={bannerDescription}
                                                                onChange={(e) => {
                                                                    setBannerDescription(e.target.value);
                                                                }}
                                                            />
                                                            <Text size="1" weight="regular" color="gray">Recommended length of 100 characters.</Text>
                                                        </Flex>
                                                    </Text>
                                                    {/* Banner URL field */}
                                                    <Text as="label" size="2" style={{alignSelf: 'stretch'}}>
                                                        <Flex gap="1" direction="column">
                                                            <Text size="2" weight="medium">Link URL</Text>
                                                            <TextField.Root size="2"
                                                                value={bannerLinkURL}
                                                                onChange={(e) => {
                                                                    setBannerLinkURL(e.target.value);
                                                                }}
                                                            />
                                                        </Flex>
                                                    </Text>
                                                    {/* Banner Image upload field */}
                                                    <Text as="label" size="2" style={{alignSelf: 'stretch'}}>
                                                        <Flex gap="1" direction="column">
                                                            <Text size="2" weight="medium">Image</Text>
                                                            <Flex gap="2">
                                                                <input type="file" accept="image/*"
                                                                    id = "banner-upload"
                                                                    name = "banner_image"
                                                                    style={{ display: 'none' }}
                                                                    ref={bannerImage.inputRef}
                                                                    onChange={(e) => {
                                                                        const result = bannerImage.handleUpload(e);
                                                                        if (!result.success) {
                                                                          if (result.reason === 'size-exceeded') {
                                                                            setToastMessage('Maximum file size of 1MB');
                                                                          }
                                                                        } else {
                                                                          setToastMessage('Uploaded');
                                                                        }
                                                                    }}
                                                                />
                                                                <Button type="button" size="2" variant="surface" color="gray"
                                                                    style={{display: 'flex', padding: '0px var(--space-2)', 
                                                                    justifyContent: 'center', flex: '1'}}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        bannerImage.triggerInput();
                                                                    }}>
                                                                    {bannerImage.preview ? (
                                                                        <Tooltip content="Upload new to replace">
                                                                            <Text>
                                                                                {bannerImage.fileName?.length >= 18
                                                                                    ? `${bannerImage.fileName.slice(0, 18)}...` 
                                                                                    : bannerImage.fileName
                                                                                }
                                                                            </Text>
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <>
                                                                        <UploadIcon />
                                                                        <Text>Upload</Text>
                                                                        </>
                                                                    )}
                                                                </Button>
                                                                {bannerImage.preview && 
                                                                <Button type="button" size="2" variant="surface" color="gray"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    bannerImage.handleClear();
                                                                }}>
                                                                    Clear
                                                                </Button>}
                                                            </Flex>
                                                            <Text size="1" weight="regular" color="gray">Recommended size of 96px by 96px. Maximum file size of 1MB.</Text>
                                                        </Flex>
                                                    </Text>
                                                </Flex>                                           
                                                }
                                            </Flex>
                                        </Flex>
                                    ) : (
                                    // Redirect Tab
                                    <Flex p="4" gap="4" direction="column" 
                                        style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                                        <Flex gap="1" direction="column" 
                                            style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                                            {/* Redirect URL */}
                                            <Text as="label" size="2">
                                                <Flex>
                                                <Text size="2" weight="medium">Redirect URL</Text>
                                                </Flex>
                                            </Text>
                                            <TextField.Root size="2"
                                                value={redirectUrl}
                                                onChange={(e) => handleInputRedirectUrlChange(e.target.value)}
                                                style={{alignSelf: 'stretch'}}
                                            />
                                        </Flex>
                                        <Text size="1" weight="regular" color="gray">
                                            If a URL is not provided, your landing page will be used as a fallback.
                                        </Text>
                                    </Flex>
                                    )}
                                    { segmentView === 'redirect' &&
                                        <Separator size="4" orientation="horizontal" style={{ minHeight: '1px' }}/>
                                    }
                                </Tabs.Content>
                            </Tabs.Root>
                        </Flex>
                        {/* Save Changes Button */}
                        <Flex direction="column" style={{alignSelf: 'stretch', alignItems: 'flex-end'}}>
                            <Separator size="4" orientation="horizontal" />
                            <Flex p="4" direction="column" style={{alignSelf: 'stretch', alignItems: 'flex-start'}}>
                                <Button 
                                    type="submit" 
                                    variant="solid"
                                    loading={isSaveChangesLoading}
                                    style={{alignSelf: 'stretch', width: '100%'}} 
                                    disabled={!isDirty}>
                                    Save changes
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Form.Root>
            </Flex>
        </Flex>
        )}
        {/* Toast Updates */}
        <ToastAlert/>
    </React.Fragment>
  );
}

export default GuestPortal;
