import { useState, useEffect } from 'react';
import { useGetLiveAccessPointsStatus } from '../guest_wifi/useGetLiveAccessPointsStatus';

export const useLiveAccessPoints = (locationsData) => {
  const [liveAccessPointsPayload, setLiveAccessPointsPayload] = useState(null);
  const [liveAccessPointsStatus, setLiveAccessPointsStatus] = useState([]);
  
  useEffect(() => {
    if (locationsData) {
      const access_point_payload = locationsData.map((location) => ({
        name: location.name,
        address: location.address,
        location_id: location.id,
      }))

      setLiveAccessPointsPayload({
        timestamp: Date.now(),
        request_type: "live-access-point-status",
        payload: access_point_payload
      });
    }
  }, [locationsData]);

  const { data: liveAccessPoints } = useGetLiveAccessPointsStatus(liveAccessPointsPayload);

  useEffect(() => {
    if (liveAccessPoints) {
      setLiveAccessPointsStatus(liveAccessPoints.data.data);
    }
  }, [liveAccessPoints]);

  // Update live stats every 30 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setLiveAccessPointsPayload(prevPayload => ({
        ...prevPayload,
        timestamp: Date.now(),
      }));
    }, 30000);

    return () => clearInterval(intervalId);
  }, [locationsData]);

  return {
    liveAccessPointsStatus,
  };
};