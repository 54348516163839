import instance from "./BaseApi";

export function attemptGetLocationTypes() {
  return instance.request({
    url: `location-types/`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}
