import { useMutation} from "@tanstack/react-query";
import { attemptChangeEmailRequest } from '../../api/AuthApi';
import { toast } from 'react-toastify';

export const useChangeEmailRequest = () => {
  return useMutation({
    mutationKey: ['change-email'],
    mutationFn: async (values) => {
      try {
        const res = await attemptChangeEmailRequest(values);
        return res.data;
      } catch (error) {
        console.error("Mutation Error:", error);
        throw error;
      }
    },
    onSuccess: () => {
     toast.success("Change Email request success")
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Change Email request failed")
    }
  });
};
