import React, {useEffect, useState} from 'react';
import { useLocations } from "hooks";
import { Flex, Text, Button, Table, Avatar, Select, DropdownMenu, IconButton, AlertDialog, Badge} from "@radix-ui/themes";
import { PersonIcon, DotsHorizontalIcon} from "@radix-ui/react-icons";
import { LocationMultiSelect } from "components";
import { getProfile } from "api/UsersApi";

export function OrgUser({ entry, orgUserId, viewerId, handleOrgUserChange, handleOrgUserDelete }) {

  const { initialItems, setLocationSelection } = useLocations(viewerId);
  const handleLocationClick = (value) => {
    setLocationSelection(value);
    handleOrgUserChange(value, orgUserId, "locations");
  }

  const handleUserRoleClick = (value) => handleOrgUserChange(value, orgUserId, "role");

  const handleCustomUserDelete = (e) => {
    handleOrgUserDelete(viewerId, orgUserId);
  }

  const [profileEmail, setProfileEmail] = useState('');

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await getProfile();
        setProfileEmail(profile.data.email);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };
  
    fetchProfile();
  }, []);


  return (
    <Table.Row align="center">
      <Table.Cell style={{ display: 'flex', alignItems: 'center', gap: '12px', alignSelf: 'stretch', minHeight: '68px' }}>
        <Avatar variant="solid" size="2" fallback={(entry.first_name !== null && entry.last_name !== null) ? `${entry.first_name.slice(0, 1)}${entry.last_name.slice(0, 1)}`: <PersonIcon />} color="blue" />
        <Flex className="text-container" display="flex" direction="column" justify="center" align="start" gap="var(--space-1)" style={{flex: '1 0 0'}}>
          <Text weight="regular" size="2" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {`${entry.first_name} ${entry.last_name}`}
          </Text>
          <Text size="1" color="gray" weight="regular" 
            style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
            {entry.email}
          </Text>
        </Flex>
      </Table.Cell>
      <Table.Cell>
        <Select.Root size="2" 
          defaultValue={`${entry.user_role}`.toLowerCase()} 
          onValueChange={handleUserRoleClick}
          disabled={entry.email === profileEmail}
        >
          <Select.Trigger variant="ghost" color="gray" style={{width: "100%"}}/>
          <Select.Content>
            <Select.Group>
              <Select.Item value="owner">Owner</Select.Item>
              <Select.Item value="manager" disabled>
                Manager&nbsp;&nbsp;
                <Badge radius="full" color="gray">Coming soon</Badge>
              </Select.Item>
            </Select.Group>
          </Select.Content>
        </Select.Root>
      </Table.Cell>
      <Table.Cell>
        {entry.locations.length === 0 ? 
        <Text>All</Text> :
          <LocationMultiSelect
            noShowBorder={false}
            toggleFlexGrow={true}
            options={initialItems}
            defaultValue={entry.locations}
            onValueChange={handleLocationClick}
          />
        }
      </Table.Cell>
      <Table.Cell justify="end">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
          {entry.email !== profileEmail ? (
            <IconButton size="1" variant='ghost' color='gray'>
              <DotsHorizontalIcon />
            </IconButton>
          ) : (
            <Text></Text>
          )}
          </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              <AlertDialog.Root>
              <AlertDialog.Trigger>
                <DropdownMenu.Item color="red" onSelect={(event) => event.preventDefault()}>
                  Delete User
                </DropdownMenu.Item>
              </AlertDialog.Trigger>
              <AlertDialog.Content maxWidth="600px" maxHeight="690px">
                <AlertDialog.Title>Delete User</AlertDialog.Title>
                <AlertDialog.Description size="2">
                  This action cannot be undone.
                </AlertDialog.Description>
                <Flex gap="3" mt="4" justify="end">
                  <AlertDialog.Cancel>
                    <Button variant="soft" color="gray">Cancel</Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <Button variant="solid" color="red"
                      onClick={handleCustomUserDelete}>
                      Delete
                    </Button>
                  </AlertDialog.Action>
                </Flex>
              </AlertDialog.Content>
            </AlertDialog.Root>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Table.Cell>
    </Table.Row>
  );
}