import { useQueryClient, useMutation } from "@tanstack/react-query";
import { attemptCreateOrgLocations } from "../../api/OrgApi";
import { toast } from 'react-toastify';

export const useCreateOrgLocation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: "create-org-location",
    mutationFn: async (data) => attemptCreateOrgLocations(data),
    onSuccess: (data) => {
      toast.success("Location Created Successfully")
    },
    onError: (error) => {
      toast.error("Location Created Failed")
    },
  });
};
