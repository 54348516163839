import { Text, Flex } from "@radix-ui/themes";

const NoResults = () => {
  return (
    <Flex className="location-details-container" direction="column" gap="5"
      style={{alignItems: 'flex-start', flex: '1 0 0', alignSelf: 'stretch', overflow: 'scroll', height: '100%'}}>
      <Flex direction="column" style={{justifyContent: 'center', alignItems: 'center', alignSelf: 'stretch',
        border: '1px dashed var(--gray-a6)', borderRadius: 'var(--radius-4)', height: '100%'}}>
        <Flex direction="column" gap="5" maxWidth="296px" style={{alignItems: 'center', width: '100%'}}>
          <Flex gap="2" direction="column" style={{alignItems: 'center', alignSelf: 'stretch', 
            justifyContent: 'center'}}>
            <Text size="4" weight="medium" align="center"> No results</Text>
            <Text size="2" weight="regular" align="center" color='gray'>
              Adjust your filters to view different results.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NoResults;