import React, { useState, useEffect, lazy, Suspense } from "react";
import { Flex, Text, Button, Card, TextField } from "@radix-ui/themes";
import * as Form from "@radix-ui/react-form";
import { useProfileContext } from "context";
import { useUpdateUser } from "hooks/app/useUpdateUser";
import { useSetPassword } from "hooks/auth/useSetPassword";
import { useToast } from "../../context/ToastContext";
import { ToastAlert } from "components";

const EmailUpdateModal = lazy(() => import("./EmailUpdateModal"));

export default function Profile() {
  const { userProfile } = useProfileContext();

  // Variables and logic for updating user's first name and last name
  const updateUserMutation = useUpdateUser();
  const [isDirtyNameUpdate, setIsDirtyNameUpdate] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    if (userProfile) {
      setFirstName(userProfile.user_first_name || "");
      setLastName(userProfile.user_last_name || "");
      setUserEmail(userProfile.user_email || "");
    }
  }, [userProfile]);

  const handleFirstNameChange = (value) => {
    setFirstName(value);
    setIsDirtyNameUpdate(true);
    if (value === userProfile.user_first_name) {
      setIsDirtyNameUpdate(false);
    }
  };

  const handleLastNameChange = (value) => {
    setLastName(value);
    setIsDirtyNameUpdate(true);
    if (value === userProfile.user_last_name) {
      setIsDirtyNameUpdate(false);
    }
  };

  const handleFirstNameLastNameSubmit = (event) => {
    event.preventDefault();
    setFirstName(firstName.trim());
    setLastName(lastName.trim());
    const updateData = {
      id: userProfile.user_id,
      first_name: firstName,
      last_name: lastName,
    };
    updateUserMutation.mutate(updateData, {
      onSuccess: () => {
        setToastMessage("Changes saved");
        setIsDirtyNameUpdate(false);
      },
      onError: (error) => {
        setToastMessage("Update failed");
      },
    });
  };

  // Variables and logic for updating user's password
  const passwordResetMutation = useSetPassword();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isDirtyPasswordUpdate, setIsDirtyPasswordUpdate] = useState(false);
  const [errors, setErrors] = useState({});

  const handleNewPasswordChange = (value) => {
    setNewPassword(value);
    setIsDirtyPasswordUpdate(true);
    if (value === "") {
      setIsDirtyPasswordUpdate(false);
    }
  };

  const handleConfirmNewPasswordChange = (value) => {
    setConfirmNewPassword(value);
    setIsDirtyPasswordUpdate(true);
    if (value === "") {
      setIsDirtyPasswordUpdate(false);
    }
  };

  const validatePasswords = () => {
    const newErrors = {};
    if (
      !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]{8,}$/.test(
        newPassword
      )
    ) {
      newErrors.newPassword = "Password does not meet requirements.";
    }
    if (newPassword !== confirmNewPassword) {
      newErrors.confirmNewPassword = "Confirm your new password.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePasswordResetSubmit = (event) => {
    event.preventDefault();
    if (validatePasswords()) {
      passwordResetMutation.mutate(
        {
          user_id: userProfile.user_id,
          new_password: newPassword,
        },
        {
          onSuccess: () => {
            setToastMessage("Password reset");
            setIsDirtyPasswordUpdate(false);
            setNewPassword("");
            setConfirmNewPassword("");
          },
          onError: (error) => {
            setToastMessage("Password reset failed");
          },
        }
      );
    } else {
      setToastMessage("Password reset failed");
    }
  };

  const handleEmailUpdate = (newEmail) => {
    setUserEmail(newEmail);
  };

  const { setToastMessage } = useToast();

  return (
    <React.Fragment>
      <Flex
        className="profile-layout-container"
        direction="column"
        height="100%"
        style={{
          alignItems: "flex-start",
          flex: "1 0 0",
          alignSelf: "stretch",
        }}
      >
        <Flex
          className="profile-layout"
          p="8"
          direction="column"
          gap="8"
          height="100%"
          style={{
            alignSelf: "stretch",
            alignItems: "flex-start",
            flex: "1 0 0",
          }}
        >
          <Flex
            className="header-container"
            justify="center"
            gap="2"
            style={{ alignSelf: "stretch", alignItems: "flex-end" }}
          >
            <Flex
              gap="2"
              className="header-content"
              direction="row"
              style={{
                flex: "1 0 0",
                alignItems: "center",
                alignSelf: "stretch",
                justifyContent: "space-between",
              }}
            >
              {/* User Name */}
              <Flex
                direction="column"
                gap="2"
                style={{
                  alignItems: "flex-start",
                  flex: "1 0 0",
                  alignSelf: "stretch",
                }}
              >
                <Text size="2" color="gray">
                  Profile
                </Text>
                <Text size="6" weight="bold">
                  {userProfile.user_first_name} {userProfile.user_last_name}
                </Text>
              </Flex>
              {/* Delete Account Button */}
              <Flex
                direction="column"
                style={{
                  height: "100%",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              >
                <Flex
                  gap="4"
                  style={{
                    alignSelf: "stretch",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="soft"
                    color="red"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const supportEmail = "support@b-connect.co.uk";
                      const subject = "Delete Account Request";
                      const body =
                        "Hello, I need help with deleting my current account. Please assist me with this process.";
                      const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(
                        subject
                      )}&body=${encodeURIComponent(body)}`;
                      window.location.href = mailtoLink;
                    }}
                  >
                    <Text size="2">Delete account</Text>
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            className="main-content-container"
            height="100%"
            gap="8"
            justify="center"
            style={{
              alignSelf: "stretch",
              flex: "1 0 0",
              alignItems: "flex-start",
            }}
          >
            <Flex
              width="496px"
              direction="column"
              gap="5"
              style={{ alignItems: "flex-start", alignSelf: "stretch" }}
            >
              {/* First Name & Last Name */}
              <Form.Root
                onSubmit={handleFirstNameLastNameSubmit}
                style={{ width: "100%" }}
              >
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "var(--space-4)",
                    alignSelf: "stretch",
                    padding: "var(--space-4)",
                  }}
                >
                  <Flex
                    gap="4"
                    style={{
                      alignItems: "center",
                      alignSelf: "stretch",
                      width: "100%",
                    }}
                  >
                    {/* First Name */}
                    <Flex
                      direction="column"
                      gap="1"
                      style={{
                        alignItems: "center",
                        alignSelf: "stretch",
                        width: "100%",
                      }}
                    >
                      <Text size="2" weight="medium" style={{ width: "100%" }}>
                        First name
                      </Text>
                      <TextField.Root
                        size="2"
                        style={{ width: "100%" }}
                        value={firstName}
                        onChange={(e) => handleFirstNameChange(e.target.value)}
                      />
                    </Flex>
                    {/* Last Name */}
                    <Flex
                      direction="column"
                      gap="1"
                      style={{
                        alignItems: "center",
                        alignSelf: "stretch",
                        width: "100%",
                      }}
                    >
                      <Text size="2" weight="medium" style={{ width: "100%" }}>
                        Last name
                      </Text>
                      <TextField.Root
                        size="2"
                        style={{ width: "100%" }}
                        value={lastName}
                        onChange={(e) => handleLastNameChange(e.target.value)}
                      />
                    </Flex>
                  </Flex>
                  {/* Save Changes Button */}
                  <Button
                    type="submit"
                    size="2"
                    variant="solid"
                    style={
                      isDirtyNameUpdate
                        ? { cursor: "pointer" }
                        : { cursor: "not-allowed" }
                    }
                    disabled={
                      !isDirtyNameUpdate || firstName === "" || lastName === ""
                    }
                    loading={updateUserMutation.isLoading}
                  >
                    Save changes
                  </Button>
                </Card>
              </Form.Root>
              {/* Email Address */}
              <Card
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  gap: "var(--space-4)",
                  alignSelf: "stretch",
                  padding: "var(--space-4)",
                }}
              >
                <Flex
                  gap="4"
                  style={{
                    alignItems: "center",
                    alignSelf: "stretch",
                    width: "100%",
                  }}
                >
                  {/* Email Address */}
                  <Flex
                    direction="column"
                    gap="1"
                    style={{
                      alignItems: "center",
                      alignSelf: "stretch",
                      width: "100%",
                    }}
                  >
                    <Text size="2" weight="medium" style={{ width: "100%" }}>
                      Email address
                    </Text>
                    <TextField.Root
                      size="2"
                      style={{ width: "100%" }}
                      value={userEmail}
                      readOnly
                    />
                  </Flex>
                </Flex>
                {/* Edit Email Button */}
                <Suspense>
                  <EmailUpdateModal
                    userEmail={userEmail}
                    onEmailUpdate={handleEmailUpdate}
                  />
                </Suspense>
              </Card>
              {/* Password */}
              <Form.Root
                onSubmit={handlePasswordResetSubmit}
                style={{ width: "100%" }}
              >
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "var(--space-4)",
                    alignSelf: "stretch",
                    padding: "var(--space-4)",
                  }}
                >
                  <Flex
                    gap="4"
                    style={{
                      alignItems: "center",
                      alignSelf: "stretch",
                      width: "100%",
                    }}
                  >
                    {/* New Password */}
                    <Flex
                      direction="column"
                      gap="1"
                      style={{
                        alignItems: "flex-start",
                        alignSelf: "stretch",
                        width: "100%",
                      }}
                    >
                      <Text size="2" weight="medium" style={{ width: "100%" }}>
                        New password
                      </Text>
                      <TextField.Root
                        size="2"
                        style={{ width: "100%" }}
                        type="password"
                        value={newPassword}
                        onChange={(e) =>
                          handleNewPasswordChange(e.target.value)
                        }
                      />
                      <Text
                        size="1"
                        weight="regular"
                        color={errors.newPassword ? "red" : "gray"}
                      >
                        Your password must be at least 8 characters long and
                        include at least one letter, one number, and one special
                        character (e.g., !@#$%^&*_+-;':"\|,./?~)
                      </Text>
                    </Flex>
                    {/* Confirm New Password */}
                    <Flex
                      direction="column"
                      gap="1"
                      style={{
                        alignItems: "flex-start",
                        alignSelf: "stretch",
                        width: "100%",
                      }}
                    >
                      <Text size="2" weight="medium" style={{ width: "100%" }}>
                        Confirm new password
                      </Text>
                      <TextField.Root
                        size="2"
                        style={{ width: "100%" }}
                        type="password"
                        value={confirmNewPassword}
                        onChange={(e) =>
                          handleConfirmNewPasswordChange(e.target.value)
                        }
                      />
                      {errors.confirmNewPassword && (
                        <Text size="1" weight="regular" color="red">
                          {errors.confirmNewPassword}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                  {/* Reset Password Button */}
                  <Button
                    type="submit"
                    size="2"
                    variant="surface"
                    style={
                      isDirtyPasswordUpdate
                        ? { cursor: "pointer" }
                        : { cursor: "not-allowed" }
                    }
                    disabled={
                      !isDirtyPasswordUpdate ||
                      newPassword === "" ||
                      confirmNewPassword === ""
                    }
                  >
                    Reset Password
                  </Button>
                </Card>
              </Form.Root>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* Toast Updates */}
      <ToastAlert />
    </React.Fragment>
  );
}
