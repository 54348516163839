import { useMutation } from "@tanstack/react-query";
import { getMailchimpAudiences } from '../../api/bIntegrationsApi';

export const useGetMailchimpAudiences = () => {
  return useMutation({
    mutationKey: ['get-mailchimp-audiences'],
    mutationFn: async (data) => {
      try {
        const response = await getMailchimpAudiences(data);
        return response.data;
      } catch (error) {
        console.error('API Error:', error);
        throw error;
      }
    }
  });
};