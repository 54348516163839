import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createMailchimpAudienceFields } from '../../api/bIntegrationsApi';

export const useCreateMailchimpFields = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationKey: ['create-mailchimp-fields'],
    mutationFn: (data) => createMailchimpAudienceFields(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["mailchimp-fields"],
      });
    },
  });
};