import { useState, useEffect } from 'react';
import { Flex, IconButton, Separator, Tooltip } from '@radix-ui/themes';
import { AvatarDropdownMenu, LayoutButton } from 'components';
import { ReactComponent as BWifiIcon } from '../assets/icons/favicon-dark.svg';
import { WifiIcon, ChatBubbleLeftIcon, BoltIcon, BuildingOffice2Icon, UsersIcon } from "@heroicons/react/16/solid";
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

export function MainLayout() {

  const navigate = useNavigate();
  const location = useLocation();

  // Helper function to get initial selection from pathname
  const getInitialSelection = (pathname) => {
    const path = pathname.split('/')[1]; // Get first segment after /
    if (path === 'wifi' || path === 'dashboard') return 'wifi';
    return path || 'wifi';
  };

  const [sidebarSelection, setSidebarSelection] = useState(
    getInitialSelection(location.pathname)
  );

  // Update selection when route changes
  useEffect(() => {
    setSidebarSelection(getInitialSelection(location.pathname));
  }, [location.pathname]);

  const handleSidebarSelection = (flag) => {
    setSidebarSelection(flag);
    if (flag === "wifi") {
      navigate(`${flag}/analytics`)
    } else {
      navigate(flag);
    }
  }

  return (
    <Flex className="sidebar" style={{alignSelf: "stretch"}} height="100%">
      <Flex className="sidebar" style={{background: "var(--color-panel-solid)", height: "100%"}} px="2" py="5" direction="column" justify="between" align="center">
        <IconButton className="sidebar-container" size="3" variant="ghost" onClick={() => navigate('/wifi/analytics')}>
          <BWifiIcon width="24" height="24" />
        </IconButton>
        <Flex className="sidebar-section" direction="column" align="center" gap="2">
          {/* <LayoutButton flag="chat" state={sidebarSelection} onClick={handleSidebarSelection} >
            <Tooltip content="Chat" side='right' sideOffset="1">
              <ChatBubbleLeftIcon width="24" height="24" color={ sidebarSelection === 'chat' ? 'white' : 'gray'}/>
            </Tooltip>
          </LayoutButton> */}
          <LayoutButton flag="wifi" state={sidebarSelection} onClick={handleSidebarSelection}>
            <Tooltip content="Wi-Fi" side='right' sideOffset="1">
              <WifiIcon width="24" height="24" color={ sidebarSelection === 'wifi' ? 'white' : 'gray'} />
            </Tooltip>
          </LayoutButton>
          <LayoutButton flag="customers" state={sidebarSelection} onClick={handleSidebarSelection}>
            <Tooltip content="Customers" side='right' sideOffset="1">
              <UsersIcon width="24" height="24" color={ sidebarSelection === 'customers' ? 'white' : 'gray'} />
            </Tooltip>
          </LayoutButton>
        </Flex>
        <Flex className="sidebar-section" display="flex" direction="column" align="center" gap="4">
          <Flex className="sidebar-section" display="flex" direction="column" align="start" gap="2">
            <LayoutButton flag="integrations" state={sidebarSelection} onClick={handleSidebarSelection}>
              <Tooltip content="Integrations" side='right' sideOffset="1">
                <BoltIcon width="24" height="24" color={ sidebarSelection === 'integrations' ? 'white' : 'gray'} />
              </Tooltip>
            </LayoutButton>
            <LayoutButton flag="organisation" state={sidebarSelection} onClick={handleSidebarSelection}>
              <Tooltip content="Organisation" side="right" sideOffset="1">
                <BuildingOffice2Icon width="24" height="24" color={ sidebarSelection === 'organisation' ? 'white' : 'gray'} />
              </Tooltip>
            </LayoutButton>
          </Flex>
          <AvatarDropdownMenu size="3"/>
        </Flex>
      </Flex>
      <Separator orientation="vertical" size="4"/>
      <Flex style={{alignSelf: 'stretch', flex: '1 0 0', alignItems: 'flex-start', height: '100%'}}>
        {/* This is where the routed components will be rendered */}
        <Outlet />
      </Flex>
    </Flex>
  );
}