import { useQuery } from "@tanstack/react-query";
import { getAirshipStatus } from "../../api/bAirshipApi";

export const useGetAirshipStatus = (organisationId, enabled = true) => {
  return useQuery({
    queryKey: ["airship-status", organisationId],
    queryFn: () => getAirshipStatus(organisationId),
    enabled: !!organisationId && enabled,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
  });
};
