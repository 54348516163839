import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { useTheme } from "../../context/ThemeContext";
import "./ChartColours.css";
import { set } from "date-fns";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const getCSSVariable = (variable) => getComputedStyle(document.documentElement).getPropertyValue(variable).trim();

export const UniqueGuestsByAgeChart = ({ metricsData, displayBy }) => {
  const { appearance } = useTheme();
  const [fontColor, setFontColor] = useState("");
  const [barColour, setBarColour] = useState("");
  const [barBorderColour, setBarBorderColour] = useState("");
  const [gridColour, setGridColour] = useState("");
  const [chartNewConnectionColour, setChartNewConnectionColour] = useState("");
  const [chartReturningConnectionColour, setChartReturningConnectionColour] = useState("");
  const [chartGenderMaleColour, setChartGenderMaleColour] = useState("");
  const [chartGenderFemaleColour, setChartGenderFemaleColour] = useState("");
  const [chartGenderOtherColour, setChartGenderOtherColour] = useState("");
  const [chartGenderNoResponseColour, setChartGenderNoResponseColour] = useState("");
  const [chartNewConnectionBorderColour, setChartNewConnectionBorderColour] = useState("");
  const [chartReturningConnectionBorderColour, setChartReturningConnectionBorderColour] = useState("");
  const [chartGenderMaleBorderColour, setChartGenderMaleBorderColour] = useState("");
  const [chartGenderFemaleBorderColour, setChartGenderFemaleBorderColour] = useState("");
  const [chartGenderOtherBorderColour, setChartGenderOtherBorderColour] = useState("");
  const [chartGenderNoResponseBorderColour, setChartGenderNoResponseBorderColour] = useState("");

  useEffect(() => {
    const fontColourLight = getCSSVariable("--chart-font-colour-light");
    const fontColourDark = getCSSVariable("--chart-font-colour-dark");
    const barColour = getCSSVariable("--chart-bar-colour");
    const barBorderColour = getCSSVariable("--chart-bar-border-colour");
    const barColourDark = getCSSVariable("--chart-bar-colour-dark");
    const barBorderColourDark = getCSSVariable("--chart-bar-border-colour-dark");
    const gridColourLight = getCSSVariable("--chart-grid-colour-light");
    const gridColourDark = getCSSVariable("--chart-grid-colour-dark");
    const chartNewConnectionColour = getCSSVariable("--chart-new-connection-colour");
    const chartReturningConnectionColour = getCSSVariable("--chart-returning-connection-colour");
    const chartGenderMaleColour = getCSSVariable("--chart-gender-male-colour");
    const chartGenderFemaleColour = getCSSVariable("--chart-gender-female-colour");
    const chartGenderOtherColour = getCSSVariable("--chart-gender-other-colour");
    const chartGenderNoResponseColour = getCSSVariable("--chart-gender-no-response-colour");
    const chartNewConnectionBorderColour = getCSSVariable("--chart-new-connection-border-colour");
    const chartReturningConnectionBorderColour = getCSSVariable("--chart-returning-connection-border-colour");
    const chartNewConnectionBorderColourDark = getCSSVariable("--chart-new-connection-border-colour-dark");
    const chartReturningConnectionBorderColourDark = getCSSVariable("--chart-returning-connection-border-colour-dark");
    const chartNewConnectionColourDark = getCSSVariable("--chart-new-connection-colour-dark");
    const chartReturningConnectionColourDark = getCSSVariable("--chart-returning-connection-colour-dark");
    const chartGenderMaleBorderColour = getCSSVariable("--chart-gender-male-border-colour");
    const chartGenderFemaleBorderColour = getCSSVariable("--chart-gender-female-border-colour");
    const chartGenderOtherBorderColour = getCSSVariable("--chart-gender-other-border-colour");
    const chartGenderNoResponseBorderColour = getCSSVariable("--chart-gender-no-response-border-colour");
    const chartGenderMaleBorderColourDark = getCSSVariable("--chart-gender-male-border-colour-dark");
    const chartGenderFemaleBorderColourDark = getCSSVariable("--chart-gender-female-border-colour-dark");
    const chartGenderOtherBorderColourDark = getCSSVariable("--chart-gender-other-border-colour-dark");
    const chartGenderNoResponseBorderColourDark = getCSSVariable("--chart-gender-no-response-border-colour-dark");
    const chartGenderMaleColourDark = getCSSVariable("--chart-gender-male-colour-dark");
    const chartGenderFemaleColourDark = getCSSVariable("--chart-gender-female-colour-dark");
    const chartGenderOtherColourDark = getCSSVariable("--chart-gender-other-colour-dark");
    const chartGenderNoResponseColourDark = getCSSVariable("--chart-gender-no-response-colour-dark");

    if (appearance === "dark") {
      setFontColor(fontColourDark);
      setGridColour(gridColourDark);
      setBarColour(barColourDark);
      setBarBorderColour(barBorderColourDark);
      setChartNewConnectionColour(chartNewConnectionColourDark);
      setChartReturningConnectionColour(chartReturningConnectionColourDark);
      setChartNewConnectionBorderColour(chartNewConnectionBorderColourDark);
      setChartReturningConnectionBorderColour(chartReturningConnectionBorderColourDark);
      setChartGenderMaleColour(chartGenderMaleColourDark);
      setChartGenderFemaleColour(chartGenderFemaleColourDark);
      setChartGenderOtherColour(chartGenderOtherColourDark);
      setChartGenderNoResponseColour(chartGenderNoResponseColourDark);
      setChartGenderMaleBorderColour(chartGenderMaleBorderColourDark);
      setChartGenderFemaleBorderColour(chartGenderFemaleBorderColourDark);
      setChartGenderOtherBorderColour(chartGenderOtherBorderColourDark);
      setChartGenderNoResponseBorderColour(chartGenderNoResponseBorderColourDark);
    } else {
      setFontColor(fontColourLight);
      setGridColour(gridColourLight);
      setBarColour(barColour);
      setBarBorderColour(barBorderColour);
      setChartNewConnectionColour(chartNewConnectionColour);
      setChartReturningConnectionColour(chartReturningConnectionColour);
      setChartNewConnectionBorderColour(chartNewConnectionBorderColour);
      setChartReturningConnectionBorderColour(chartReturningConnectionBorderColour);
      setChartGenderMaleColour(chartGenderMaleColour);
      setChartGenderFemaleColour(chartGenderFemaleColour);
      setChartGenderOtherColour(chartGenderOtherColour);
      setChartGenderNoResponseColour(chartGenderNoResponseColour);
      setChartGenderMaleBorderColour(chartGenderMaleBorderColour);
      setChartGenderFemaleBorderColour(chartGenderFemaleBorderColour);
      setChartGenderOtherBorderColour(chartGenderOtherBorderColour);
      setChartGenderNoResponseBorderColour(chartGenderNoResponseBorderColour);
    }
  }, [appearance]);

  let processed_chart_data = [];

  if (metricsData && metricsData.connection_details && metricsData.connection_details.length > 0) {
    const ageGroups = ["Under 18", "18-24", "25-34", "35-44", "45-54", "55-64", "Above 65"];
    processed_chart_data = ageGroups.map((group) => ({
      ageGroup: group,
      new: 0,
      returning: 0,
      total: 0,
      male: 0,
      female: 0,
      others: 0,
      "prefer not to respond": 0,
    }));

    const uniqueEmails = new Set();

    for (let entry of metricsData.connection_details) {
      if (!uniqueEmails.has(entry.email_address)) {
        uniqueEmails.add(entry.email_address);
        const age = entry.age;
        let index = -1;

        if (age === "Under 18") index = 0;
        else if (age === "18-24") index = 1;
        else if (age === "25-34") index = 2;
        else if (age === "35-44") index = 3;
        else if (age === "45-54") index = 4;
        else if (age === "55-64") index = 5;
        else if (age === "65+") index = 6;

        if (index !== -1) {
          if (entry.guest_status === "returning") {
            processed_chart_data[index].returning++;
          } else {
            processed_chart_data[index].new++;
          }

          if (entry.gender) {
            processed_chart_data[index][entry.gender]++;
          }

          processed_chart_data[index].total = processed_chart_data[index].new + processed_chart_data[index].returning;
        }
      }
    }
  }

  const hasNonZeroData = (data) => {
    return data.some((value) => value > 0);
  };

  const chartData = {
    labels: processed_chart_data.map((entry) => entry.ageGroup),
    datasets:
      displayBy === "guest_status"
        ? [
            {
              label: "Returning",
              data: processed_chart_data.map((entry) => entry.returning),
              backgroundColor: chartReturningConnectionColour,
              borderColor: chartReturningConnectionBorderColour,
              borderWidth: 1,
              borderRadius: 4,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry.returning)),
            },
            {
              label: "New",
              data: processed_chart_data.map((entry) => entry.new),
              backgroundColor: chartNewConnectionColour,
              borderColor: chartNewConnectionBorderColour,
              borderWidth: 1,
              borderRadius: 4,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry.new)),
            },
          ]
        : displayBy === "gender"
        ? [
            {
              label: "Male",
              data: processed_chart_data.map((entry) => entry["male"]),
              backgroundColor: chartGenderMaleColour,
              borderColor: chartGenderMaleBorderColour,
              borderWidth: 1,
              borderRadius: 4,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry["male"])),
            },
            {
              label: "Female",
              data: processed_chart_data.map((entry) => entry["female"]),
              backgroundColor: chartGenderFemaleColour,
              borderColor: chartGenderFemaleBorderColour,
              borderWidth: 1,
              borderRadius: 4,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry["female"])),
            },
            {
              label: "Other",
              data: processed_chart_data.map((entry) => entry["others"]),
              backgroundColor: chartGenderOtherColour,
              borderColor: chartGenderOtherBorderColour,
              borderWidth: 1,
              borderRadius: 4,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry["others"])),
            },
            {
              label: "No Response",
              data: processed_chart_data.map((entry) => entry["prefer not to respond"]),
              backgroundColor: chartGenderNoResponseColour,
              borderColor: chartGenderNoResponseBorderColour,
              borderWidth: 1,
              borderRadius: 4,
              hidden: !hasNonZeroData(processed_chart_data.map((entry) => entry["prefer not to respond"])),
            },
          ]
        : [
            {
              label: "Total",
              data: processed_chart_data.map((entry) => entry.total),
              backgroundColor: barColour,
              borderColor: barBorderColour,
              borderWidth: 1,
              borderRadius: 4,
            },
          ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        usePointStyle: true,
        itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
        boxWidth: 6,
        callbacks: {
          title: function (tooltipItems) {
            return tooltipItems[0].label;
          },
          label: function (context) {
            if (displayBy === "guest_status") {
              const dataPoint = processed_chart_data[context.dataIndex];
              const total = dataPoint.total;
              const percentage = total > 0 ? ((context.parsed.y / total) * 100).toFixed(1) : 0;

              if (context.dataset.label === "New") {
                return `New: ${percentage}% (${context.parsed.y})`;
              } else if (context.dataset.label === "Returning") {
                return `Returning: ${percentage}% (${context.parsed.y})`;
              }
            } else if (displayBy === "gender") {
              const dataPoint = processed_chart_data[context.dataIndex];
              const total = dataPoint.total;
              const percentage = total > 0 ? ((context.parsed.y / total) * 100).toFixed(1) : 0;
              return `${context.dataset.label}: ${percentage}% (${context.parsed.y})`;
            }
            return `Total: ${context.parsed.y}`;
          },
          afterBody: function (tooltipItems) {
            if (displayBy === "guest_status" || displayBy === "gender") {
              const index = tooltipItems[0].dataIndex;
              const total = processed_chart_data[index].total;
              return [`Total: ${total}`];
            }
            return [];
          },
          labelColor: function (context) {
            return {
              borderColor: context.dataset.backgroundColor,
              backgroundColor: context.dataset.backgroundColor,
            };
          },
          labelPointStyle: function (context) {
            return {
              pointStyle: "circle",
              rotation: 0,
            };
          },
        },
        bodyFont: {
          family: "Figtree",
        },
        titleFont: {
          family: "Figtree",
        },
        footerFont: {
          family: "Figtree",
        },
      },
    },
    scales: {
      x: {
        stacked: displayBy === "guest_status" || displayBy === "gender",
        ticks: {
          color: fontColor,
          font: {
            family: "Figtree",
            size: 12,
          },
          padding: 8,
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        },
      },
      y: {
        stacked: displayBy === "guest_status" || displayBy === "gender",
        beginAtZero: true,
        position: "right",
        ticks: {
          color: fontColor,
          font: {
            family: "Figtree",
            size: 12,
          },
          padding: 8,
          stepSize: 1, // Force integer steps
          callback: function (value) {
            if (Number.isInteger(value)) {
              return value;
            }
            return null; // Hide non-integer ticks
          },
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};
