import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement, Filler } from "chart.js";
import { DateTime } from "luxon";
import { Badge } from "@radix-ui/themes";
import { useTheme } from "../../../context/ThemeContext";
import "../ChartColours.css";
import { stack } from "d3";

ChartJS.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement, Filler);

const getCSSVariable = (variable) => getComputedStyle(document.documentElement).getPropertyValue(variable).trim();

export const PeoplePasserbyByDayChart = ({ data, startTimeMilliseconds, endTimeMilliseconds }) => {
  const { appearance } = useTheme();
  const [fontColor, setFontColor] = useState("");
  const [gridColour, setGridColour] = useState("");
  const [chartPointsBgColour, setChartPointsBgColour] = useState("");
  const [chartContainerRef, setChartContainerRef] = useState(null);
  const [badgePosition, setBadgePosition] = useState({ left: 0, top: 0, display: "none" });
  const [chartGuestBorderColour, setChartGuestBorderColour] = useState("");
  const [chartGuestFillColour, setChartGuestFillColour] = useState("");
  const [chartPasserbyBorderColour, setChartPasserbyBorderColour] = useState("");
  const [chartPasserbyFillColour, setChartPasserbyFillColour] = useState("");

  useEffect(() => {
    const fontColourLight = getCSSVariable("--chart-font-colour-light");
    const fontColourDark = getCSSVariable("--chart-font-colour-dark");
    const gridColourLight = getCSSVariable("--chart-grid-colour-light");
    const gridColourDark = getCSSVariable("--chart-grid-colour-dark");
    const chartPointsBgColourLight = getCSSVariable("--chart-points-bg-colour-light");
    const chartPointsBgColourDark = getCSSVariable("--chart-points-bg-colour-dark");
    const guestBorderColour = getCSSVariable("--chart-guest-border-colour");
    const guestFillColour = getCSSVariable("--chart-guest-fill-colour");
    const passerbyBorderColour = getCSSVariable("--chart-passerby-border-colour");
    const passerbyFillColour = getCSSVariable("--chart-passerby-fill-colour");
    const passerbyFillColourLight = getCSSVariable("--chart-passerby-fill-colour-light");
    const guestFillColourLight = getCSSVariable("--chart-guest-fill-colour-light");
    const passerbyBorderColourLight = getCSSVariable("--chart-passerby-border-colour-light");
    const guestBorderColourLight = getCSSVariable("--chart-guest-border-colour-light");

    if (appearance === "dark") {
      setFontColor(fontColourDark);
      setGridColour(gridColourDark);
      setChartPointsBgColour(chartPointsBgColourDark);
      setChartPasserbyFillColour(passerbyFillColour);
      setChartGuestFillColour(guestFillColour);
      setChartGuestBorderColour(guestBorderColour);
      setChartPasserbyBorderColour(passerbyBorderColour);
    } else {
      setFontColor(fontColourLight);
      setGridColour(gridColourLight);
      setChartPointsBgColour(chartPointsBgColourLight);
      setChartPasserbyFillColour(passerbyFillColourLight);
      setChartGuestFillColour(guestFillColourLight);
      setChartGuestBorderColour(guestBorderColourLight);
      setChartPasserbyBorderColour(passerbyBorderColourLight);
    }
  }, [appearance]);

  // Calculate date intervals
  const calculateIntervals = (startTime, endTime) => {
    const intervals = [];
    let current = new Date(startTime);

    while (current < endTime) {
      const next = new Date(current.getFullYear(), current.getMonth(), current.getDate() + 1);
      intervals.push(next - current);
      current = next;
    }

    return intervals;
  };

  const generateBins = (start, end, intervals) => {
    const bins = [];
    let current = start;
    let intervalIndex = 0;

    while (current < end) {
      const interval = intervals[intervalIndex % intervals.length];
      bins.push([current, current + interval]);
      current += interval;
      intervalIndex++;
    }

    return bins;
  };

  const interval = calculateIntervals(startTimeMilliseconds, endTimeMilliseconds);
  const date_bins = generateBins(startTimeMilliseconds, endTimeMilliseconds, interval);

  // Always initialize processed_chart_data with date bins
  let processed_chart_data = date_bins.map(([start]) => ({
    date: start,
    visitors: 0,
    passersby: 0,
  }));

  // Fill in the data from the API only if it exists
  if (data?.data?.chart_data?.daily) {
    data.data.chart_data.daily.forEach((entry) => {
      const entryDate = new Date(entry.time_period).getTime();
      const binIndex = processed_chart_data.findIndex((bin) => DateTime.fromMillis(bin.date).hasSame(DateTime.fromMillis(entryDate), "day"));

      if (binIndex !== -1) {
        processed_chart_data[binIndex] = {
          date: processed_chart_data[binIndex].date,
          visitors: entry.visitors,
          passersby: entry.passersby,
        };
      }
    });
  }

  // Find today's index
  const today = DateTime.now().startOf("day");
  const todayIndex = processed_chart_data.findIndex((entry) => DateTime.fromMillis(entry.date).hasSame(today, "day"));

  const formatXAxisLabel = (timestamp) => {
    return DateTime.fromMillis(timestamp).toFormat("dd MMM");
  };

  const isCurrentDayInRange = () => {
    const endDate = DateTime.fromMillis(endTimeMilliseconds);
    const now = DateTime.now();
    return now.hasSame(endDate, "day");
  };

  const chartData = {
    labels: processed_chart_data.map((entry, index) => (index === todayIndex && isCurrentDayInRange() ? "" : formatXAxisLabel(entry.date))),
    datasets: [
      {
        label: "Est. guests",
        data: processed_chart_data.map((entry) => entry.visitors),
        backgroundColor: chartGuestFillColour,
        borderColor: chartGuestBorderColour,
        fill: true,
        borderWidth: 1,
        tension: 0,
        pointBackgroundColor: chartPointsBgColour,
        order: 1,
      },
      {
        label: "Est. passersby",
        data: processed_chart_data.map((entry) => entry.passersby),
        backgroundColor: chartPasserbyFillColour,
        borderColor: chartPasserbyBorderColour,
        fill: true,
        borderWidth: 1,
        borderDash: [5, 5],
        tension: 0,
        pointBackgroundColor: chartPointsBgColour,
        order: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      onComplete: function (animation) {
        if (chartContainerRef && todayIndex !== -1) {
          const chart = animation.chart;
          const xAxis = chart.scales.x;
          const currentDayX = xAxis.getPixelForValue(todayIndex);
          const currentDayY = xAxis.top + xAxis.options.ticks.padding;

          setBadgePosition({
            left: currentDayX,
            top: currentDayY - 6,
            display: isCurrentDayInRange() ? "block" : "none",
          });
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        usePointStyle: true,
        itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
        boxWidth: 6,
        callbacks: {
          title: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            return formatXAxisLabel(processed_chart_data[index].date);
          },
          label: function (context) {
            const dataPoint = processed_chart_data[context.dataIndex];
            const total = dataPoint.visitors + dataPoint.passersby;
            const percentage = total > 0 ? ((context.parsed.y / total) * 100).toFixed(1) : 0;
            return `${context.dataset.label}: ${percentage}% (${context.parsed.y})`;
          },
          afterBody: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            const total = processed_chart_data[index].visitors + processed_chart_data[index].passersby;
            return [`Total: ${total}`];
          },
          labelColor: function (context) {
            return {
              borderColor: context.dataset.borderColor,
              backgroundColor: context.dataset.borderColor,
            };
          },
          labelPointStyle: function (context) {
            return {
              pointStyle: "circle",
              rotation: 0,
            };
          },
        },
        bodyFont: {
          family: "Figtree",
        },
        titleFont: {
          family: "Figtree",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: fontColor,
          font: {
            family: "Figtree",
            size: 12,
          },
          padding: 8,
          callback: function (value, index) {
            if (processed_chart_data.length > 17) {
              return index % 2 === 0 ? this.getLabelForValue(value) : "";
            }
            return this.getLabelForValue(value);
          },
          maxRotation: 0,
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        position: "right",
        ticks: {
          color: fontColor,
          font: {
            family: "Figtree",
            size: 12,
          },
          padding: 8,
          stepSize: 1, // Force integer steps
          callback: function (value) {
            if (Number.isInteger(value)) {
              return value;
            }
            return null; // Hide non-integer ticks
          },
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        },
      },
    },
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }} ref={setChartContainerRef}>
      <Line data={chartData} options={options} style={{ width: "100%", height: "100%" }} />
      {todayIndex !== -1 && (
        <div
          style={{
            position: "absolute",
            left: `${badgePosition.left}px`,
            top: `${badgePosition.top}px`,
            transform: "translate(-50%, 0)",
            display: badgePosition.display,
            pointerEvents: "none",
            zIndex: 10,
          }}
        >
          <Badge variant="soft" size="1">
            {formatXAxisLabel(processed_chart_data[todayIndex]?.date)}
          </Badge>
        </div>
      )}
    </div>
  );
};
