import { useQuery } from "@tanstack/react-query";
import { checkCiscoIntegrationStatus } from "../../api/ciscoMerakiApi";

export const useCheckCiscoIntegration = (organisationId) => {
  return useQuery({
    queryKey: ["cisco-integration-status", organisationId],
    queryFn: () => checkCiscoIntegrationStatus(organisationId),
    enabled: !!organisationId,
  });
};
