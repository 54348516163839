import React from "react";
import { Dialog, Flex, Text, TextField, Button, Progress } from "@radix-ui/themes";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

export function AuthMailchimpDialog({ isOpen, onOpenChange, onCancel, onSave, handleTextFieldChange, isApiKeyValid, isLoading, progressValue, title = "Connect Mailchimp" }) {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content maxWidth="600px" maxHeight="690px" align="center">
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          You can find your API key by logging into Mailchimp, navigating to Account Settings {">"} Extras {">"} API Keys, and copying an existing key or creating a new one.
        </Dialog.Description>

        <Flex className="slot-container" style={{ alignSelf: "stretch" }} display="flex" direction="column" align="start" gap="5">
          <Flex className="api-key-field" style={{ alignSelf: "stretch" }} display="flex" direction="column" align="start" gap="1">
            <Text size="2" weight="medium">
              API key
            </Text>
            <TextField.Root onChange={handleTextFieldChange} placeholder="Paste your key here" style={{ alignSelf: "stretch" }} size="2" variant="surface" />
            {!isApiKeyValid && (
              <Text size="1" weight="regular" color="red">
                Please enter a valid API key.
              </Text>
            )}
          </Flex>
        </Flex>

        <Flex
          style={{
            alignSelf: "stretch",
            width: "100%",
          }}
          display="flex"
          justify="between"
          align="center"
          mt="4"
        >
          <Button size="2" variant="ghost" color="gray" onClick={() => window.open("https://us12.admin.mailchimp.com/account/api/", "_blank")}>
            <Flex display="flex" align="center" gap="2">
              Open Mailchimp
              <ArrowTopRightOnSquareIcon style={{ width: "16px", height: "16px" }} />
            </Flex>
          </Button>

          <Flex display="flex" gap="3" justify="end">
            <Dialog.Close>
              <Button onClick={onCancel} size="2" variant="soft" color="gray" style={{ cursor: "pointer" }}>
                Cancel
              </Button>
            </Dialog.Close>
            <Button onClick={onSave} size="2" variant="soft" color="accent" loading={isLoading} style={{ cursor: "pointer" }}>
              Save and continue
            </Button>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}
