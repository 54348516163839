import React, { useState, useEffect } from "react";
import { validateUnifiControllerUrl } from "components/utils/validationUtils";
import { AuthUnifiDialog } from "./AuthUnifiDialog";
import { useLocationContext } from "context";
import { Button, Badge, Card, Flex, Text } from "@radix-ui/themes";
import { useAuthenticateIntegrations, useGetActiveIntegrations } from "hooks";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../context/ToastContext";
import { ToastAlert } from "components";

export function BrowseUnifiCard({ userProfile }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setToastMessage } = useToast();

  const [payloadOne, setPayloadOne] = useState({
    unifi_controller_url: null,
    username: null,
    password: null,
    controller_name: "Controller 1", // Default for new controllers
  });

  const [openDialog, setOpenDialog] = useState({
    auth: false,
    detail: false,
    success: false,
  });
  const [validAuthDetails, setValidAuthDetails] = useState({
    unifi_url_valid: true,
    username_valid: true,
    password_valid: true,
  });

  const authenticateIntegrations = useAuthenticateIntegrations();
  const { data: activeIntegrationsData } = useGetActiveIntegrations(
    userProfile.organisation_id
  );

  const [progressValue, setProgressValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // Add this after your state declaration
  useEffect(() => {
    const isActive = isUnifiActive();
    setIntegrationStatus(isActive ? "active" : "inactive");
  }, [activeIntegrationsData]); // Dependency on activeIntegrationsData

  // The rest of your component remains the same
  const isUnifiActive = () => {
    if (!activeIntegrationsData?.data?.integrations) return false;
    return activeIntegrationsData.data.integrations.some(
      (integration) => integration.name === "unifi"
    );
  };

  const handleOpenChange = (dialog_type, isOpen) => {
    setOpenDialog({
      ...openDialog,
      [dialog_type]: isOpen,
    });
    if (!isOpen) {
      handleCancelAuthDialog();
    }
  };

  const handleTextFieldChange = (key) => {
    return (event) => {
      setPayloadOne({
        ...payloadOne,
        [key]: event.target.value,
      });
    };
  };

  const handleCancelAuthDialog = () => {
    setPayloadOne({
      unifi_controller_url: null,
      username: null,
      password: null,
      controller_name: "Controller 1", // Reset to default
    });
    setValidAuthDetails({
      unifi_url_valid: true,
      username_valid: true,
      password_valid: true,
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    setProgressValue(10);

    const url = payloadOne.unifi_controller_url;
    const username = payloadOne.username;
    const password = payloadOne.password;

    try {
      setProgressValue(50);
      const result = await authenticateIntegrations.mutateAsync({
        timestamp: Date.now(),
        organisation_id: userProfile.organisation_id,
        type: "unifi",
        sub_payload: {
          ...payloadOne,
          controller_name: "Controller 1",
        },
      });

      setProgressValue(80);
      queryClient.invalidateQueries(["authenticate-integrations"]);

      navigate("/unifi-setup", {
        state: {
          authData: result?.data?.data,
          orgId: userProfile.organisation_id,
          authPayload: payloadOne,
        },
      });
    } catch (error) {
      if (error.response?.status === 400) {
        setValidAuthDetails((prev) => {
          const newState = {
            unifi_url_valid: false,
            username_valid: false,
            password_valid: false,
          };
          return newState;
        });
      } else if (error.response?.status === 500) {
        setToastMessage(
          "Internal server error occurred. Please try again later."
        );
      }
    } finally {
      setIsLoading(false);
      setProgressValue(0);
    }
  };

  const [integrationStatus, setIntegrationStatus] = useState("inactive");

  const handleEmailRedirect = () => {
    const supportEmail = "support@b-connect.co.uk";
    const subject = "Support Request";
    const body = "Hello, I need help with...";
    const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const statusButton =
    integrationStatus === "active" ? (
      <Badge
        size="1"
        variant="soft"
        color="green"
        asChild={false}
        style={{ pointerEvents: "none", userSelect: "none" }}
      >
        Connected
      </Badge>
    ) : (
      // <Button
      //   onClick={() => handleOpenChange("auth", true)}
      //   size="2"
      //   variant="soft"
      //   color="gray"
      // >
      //   Activate
      // </Button>
      <Button
        onClick={handleEmailRedirect}
        size="2"
        variant="soft"
        color="gray"
      >
        Contact support
      </Button>
    );

  return (
    <>
      <Card
        style={{
          display: "flex",
          minWidth: "455px",
          padding: "var(--space-4)",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Flex display="flex" align="center" gap="4">
          <Flex
            style={{
              borderRadius: "var(--space-1)",
              background: "#FFFFFF",
              border: "1px solid var(--gray-a6)",
            }}
            display="flex"
            height="40px"
            width="40px"
            p="2"
            justify="center"
            align="center"
            gap="2"
          >
            <img
              src={require("../../../assets/images/unifi.png")}
              alt="unifi icon"
              style={{
                width: "24px",
                borderRadius: "var(--space-1)",
              }}
            />
          </Flex>
          <Flex
            display="flex"
            direction="column"
            justify="center"
            align="start"
          >
            <Text size="4" weight="medium">
              Unifi
            </Text>
            <Text
              style={{ color: "var(--gray-a11)" }}
              size="1"
              weight="regular"
            >
              Wi-Fi
            </Text>
          </Flex>
        </Flex>
        {statusButton}
      </Card>

      <AuthUnifiDialog
        isOpen={openDialog.auth}
        onOpenChange={(isOpen) => handleOpenChange("auth", isOpen)}
        onCancel={handleCancelAuthDialog}
        onSave={handleSave}
        handleTextFieldChange={handleTextFieldChange}
        validAuthDetails={validAuthDetails}
        isLoading={isLoading}
        progressValue={progressValue}
        title="Activate Unifi"
      />
      <div
        style={{ position: "fixed", zIndex: 2147483647, pointerEvents: "none" }}
      >
        <ToastAlert />
      </div>
    </>
  );
}
