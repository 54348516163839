import { useQueryClient, useMutation } from "@tanstack/react-query";
import { attemptUpdateOrg } from "../../api/OrgApi";

export const useUpdateOrg = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["update-org"],
    mutationFn: async (data) => attemptUpdateOrg(data),
    onSuccess: async (data) => {
      // Invalidate relevant queries to refresh data
      await queryClient.invalidateQueries({ queryKey: ['org-details'] });
      await queryClient.invalidateQueries({ queryKey: ['profile'] });
    }
  });
};
