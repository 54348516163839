import { useState, useEffect } from "react";
import { Flex, Text, Button, SegmentedControl } from "@radix-ui/themes";
import { MenuItem, DatePickerWithRange, LocationMultiSelect } from "components";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useProfileContext, useLocationContext, useDateRangeContext } from "context";
import { SparklesIcon } from "@heroicons/react/16/solid";
import { ZonesMultiSelect } from "components/zonesMultiSelect/ZonesMultiSelect";
import { useGetZones, useCheckCiscoIntegration } from "hooks";
import { FilterByMultiSelect } from "components/multiSelect/FilterByMultiSelect";
import { DisplayBySelect } from "components/select/DisplayBySelect";

export function WifiLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userProfile } = useProfileContext();
  const { initialItems, locationSelection, setLocationSelection, locationsData, isLocationsSuccess, allLocations } = useLocationContext();
  const { selectedDateRange, setSelectedDateRange, dateRangeDifference } = useDateRangeContext();
  const [disableBefore, setDisableBefore] = useState(null);
  const [insightsReady, setInsightsReady] = useState(false);
  const [triggerInsights, setTriggerInsights] = useState(false);
  const [zoneSelection, setZoneSelection] = useState([]);
  const [displayBy, setDisplayBy] = useState("default");
  const [activeView, setActiveView] = useState("presence");
  const { data: ciscoIntegration, isLoading: isIntegrationLoading } = useCheckCiscoIntegration(userProfile?.organisation_id);
  const [showSnackInsights, setShowSnackInsights] = useState(false);

  const { data: zonesData } = useGetZones(locationSelection?.map((location) => parseInt(location)) || []);

  useEffect(() => {
    if (zonesData && zonesData.length > 0) {
      const allZoneIds = zonesData.map((zone) => zone.id.toString());
      setZoneSelection(allZoneIds);
    }
  }, [zonesData]);

  const zoneOptions = zonesData || [];

  const filterOptions = [
    { label: "Customer loyalty", value: "guest_status" },
    { label: "Age", value: "age" },
    { label: "Gender", value: "gender" },
  ];

  const displayOptions = [
    { label: "Default", value: "default" },
    { label: "Customer loyalty", value: "guest_status" },
    { label: "Age", value: "age" },
    { label: "Gender", value: "gender" },
  ];

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filterSelection, setFilterSelection] = useState([]);

  // Menu state management
  const getMenuStateFromPath = (path) => {
    if (path.includes("/wifi/analytics")) return "analytics";
    if (path.includes("/wifi/locations")) return "locations";
    if (path.includes("/wifi/guest-portal")) return "guest-portal";
    if (path.includes("/wifi/networks")) return "networks";
    if (path.includes("/wifi/presence")) return "presence";
    return "analytics";
  };

  const [wifiMenuSelection, setWifiMenuSelection] = useState(getMenuStateFromPath(location.pathname));

  const handleWifiMenuSelection = (flag) => {
    setWifiMenuSelection(flag);
    navigate(flag);
  };

  useEffect(() => {
    setWifiMenuSelection(getMenuStateFromPath(location.pathname));
  }, [location.pathname]);

  return (
    <Flex className="guest-wifi-container" direction="column" height="100%" style={{ alignItems: "flex-start", flex: "1 0 0", alignSelf: "stretch" }}>
      <Flex
        className="guest-wifi-layout"
        p="8"
        direction="column"
        gap="8"
        height="100%"
        style={{
          alignSelf: "stretch",
          alignItems: "flex-start",
          flex: "1 0 0",
        }}
      >
        <Flex className="header-container" style={{ alignSelf: "stretch" }} justify="center" align="end" gap="2">
          <Flex
            className="date-greeting-container"
            justify="between"
            style={{
              flex: "1 0 0",
              alignItems: "center",
              alignSelf: "stretch",
            }}
          >
            <Text size="6" weight="bold">
              Wi-Fi
            </Text>

            {(wifiMenuSelection === "analytics" || (wifiMenuSelection === "presence" && ciscoIntegration?.data?.is_active)) && (
              <Flex
                className="filter-bar"
                style={{
                  flex: 1,
                  alignSelf: "stretch",
                  paddingLeft: "134px",
                }}
                display="flex"
                justify="between"
                align="center"
                gap="2"
              >
                {wifiMenuSelection === "presence" && ciscoIntegration?.data?.is_active && (
                  <Flex style={{ flexShrink: 0 }}>
                    <SegmentedControl.Root value={activeView} onValueChange={setActiveView}>
                      <SegmentedControl.Item value="presence">Presence</SegmentedControl.Item>
                      <SegmentedControl.Item value="dwell">Dwell</SegmentedControl.Item>
                    </SegmentedControl.Root>
                  </Flex>
                )}
                <Flex gap="2" align="center" style={{ marginLeft: "auto" }}>
                  {wifiMenuSelection === "analytics" && (
                    <>
                      <DisplayBySelect
                        options={displayOptions}
                        value={displayBy}
                        onValueChange={(newValue) => {
                          setDisplayBy((prevValue) => (prevValue === newValue ? null : newValue));
                        }}
                        placeholder="Display by"
                      />
                      <FilterByMultiSelect options={filterOptions} defaultValue={selectedCategories} onValueChange={setSelectedCategories} />
                    </>
                  )}
                  <DatePickerWithRange value={selectedDateRange} onDateChange={setSelectedDateRange} disableBeforeDate={disableBefore} />
                  {zoneOptions.length > 0 && <ZonesMultiSelect options={zoneOptions} defaultValue={zoneSelection} onValueChange={setZoneSelection} />}
                  <LocationMultiSelect options={initialItems} defaultValue={locationSelection} onValueChange={setLocationSelection} />
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex
          className="main-content"
          height="100%"
          gap="8"
          style={{
            alignSelf: "stretch",
            flex: "1 0 0",
            alignItems: "flex-start",
          }}
        >
          <Flex className="main-content-sidebar" width="144px" direction="column" gap="1" height="100%" style={{ alignItems: "flex-start" }}>
            <MenuItem flag="analytics" state={wifiMenuSelection} onClick={handleWifiMenuSelection} text="Connections" />
            <MenuItem flag="presence" state={wifiMenuSelection} onClick={handleWifiMenuSelection} text="Presence" />
            <MenuItem flag="locations" state={wifiMenuSelection} onClick={handleWifiMenuSelection} text="Locations" />
            <MenuItem flag="guest-portal" state={wifiMenuSelection} onClick={handleWifiMenuSelection} text="Portal" />
            <MenuItem flag="networks" state={wifiMenuSelection} onClick={handleWifiMenuSelection} text="Networks" />
          </Flex>
          {wifiMenuSelection === "presence" && !isIntegrationLoading && !ciscoIntegration?.data?.is_active ? (
            <Flex
              width="100%"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                flex: "1 0 0",
              }}
            >
              <Flex
                p="5"
                direction="column"
                gap="5"
                style={{
                  alignSelf: "stretch",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  borderRadius: "8px",
                  border: "1px dashed var(--gray-a6)",
                  flex: 1,
                  display: "flex",
                  position: "relative",
                  backgroundColor: "var(--color-background)",
                }}
              >
                <Flex direction="column" gap="5" style={{ alignItems: "center" }}>
                  <Text size="2" weight="regular" color="gray" style={{ width: "296px", textAlign: "center" }}>
                    To capture presence insights, ensure that your locations use a compatible Wi-Fi integration.
                  </Text>
                  <Button size="2" variant="soft" color="gray" onClick={() => navigate("/integrations/browse-all")}>
                    View Integrations
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          ) : (
            <Flex
              style={{
                alignSelf: "stretch",
                flex: "1 0 0",
                alignItems: "flex-start",
                height: "100%",
              }}
            >
              <Outlet
                context={{
                  selectedCategories,
                  setSelectedCategories,
                  filterSelection,
                  setFilterSelection,
                  setDisableBefore,
                  setInsightsReady,
                  triggerInsights,
                  showSnackInsights,
                  setShowSnackInsights,
                  zoneSelection,
                  setZoneSelection,
                  displayBy,
                  activeView,
                }}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
