import { useMutation } from "@tanstack/react-query";
import { mapMailchimpFields } from '../../api/bIntegrationsApi';

export const useMapFieldsInMailchimp = () => {
  return useMutation({
    mutationKey: ['map-mailchimp-fields'],
    mutationFn: async ({ audienceId, ...data }) => {
      try {
        const response = await mapMailchimpFields(audienceId, data);
        return response.data;
      } catch (error) {
        console.error('API Error:', error);
        throw error;
      }
    }
  });
};