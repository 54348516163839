import { Flex, Text, Card, Tooltip, IconButton } from "@radix-ui/themes";
import { InfoCircledIcon } from "@radix-ui/react-icons";

export function ChartCard({ text, children, tooltip, legend }) {
  return (
    <Card size="2" style={{
      display: "flex",
      height: "200px",
      width: "100%",
      flexDirection: "column",
      gap: "var(--space-2)",
      }}
    >
  
      <Flex className="title-and-icon-button" display="inline-flex" direction="row" align="center" justify="between">
        <Text as="span" size="2" weight="medium">
          {text}
        </Text>
        <Flex display="inline-flex" align="center" gap="16px">
          {legend}
          {/* <Tooltip content={tooltip}>
            <IconButton size="1" variant="ghost" color="gray">
              <InfoCircledIcon />
            </IconButton>
          </Tooltip> */}
        </Flex>  
      </Flex>
      <Flex height="144px">
      {children}
      </Flex>

    </Card>
  )
}