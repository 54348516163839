import baseCiscoMerakiApi from "./BaseCiscoMerakiApi";

export function attemptGetPresenceMetrics(data) {
  return baseCiscoMerakiApi.request({
    url: "/presence-metrics/",
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function checkCiscoIntegrationStatus(organisationId) {
  return baseCiscoMerakiApi.request({
    url: `/check-integration/?organisation_id=${organisationId}`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}
