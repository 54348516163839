import * as React from "react";
import { Text, Flex, Button, CheckboxGroup, Popover } from "@radix-ui/themes";
import { ChevronDownIcon } from "@radix-ui/react-icons";

export function FilterByMultiSelect({ options, onValueChange, defaultValue }) {
  const [selectedValues, setSelectedValues] = React.useState(defaultValue);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [display, setDisplay] = React.useState("Filter by");

  React.useEffect(() => {
    updateDisplay(defaultValue);
  }, []);

  const updateDisplay = (newSelectedValues) => {
    if (newSelectedValues.length === 0) {
      setDisplay("Filter by");
    } else if (newSelectedValues.length === options.length) {
      setDisplay("All filters");
    } else {
      setDisplay(
        <Flex align="center" gap="1">
          {newSelectedValues.length} filters
        </Flex>
      );
    }
  };

  const handleValueChange = (newValues) => {
    setSelectedValues(newValues);
    onValueChange(newValues);
    updateDisplay(newValues);
  };

  return (
    <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <Popover.Trigger asChild>
        <Button style={{ cursor: "pointer" }} size="2" variant="soft" color="gray" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
          <Text color="gray" weight="medium">
            {display}
          </Text>
          <ChevronDownIcon style={{ color: "var(--gray-11)" }} />
        </Button>
      </Popover.Trigger>

      <Popover.Content style={{ width: "200px", padding: "0" }}>
        <Flex direction="column" gap="1">
          <CheckboxGroup.Root size="2" value={selectedValues} onValueChange={handleValueChange}>
            {options.map((option) => (
              <Flex key={option.value} p="2">
                <CheckboxGroup.Item value={option.value}>{option.label}</CheckboxGroup.Item>
              </Flex>
            ))}
          </CheckboxGroup.Root>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
}
