import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text, TextField, Button, Link } from "@radix-ui/themes";
import { getProfile } from "api/UsersApi";
import { useUpdateUser } from 'hooks/app/useUpdateUser';
import { useSetPassword } from 'hooks/auth/useSetPassword';
import { ThemeProvider } from "../../context/ThemeContext";
import { Logo } from 'components';

function FirstTimeLoginComponent() {
  const navigate = useNavigate();
  const updateUserMutation = useUpdateUser();
  const passwordResetMutation = useSetPassword();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [organisationName, setorganisationName] = useState('');
  const [organisationImageUrl, setOrganisationImageUrl] = useState('');
  const [userId, setUserId] = useState(null);
  
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await getProfile();

        if (!profile.data.is_first_login || profile.data.user_role !== 'Owner') {
          navigate('/wifi/analytics');
          return;
        }

        setFormData({
          firstName: profile.data.first_name,
          lastName: profile.data.last_name,
          email: profile.data.email,
          newPassword: '',
          confirmPassword: '',
        });
        setorganisationName(profile.data.organisation_name);
        if (profile.data.organisation_image_url === null) {
          setOrganisationImageUrl('/LoginPageBG.png');
        } else {
          setOrganisationImageUrl(`${profile.data.organisation_image_url}`);
        }        
        setUserId(profile.data.id);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };
    
  
    fetchProfile();
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) newErrors.firstName = 'Input your first name.';
    if (!formData.lastName.trim()) newErrors.lastName = 'Input your last name.';
    if (!formData.newPassword) {
      newErrors.newPassword = 'Input a new password';
    } else if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]{8,}$/.test(formData.newPassword)) {
      newErrors.newPasswordFormat = 'Password does not meet requirements';
    }
    if (!formData.confirmPassword) {
      newErrors.confirmPassword = 'Input confirm new password';
    } else if (formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // First, update user profile information
      const updateData = { 
        id: userId, 
        is_first_login: false 
      };
      
      if (formData.firstName.trim()) updateData.first_name = formData.firstName.trim();
      if (formData.lastName.trim()) updateData.last_name = formData.lastName.trim();
      
      // Update user profile first
      updateUserMutation.mutate(updateData, {
        onSuccess: () => {
          // After profile update succeeds, reset the password
          passwordResetMutation.mutate({ 
            user_id: userId, 
            new_password: formData.newPassword 
          }, {
            onSuccess: () => {
              navigate('/organisation-details');
            },
            onError: (error) => {
              console.error('Password reset failed:', error);
            }
          });
        },
        onError: (error) => {
          console.error('Profile update failed:', error);
        }
      });
    }
  };

  const handleChange = (field) => (e) => {
    setFormData(prev => ({
      ...prev,
      [field]: e.target.value
    }));
    
    // Clear errors for this field when user starts typing
    setErrors(prev => {
      const updatedErrors = {...prev};
      
      // Remove direct field error
      delete updatedErrors[field];
      
      // Additionally handle password format error when typing in password field
      if (field === 'newPassword') {
        delete updatedErrors.newPasswordFormat;
      }
      
      // For confirm password, check if it matches when typing
      if (field === 'confirmPassword' || field === 'newPassword') {
        if (field === 'confirmPassword' && e.target.value === formData.newPassword) {
          delete updatedErrors.confirmPassword;
        } else if (field === 'newPassword' && e.target.value === formData.confirmPassword) {
          delete updatedErrors.confirmPassword;
        }
      }
      
      return updatedErrors;
    });
  };

  const [isImageLoaded, setIsImageLoaded] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.src = organisationImageUrl;
    img.onload = () => {
      setIsImageLoaded(true);
    };
  }
  , [organisationImageUrl]);

  return (
    <React.Fragment>      
      <Flex style={{ height: '100vh', width: '100vw'}}>
        {/* First Login Section */}
        <Flex direction="column" align="stretch" px="7" py="8" style={{alignItems: 'center', flex: '1 1 50%'}}>      
          <Flex justify="center" align="stretch" width="100%" style={{alignItems: 'center', flex: '1 0 0'}}>
            <Flex direction="column" gap="7" maxWidth="296px" style={{alignItems: 'center', justifyContent: 'center'}}>
              {/* Sign up header*/}
              <Flex direction="column" gap="2" maxWidth="169px" style={{alignItems: 'center', width: '100%'}}>
                <Text size="1" weight="regular" color='gray' align="center" style={{width: '100%'}}>{organisationName}</Text>
                <Text size="4" weight="bold" align="center" style={{width: '100%'}}>Confirm your details</Text>
              </Flex>
              {/* Form Section */}
              <form onSubmit={handleSubmit}>
              <Flex direction="column" gap="5" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                <Flex direction="column" gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                  <Flex direction="row" gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    {/* First Name */}
                    <Flex direction="column" gap="1" 
                      style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                      <Text as="label" size="2" weight="medium" style={{width: '100%'}}>First name</Text>
                      <TextField.Root style={{width: '100%'}}
                        value={formData.firstName}
                        onChange={handleChange('firstName')}
                      />
                      {errors.firstName && (<Text color="red" size="1">{errors.firstName}</Text>)}
                    </Flex>
                    {/* Last Name */}
                    <Flex direction="column" gap="1" 
                      style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                      <Text as="label" size="2" weight="medium" style={{width: '100%'}}>Last name</Text>
                      <TextField.Root style={{width: '100%'}}
                        value={formData.lastName}
                        onChange={handleChange('lastName')}
                      />
                      {errors.lastName && (<Text color="red" size="1">{errors.lastName}</Text>)}
                    </Flex>
                  </Flex>
                  {/* Email Address readonly Field */}
                  <Flex direction="column" gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Flex direction="column" gap="1" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                      <Text as="label" size="2" weight="medium" style={{width: '100%'}}>Email address</Text>
                      <TextField.Root style={{width: '100%'}} value={formData.email} readOnly />
                    </Flex>
                  </Flex>
                  {/* New and Confirm new password */}
                  <Flex gap="2" direction="column" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Flex direction="row" gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                      <Flex direction="column" gap="1" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                        <Text as="label" size="2" weight="medium" style={{width: '100%'}}>New password</Text>
                        <TextField.Root style={{width: '100%'}}
                          type='password'
                          value={formData.newPassword}
                          onChange={handleChange('newPassword')}
                        />
                        {errors.newPassword && (<Text color="red" size="1">{errors.newPassword}</Text>)}
                      </Flex>
                      <Flex direction="column" gap="1" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                        <Text as="label" size="2" weight="medium" style={{width: '100%'}}>Confirm password</Text>
                        <TextField.Root style={{width: '100%'}}
                          type='password'
                          value={formData.confirmPassword}
                          onChange={handleChange('confirmPassword')}
                        />
                        {errors.confirmPassword && (<Text color="red" size="1">{errors.confirmPassword}</Text>)}
                      </Flex>
                    </Flex>
                    <Text size="1" color={errors.newPasswordFormat ? "red" : "gray"} >
                      Your password must be at least 8 characters long and include at least one letter, one number, and one 
                      special character.
                    </Text>
                  </Flex>
                </Flex>
                <Flex direction="column" gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                  {/* Launch button */}
                  <Flex gap="4" direction="column" style={{alignItems: 'center', alignSelf: 'stretch', width: '100%'}}>
                    <Button type="submit" size="2" variant='solid' style={{width: '100%'}}>
                      Save and continue
                    </Button>
                    <Text size="1" color="gray" align="center">You can update your details later.</Text>
                  </Flex>
                </Flex>
                </Flex>
              </form>
            </Flex>
          </Flex>
          {/* Footer links */}
          <Flex gap="7" justify="center" width="100%">
            <Link size="1" weight="regular" color="gray" href="https://b-connect.co.uk/terms" target='_blank'>
              Terms of Service
            </Link>
            <Link size="1" weight="regular" color="gray" href="https://b-connect.co.uk/privacy" target='_blank'>
              Privacy Notice
            </Link>
            <Link size="1" weight="regular" color="gray" href="mailto:support@b-connect.co.uk">Support</Link>
          </Flex>
        </Flex>
        {/* Background Image and  Logo Section */}
        <Flex direction="column" align="stretch" style={{ padding: '80px 120px 0 120px', 
          background: isImageLoaded
          ? `url(${organisationImageUrl}) no-repeat center center / cover`
          : 'var(--color-background)',
          transition: 'background 0.3s ease-in-out',
          backgroundBlendMode: 'soft-light', flex: '1 1 50%', alignItems: 'center'}}>
          <Logo icon="LogoBWifi" width="40px" height="32px" color="white"/>
        </Flex>
      </Flex>
    </React.Fragment>
  );
}

export default function FirstTimeLogin() {
  return (
    <ThemeProvider>
      <FirstTimeLoginComponent />
    </ThemeProvider>
  );
}