import React, { useState, lazy, Suspense } from 'react';
import { Flex, Text, Card, Button, Badge, IconButton, DropdownMenu, Separator, TextField, Select } from '@radix-ui/themes';
import { DotsHorizontalIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { useLocationContext } from 'context';
import { useFetchLocationType, useEditOrgLocation } from 'hooks';
import { useToast } from '../../context/ToastContext';
import { ToastAlert } from "components";

const CreateLocationModal = lazy(() => import('./CreateLocationModal'));

const OrganisationLocations = () => {
  const { setToastMessage } = useToast();
  // Fetching all the locations info for the organisation
  const { locationsData, refetchLocations } = useLocationContext();
  // Fetching all the location types
  const { data: locationTypes } = useFetchLocationType();

  // Sorting locations by name
  const sortedLocations = Array.isArray(locationsData) 
    ? [...locationsData].sort((a, b) => 
        (a?.name || '').toLowerCase().localeCompare((b?.name || '').toLowerCase())
      )
    : [];
  
  // Hook to update location details
  const updateLocation = useEditOrgLocation();
  const [errors, setErrors] = useState({});
 
  // For the dropdown menu
  const [dropdownStates, setDropdownStates] = useState({});
  const handleShowDropdown = (locationId) => () => {
    setDropdownStates(prevStates => ({
      ...prevStates,
      [locationId]: !prevStates[locationId]
    }));
  };

  const [modifiedLocations, setModifiedLocations] = useState({});

  const handleFieldChange = (locationId, field, value) => {
    if (errors[locationId]?.[field]) {
      setErrors({
        ...errors,
        [locationId]: {
          ...errors[locationId],
          [field]: undefined
        }
      });
    }
    setModifiedLocations(prevLocations => ({
      ...prevLocations,
      [locationId]: {
        ...prevLocations[locationId],
        [field]: value
      }
    }));
  };

  const handleSaveChanges = (locationId) => {

    const currentName = modifiedLocations[locationId]?.name ?? 
      sortedLocations.find(loc => loc.id === locationId).name;
  
    const currentAddress = modifiedLocations[locationId]?.address ?? 
      sortedLocations.find(loc => loc.id === locationId).address;

    // Validate fields
    const locationErrors = {};
    if (!currentName || currentName.trim() === '') {
      locationErrors.name = 'Input location name.';
    }
    
    if (!currentAddress || currentAddress.trim() === '') {
      locationErrors.address = 'Input location address.';
    }

    if (Object.keys(locationErrors).length > 0) {
      setErrors({...errors,[locationId]: locationErrors});
      return;
    }

    updateLocation.mutate({
      id: locationId,
      name: modifiedLocations[locationId]?.name,
      address: modifiedLocations[locationId]?.address,
      location_type: locationTypes.data.find(type => type.name === modifiedLocations[locationId]?.location_type)?.id
    },
    {
      onSuccess: () => {
        setToastMessage('Changes saved');
        setModifiedLocations({});
        setErrors({});
        refetchLocations();
      }
    });
  };
  
  return (
    <React.Fragment>
      <Flex direction="column" gap="5" height="100%" style={{alignSelf: 'stretch', flex: '1 0 0'}}>
          {/* Location Details Section */}
          <Flex className="location-details-container" direction="column" gap="5"
            style={{alignItems: 'flex-start', flex: '1 0 0',
            alignSelf: 'stretch', overflow: 'scroll', maxHeight: 'calc(100vh - 184px)'}}>
            { sortedLocations.length === 0 && (
              <Flex direction="column" style={{justifyContent: 'center', alignItems: 'center', alignSelf: 'stretch',
                border: '1px dashed var(--gray-a6)', borderRadius: 'var(--radius-4)', height: '100%'}}>
                <Flex direction="column" gap="5" maxWidth="296px" style={{alignItems: 'center', width: '100%'}}>
                  <Flex gap="2" direction="column" style={{alignItems: 'center', alignSelf: 'stretch', 
                    justifyContent: 'center'}}>
                    <Text size="4" weight="medium" align="center">No locations</Text>
                    <Text size="2" weight="regular" align="center" color='gray'>
                      Create your first location to start activating your integrations.
                    </Text>
                  </Flex>
                  <Suspense>
                    <CreateLocationModal
                      buttontype="soft"
                    />
                  </Suspense>
                </Flex>
              </Flex>
            )}
            { sortedLocations && sortedLocations.map((location) => (
              <Card key={location.id} style={{display: 'flex', flexDirection: 'column', flex: '0 0 auto',
                alignItems: 'flex-start', alignSelf: 'stretch', gap: 'var(--space-4)', padding: 'var(--space-4)'}}>
                {/* Locations Header Section */}
                <Flex style={{alignSelf: 'stretch', alignItems: 'center', justifyContent: 'space-between'}}>
                  {/* Location Name & Address */}
                  <Flex direction="column" gap="1" style={{alignSelf: 'stretch', alignItems: 'flex-start', width: '30%'}}>
                    <Text size="4" weight="medium">{location.name}</Text>
                    <Text size="1" weight="medium" color="gray" style={{textOverflow: 'ellipsis', overflow: 'hidden', 
                      whiteSpace: 'nowrap', width: '100%', paddingRight: '4px'}}>
                      {location.address}
                    </Text>
                  </Flex>
                  {/* Information Badges */}
                  <Flex style={{alignSelf: 'stretch', alignItems: 'center', justifyContent: 'space-between', width: '70%' }}>
                    <Badge size="1" variant="soft" color="gray">{(location.access_point_mac_list).length}&nbsp;
                      {(location.access_point_mac_list).length === 1 ? 'Wi-Fi access point' : 'Wi-Fi access points'}
                    </Badge>
                    <Badge size="1" variant="soft" color="gray">{(location.integrations).length}&nbsp;
                      {(location.integrations).length === 1 ? 'integration' : 'integrations'}
                    </Badge>
                    <Text size="1" weight="regular" color="gray">{location.location_type_name}</Text>
                    <Text size="1" weight="regular" color="gray">{location.user_manager_count}&nbsp;
                      {(location.user_manager_count) === 1 ? 'manager' : 'managers'}
                    </Text>
                    {/* Possible Actions */}
                    <Flex p="1" gap="4" style={{alignItems: 'center'}}>
                        {/* Ellipsis Icon for Deleting Location */}
                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger asChild>
                            <IconButton size="1" variant="ghost" color="gray" style={{cursor: 'pointer'}}>
                              <DotsHorizontalIcon width='16' height='16' />
                            </IconButton>
                          </DropdownMenu.Trigger>
                          <DropdownMenu.Content>
                            <DropdownMenu.Item color='red'
                              style={{cursor: 'pointer'}}
                              onClick={() => {
                                const supportEmail = 'support@b-connect.co.uk';
                                const subject = 'Delete Account Request';
                                const body = `Hello, I need help with deleting this location ${location.name}(id: ${location.id}). Please assist me with this process.`;
                                const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                                window.location.href = mailtoLink; 
                              }}>
                              Delete
                            </DropdownMenu.Item>
                          </DropdownMenu.Content>
                        </DropdownMenu.Root>
                        {/* Arrow Icon for Editing Location Details */}
                        <IconButton size="1" variant="ghost" color="gray"
                          onClick={handleShowDropdown(location.id)}
                          style={{cursor: 'pointer'}}>
                          {dropdownStates[location.id]
                            ? <ChevronUpIcon width='16' height='16' />
                            : <ChevronDownIcon width='16' height='16' />
                          }
                        </IconButton>
                    </Flex>
                  </Flex>
                </Flex>
                {/* Location Details Section */}
                {dropdownStates[location.id] && 
                (
                <Flex direction="column" gap="4" style={{alignSelf: 'stretch', alignItems: 'flex-start'}}>
                  <Separator size="4" orientation="horizontal"/>
                  {/* Location Details */}
                  <Flex gap="4" style={{alignItems: 'flex-start', alignSelf:'stretch'}}>
                    <Flex direction="column" gap="2" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '40%'}}>
                      <Text size="2" weight="medium">Details</Text>
                      <Text size="1" weight="regular" color="gray">
                        Share your location name and address to enhance your integrations.
                      </Text>
                    </Flex>
                    <Flex direction="column" gap="4" style={{alignItems: 'flex-end', alignSelf: 'stretch', width: '60%', flex: '1 0 0'}}>
                      {/* Name and Type Fields */}
                      <Flex direction="row" gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch', justifyContent: 'center'}}>
                        {/* Name Field */}
                        <Flex direction="column" gap="1" style={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                          <Text size="2" weight="medium" style={{width: '100%'}}>Name</Text>
                          <TextField.Root size="2" style={{width: '100%'}}
                            value={modifiedLocations[location.id]?.name ?? location.name}
                            onChange={(e) => handleFieldChange(location.id, 'name', e.target.value)}
                          />
                          {errors[location.id]?.name && (<Text size="1" color="red">{errors[location.id].name}</Text>)}
                        </Flex>
                        {/* Type Select Field */}
                        <Flex direction="column" gap="1" style={{alignItems: 'center', alignSelf: 'stretch', width: '100%'}}>
                          <Text size="2" weight="medium" style={{width: '100%'}}>Type</Text>
                          <Select.Root style={{width: '100%'}}
                            defaultValue={location.location_type_name}
                            onValueChange={(value) => handleFieldChange(location.id, 'location_type', value)}
                          >
                            <Select.Trigger varinat="surface" style={{width: '100%', cursor: 'pointer'}}/>
                            <Select.Content position="popper" style={{cursor: 'pointer'}}>
                              <Select.Group>
                                {locationTypes.data.map((type, index) => (
                                  <Select.Item key={index} value={type.name} style={{cursor: 'pointer'}}>
                                    {type.name}
                                  </Select.Item>
                                ))}
                              </Select.Group>
                            </Select.Content>
                          </Select.Root>
                        </Flex>
                      </Flex>
                      {/* Address Field */}
                      <Flex direction="column" gap="1" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                        <Text size="2" weight="medium" style={{width: '100%'}}>Address</Text>
                        <TextField.Root size="2" style={{width: '100%'}}
                          value={modifiedLocations[location.id]?.address ?? location.address}
                          onChange={(e) => handleFieldChange(location.id, 'address', e.target.value)}
                        />
                        {errors[location.id]?.address && (<Text size="1" color="red">{errors[location.id].address}</Text>)}
                      </Flex>
                      {/* Save Changes Button */}
                      <Button size="2" variant='solid' 
                        style={{cursor: modifiedLocations[location.id] ? 'pointer' : 'not-allowed'}}
                        disabled={
                          !modifiedLocations[location.id] ||
                          ((modifiedLocations[location.id]?.name ?? location.name) === location.name &&
                           (modifiedLocations[location.id]?.location_type ?? location.location_type) === location.location_type &&
                           (modifiedLocations[location.id]?.address ?? location.address) === location.address)
                        }
                        onClick={() => handleSaveChanges(location.id)}
                      >
                        Save changes
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
                )}
              </Card>
            ))}
          </Flex>
      </Flex>
      {/* Toast Updates */}
      <ToastAlert />
    </React.Fragment>
  );
}

export default OrganisationLocations;
