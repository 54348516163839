// hooks/useDateRange.js
import { createContext, useContext, useEffect, useState } from 'react';
import { differenceInDays, subDays, startOfDay, endOfDay } from 'date-fns';

const DateRangeContext = createContext();

export function DateRangeProvider({ children }) {
  const [selectedDateRange, setSelectedDateRange] = useState({
    from: startOfDay(subDays(new Date(), 7)),
    to: endOfDay(new Date()),
  });
  const [dateRangeDifference, setDateRangeDifference] = useState(7);

  useEffect(() => {
    const changedDateRangeDifference = differenceInDays(selectedDateRange.to, selectedDateRange.from) + 1;
    setDateRangeDifference(changedDateRangeDifference);
  }, [selectedDateRange]);

  return (
    <DateRangeContext.Provider value={{ selectedDateRange, setSelectedDateRange, dateRangeDifference }}>
      {children}
    </DateRangeContext.Provider>
  );
};

export function useDateRangeContext() {
  return useContext(DateRangeContext);
}