import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { attemptGetPresenceMetrics } from "../../api/ciscoMerakiApi";
import { toast } from "react-toastify";

const fetchPresenceMetrics = async (data) => {
  return attemptGetPresenceMetrics(data).then((res) => res.data);
};

export const usePresenceMetrics = (selectedDateRange, locationSelection, zoneSelection, isLocationsSuccess) => {
  const [presenceDataPayload, setPresenceDataPayload] = useState(null);

  useEffect(() => {
    if (locationSelection && locationSelection.length > 0) {
      const payload = {
        start_time: selectedDateRange.from?.valueOf(),
        end_time: selectedDateRange.to?.valueOf(),
        location_ids: locationSelection,
        zone_ids: zoneSelection,
      };
      setPresenceDataPayload(payload);
    } else {
      setPresenceDataPayload(null);
    }
  }, [selectedDateRange, locationSelection, zoneSelection]);

  const { data: presenceMetrics, isLoading } = useQuery({
    queryKey: ["presence-metrics", presenceDataPayload],
    queryFn: () => fetchPresenceMetrics(presenceDataPayload),
    enabled: isLocationsSuccess && presenceDataPayload !== null,
    onError: (error) => {
      toast.error(`Error fetching presence metrics: ${error.message}`);
    },
  });

  return {
    presenceMetrics,
    isLoading,
  };
};
