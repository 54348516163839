import * as React from "react";
import { Text, Flex, Button, Checkbox, CheckboxGroup, Popover } from "@radix-ui/themes";
import { ChevronDownIcon } from "@radix-ui/react-icons";

export function MultiSelect({ options, onValueChange, defaultValue, placeholder }) {
  const [selectedValues, setSelectedValues] = React.useState(defaultValue);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [display, setDisplay] = React.useState(placeholder);
  const [topCheckBoxBool, setTopCheckBoxBool] = React.useState(true);

  React.useEffect(() => {
    updateDisplay(defaultValue);
  }, []);

  const updateDisplay = (newSelectedValues) => {
    const isCustomerLoyalty = placeholder.toLowerCase() === "customer loyalty";
    const isAge = placeholder.toLowerCase() === "age";
    const isGender = placeholder.toLowerCase() === "gender";
    const isEmailMarketing = placeholder.toLowerCase() === "email marketing";

    if (newSelectedValues.length === 0) {
      // Custom placeholders for empty selection
      setDisplay(`Select ${placeholder.toLowerCase()}${isAge || isGender ? " groups" : ""}`);
    } else if (isEmailMarketing) {
      // Special case for email marketing
      if (newSelectedValues.length === options.length) {
        setDisplay(placeholder);
      } else if (newSelectedValues.length === 1) {
        const selectedOption = options.find((option) => option.value === newSelectedValues[0]);
        setDisplay(selectedOption?.label || "");
      } else {
        setDisplay(
          <Flex align="center" gap="1">
            {newSelectedValues.length} {placeholder.toLowerCase()}
          </Flex>
        );
      }
    } else if (newSelectedValues.length === options.length) {
      setDisplay(placeholder);
    } else if (isCustomerLoyalty) {
      setDisplay(newSelectedValues.length === options.length ? placeholder : newSelectedValues.map((value) => options.find((option) => option.value === value)?.label).join(" & "));
    } else if (isAge || isGender) {
      setDisplay(`${newSelectedValues.length} ${placeholder.toLowerCase()} ${newSelectedValues.length === 1 ? "group" : "groups"}`);
    } else {
      setDisplay(
        <Flex align="center" gap="1">
          {newSelectedValues.length} {placeholder.toLowerCase()}
        </Flex>
      );
    }

    setTopCheckBoxBool(newSelectedValues.length === options.length);
  };

  const handleClear = () => {
    const newValues = [];
    setSelectedValues(newValues);
    onValueChange(newValues);
    updateDisplay(newValues);
    setTopCheckBoxBool(false);
  };

  const toggleAll = () => {
    // If all items are currently selected, clear selection
    if (selectedValues.length === options.length) {
      handleClear();
      setTopCheckBoxBool(false);
    }
    // Otherwise select all items
    else {
      const allValues = options.map((option) => option.value);
      setSelectedValues(allValues);
      onValueChange(allValues);
      updateDisplay(allValues);
      setTopCheckBoxBool(true);
    }
  };

  const handleValueChange = (newValues) => {
    setSelectedValues(newValues);
    onValueChange(newValues);
    updateDisplay(newValues);
    setTopCheckBoxBool(newValues.length === options.length);
  };

  return (
    <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <Popover.Trigger asChild>
        <Button style={{ cursor: "pointer" }} size="2" variant="soft" color={selectedValues.length === 0 ? "red" : "gray"} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
          <Text color="gray" weight="medium">
            {display}
          </Text>
          <ChevronDownIcon style={{ color: "var(--gray-11)" }} />
        </Button>
      </Popover.Trigger>

      <Popover.Content style={{ width: "200px", padding: "0" }}>
        <Flex direction="column" gap="1">
          <Flex p="2" align="center" onClick={toggleAll}>
            <Checkbox size="2" checked={topCheckBoxBool} onCheckedChange={toggleAll} />
            <Text
              size="2"
              style={{
                marginLeft: "8px",
                userSelect: "none",
              }}
            >
              Select All
            </Text>
          </Flex>
          <CheckboxGroup.Root size="2" value={selectedValues} onValueChange={handleValueChange}>
            {options.map((option) => (
              <Flex key={option.value} p="2">
                <CheckboxGroup.Item value={option.value}>{option.label}</CheckboxGroup.Item>
              </Flex>
            ))}
          </CheckboxGroup.Root>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
}
