import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { DateTime } from "luxon";
import { Badge } from "@radix-ui/themes";
import { useTheme } from "../../../context/ThemeContext";
import "../ChartColours.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const getCSSVariable = (variable) => getComputedStyle(document.documentElement).getPropertyValue(variable).trim();

export const DwellByDayOfWeekChart = ({ data, startTimeMilliseconds, endTimeMilliseconds }) => {
  const { appearance } = useTheme();
  const [fontColor, setFontColor] = useState("");
  const [gridColour, setGridColour] = useState("");
  const [chartPointsBgColour, setChartPointsBgColour] = useState("");
  const [chartContainerRef, setChartContainerRef] = useState(null);
  const [badgePosition, setBadgePosition] = useState({ left: 0, top: 0, display: "none" });
  const [chartDwellColour, setChartDwellColour] = useState("");
  const [chartDwellFillColour, setChartDwellFillColour] = useState("");

  useEffect(() => {
    const fontColourLight = getCSSVariable("--chart-font-colour-light");
    const fontColourDark = getCSSVariable("--chart-font-colour-dark");
    const gridColourLight = getCSSVariable("--chart-grid-colour-light");
    const gridColourDark = getCSSVariable("--chart-grid-colour-dark");
    const chartPointsBgColourLight = getCSSVariable("--chart-points-bg-colour-light");
    const chartPointsBgColourDark = getCSSVariable("--chart-points-bg-colour-dark");
    const dwellColour = getCSSVariable("--chart-guest-border-colour");
    const dwellFillColour = getCSSVariable("--chart-guest-fill-colour");
    const dwellFillColourLight = getCSSVariable("--chart-guest-fill-colour-light");
    const dwellColourLight = getCSSVariable("--chart-guest-border-colour-light");

    if (appearance === "dark") {
      setFontColor(fontColourDark);
      setGridColour(gridColourDark);
      setChartPointsBgColour(chartPointsBgColourDark);
      setChartDwellFillColour(dwellFillColour);
      setChartDwellColour(dwellColour);
    } else {
      setFontColor(fontColourLight);
      setGridColour(gridColourLight);
      setChartPointsBgColour(chartPointsBgColourLight);
      setChartDwellFillColour(dwellFillColourLight);
      setChartDwellColour(dwellColourLight);
    }
  }, [appearance]);

  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const today = new Date().getDay();
  const adjustedToday = today === 0 ? 6 : today - 1;
  const currentDayLabel = daysOfWeek[adjustedToday];

  const isCurrentDayInRange = () => {
    const endDate = DateTime.fromMillis(endTimeMilliseconds);
    const now = DateTime.now();
    return now.hasSame(endDate, "day");
  };

  // Process the data for the chart
  let processed_chart_data = Array.from({ length: 7 }, (_, day) => ({
    day,
    dwell: 0,
  }));

  if (data?.data?.chart_data?.day_of_week) {
    data.data.chart_data.day_of_week.forEach((entry) => {
      const dayIndex = daysOfWeek.indexOf(entry.time_period.substring(0, 3));
      if (dayIndex !== -1) {
        processed_chart_data[dayIndex] = {
          day: dayIndex,
          dwell: entry.avg_dwell,
        };
      }
    });
  }

  const chartData = {
    labels: daysOfWeek.map((day, index) => (index === adjustedToday && isCurrentDayInRange() ? "" : day)),
    datasets: [
      {
        label: "Avg. guest dwell",
        data: processed_chart_data.map((entry) => entry.dwell),
        backgroundColor: chartDwellFillColour,
        borderColor: chartDwellColour,
        borderWidth: 1,
        borderRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      onComplete: function (animation) {
        if (chartContainerRef) {
          const chart = animation.chart;
          const xAxis = chart.scales.x;
          const currentDayX = xAxis.getPixelForValue(adjustedToday);
          const currentDayY = xAxis.top + xAxis.options.ticks.padding;

          setBadgePosition({
            left: currentDayX,
            top: currentDayY - 6,
            display: isCurrentDayInRange() ? "block" : "none",
          });
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        usePointStyle: true,
        boxWidth: 6,
        callbacks: {
          title: function (tooltipItems) {
            return tooltipItems[0].label || currentDayLabel;
          },
          label: function (context) {
            return `${context.dataset.label}: ${context.parsed.y.toFixed(1)} mins`;
          },
          labelColor: function (context) {
            return {
              borderColor: context.dataset.backgroundColor,
              backgroundColor: context.dataset.backgroundColor,
            };
          },
          labelPointStyle: function (context) {
            return {
              pointStyle: "circle",
              rotation: 0,
            };
          },
        },
        bodyFont: {
          family: "Figtree",
        },
        titleFont: {
          family: "Figtree",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: fontColor,
          font: {
            family: "Figtree",
            size: 12,
          },
          padding: 8,
          maxRotation: 0,
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        },
      },
      y: {
        beginAtZero: true,
        position: "right",
        ticks: {
          color: fontColor,
          font: {
            family: "Figtree",
            size: 12,
          },
          padding: 8,
          stepSize: 1, // Force integer steps
          callback: function (value) {
            if (Number.isInteger(value)) {
              return `${value} mins`;
            }
            return null; // Hide non-integer ticks
          },
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        },
      },
    },
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }} ref={setChartContainerRef}>
      <Bar data={chartData} options={options} style={{ width: "100%", height: "100%" }} />
      <div
        style={{
          position: "absolute",
          left: `${badgePosition.left}px`,
          top: `${badgePosition.top}px`,
          transform: "translate(-50%, 0)",
          display: badgePosition.display,
          pointerEvents: "none",
          zIndex: 10,
        }}
      >
        <Badge variant="soft" size="1">
          {currentDayLabel}
        </Badge>
      </div>
    </div>
  );
};
