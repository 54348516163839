import instance from "./BasebAirshipApi";

export function validateAirshipCredentials(data) {
  return instance.request({
    url: `validate-credentials/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function getAirshipUnits(organisationId) {
  return instance.request({
    url: `units/?organisation_id=${organisationId}`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function saveAirshipMappings(data) {
  return instance.request({
    url: `save-mappings/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function getAirshipStatus(organisationId) {
  return instance.request({
    url: `status/?organisation_id=${organisationId}`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getAirshipMappings(organisationId) {
  return instance.request({
    url: `mappings/?organisation_id=${organisationId}`,
    method: "GET",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function deactivateAirship(data) {
  return instance.request({
    url: `deactivate/`,
    method: "POST",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  });
}
