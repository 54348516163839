// hooks/useLiveConnectionData.js
import { useState, useEffect } from 'react';
import { useGetLiveConnectionStats } from '../app/useGetLiveConnectionStats';

export const useLiveConnectionStats = (locationSelection) => {
  const [liveConnectionPayload, setLiveConnectionPayload] = useState({});
  const [liveConnectionStat, setLiveConnectionStat] = useState({
    title: "Guests connected",
    value: 0,
  });

  useEffect(() => {
    if (locationSelection.length > 0) {
      // Prepare payload for fetching live connection stats
      setLiveConnectionPayload({
        timestamp: Date.now(),
        request_type: "live-connections-metrics",
        payload: {
          location_ids: locationSelection,
        },
      });
    }
  }, [locationSelection]);

  const { data: liveConnectionStats } = useGetLiveConnectionStats(liveConnectionPayload);

  useEffect(() => {
    if (liveConnectionStats) {
      const liveData = liveConnectionStats.data.data;
      setLiveConnectionStat(
        {
          title: "Guests connected",
          value: liveData.live_connections,
        }
      );
    }
  }, [liveConnectionStats]);

  // Update live stats every 30 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setLiveConnectionPayload(prevPayload => ({
        ...prevPayload,
        timestamp: Date.now(),
      }));
    }, 30000);

    return () => clearInterval(intervalId);
  }, [locationSelection]);

  return {
    liveConnectionStat,
  };
};