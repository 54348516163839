import { useState, useEffect, createContext, useContext } from 'react';
import { useProfile } from "hooks";

const ProfileContext = createContext();

export function ProfileProvider({ children }) {

  const { data: profile } = useProfile();

  const [userProfile, setUserProfile] = useState({
    user_id: null,
    organisation_id: null,
    organisation_name: null,
    organisation_image_url: null,
    add_bconnect_ap_state: null,
    user_role: null,
    user_first_name: null,
    user_last_name: null,
    user_email: null
  });

  useEffect(() => {
    if (profile) {
      setUserProfile({
        user_id: profile.id,
        organisation_id: profile.organisation_id,
        organisation_name: profile.organisation_name,
        organisation_image_url: profile.organisation_image_url,
        add_bconnect_ap_state: profile.add_bconnect_ap,
        user_role: profile.user_role,
        user_first_name: profile.first_name,
        user_last_name: profile.last_name,
        user_email: profile.email
      });
    }
  }, [profile]);

  return ( 
    <ProfileContext.Provider value={{ userProfile }}>
      {children}
    </ProfileContext.Provider>
  );
}

export function useProfileContext() {
  return useContext(ProfileContext);
}